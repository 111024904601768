import React from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col, Spinner } from 'react-bootstrap';

function LoadingSpinner(props) {
    return (
        <>
            <Container className="text-center">
                <Spinner className="m-5" animation="border" role="status" variant="primary">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </Container>
        </>
    )
}

export default withRouter(LoadingSpinner);

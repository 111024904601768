import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import "./login.css";
import { connect } from "react-redux";
import { beginUserSession } from "../redux/actions/userSession";
import { createHeroApiClient,  EngagementEvent } from '../hero-api-client';
import dataStores from "../redux/reducers/dataStores";
import HeroOutlineSubmit from "../common/Buttons/OutlineSubmit";
import { sendCommand } from "../redux/actions/commands";
import MicrosoftLogin from "react-microsoft-login";
import CryptoJS from "crypto-js";

function ForgotPassword(props) {
    const e = new URLSearchParams(document.location.search).get('E');
    const [model, setModel] = useState({
        Email:"",
        Token: "",
        Password:"",
        ConfirmPassword:""
      });
      model.Email = e;
      const [errorMessage, setErrorMessage] = useState("");

      function handleInputChange(event) {
        setModel({
          ...model,
          [event.target.name]: event.target.value
         });
      }
function handleFormSubmit(event) {
    event.preventDefault();
    var x = Math.random().toString(36).substr(2, 6);
    var keyx = '?D(G+KbPeS' + x;
    var key = CryptoJS.enc.Utf8.parse(keyx);
    var iv = CryptoJS.enc.Utf8.parse(keyx);
    var settings = {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    };
    var pswd = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(model.Password), key, settings).toString();
    var pswdC = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(model.ConfirmPassword), key, settings).toString();
    createHeroApiClient().resetPassword(model.Email,model.Token,pswd,pswdC,x)
      .then(tokenResponse => {
        setErrorMessage('')
        window.location = '/Login'
      })
      .catch(() => setErrorMessage('Error'));
  }
    return (
        <div>
        <div className="login">
          <div
            style={{
              backgroundRepeat: "no-repeat",
              background: `url(https://hero-wellbeing.s3-eu-west-1.amazonaws.com/hero_home.jpg)`,
              position: "relative"
            }}
            className="login__companyImg"
          >
          </div>
  
          <div
            className="login__loginWindow"
            style={{}}
          >
            <img
              src='https://a.storyblok.com/f/85463/993x559/eb9a813596/hero-logo-transparent-white.png'
              className="login__heroLogo"
            ></img>
  
            <form className="loginWindow__form" onSubmit={handleFormSubmit}>
                <input
                className="form__input"
                name="Email"
                type="text"
                placeholder="Your Email Address"
                Value={model.Email}
                defaultValue= {e}
                onChange={handleInputChange}
                required
              />
              <input
                className="form__input"
                name="Token"
                type="text"
                placeholder="Enter the code we sent you"
                Value={model.Token}
                onChange={handleInputChange}
                required
              />
              <input
                  className="signUp__input"
                  name="Password"
                  type="Password"
                  placeholder="Password"
                  value={model.Password}
                  onChange={handleInputChange}
                />

            <Row>
              <Col className="signUp__col" xs="2"></Col>
              <Col className="signUp__col" xs="7">
                <ul class="pass-val-checker">
                  <li>at least 11 characters</li>
                  <li>at least one uppercase character</li>
                  <li>at least one lowercase character</li>
                  <li>at least one numeric character</li>
                  <li>at least one special character</li>
                </ul>
              </Col>
            </Row>
            
              <input
                  className="signUp__input"
                  name="ConfirmPassword"
                  type="Password"
                  placeholder="Confirm Password"
                  value={model.ConfirmPassword}
                  onChange={handleInputChange}
                />
  
              <HeroOutlineSubmit
                size="xlarge"
                value="Reset Password"
                onClick={handleFormSubmit}
                colour={'white'}
              />
              </form>
              </div>
              </div>
              <p style={{marginTop: "2rem", color: "rgb(255, 255, 255)"}}>{errorMessage}</p>
              </div>
              
        );
}

function mapStateToProps(state) {
    return {
      appConfig: (state.appObject[dataStores.APP_CONFIG] || {}),
      redirectPath: '/dashboard',
      isAuthenticated: state.user.session.isAuthenticated
    };
  }

export default connect(mapStateToProps)(ForgotPassword);
import { REQUEST_DATA_PROTECTION, RECEIVE_DATA_PROTECTION, INVALIDATE_DATA_PROTECTION } from '../actions/dataProtection'
import { END_USER_SESSION } from '../actions/userSession'

const initialState = {
    isFetching: false,
    isValid: false,
    value: null
}

export default function dataProtection(state = initialState, action) {
    switch (action.type) {
        case REQUEST_DATA_PROTECTION:
            return {
                ...state,
                isFetching: true,
                isValid: true
            }
        case RECEIVE_DATA_PROTECTION:
            return {
                ...state,
                isFetching: false,
                isValid: true,
                value: action.payload.value,
                lastUpdated: action.payload.receivedAt
            }
        case INVALIDATE_DATA_PROTECTION:
            return {
                ...state,
                isValid: false
            }
        case END_USER_SESSION:
            return initialState
        default:
            return state
    }
}
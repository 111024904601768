import React, {
    useEffect,
    useReducer,
    createContext,
    Dispatch,
    useRef,
    useState,
  } from 'react';
  import {Row} from 'react-bootstrap'
  import '../../pages/chat.scss'
  import {ChatBubbleContainer, ChatBubble, Input,PrimaryButton,InfiniteList} from 'amazon-chime-sdk-component-library-react';
  import {PaperAirplaneIcon} from '@heroicons/react/24/solid';
import { left } from 'styled-system';
  export const DEFAULT_WEB_SOCKET_TIMEOUT_MS = 10000;
  var wsStabilizer;
  var meetingSocket;
  function MeetingMessagingService({messages,sendChatMessage, marginBottom}) {
    const [chatMessage,setChatMessage] = useState('');
    const listRef = useRef(null);
    const sendMessage = (message) => {
      setChatMessage('');
      if(message){
        sendChatMessage(message);
      }
      
    }
    useEffect(()=>{
      listRef.current.scrollTop = listRef.current.scrollHeight;
    },[messages])
    function urlify(text) {
  var urlRegex = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g;
  return text.split(' ').map(url => {
    if(urlRegex.test(url)){
      var linkUrl = url
      if(!url.startsWith('http')){
        linkUrl='https://' + url;
      }
      return <a href={linkUrl} target="_blank" >{url + ' '}</a>;
    }
    return <p style={{display:'inline'}}>{url + ' '}</p>
  })
}
   
    return(
        <div>
        <div ref={listRef} className='videoHeightScroll' style={{padding:30}}>
          {messages.map((message,index,messages)=>{
            return index>0 && message.sender === messages[index-1].sender &&  message.timestamp === messages[index-1].timestamp?(<div className='videoChatMessage'>{urlify(message.payload)}</div>):
            (<><p className='videoChatName'>
              {message.sender} - {message.timestamp}
            </p>
            <div className='videoChatMessage'>
              {urlify(message.payload)}
            </div>
              </>
            )
          //   return(<ChatBubbleContainer timestamp={message.timestamp} style={{width:'90%'}}>
          //           <ChatBubble senderName={message.sender} showTail={false} variant='incoming'>
          //             {urlify(message.payload)}
          //             </ChatBubble>
          //         </ChatBubbleContainer>)
           })}
        </div>
        <div className={'flexCentered'} style={{height:50,position:'absolute', right:20,bottom:20}}>
          <Input
            onChange={(e) => setChatMessage(e.target.value)}
            value={chatMessage}
            placeholder="Type Message..."
            //sizing={'md'}
            onKeyDown={(event)=>{if(event.key==='Enter' && chatMessage){sendMessage(chatMessage)}}}
            type="text" 
          ></Input>
          <PaperAirplaneIcon onClick={()=>{sendMessage(chatMessage)}} color={'grey'} style={{width:20,marginLeft:5}}/>
        </div>
      </div>
    )

  }
  
  export default MeetingMessagingService;
import { reject } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import LinkBox from "../LinkBox";
import AllUsersICoach from "./AllUsersICoach";
import "./NewMeeting.scss";
import UserSearch from "./UserSearch";
import { meetingType } from "./YourCoaches";
import { MinusCircleIcon } from "@heroicons/react/24/solid";

const MeetingCard = ({
  meeting,
  remove,
  heroApiClient,
  setMeetingId,
  availabilityEvents,
  users,
  currentUserId,
}) => {
  const [role, setRole] = useState("");
  const [cancel, setCancel] = useState(false);
  const [timeUntil, setTimeUntil] = useState(null);
  const twoHours = 2 * 60 * 60 * 1000;

  const cancelMeeting = () => {
    heroApiClient.cancelMeeting(meeting.meetingId).then(() => {
      remove(true);
    });
  };
  useEffect(() => {
    if (meeting && meeting.attendeeInfo && currentUserId) {
      setRole(
        meeting.attendeeInfo.filter((x) => x.userId == currentUserId)[0]
          .attendeeType
      );
    }

    if (meeting && meeting.start) {
      setTimeUntil(moment(meeting.start).diff(moment()));
    }
  }, [meeting]);

  return (
    <>
      {meeting && (
        <Modal show={meeting}>
          <div className="meeting-card">
            {meeting.type === meetingType.meeting ? (
              <>
                <div className="modal-header">
                  <button
                    onClick={() => setMeetingId(meeting.meetingId)}
                    type="submit"
                    disabled={timeUntil > twoHours}
                    style={{
                      backgroundColor: timeUntil > twoHours ? "grey" : null,
                    }}
                  >
                    {timeUntil > twoHours ? (
                      <>Starts in {moment().to(meeting.start, true)}</>
                    ) : timeUntil > 60 * 1000 * 10 ? (
                      <>Start {moment().to(meeting.start, true)} early</>
                    ) : (
                      <>Start Session</>
                    )}
                  </button>
                  {(role === 1 || role === 3) && (
                    <button onClick={() => setCancel(true)}>
                      Cancel this Session
                    </button>
                  )}

                  <Modal show={cancel}>
                    <div className="meeting-card">
                      <div className="modal-header">
                        <h3 className="meeting-card-title">Cancel Session</h3>
                      </div>
                      <div className="meeting-card-title textCentered">
                        Are you sure?
                      </div>
                      <div className="flexCentered">
                        <button type="submit" onClick={cancelMeeting}>
                          Yes
                        </button>
                        <a onClick={() => setCancel(false)}>No</a>
                      </div>
                    </div>
                  </Modal>
                  <button onClick={remove}>X</button>
                </div>
                <h3 className="meeting-card-title">{meeting.title}</h3>
                <div className="meeting-card-time">
                  {new Date(meeting.dateTime).toLocaleString("en-GB", {
                    day: "numeric",
                    month: "long",
                    hour: "2-digit",
                    minute: "2-digit",
                    year: "numeric",
                  })}{" "}
                  -{" "}
                  {new Date(meeting.meetingEnd).toLocaleString("en-GB", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </div>
                <p className="meeting-card-title">Meeting Link</p>
                <LinkBox
                  link={`https://app.herowellbeing.com/meetings/${meeting.meetingId}`}
                ></LinkBox>
                <AttendeesList Attendees={meeting.attendeeInfo} />
              </>
            ) : meeting.type === meetingType.availability ? (
              <AvailabilityCard
                heroApiClient={heroApiClient}
                meeting={meeting}
                refresh={() => remove(true)}
              />
            ) : (
              <CancelCard
                heroApiClient={heroApiClient}
                meeting={meeting}
                refresh={() => remove(true)}
                events={availabilityEvents}
              />
            )}
          </div>
        </Modal>
      )}
    </>
  );
  function AttendeesList({ Attendees }) {
    const [attendees, setAttendees] = useState([]);
    const [guests, setGuests] = useState([]);
    const [newAttendees, setNewAttendees] = useState([]);
    const [showSave, setShowSave] = useState(false);

    const makeAdmin = (attendee) => {
      var newAttendees = meeting.attendeeInfo.map((x) => {
        if (x.userId === attendee.userId) {
          return { ...x, attendeeType: 1 };
        } else {
          return x;
        }
      });
      setAttendees(newAttendees);
      setShowSave(true);
    };

    const removeAdmin = (attendee) => {
      var newAttendees = meeting.attendeeInfo.map((x) => {
        if (x.userId === attendee.userId) {
          return { ...x, attendeeType: 0 };
        } else {
          return x;
        }
      });
      setAttendees(newAttendees);
      setShowSave(true);
    };

    const saveAttendees = () => {
      var response = [
        ...attendees.map((x) => {
          return {
            userId: x.userId,
            attendeeType: x.attendeeType,
          };
        }),
        ...guests.map((x) => {
          return {
            userId: x.userId,
          };
        }),
        ...newAttendees.map((x) => {
          return {
            userId: x.id,
          };
        }),
      ];
      console.log(response, "data");
      heroApiClient.updateAttendees(meeting.meetingId, response).then((res) => {
        setShowSave(false);
        setAttendees([
          ...attendees,
          ...newAttendees
            .filter((l) => !l.id.startsWith("guest"))
            .map((att) => {
              return { userId: att.id, name: att.name };
            }),
        ]);
        setGuests([
          ...guests,
          ...newAttendees
            .filter((l) => l.id.startsWith("guest"))
            .map((att) => {
              return { userId: att.id };
            }),
        ]);
        setNewAttendees([]);
      });
    };
    const removeAttendee = (attendee) => {
      setShowSave(true);
      setAttendees(attendees.filter((a) => a !== attendee));
    };
    const removeGuest = (guest) => {
      setShowSave(true);
      setGuests(guests.filter((x) => x !== guest));
    };
    useEffect(() => {
      if (newAttendees && newAttendees.length) {
        setShowSave(true);
      }
    }, [newAttendees]);
    useEffect(() => {
      setAttendees(Attendees.filter((x) => !x.userId.startsWith("guest:")));
      setGuests(Attendees.filter((x) => x.userId.startsWith("guest:")));
    }, [Attendees]);
    return (
      <div className="attendees-list">
        <p className="meeting-card-title">Attendees</p>
        <div className="meeting-card-users">
          {role === 1 && (
            <UserSearch
              className={"m5"}
              heroApiClient={heroApiClient}
              text={"Invite more people..."}
              setFullGuests={setNewAttendees}
              values={newAttendees}
              users={users}
            />
          )}
          {showSave && (
            <button type="submit" onClick={saveAttendees}>
              Update invited people
            </button>
          )}
        </div>
        <ul className="meeting-card-users">
          {attendees.map((attendee) => (
            <li key={attendee.userId} className="meeting-card-user">
              <div className="profile-image">
                {attendee.photoUrl && (
                  <img src={attendee.photoUrl} alt="Profile" />
                )}
              </div>
              <div>
                {attendee.name}
                {attendee.attendeeType && attendee.attendeeType === 1 && (
                  <p>Host</p>
                )}
              </div>
              {role === 1 && (
                <>
                  {attendee.attendeeType !== 1 &&
                    attendee.attendeeType !== 3 && (
                      <MinusCircleIcon
                        color={"#d92a26"}
                        style={{ width: "20px" }}
                        onClick={() => removeAttendee(attendee)}
                      />
                    )}
                  {attendee.attendeeType !== 1 ? (
                    <button onClick={() => makeAdmin(attendee)}>
                      Make admin
                    </button>
                  ) : (
                    <button onClick={() => removeAdmin(attendee)}>
                      Remove admin
                    </button>
                  )}
                </>
              )}
            </li>
          ))}
        </ul>
        {guests && guests.length > 0 && (
          <>
            <p className="meeting-card-title">Invited Guest Email Address'</p>
            <ul className="meeting-card-users">
              {guests.map((guest) => (
                <li key={guest.userId} className="meeting-card-user">
                  <div>{guest.userId.replace("guest:", "")}</div>
                  {role === 1 && (
                    <MinusCircleIcon
                      style={{ width: "20px" }}
                      color={"#d92a26"}
                      onClick={() => removeGuest(guest)}
                    />
                  )}
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    );
  }
  function AvailabilityCard({ heroApiClient, meeting, refresh }) {
    const submit = () => {
      heroApiClient
        .bookAvailability(meeting.userId, meeting.start.toISOString())
        .then(() => {
          refresh();
        });
    };

    return (
      <>
        <div className="modal-header">
          <button onClick={submit} type="submit">
            Book this session
          </button>
          <button onClick={remove}>X</button>
        </div>
        <h3 className="meeting-card-title">
          {meeting.title.replace("Availability:", "")}
        </h3>

        <div className="meeting-card-time">
          {new Date(meeting.start).toLocaleString("en-GB", {
            day: "numeric",
            month: "long",
            hour: "2-digit",
            minute: "2-digit",
            year: "numeric",
          })}{" "}
          -{" "}
          {new Date(meeting.end).toLocaleString("en-GB", {
            hour: "2-digit",
            minute: "2-digit",
          })}
        </div>
      </>
    );
  }

  function CancelCard({ heroApiClient, meeting, refresh, events }) {
    const [bookModal, setBookModal] = useState(false);
    const [cancel, setCancel] = useState(false);
    const [selection, setSelection] = useState(null);
    const [cancellationList, setCancellationList] = useState([]);
    const [removeSchedule, setRemoveSchedule] = useState(false);
    useEffect(() => {
      console.log("book modal closed?", bookModal);
    }, [bookModal]);
    const goToCancel = () => {
      var futureScheduleFilter = events.filter(
        (x) =>
          x.start.getHours() === meeting.start.getHours() &&
          x.start.getMinutes() === meeting.start.getMinutes() &&
          x.start.getDay() === meeting.start.getDay()
      );
      setCancellationList(futureScheduleFilter);
      setCancel(true);
    };
    const submit = () => {
      if (removeSchedule) {
        var promises = cancellationList.map((event) => {
          return new Promise((resolve, reject) => {
            heroApiClient
              .cancelAvail(event.start.toISOString())
              .then(() => {
                resolve();
              })
              .catch((error) => {
                console.log(error);
                reject();
              });
          });
        });
        Promise.all(promises).then(() => {
          refresh();
        });
      } else {
        heroApiClient.cancelAvail(meeting.start.toISOString()).then(() => {
          refresh();
        });
      }
    };
    const bookSession = () => {
      heroApiClient
        .bookAvailability(selection.id, meeting.start.toISOString(), true)
        .then(() => {
          refresh();
        });
    };
    return (
      <>
        <Modal show={bookModal}>
          <div className="modal-header">
            <h3>Book session with user</h3>
            <button onClick={bookSession} type="submit">
              Book
            </button>
            <button onClick={() => setBookModal(false)}>Cancel</button>
          </div>
          <div className="m-5">
            <p className="meeting-card-title">
              Who do you want to book this session for?
            </p>
            <div>
              <UserSearch
                heroApiClient={heroApiClient}
                multi={false}
                setGuests={setSelection}
                users={users}
              />
            </div>
          </div>
        </Modal>
        <Modal show={cancel}>
          <div className="meeting-card">
            <div className="modal-header">
              <h3 className="meeting-card-title">Remove Availability</h3>
            </div>
            {cancellationList && cancellationList.length > 1 && (
              <div className="textCentered">
                <label
                  for="removeSchedule"
                  onClick={() => setRemoveSchedule(!removeSchedule)}
                >
                  Also remove all future availabilities on{" "}
                  {meeting.start.toLocaleString("en-GB", {
                    weekday: "long",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}{" "}
                  <input
                    type="checkbox"
                    name="removeSchedule"
                    checked={removeSchedule}
                    onClick={() => setRemoveSchedule(!removeSchedule)}
                  />
                  <div>{cancellationList.length - 1} session(s)</div>
                </label>
              </div>
            )}
            <div className="flexCentered">
              <button type="submit" onClick={submit}>
                Yes
              </button>
              <a onClick={() => setCancel(false)}>No</a>
            </div>
          </div>
        </Modal>
        <div className="modal-header">
          <button onClick={goToCancel} type="submit">
            Cancel this session
          </button>

          <button onClick={() => setBookModal(true)} type="submit">
            Book for a user
          </button>
          <button onClick={remove}>X</button>
        </div>
        <h3 className="meeting-card-title">Your availability</h3>
        <div className="meeting-card-time">
          {new Date(meeting.start).toLocaleString("en-GB", {
            day: "numeric",
            month: "long",
            hour: "2-digit",
            minute: "2-digit",
            year: "numeric",
          })}{" "}
          -{" "}
          {new Date(meeting.end).toLocaleString("en-GB", {
            hour: "2-digit",
            minute: "2-digit",
          })}
        </div>
      </>
    );
  }
};

export default MeetingCard;

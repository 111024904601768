import dataStores from '../reducers/dataStores'
import { createError } from './errors'

export const REQUEST_APP_CONNECTIONS = 'REQUEST_APP_CONNECTIONS'
function requestAppConnections() {
    return {
        type: REQUEST_APP_CONNECTIONS
    }
}

export const RECEIVE_APP_CONNECTIONS = 'RECEIVE_APP_CONNECTIONS'
function receiveAppConnections(appConnections) {
    return {
        type: RECEIVE_APP_CONNECTIONS,
        payload: {
            appConnections: appConnections,
            receivedAt: Date.now()
        }
    }
}

export const INVALIDATE_APP_CONNECTIONS = 'INVALIDATE_APP_CONNECTIONS'
export function invalidateAppConnections() {
    return {
        type: INVALIDATE_APP_CONNECTIONS
    }
}

function doFetchAppConnections(accessToken) {
    return async dispatch => {
        dispatch(requestAppConnections())
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user/app-connections`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'hero-token': accessToken
                }
            })
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const appConnections = await response.json()
            dispatch(receiveAppConnections(appConnections))
        }
        catch (error) {
            return dispatch(createError(error.message))
        }
    }
}

function shouldFetchAppConnections(state) {
    const appConnections = state.appConnections
    if (!appConnections) {
        return true
    } else if (appConnections.isFetching) {
        return false
    } else {
        return !appConnections.isValid
    }
}

export function fetchAppConnections() {
    return (dispatch, getState) => {
        const state = getState()
        if (shouldFetchAppConnections(state)) {
            const accessToken = state.user.session.accessToken
            return dispatch(doFetchAppConnections(accessToken))
        } else {
            return Promise.resolve()
        }
    }
}

import { createError } from './errors'

export const REQUEST_METRIC_SOURCES = 'REQUEST_METRIC_SOURCES'
function requestMetricSources() {
    return {
        type: REQUEST_METRIC_SOURCES
    }
}

export const RECEIVE_METRIC_SOURCES = 'RECEIVE_METRIC_SOURCES'
function receiveMetricSources(metricSources) {
    return {
        type: RECEIVE_METRIC_SOURCES,
        payload: {
            metricSources: metricSources,
            receivedAt: Date.now()
        }
    }
}

export const INVALIDATE_METRIC_SOURCES = 'INVALIDATE_METRIC_SOURCES'
function invalidateMetricSources() {
    return {
        type: INVALIDATE_METRIC_SOURCES
    }
}

function doFetchMetricSources(accessToken) {
    return dispatch => {
        dispatch(requestMetricSources())
        return fetch(`${process.env.REACT_APP_API_BASE_URL}/user/metric-sources`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'hero-token': accessToken
            }
        })
            .then(
                response => response.json(),
                error => dispatch(createError(error.message))
            )
            .then(metricSources => dispatch(receiveMetricSources(metricSources)))
    }
}

function shouldFetchMetricSources(state) {
    const metricSources = state.user.healthMetricSources
    if (!metricSources) {
        return true
    } else if (metricSources.isFetching) {
        return false
    } else {
        return !metricSources.isValid
    }
}

export function fetchMetricSources() {
    return (dispatch, getState) => {
        const state = getState()
        if (shouldFetchMetricSources(state)) {
            const accessToken = state.user.session.accessToken
            return dispatch(doFetchMetricSources(accessToken))
        } else {
            return Promise.resolve()
        }
    }
}

export function updateMetricSources(healthMetricIds, appConnectionType) {
    return (dispatch, getState) => {
        const accessToken = getState().user.session.accessToken
        return fetch(`${process.env.REACT_APP_API_BASE_URL}/user/metric-sources`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'hero-token': accessToken
            },
            body: JSON.stringify({healthMetricIds, appConnectionType})
        })
            .then(
                response => dispatch(invalidateMetricSources()),
                error => dispatch(createError(error.message))
            )
    }
}
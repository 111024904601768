import React, { useState, useEffect } from 'react';
import { Button, Col, Row, Form, Slider } from 'react-bootstrap';
import FinanceSliderInput from '../../components/FinanceCalculators/FinanceSliderInput';
import LoanResults from '../../components/FinanceCalculators/LoanResults';

const LoanCalculator = ({ setSelectedCalculator }) => {
    // Define your state variables here if needed
    const [loanAmount, setLoanAmount] = useState(10000);
    const [interestRate, setInterestRate] = useState(5.9);
    const [loanTerm, setLoanTerm] = useState(5);

    const [output, setOutput] = useState({
        monthlyPayment: 0,
        totalInterest: 0,
        totalRepayment: 0,
        totalBorrowed: 0
    });

    useEffect(() => {
        handleCalculate();
    }, [loanAmount, interestRate, loanTerm]);

    const handleValueSliderChange = (value) => {
        setLoanAmount(value);
    };

    const handleInterestRateSliderChange = (value) => {
        setInterestRate(value);
    };

    const handleLoanTermSliderChange = (value) => {
        setLoanTerm(value);
    };

    const handleCalculate = () => {
        const monthlyInterestRate = interestRate / 100 / 12;
        const payments = loanTerm * 12;
        const equate = Math.pow(1 + monthlyInterestRate, payments);
        const monthlyPayment = (loanAmount * equate * monthlyInterestRate) / (equate - 1);
        const totalRepayment = monthlyPayment * payments;
        const totalInterest = totalRepayment - loanAmount;

        setOutput({
            monthlyPayment: round(monthlyPayment).toFixed(0),
            totalInterest: round(totalInterest).toFixed(0),
            totalRepayment: round(totalRepayment).toFixed(0),
            totalBorrowed: round(loanAmount).toFixed(0),
        });
    };

    function round(value) {
        return Math.round(value * 100) / 100;
    }

    return (
        <div>
         <Row>
            <Col>
                <h1 className="pb-3">Loan Calculator</h1>
                <p className="pb-4">
                Welcome to our Loan Calculator! This tool is designed to help you estimate the monthly payment, total interest, and total repayment for a loan based on various factors.
                Simply adjust the sliders below to set the loan amount, annual interest rate, and the duration of the loan. 
                The calculator will then provide you with a projection of your monthly payment, total interest paid, total repayment, and the total amount borrowed.
                </p>
            </Col>
        </Row>

        <Row className="p-4">
            <Col md={6}>
                <FinanceSliderInput
                    labelBefore="I want to borrow £"
                    minValue={1000}
                    maxValue={25000}
                    step={100}
                    toFixed={0}
                    onValueChange={handleValueSliderChange}
                    defaultValue={loanAmount}
                />
                <FinanceSliderInput
                    labelBefore="At "
                    labelAfter="% APR"
                    minValue={0}
                    maxValue={25.9}
                    step={0.1}
                    toFixed={1}
                    onValueChange={handleInterestRateSliderChange}
                    defaultValue={interestRate}
                />
                <FinanceSliderInput
                    labelBefore="Over "
                    labelAfter=" years"
                    minValue={1}
                    maxValue={25}
                    step={1}
                    toFixed={0}
                    onValueChange={handleLoanTermSliderChange}
                    defaultValue={loanTerm}
                />
            </Col>
          <Col md={6}>
            <LoanResults monthlyPayment={output.monthlyPayment} totalInterest={output.totalInterest} totalRepayment={output.totalRepayment} totalBorrowed={output.totalBorrowed} />
            This calculator is for illustrative purposes only. The actual interest rate you will pay will depend upon your circumstances. Always check with your loan provider.
          </Col>
        </Row>
        </div>
    );
};

export default LoanCalculator;

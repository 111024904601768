import { createHeroApiClient } from '../../hero-api-client';
import { createError } from './errors';
import NProgress from "nprogress";

export const REQUEST_CHALLENGES_PAST = 'REQUEST_CHALLENGES_PAST'
function requestChallengesPast() {
    return {
        type: REQUEST_CHALLENGES_PAST
    }
}

export const RECEIVE_CHALLENGES_PAST = 'RECEIVE_CHALLENGES_PAST'
function receiveChallengesPast(challenges, userId) {
    return {
        type: RECEIVE_CHALLENGES_PAST,
        payload: {
            challenges: challenges,
            receivedAt: Date.now(),
            userId: userId
        }
    }
}

function doFetchChallengesPast(accessToken, userId) {
    return async dispatch => {
        NProgress.start();
        dispatch(requestChallengesPast())
        createHeroApiClient(accessToken).getChallengesPast()
            .then(challenges => dispatch(receiveChallengesPast(challenges, userId)))
            .catch(error => dispatch(createError(error.message)))
            .finally(() => NProgress.done());
    }
}

function shouldFetchChallengesPast(state) {
    const challenges = state.challengesPast
    if (!challenges) {
        return true
    } else if (challenges.isFetching) {
        return false
    } else {
        return !challenges.isValid
    }
}

export function fetchChallengesPast() {
    return (dispatch, getState) => {
        const state = getState()
        if (!shouldFetchChallengesPast(state)) {
            return Promise.resolve()
        }
        
        const accessToken = state.user.session.accessToken
        const userId = state.user.profile.id
        return dispatch(doFetchChallengesPast(accessToken, userId))
    }
}

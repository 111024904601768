import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import StoryblokClient from 'storyblok-js-client';
import { createError } from '../../redux/actions/errors';
import Post from './Post';
import LoadingSpinner from '../controls/LoadingSpinner';
import {createHeroApiClient} from '../../hero-api-client/index'

function StoryblokStory(props) {
  const [story, setStory] = useState({});
  const [bio, setBio] = useState({});

  useEffect(() => {
    const storyblokClient = new StoryblokClient({
      accessToken: process.env.REACT_APP_STORYBLOK_TOKEN
    });
    storyblokClient.get(`cdn/stories${props.location.pathname}`)
      .then(
        (response) => {
          setStory(response.data.story);
          if(response.data.story.content.creator_id){
            createHeroApiClient().getCreators(response.data.story.content.creator_id)
            .then(
              (response) => {
                setBio(response);
              }
            )
          }

        },
        (error) => {
          if(error.response.status == 404) {
            props.history.push('/dashboard');
            return;
          }
          props.dispatch(createError(error.message));
        } 
      );
  }, []);

  if (!story.content) {
    return <LoadingSpinner />;
  }

  switch (story.content.component) {
    case 'post':
      return <Post story={story} bio={bio} />
    default:
      return <></>;
  }
}

export default withRouter(connect()(StoryblokStory));
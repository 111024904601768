import React, { useState, useEffect } from "react";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as moment from "moment";

// Redux Actions
import dataStores from "../../redux/reducers/dataStores";
import { invalidateHealthData } from "../../redux/actions/healthData";

import TextInput from "../../common/TextInput";
import { createHeroApiClient,  EngagementEvent } from '../../hero-api-client';

// Bootstrap
import Button from "react-bootstrap/Button";
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Image from 'react-bootstrap/Image'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import Modal from "react-bootstrap/Modal";

// Hero Components
import HeroOutlineButton from '../../common/Buttons/OutlineButton';

// Other imports
import FoodServingSelection from "./food-serving-selection";

// hero Core API
import { NutritionApi } from "hero-api-client";

import { id } from "date-fns/esm/locale";

import { DelayInput } from "react-delay-input";

const NuApi = new NutritionApi({ basePath: process.env.REACT_APP_API_BASE_URL });

function FoodSearch(props) {
  const [searchResults, setSearchResults] = useState([]);
  const [foodSearch, setFoodSearch] = useState("");

  const [selectedFoodId, setSelectedFoodId] = useState(0);
  const [selectedFoodDetail, setSelectedFoodDetail] = useState({});
  const [infoFoodId, setInfoFoodId] = useState(0);
  const [infoFoodDetail, setInfoFoodDetail] = useState({});
  const [foodSearchResultsVisible, setFoodSearchResultsVisible] = useState(
    true
  );
  const [filterColourFit, setFilterColourFit] = useState(
    false
  );
  // This useEffect handles change when the user types in to the search box
  useEffect(() => {
    const searchForFood = () => {
      var foodsearch = foodSearch
      if (filterColourFit == true) {
        foodsearch = foodSearch + " Colour Fit"
      }
      props.heroApiClient.createEngagementEvent(EngagementEvent.BASIC_FOOD_SEARCH)
      NuApi.foodSearchBasic(foodsearch, {
        headers: {
          "hero-token": props.heroToken
        }
      }).then(res => {
        if (res.foods.food != null) {
          setSearchResults(res.foods.food);
          setFoodSearchResultsVisible(true);
        }
      });
    };

    if (foodSearch.length > 1) {
      searchForFood();
    }
  }, [foodSearch, props.heroToken, filterColourFit]);

  // This useEffect watches the selected food id, when it changes, this will get the details of the selected food
  useEffect(() => {
    const getSelectedFoodDetail = () => {
      if (selectedFoodId > 0) {
        NuApi.foodDetail(selectedFoodId, {
          headers: {
            "hero-token": props.heroToken
          }
        }).then(res => {
          if (res != null) {
            setSelectedFoodDetail(res);
            setFoodSearchResultsVisible(false);
          }
        });
      }
    };
    // Load the details for this food ID
    getSelectedFoodDetail();
  }, [selectedFoodId, props.heroToken]);

  // This useEffect turns on the detailed display or hides it
  useEffect(() => {
    if (selectedFoodId > 0 && selectedFoodDetail) {
      //Hide the results
      setFoodSearchResultsVisible(false);
    }
  }, [selectedFoodDetail, selectedFoodId]);

  useEffect(() => {
    const getInfoFoodDetail = () => {
      if (infoFoodId > 0) {
        NuApi.foodDetail(infoFoodId, {
          headers: {
            "hero-token": props.heroToken
          }
        }).then(res => {
          if (res != null) {
            setInfoFoodDetail(res);
            setFoodSearchResultsVisible(false);
          }
        });
      }
      else {
        setInfoFoodDetail({ food: null });
        setFoodSearchResultsVisible(true);
      }
    };
    // Load the details for this food ID
    getInfoFoodDetail();
  }, [infoFoodId, props.heroToken]);

  // This useEffect turns on the detailed display or hides it
  useEffect(() => {
    if (infoFoodId > 0 && infoFoodDetail) {
      //Hide the results
      setFoodSearchResultsVisible(false);

    }
  }, [infoFoodDetail, infoFoodId]);

  function foodSearchChanged({ target }) {
    setFoodSearch(target.value);
    setInfoFoodId(0);
  }

  function getSearchBox() {
    return (
      <DelayInput
        name="foodSearch"
        value={foodSearch}
        onChange={foodSearchChanged}
        placeholder="Search for food..."
        className="input__base"
      />
    );
  }

  // This function is called by a child component after the user has added a food item
  // It is used to reset the input form
  function foodAddingComplete() {
    setSearchResults([]);
    setFoodSearch("");
    setSelectedFoodId(0);
    setSelectedFoodDetail({});
    setFoodSearchResultsVisible(true);
    props.dispatch(invalidateHealthData(props.userProfile.id, moment().format('YYYY-MM-DD')));

    // Let any parent components know that we have added the food item
    props.reload();
  }

  return (
    <div className="food-search">
      {selectedFoodId === 0 && getSearchBox()}
      <Row className="mt-2">
        <HeroOutlineButton
          styles={{ margin: "0.7rem", backgroundColor: filterColourFit ? "#21b4f130" : "transparent" }}
          colour="#15CBCE"
          size="medium"
          text="Colour-Fit"
          onClick={() => {
            setFilterColourFit(true)
            setInfoFoodId(0)
          }
          }
        ></HeroOutlineButton>
        <HeroOutlineButton
          styles={{ margin: "0.7rem", backgroundColor: filterColourFit ? "transparent" : "#21b4f130" }}
          colour="#15CBCE"
          size="medium"
          text="All"
          onClick={() => {
            setFilterColourFit(false)
            setInfoFoodId(0)
          }
          }
        ></HeroOutlineButton>
      </Row>
      {/* This div lists out the search results */}
      {searchResults.map(item => {
        if (item.brand_name === "Colour Fit" || filterColourFit == false) {
          return (
            <div
              key={item.food_id}
              className="card"
              style={{ marginBottom: "15px", padding: "15px" }}>
              <Row>
                <Col xs={12}>
                  <div className="card-body">
                    <h4 className="card-title">{item.food_name}</h4>
                    <h6 className="card_subtitle">{item.brand_name == "Colour Fit" ? "Colour-Fit" : item.brand_name}</h6>
                    <p className="card-text m-3">{item.food_description}</p>

                    <HeroOutlineButton
                      size="large"
                      colour="#15CBCE"
                      classes="card-link"
                      onClick={() => {
                        setSelectedFoodId(item.food_id);
                      }}
                      text={`Add Item`} />
                    <HeroOutlineButton
                      size="large"
                      colour="#15CBCE"
                      classes="card-link"
                      onClick={() => {
                        if (infoFoodId === 0)
                          setInfoFoodId(item.food_id);
                        else
                          setInfoFoodId(0);
                      }}
                      text={`Nutrition Info`} />
                  </div>
                </Col>
                <Col xs={12}>
                  {item.brand_name == "Colour Fit" || item.brand_name == "Colour-Fit" ?
                    <Image
                      width={150}
                      height={108}
                      alt="150x108"
                      src="https://herowellbeing.com/wp-content/uploads/2018/12/colourfit_logo_black_on_white-300x216.jpg" roundedCircle />
                    : undefined
                  }
                </Col>
              </Row>
              <Row>

                {infoFoodDetail.food ?
                  infoFoodDetail.food.food_id == item.food_id ?
                    (
                      <Modal centered={true} show={true} onHide={() => setInfoFoodId(0)}>
                        <Modal.Body>
                          {
                            infoFoodDetail.food.servings.serving.map(serving => {
                              return (<Row className="p-3" key={serving.serving_id}>
                                <Col>
                                  <Row>
                                    <h5 className="card-title">Serving: {serving.serving_description} ({serving.metric_serving_amount}{serving.metric_serving_unit})</h5>
                                  </Row>
                                  <Row>
                                    <Col md={12}>
                                      <table className="w-100 mb-3">
                                        <tr>
                                          <td className="w-90">
                                            Calcium
                                          </td>
                                          <td className="text-right">
                                            <p>{serving.calcium ? serving.calcium : 0} %</p>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="w-90">
                                            Iron
                                          </td>
                                          <td className="text-right">
                                            <p>{serving.iron ? serving.iron : 0} %</p>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="w-90">
                                            Potassium
                                          </td>
                                          <td className="text-right">
                                            <p>{serving.potassium ? serving.potassium : 0} mg</p>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="w-90">
                                            Sodium
                                          </td>
                                          <td className="text-right">
                                            <p>{serving.sodium ? serving.sodium : 0} mg</p>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="w-90">
                                            Carbohydrates
                                          </td>
                                          <td className="text-right">
                                            <p>{serving.carbohydrate ? serving.carbohydrate : 0} g</p>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="w-90">
                                            Fibre
                                          </td>
                                          <td className="text-right">
                                            <p>{serving.fiber ? serving.fiber : 0} g</p>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="w-90">
                                            Sugar
                                          </td>
                                          <td className="text-right">
                                            <p>{serving.sugar ? serving.sugar : 0} g</p>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="w-90">
                                            Protein
                                          </td>
                                          <td className="text-right">
                                            <p>{serving.protein ? serving.protein : 0} g</p>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="w-90">
                                            Fat
                                          </td>
                                          <td className="text-right">
                                            <p>{serving.fat ? serving.fat : 0} g</p>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="w-90">
                                            Saturated Fat
                                          </td>
                                          <td className="text-right">
                                            <p>{serving.saturated_fat ? serving.saturated_fat : 0} g</p>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="w-90">
                                            Polyunsaturated Fat
                                          </td>
                                          <td className="text-right">
                                            <p>{serving.polyunsaturated_fat ? serving.polyunsaturated_fat : 0} g</p>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="w-90">
                                            Monounsaturated Fat
                                          </td>
                                          <td className="text-right">
                                            <p>{serving.monounsaturated_fat ? serving.monounsaturated_fat : 0} g</p>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="w-90">
                                            Vitamin A
                                          </td>
                                          <td className="text-right">
                                            <p>{serving.vitamin_a ? serving.vitamin_a : 0} %</p>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="w-90">
                                            Vitamin C
                                          </td>
                                          <td className="text-right">
                                            <p>{serving.vitamin_c ? serving.vitamin_c : 0} %</p>
                                          </td>
                                        </tr>
                                      </table>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <p> % value are based on percentage of recommended daily intake of a 2000 calorie diet </p>
                                  </Row>
                                </Col>
                              </Row>)
                            })
                          }
                          <div className="col-12 text-center">
                            <Button color="gray-200" className="btn-md" onClick={() => setInfoFoodId(0)}>Close</Button>
                          </div>
                        </Modal.Body>
                      </Modal>
                    )
                    : undefined
                  : undefined}
              </Row>
            </div>
          );
        }
      })}

      {/* This div shows the details for the selected food items */}

      <Modal centered={true} show={selectedFoodId > 0} onHide={() => setSelectedFoodId(0)}>
        <Modal.Body>
          {getFoodServing()}
        </Modal.Body>
      </Modal>
    </div>
  );

  function getFoodServing() {
    if (selectedFoodDetail.food) {
      return (
        <FoodServingSelection
          foodId={selectedFoodDetail.food.food_id}
          entryName={selectedFoodDetail.food.food_name}
          meal={props.mealName}
          servings={selectedFoodDetail.food.servings.serving}
          forDate={props.forDate}
          foodAddedComplete={foodAddingComplete}
          hideMealSelection={props.hideMealSelection} />
      );
    }
    return <></>;
  }
}

function mapStateToProps(state, ownProps) {
  return {
    heroToken: state.appObject[dataStores.DELEGATED_PERMISSIONS].coachToken ? state.appObject[dataStores.DELEGATED_PERMISSIONS].coachToken : state.appObject[dataStores.HEADCOACH_PERMISSIONS].coachToken ? state.appObject[dataStores.HEADCOACH_PERMISSIONS].coachToken : state.user.session.accessToken,
    feed: state.feed,
    mealName: ownProps.mealName,
    forDate: ownProps.forDate,
    reload: ownProps.reload,
    hideMealSelection: ownProps.hideMealSelection,
    userProfile: state.user.profile,
  };
}

export default connect(mapStateToProps)(FoodSearch);

import React from 'react';

// import { tsImportEqualsDeclaration } from '@babel/types';

export class Help extends React.Component {
    render() {
        return (
            <div>
                <h1>Help page</h1>
            </div >
        );
    }
}

export default Help;
import React, { useCallback, useEffect } from "react";
import {Link, useParams, withRouter} from "react-router-dom";
import {useHistory} from "react-router-dom";
import {connect} from "react-redux";
import {dismissError} from "../redux/actions/errors";
import {Helmet} from "react-helmet";
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import NavBar from "./NavBar";
import dataStores from "../redux/reducers/dataStores";
import {endUserSession} from '../redux/actions/userSession';
import $ from "jquery";
import {useState} from "react";
import Carousel from "react-bootstrap/Carousel";
import DropDown from "react-bootstrap/Dropdown";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import { Alert, Button, Row, Col, Container, Card, Modal, ButtonGroup } from 'react-bootstrap';
import {createHeroApiClient, EngagementEvent} from '../hero-api-client';
import { fetchAppConnections } from '../redux/actions/appConnections'
import { fetchMetricSources } from '../redux/actions/userHealthMetricSources'

// Sidebar Overlay
import SidebarLayout from "react-sidebar";
import NewsFeedListItem from "../components/lists/NewsFeedListItem";
import UserReport from "./reports/UserReport";
import GroupReport from "./reports/GroupReport";
import jwtDecode from "jwt-decode";
function mapRouteToEngagementEvent(route) {
    switch (route) {
        case '/dashboard':
            return EngagementEvent.VIEW_DASHBOARD
        case '/calendar':
            return EngagementEvent.VIEW_CALENDAR
        case '/reports':
            return EngagementEvent.VIEW_REPORTS
        case '/nutrition/diary':
            return EngagementEvent.VIEW_DIARY
        case '/nutrition/calendar':
            return EngagementEvent.VIEW_NUTRITION_CALENDAR
        case '/nutrition/meals':
            return EngagementEvent.VIEW_MEALS
        case '/nutrition/meal-plans':
            return EngagementEvent.VIEW_MEAL_PLANS
        case '/nutrition/meal-resources':
            return EngagementEvent.VIEW_MEAL_RESOURCES
        case '/nutrition/meal-builders':
            return EngagementEvent.VIEW_MEAL_BUILDERS
        case '/hr-reports':
            return EngagementEvent.VIEW_HR_REPORTS
        case '/hr-user-management':
            return EngagementEvent.VIEW_HR_USER_MANAGEMENT
        case '/health-check':
            return EngagementEvent.VIEW_HEALTH_CHECK
        case '/health-assessment':
            return EngagementEvent.VIEW_HEALTH_ASSESSMENT
        case '/health-store':
            return EngagementEvent.VIEW_HEALTH_STORE
        case '/challenges':
            return EngagementEvent.VIEW_CHALLENGES
        case '/groups':
            return EngagementEvent.VIEW_GROUPS
        case '/wellness-library':
            return EngagementEvent.VIEW_WELLNESS_LIBRARY
        case '/fitness-library':
            return EngagementEvent.VIEW_FITNESS_LIBRARY
        case '/health-benefits':
            return EngagementEvent.VIEW_HEALTH_BENEFITS
        case '/profile':
            return EngagementEvent.VIEW_MY_PROFILE
        case '/goals':
            return EngagementEvent.VIEW_MY_GOALS
        case '/app-connections':
            return EngagementEvent.VIEW_MY_APP_CONNECTIONS
        case '/sources':
            return EngagementEvent.VIEW_MY_SOURCES
        case '/my-modules':
            return EngagementEvent.VIEW_MY_MODULES
        default:
            return null
    }
}


function DashboardLayout(props) {
    useEffect(() => {
        props.history.listen((location, action) => {
            if (action != 'PUSH') {
                return
            }
            const event = mapRouteToEngagementEvent(location.pathname)
            if (event === null) {
                return
            }
            props.heroApiClient.createEngagementEvent(event)
        })
    }, [])

    useEffect(() => {
        props.dispatch(fetchAppConnections())
    }, [props.appConnections])

    const { name, healthMetrics } = props
    const [menuOpen, openMenu] = useState(false);
    const history = useHistory();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [fadeInElements] = useState("animated fadeIn customDelay");
    const [activityLog, setActivityLog] = useState(null);
    const [showOnboarding, setShowOnboarding] = useState (false)
    const [slideIndex, setSlideIndex] = useState (0)
    const lastSlide = 2;
    const isVodafone = props.userProfile && props.userProfile.companyKey === 'vodafoneglobalchallenge';

    const nextSlide  = useCallback(()=>{
        if (slideIndex === lastSlide) {
            setShowOnboarding(false)
        } else {
            setSlideIndex(slideIndex+1)
        }
    })
    const prevSlide  = useCallback(()=>{
        if (slideIndex !== 0) {
            setSlideIndex(slideIndex - 1)
        }
    })

    function renderActivityLog() {
        var log = [];

        if (activityLog) {
            if (activityLog.length == 0) {
                return (<p>
                    No group activity
                </p>)
            }

            for (var key in activityLog) {
                activityLog[key].map(activity => {
                    log.push(
                        <NewsFeedListItem activity={activity} />
                    )
                })
            }
            return log;
        }

        return undefined;
    }

    function Nav(onSelect) {
        if (props.user.session.isAuthenticated && props.appConfig.styles) {
            const token = props.user.session.accessToken;
            const decoded = jwtDecode(token)
            const showHR = decoded.HeroAdmin === "true" || decoded.CompanyHR === "true" || decoded.HeroCompanyAdmin;
            const elements = props.appConfig.elements && props.appConfig.elements.filter((e)=>{
                if (e.groupName === "Organisation") {
                    return showHR;
                }
                return true
            })
            return (
                <NavBar
                    elements={elements}
                    styles={props.appConfig.styles}
                    onSelect={onSelect}
                    companyName={props.userProfile.companyName}
                />
            );
        }
        return <></>;
    }

    function CoachedUser() {
        var user = props.coachPermisions.specialistsWithAccess.find(user => user.userId == props.coachPermisions.uid)
        if (!user) {
            user = props.headPermisions.specialistsWithAccess.find(user => user.userId == props.headPermisions.uid)
        }
        return (
            <div className="coachview-notification d-flex flex-row align-items-center flex-wrap">
                <Button className="btn btn-secondary" onClick={logOutOfUser}>Back to personal navigator</Button>
                <div className="d-flex col justify-content-center"><label className="mb-0">You are Currently accessing {user.firstName} {user.lastName}'s navigator as a coach
            ({user.email})
            </label></div>

            </div>
        )
    }

    function DarkMode(Enabled) {
        if (Enabled) {
            return '#000000'
        } else {
            return ''
        }
    }

    function QuickNav() {
        var IconColour = DarkMode(false)

        if (props.user.session.isAuthenticated && props.appConfig.styles) {

            const links = props.appConfig.elements.filter(e => e.quickLink);
            const icons = props.appConfig.elements.filter(e => e.quickIcon || e.favourite);

            const isColorFit = props.userProfile && props.userProfile.companyKey === 'colour-fit';

            return (
                <>
                    {/*<nav className="c-navbar__nav ml-3 mr-auto" id="secondary-nav">*/}
                    {/*  <ul className="c-navbar__nav-list">*/}
                    {/*    {*/}
                    {/*      links != null ? links.map(element => {*/}
                    {/*        return (<li className="c-navbar__nav-item">*/}
                    {/*          <Link className="c-navbar__nav-link" to={element.url}>{element.displayText}</Link>*/}
                    {/*        </li>);*/}
                    {/*      }) : <></>*/}
                    {/*    }*/}
                    {/*  </ul>*/}
                    {/*</nav>*/}
                    <nav className="c-navbar__nav" id="icon-nav">
                        <ul className="c-navbar__nav-list">
                            {/*{*/}
                            {/*  icons != null ? icons.map(element => {*/}
                            {/*    return (<li className="c-navbar__nav-item mr-4">*/}
                            {/*      <Link className="btn-icon c-navbar__nav-link" style={{color: `${IconColour}`}} title={element.displayText} to={element.url}>*/}
                            {/*        <i className={element.iconName}></i>*/}
                            {/*      </Link>*/}
                            {/*    </li>);*/}
                            {/*  }) : <></>*/}
                            {/*}*/}
                            {!isColorFit && (
                                <li className="c-navbar__nav-item mr-4">
                                    <a className="btn-icon c-navbar__nav-link" style={{color: `${IconColour}`}} onClick={e => setSidebarOpen(true)}>
                                        <i className="fal fa-list-alt"></i>
                                        {/*<span className="newsfeed-list__notification newsfeed-list__notification--navbar"/>*/}
                                    </a>
                                </li>
                            )}

                        </ul>
                    </nav>
                </>
            );
        }
        return <></>;
    }

    function Sidebar(props) {
        const {setSidebarOpen} = props;

        return (
            <div>
                <div className="sidebar sidebar--dashboard">
                    <div className="sidebar--header">
                        <div className="sidebar--close"></div>
                        <h6 className="slim-pagetitle">
                            <div className="help-container">
                                <button className="btn btn--close p-0 tx-20">
                                    <i className="far fa-times" onClick={e => setSidebarOpen(false)}></i>
                                </button>
                            </div>
                        </h6>
                    </div>
                    <div className="sidebar--content mg-t-30">
                        <div className="p-5">
                            <h5 className="mb-3">News Feed</h5>
                            {renderActivityLog()}
                        </div>
                    </div>
                </div>
            </div>

        );
    }


    useEffect(() => {
        if (
            props.user.session.accessToken != null &&
            props.userProfile.id
        ) {
            var body = { userIds: [props.userProfile.id], byGroup: true, endDate: new Date(), pageSize: 10, PageNo: 1 }
            const myRequest = new Request(process.env.REACT_APP_API_BASE_URL + "/useractivitydata", {
                method: 'POST',
                headers: {
                    "hero-token": props.user.session.accessToken,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });

            fetch(myRequest)
                .then((response) => {
                    return response.json()
                })
                .then((data) => setActivityLog(data))
        }
    }, [props.userProfile]);


    function logUserOut() {
        props.dispatch(endUserSession())
    }

    function logOutOfUser() {
        window.location.reload();
    }

    $(document).ready(function () {
        $('body').removeClass('overflow-hidden');
    });


    const search = document?.location.search || "";
    useEffect(()=>{
        if(search.includes("signup=1")) {
            window.history.replaceState("", "", document.location.pathname);
            setShowOnboarding(true)
        }
    }, [search])

    return (
        <>
            <Helmet titleTemplate={`%s | hero`}/>
            <ToastContainer position={toast.POSITION.BOTTOM_CENTER}/>
            <div className={`o-page ${menuOpen ? "is-sidebar-open" : ""}`}>
                <div className="o-page--overlay" onClick={() => menuOpen ? openMenu(false) : null}></div>
                <div className="o-page__sidebar js-page-sidebar">
                    <aside style={{backgroundColor: props.styles ? `${props.styles.navBarSideColor}` : '#fff'}}
                           className="c-sidebar">
                        <div className="c-sidebar__brand">
                            <img style={{maxHeight: "55%", margin: "auto"}}
                                 src={props.styles && props.styles.logoUrlWeb || props.styles && props.styles.logoUrl || require("../assets/logo/hero-logo-nowords-black.svg")}/>
                        </div>
                        <div className="c-sidebar__body">
                            {Nav(() => menuOpen ? openMenu(false) : null)}
                        </div>

                        <div className="c-sidebar__footer">
                            <Button variant="outline-dark" className="btn-block" onClick={() => {
                                logUserOut()
                            }}>
                                Logout
                            </Button>
                        </div>

                    </aside>
                </div>
                <Row>
                    <div className="c-sidebar--desktop-wrapper">
                        <aside className="c-sidebar c-sidebar--desktop" style={{backgroundColor: props.styles ? `${props.styles.navBarSideColor}` : '#fff'}}>
                            <div className="c-sidebar__brand--desktop">
                                <img style={{maxHeight: "55%"}}
                                     src={props.styles && props.styles.logoUrlWeb || props.styles && props.styles.logoUrl || require("../assets/logo/hero-logo-nowords-black.svg")}/>
                            </div>
                            <div className="c-sidebar__body">
                                {Nav(() => menuOpen ? openMenu(false) : null)}
                            </div>

                            <div className="c-sidebar__footer">
                                <Button variant="outline-dark" className="btn-block" onClick={() => {
                                    logUserOut()
                                }}>
                                    Logout
                                </Button>
                            </div>
                        </aside>
                    </div>
                    <Col className="pl-3 pr-3">
                        <main className="o-page__content">
                            {props.coachView ? CoachedUser() : null}

                            <header style={{backgroundColor: props.styles ? `${props.styles.navBarColor}` : '#fff'}}
                                    className="c-navbar">
                                <button className="c-sidebar-toggle js-sidebar-toggle"
                                        onClick={() => openMenu(!menuOpen)}>
                                    <i className="fal fa-bars"></i>
                                </button>
                                {/*<h2 className="c-navbar__brand" style={{ cursor: 'pointer' }} onClick={() => props.history.push('/dashboard')}>*/}
                                {/*  {*/}
                                {/*    props.styles && props.styles.logoUrl ?*/}
                                {/*      <img src={props.styles.logoUrl} style={{ height: "60px" }} className="d-inline-block align-top" alt="logo" /> :*/}
                                {/*      <>HERO <span>Wellbeing</span></>*/}
                                {/*  }*/}
                                {/*</h2>*/}
                                {QuickNav()}
                                <DropDown className="c-dropdown">
                                    <DropdownToggle as="a" className="c-avatar c-avatar--menu c-avatar--xsmall">
                                        <img className="avatar__img"
                                             src={props.user.profile.avatarUrl || `https://eu.ui-avatars.com/api?name=${props.user.profile.givenName} ${props.user.profile.familyName}`}/>
                                    </DropdownToggle>
                                    <DropdownMenu className="c-dropdown__menu  has-arrow">
                                        <DropDown.Item className="c-dropdown__item"
                                                       onClick={() => history.push("/profile")}>
                                            Profile
                                        </DropDown.Item>
                                        <DropDown.Item className="c-dropdown__item"
                                                       onClick={() => history.push("/coach")}>
                                            Coach
                                        </DropDown.Item>
                                        <DropDown.Item className="c-dropdown__item" onClick={() => {
                                            logUserOut()
                                        }}>
                                            Log out
                                        </DropDown.Item>
                                    </DropdownMenu>
                                </DropDown>
                            </header>
                            <div>
                                {/* {props.errors.items.filter(x => !x.dismissed).map(error =>
                                    <Alert variant="success" onClose={() => props.dispatch(dismissError(error.id))}
                                           dismissible>
                                        <Alert.Heading>New data available! Please refresh!</Alert.Heading>
                                    </Alert>
                                )} */}

                                {props.children}
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <footer className="c-footer">
                                            <p>© {moment().format("YYYY")} <a href="https://zenohealthgroup.com/"
                                                                              target="_blank">Zeno</a></p>
                                            <span className="c-footer__divider">|</span>
                                            <nav>
                                                <a className="c-footer__link" href={require('../assets/hero-tandcs.pdf')} target={'_blank'}>Terms</a>
                                                <a className="c-footer__link" href={require('../assets/hero-privacy.pdf')} target={'_blank'}>Privacy</a>
                                                <a className="c-footer__link" href="/eligible">Help</a>
                                            </nav>
                                        </footer>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </Col>
                </Row>
                <Modal centered show={showOnboarding}>
                    <Modal.Body>
                        <Carousel activeIndex={slideIndex} interval={null} wrap={false} controls={false}>
                        <Carousel.Item>
                            <div className="slide">
                                <h4>
                                    Welcome
                                </h4>
                                {isVodafone ? (<p>Welcome to this years Vodafone Global Wellbeing Challenge Starting on 1st October</p>) :
                                    (<><p>
                                        Customise your profile and our all-in-one tech led solution will provide a bespoke,
                                        holistic wellbeing programme for your organisation.
                                    </p></>)}

                                <Button variant="primary-text" onClick={nextSlide}>
                                    Next
                                </Button>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="slide">
                                {isVodafone ? (
                                    <>
                                    <h4>Download the Apple or Android app</h4>
                                    <p>You can download the HealthNav app via the apple and google play app stores or sync your wearable devices </p>
                                    </>
                                ) : (<>
                                    <h4>Connect devices</h4>
                                    <p>Connect your apps and wearables to give deeper insights and track your progress.</p>
                                </>)}
                                <Container>
                                    <Row>
                                        <Button variant="primary-text" onClick={prevSlide}>
                                            Previous
                                        </Button>
                                        <Button variant="primary-text" onClick={nextSlide}>
                                            Next
                                        </Button>
                                    </Row>
                                </Container>

                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="slide">
                                {isVodafone ? (
                                <>
                                    <h4>Our goal is 150 minutes of moderate intensity activity per week</h4>
                                    <p>Our goal as individuals is to keep active and try to register 150 minutes moderate intensity activity per week which roughly equates to 22 minute per day target - your progress will be visible on a shared leaderboard.</p>
                                </>) :
                                    (<>
                                        <h4>Monitor your health and wellbeing</h4>
                                        <p>Track your activity and nutrition on our amazing web and mobile apps.</p>
                                    </>)
                                }

                                <Container>
                                    <Row>
                                        <Button variant="primary-text" onClick={prevSlide}>
                                            Previous
                                        </Button>
                                        <Button variant="primary-text" onClick={nextSlide}>
                                            Finish
                                        </Button>
                                    </Row>
                                </Container>
                            </div>
                        </Carousel.Item>
                    </Carousel>
                    </Modal.Body>
                </Modal>
                <Container className={`${fadeInElements}`}>
                    <SidebarLayout
                        sidebar={<Sidebar setSidebarOpen={setSidebarOpen}/>}
                        pullRight={true}
                        open={sidebarOpen}
                        styles={{
                            content: {overflowY: "visible", position: "relative", height: "100%"},
                            root: {position: "relative", overflow: "visible"},
                            sidebar: {
                                position: "fixed",
                                background: "white",
                                borderTopLeftRadius: 36,
                                borderBottomLeftRadius: 36
                            }
                        }}/>
                </Container>
            </div>
        </>
    );
}

function mapStateToProps(state, ownProps) {
    return {
        forDate: ownProps.forDate,
        changeDateByDays: ownProps.changeDateByDays,
        dataStores: state.appObject,
        userProfile: state.user.profile,
        styles: state.appObject[dataStores.APP_CONFIG].styles,
        coachView: state.appObject[dataStores.DELEGATED_PERMISSIONS].coachToken || state.appObject[dataStores.HEADCOACH_PERMISSIONS].coachToken ? true : false,
        coachPermisions: state.appObject[dataStores.DELEGATED_PERMISSIONS],
        headPermisions: state.appObject[dataStores.HEADCOACH_PERMISSIONS],
        errors: state.errors,
        heroApiClient: createHeroApiClient(state.user.session.accessToken),
        user: state.user
    };
}

export default withRouter(connect(mapStateToProps)(DashboardLayout))

import React, { useState } from 'react'
import axios from 'axios';
import { IoMdRefresh } from 'react-icons/io';
import { connect } from "react-redux";
import {Row,Modal} from 'react-bootstrap';
import { sendChatMessage } from '../../redux/actions/chatMessages.js';
import { fetchChatMessages } from '../../redux/actions/chatMessages.js';
import Tenor from 'react-tenor'
import {GifIcon, ArrowUpOnSquareStackIcon} from '@heroicons/react/24/solid'
import { FaCommentsDollar } from 'react-icons/fa';
import { createHeroApiClient } from '../../hero-api-client/index.js';

function SendChatMessageInput({ chatId, name, dispatch, refresh,heroApiClient }) {
  const [newMessage, setNewMessage] = useState('')
  const [showGIFSearch, setShowGIFSearch] = useState(false);
  const [showFileUpload, setShowFileUpload] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileText, setFileText] = useState('');
  const [fileError, setFileError] = useState('');
  const [messageError, setMessageError] = useState('');
  const [messageSending,setMessageSending] = useState(false);

  const handleInputChange = event => {
    setNewMessage(event.target.value)
  }
  const handleClose = () => setShowGIFSearch(false);
  const handleCloseFileUpload = () => setShowFileUpload(false);
  const onGIFSelect = (result) => {
      dispatch(sendChatMessage(chatId, encodeURIComponent(result.media[0].gif.url))).then((x)=>{
    
      dispatch(fetchChatMessages(chatId, { latest: true }));
      refresh();
    setNewMessage('');
    });
    handleClose();
    
  }
  const isValid = () => !!newMessage

  const handleFormSubmit = event => {
    event.preventDefault()

    if(!isValid()) {
      alert('Invalid message')
      return
    }
    
    dispatch(sendChatMessage(chatId, encodeURIComponent(newMessage))).then((x)=>{

      dispatch(fetchChatMessages(chatId, { latest: true }));
      refresh();
    });
    setNewMessage('');
  }

  const onFileChange = event => { 
    // Update the state 
     setSelectedFile(event.target.files[0]) 
  };
  const onFileTextChange = event => {
    setFileText(event.target.value);
  }
  const onFileUpload = () => {
    const body = new FormData(); 
     
      // Update the formData object 
      body.append( 
        "File", 
        selectedFile, 
        selectedFile.name 
      ); 
     body.append(
      "FakeName",
      fileText
     )
      heroApiClient.sendFileToChat(chatId,body).then(()=>{
        setShowFileUpload(false);
        setFileError('');
      }).catch((error)=>{

        setFileError('Error uploading file');
      });

  }

  return (
    <>
       <Row style={{marginLeft:'3px'}} className='uploadIcons'>
          <button className='none' style={{height:20,width:20}} onClick={() => setShowGIFSearch(true)}>
            <GifIcon size={5} color={'black'} />
          </button>
          <button className='none' style={{height:20,width:20}} onClick={() => setShowFileUpload(true)}>
            <ArrowUpOnSquareStackIcon size={5} color={'black'} />
          </button>
          <p className='redText'>{}</p>
          <p className='greyText'></p>
        </Row>
        <form onSubmit={handleFormSubmit}>
          <input
            type="text"
            placeholder={`Type message here...`}
            value={newMessage}
            onChange={handleInputChange}
          />
        
          <button type="submit" className="msg_send_btn" disabled={!isValid()}><i className="fa fa-paper-plane" aria-hidden="true"></i></button>
        </form>
      <Modal onHide={handleClose} centered show={showGIFSearch}>
        <Modal.Body>

          <Tenor limit={6} token="21J192D8ZYGA" defaultResults={true} locale='en_GB' searchPlaceholder='Search GIFs' initialSearch='healthy' onSelect={onGIFSelect} />
        </Modal.Body>
      </Modal>
      <Modal className='fileModal' onHide={handleCloseFileUpload} centered show={showFileUpload}>
        <Modal.Body>
          <Row>
          <div className='col-4'>
            <input type="file" id="actual-btn" onChange={onFileChange} hidden/>
            <label className='btn light' for="actual-btn">Choose File</label>
            {selectedFile && (<p>{selectedFile.name}</p>)}
          </div>
          <div className='col-8'>
          <input  type="text" placeholder='Enter custom name or note.' onChange={onFileTextChange} />
          </div>
          </Row>
          <div>
            <button disabled={!selectedFile} onClick={onFileUpload}> 
              Send File
            </button> 
            <p style={{color:'red'}}>{fileError}</p>
          </div>
        </Modal.Body>
      </Modal>                   
    </>

  );
}

function mapStateToProps(state) {
  return {
    heroApiClient: createHeroApiClient(state.user.session.accessToken),
  }
}

export default connect(mapStateToProps)(SendChatMessageInput);
import React, { useState, useEffect } from "react";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Redux Actions
import dataStores from "../../redux/reducers/dataStores";

import * as commandActions from "../../redux/actions/commands";
import { invalidateHealthData } from "../../redux/actions/healthData";

// Bootstrap
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Image from 'react-bootstrap/Image'

// Hero Components
import HeroOutlineButton from '../../common/Buttons/OutlineButton';

// Other imports
import FoodServingSelection from "./food-serving-selection";

// hero Core API
import { NutritionApi } from "hero-api-client";

import { Row, Col, Card, ButtonGroup, Button } from "react-bootstrap";

import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import * as moment from "moment";
import { FaInfo, FaPlus, FaTimes } from "react-icons/fa";

const NuApi = new NutritionApi({ basePath: process.env.REACT_APP_API_BASE_URL });

function FoodQuickAdd(props) {

  const [selectedFoodId, setSelectedFoodId] = useState(0);
  const [selectedFoodDetail, setSelectedFoodDetail] = useState({});

  const [recentResults, setRecentResults] = useState([]);
  const [recentResultsVisible, setRecentResultsVisible] = useState(true);
  const [mostResults, setMostResults] = useState([]);
  const [mostResultsVisible, setMostResultsVisible] = useState(true);
  const [infoFoodId, setInfoFoodId] = useState(0);
  const [infoFoodDetail, setInfoFoodDetail] = useState({});
  const [cloneDay, setCloneDay] = useState(moment(props.forDate).subtract("days", 1).format("YYYY-MM-DD"))

  // This useEffect watches the selected food id, when it changes, this will get the details of the selected food
  useEffect(() => {
    const getSelectedFoodDetail = () => {
      if (selectedFoodId > 0) {

        NuApi.foodDetail(selectedFoodId, {
          headers: {
            "hero-token": props.heroToken
          }
        }).then(res => {
          if (res != null) {
            setSelectedFoodDetail(res);
            setRecentResultsVisible(false);
            setMostResultsVisible(false);
          }
        });

        // Load the details for this food ID
      }
      if (selectedFoodId == 0) {
        setRecentResultsVisible(true);
        setMostResultsVisible(true);
      }
    };
    getSelectedFoodDetail();
  }, [selectedFoodId, props.heroToken]);

  // This useEffect turns on the detailed display or hides it
  useEffect(() => {
    if (selectedFoodId > 0 && selectedFoodDetail) {
      //Hide the results
      setRecentResultsVisible(false);
      setMostResultsVisible(false);
    }
  }, [selectedFoodDetail, selectedFoodId]);

  function getRecentFood(meal) {
    NuApi.foodRecent(meal, {
      headers: {
        "hero-token": props.heroToken
      }
    }).then(res => {
      if (res.foods != null) {
        if (res.foods.food != null) {
          setRecentResults(res.foods.food);
        }
      }
    });
  }

  function foodAddingComplete() {
    setSelectedFoodId(0);
    setSelectedFoodDetail({});
    setRecentResultsVisible(true);
    setMostResultsVisible(true);
    props.invalidateHealthData(props.userProfile.id, moment().format('YYYY-MM-DD'));
    props.reload();
  }

  useEffect(() => {
    const getInfoFoodDetail = () => {
      if (infoFoodId > 0) {
        NuApi.foodDetail(infoFoodId, {
          headers: {
            "hero-token": props.heroToken
          }
        }).then(res => {
          if (res != null) {
            setInfoFoodDetail(res);
            setMostResultsVisible(false);
            setRecentResultsVisible(false);
          }
        });
      }
      else {
        setInfoFoodDetail({ food: null });
        if (selectedFoodId === 0) {
          setMostResultsVisible(true);
          setRecentResultsVisible(true);
        }
      }
    };
    // Load the details for this food ID
    getInfoFoodDetail();
  }, [infoFoodId, props.heroToken]);

  // This useEffect turns on the detailed display or hides it
  useEffect(() => {
    if (infoFoodId > 0 && infoFoodDetail) {
      //Hide the results
      setMostResultsVisible(false);
      setRecentResultsVisible(false);
    }
  }, [infoFoodDetail, infoFoodId]);

  function getMostFood(meal) {
    NuApi.foodMost(meal, {
      headers: {
        "hero-token": props.heroToken
      }
    }).then(res => {
      if (res.foods != null) {
        if (res.foods.food != null) {
          setMostResults(res.foods.food);
        }
      }
    });
  }

  function getHistory() {
    {
      if (
        props.heroToken != null &&
        recentResults.length == 0 &&
        mostResults.length == 0
      ) {
        getRecentFood();
        getMostFood();
      }
    }
  }

  function DrawMealItem(item) {
    return (
      <div
        key={item.food_id}
        style={{ marginBottom: "15px" }}
      >
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                <h4 className="card-title">
                  {item.food_name}
                  <span className="badge badge-secondary badge--small float-right">
                    {item.brand_name == "Colour Fit" ? "Colour-Fit" : item.brand_name}
                  </span>
                </h4>
                <p className="card-text">{item.food_description}</p>
                <Button className="mr-2" variant={"primary"} onClick={() => setSelectedFoodId(item.food_id)}>
                  <FaPlus /> Select
                </Button>
                <Button variant="primary-text" onClick={() => {
                  if (infoFoodId === 0) {
                    setInfoFoodId(item.food_id);
                  }
                  else {
                    setInfoFoodId(0);
                  }
                }}>
                  {infoFoodId === 0 ? <><FaInfo /> {`Nutrition`}</> : <><FaTimes /> {`Close`}</>}
                </Button>
              </Card.Body>
            </Card>
          </Col>
          {/* <Col xs={1}>
            {item.brand_name == "Colour Fit" || item.brand_name == "Colour-Fit" ?
              <Image
                width={150}
                height={108}
                alt="150x108"
                src="https://herowellbeing.com/wp-content/uploads/2018/12/colourfit_logo_black_on_white-300x216.jpg" roundedCircle></Image>
              : undefined
            }
          </Col> */}
        </Row>
        <Row>
          {infoFoodDetail.food ?
            infoFoodDetail.food.food_id == item.food_id ?
              infoFoodDetail.food.servings.serving.map(serving => {
                return (
                  <div
                    key={serving.serving_id}
                    className="card"
                    style={{ margin: "15px", padding: "15px" }}
                  >
                    <div className="card-body">
                      <Row>
                        <Col>
                          <Row>
                            <h5 className="card-title">Serving: {serving.serving_description} ({serving.metric_serving_amount}{serving.metric_serving_unit})</h5>
                          </Row>
                          <Row>
                            <Col>
                              <p>Calcium: {serving.calcium ? serving.calcium : 0} %</p>
                              <p>Iron: {serving.iron ? serving.iron : 0} %</p>
                              <p>Potassium: {serving.potassium ? serving.potassium : 0} mg</p>
                              <p>Sodium: {serving.sodium ? serving.sodium : 0} mg</p>
                            </Col>
                            <Col>
                              <p>Carbohydrates: {serving.carbohydrate ? serving.carbohydrate : 0} g</p>
                              <p>Fibre: {serving.fiber ? serving.fiber : 0} g</p>
                              <p>Sugar: {serving.sugar ? serving.sugar : 0} g</p>
                            </Col>
                            <Col>
                              <p>Protein: {serving.protein ? serving.protein : 0} g</p>
                            </Col>
                            <Col>
                              <p>Fat: {serving.fat ? serving.fat : 0} g</p>
                              <p>Saturated Fat: {serving.saturated_fat ? serving.saturated_fat : 0} g</p>
                              <p>Polyunsaturated Fat: {serving.polyunsaturated_fat ? serving.polyunsaturated_fat : 0} g</p>
                              <p>Monounsaturated Fat: {serving.monounsaturated_fat ? serving.monounsaturated_fat : 0} g</p>
                            </Col>
                            <Col>
                              <p>Vitamin A: {serving.vitamin_a ? serving.vitamin_a : 0} %</p>
                              <p>Vitamin C: {serving.vitamin_c ? serving.vitamin_c : 0} %</p>
                            </Col>
                          </Row>
                          <Row>
                            <p> % value are based on percentage of recommended daily intake of a 2000 calorie diet </p>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </div>
                )
              })
              : undefined
            : undefined}
        </Row>
      </div>
    );
  }
  return (

    <div className="quick-add-food">
      {getHistory()}
      <Tabs fill defaultActiveKey="Yesterday" variant="pills" id="uncontrolled-tab-AddFood" onSelect={() => setInfoFoodId(0)}>
        <Tab eventKey="Yesterday" title="Date">
          <Row className="d-flex justify-content-center align-items-center">
            <Col md={8}>
              <DayPickerInput
                value={moment(cloneDay).format("Do MMMM YYYY")}
                format="Do MMMM YYYY"
                placeholder="DD-MM-YYYY"
                formatDate={(d, f) => moment(d).format(f)}
                onDayChange={day => {
                  setCloneDay(moment(day).format("YYYY-MM-DD"));
                }}
              />
            </Col>
            <Col md={4}>
              <Button className="btn__outline" onClick={()=> props.cloneDay(cloneDay)}>Clone Day</Button>
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="RecentFood" title="Recent">
          {selectedFoodId === 0}
          {recentResults.map(item => {
            return (
              <div>
                {recentResultsVisible || item.food_id == infoFoodId || item.food_id == selectedFoodId ?
                  DrawMealItem(item)
                  : undefined}
              </div>
            );
          })}
        </Tab>
        <Tab eventKey="MostFood" title="Popular">
          {selectedFoodId === 0}
          {mostResults.map(item => {
            return (
              <div>
                {mostResultsVisible || item.food_id == infoFoodId ?
                  DrawMealItem(item)
                  : undefined}
              </div>
            );
          })}

        </Tab>
      </Tabs>

      {selectedFoodDetail.food && getFoodServing()}
    </div>
  );

  function getFoodServing() {
    if (selectedFoodDetail.food) {
      return (
        <Card>
          <Card.Body>
            <FoodServingSelection
              foodId={selectedFoodDetail.food.food_id}
              entryName={selectedFoodDetail.food.food_name}
              meal={props.mealName}
              servings={selectedFoodDetail.food.servings.serving}
              forDate={props.forDate}
              foodAddedComplete={foodAddingComplete}
              hideMealSelection={props.hideMealSelection}
              foodCancel={e=> setSelectedFoodId(0)} />
          </Card.Body>
        </Card>
      );
    }
    return <></>;
  }
}

function mapStateToProps(state, ownProps) {
  return {
    heroToken: state.appObject[dataStores.DELEGATED_PERMISSIONS].coachToken ? state.appObject[dataStores.DELEGATED_PERMISSIONS].coachToken : state.appObject[dataStores.HEADCOACH_PERMISSIONS].coachToken ? state.appObject[dataStores.HEADCOACH_PERMISSIONS].coachToken : state.user.session.accessToken,
    feed: state.feed,
    mealName: ownProps.mealName,
    forDate: ownProps.forDate,
    reload: ownProps.reload,
    hideMealSelection: ownProps.hideMealSelection,
    userProfile: state.user.profile,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    commandActions: bindActionCreators(commandActions, dispatch),
    invalidateHealthData: bindActionCreators(invalidateHealthData, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FoodQuickAdd);

import React, { useState, useEffect, useRef } from 'react'
import { connect } from "react-redux";
import { useParams, withRouter, useHistory } from "react-router-dom";
import { fetchUsers } from '../redux/actions/users'
import moment from 'moment';
import { Container, Spinner, Button, Modal, Row, Col, Card, Tab, Tabs } from 'react-bootstrap'
import LoadingSpinner from '../components/controls/LoadingSpinner';
import { createHeroApiClient } from '../hero-api-client';
import {EnvelopeIcon,ChatBubbleLeftIcon} from '@heroicons/react/24/solid'
import './chat.scss'
function UserSearch(props) {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchedUsers, setSearchUsers] = useState(props.users);
    const [usersByDept, setUsersByDept] = useState([]);
    const [usersByJob, setUsersByJob] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);

    const history = useHistory();
  useEffect(() => {
    props.dispatch(fetchUsers())
  }, []);

useEffect(() => {
    if(!searchTerm || searchTerm===''){
      setUsersByDept([]);
      setUsersByJob([]);
      setSearchUsers(props.users)
    }
    else{
      setSearchUsers(props.users.filter(x=>x.name?.toLowerCase().includes(searchTerm.toLowerCase())));
      setUsersByDept(props.users.filter(x=>x.department?.toLowerCase().includes(searchTerm.toLowerCase())));
      setUsersByJob(props.users.filter(x=>x.jobTitle?.toLowerCase().includes(searchTerm.toLowerCase())));
    }
  }, [searchTerm,props.users]);
  const profile = (user) => {
    const source = user.avatarUrl?user.avatarUrl:'https://eu.ui-avatars.com/api?name=' + user.name;
    return (
      <Card className='m-auto max-500 p-3' style={{textAlign:'center'}}>
        <div className='m-auto'>
          <h4>{user.name}</h4>
          <h5>{user.jobTitle}</h5>
          <h6>{user.department}</h6>
          <img className='avatar-large m-2' src={source} />
          <p>{user.bio}</p>
        </div>
      </Card>
    )
  }
  const addBold = (text) => {
    if(!searchTerm || !text || !text.toLowerCase().includes(searchTerm.toLowerCase()))
    {
      return text;
    }
    try{
      const searchSplit = text.split(new RegExp(`(${searchTerm})`, 'gi'));
      return searchSplit.map(text=>{
          if(text.toLowerCase()===searchTerm.toLowerCase()){
              return(<span className='bold'>{text}</span>)
          }
          return (text)
      })
      }catch(e){
          console.error(e)
          return (text)
      }
  }
  const goTo1To1 = (userId) => {
    props.heroApiClient.start1To1(userId).then((res)=>{
      const arnArr = res.channelSummary.channelArn.split('/');
      history.push("/chat/"+arnArr[arnArr.length-1]);
    })
  }
  const userRow = (user) => {
    const source = user.avatarUrl?user.avatarUrl:'https://eu.ui-avatars.com/api?name=' + user.name;
    const className = 'userSearchRow' + (user.id === selectedUser?.id?' selected':'');
    return(
    <Row onClick={()=>setSelectedUser(user)} className={className} >
      <img className='avatar-xxsmall' src={source} />
      <div  className='col-8'>
        <div>
          <p>{addBold(user.name)}</p>
            <div>
            <p className='small'>{addBold(user.jobTitle)}</p>
            <p className='small'>{addBold(user.department)}</p>
            </div>
        </div>
    </div>
    <div className='col-2'>
      <Row>
        {user.emailAddress && <a className='none' href={"mailto:"+user.emailAddress}><EnvelopeIcon style={{color:'grey',height:'15px'}}/></a>}
        <button className='none' onClick={()=>goTo1To1(user.id)}><ChatBubbleLeftIcon style={{color:'grey',height:'15px'}} /> </button>
      </Row>
    </div>
    </Row>)
  }

  return (
    <Row>
      <Card className="col-4">
            
        <input placeholder='Search...' 
              value={searchTerm}
              onChange={(event) =>{setSearchTerm(event.target.value)}} className='m-2' />
        <div className='chat_finder mt-3'>
        <div class={'input_msg_write'} >
            {searchedUsers.map(user=>{
                return userRow(user);
            })}
            {usersByDept && usersByDept.length > 0 && (<><h4>Results by department</h4> {usersByDept.map(user=>{
              return userRow(user);
            })}</>)}
            {usersByJob && usersByJob.length > 0 && (<><h4>Results by job title</h4> {usersByJob.map(user=>{
              return userRow(user);
            })}</>)}

        </div>
        </div>
      </Card>
      <div className='col-8'>
        {selectedUser && profile(selectedUser)}
      </div>
    </Row>
  );
}

const mapStateToProps = state => {
  return {
    users: state.users.items.filter(x=>x.id !==state.user.profile.id),
    user: state.user,
    heroApiClient: createHeroApiClient(state.user.session.accessToken)
  };
}

export default withRouter(connect(mapStateToProps)(UserSearch));


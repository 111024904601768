import {
    REQUEST_AUTHENTICATED_USER_PROFILE,
    RECEIVE_AUTHENTICATED_USER_PROFILE,
    INVALIDATE_AUTHENTICATED_USER_PROFILE
} from '../actions/userProfile';
import { BEGIN_USER_SESSION, END_USER_SESSION } from '../actions/userSession';

const initialState = {
    isFetching: false,
    isValid: false,
};

export default function userProfile(
    state = initialState,
    action
) {
    switch (action.type) {
        case REQUEST_AUTHENTICATED_USER_PROFILE:
            return {
                ...state,
                isFetching: true
            }
        case RECEIVE_AUTHENTICATED_USER_PROFILE:
            return {
                ...state,
                ...action.payload.user,
                isFetching: false,
                isValid: true
            }
        case INVALIDATE_AUTHENTICATED_USER_PROFILE:
            return {
                ...state,
                isValid: false
            }
        case BEGIN_USER_SESSION:
            return {
                ...state,
                givenName: action.payload.givenName,
                familyName: action.payload.familyName,
            }
        case END_USER_SESSION:
            return initialState
        default:
            return state
    }
}

import { createHeroApiClient } from '../../hero-api-client';
import coaches from '../reducers/Coaches';
import { createError } from './errors';

export const REQUEST_COACHES = 'REQUEST_COACHES'
function requestMeetings() {
    return {
        type: REQUEST_COACHES
    }
}

export const RECEIVE_COACHES = 'RECEIVE_COACHES'
function receiveCoaches(coaches) {
    return {
        type: RECEIVE_COACHES,
        payload: {
            coaches:coaches
        }
    }
}

export const INVALIDATE_COACHES = 'INVALIDATE_COACHES'
export function invalidateCoaches() {
    return {
        type: INVALIDATE_COACHES
    }
}


function doFetchCoaches(accessToken) {
    return async dispatch => {
        dispatch(requestMeetings());
        createHeroApiClient(accessToken).coaches.get()
            .then((coaches) => dispatch(receiveCoaches(coaches)))
            .catch(error => dispatch(createError(error.message)));
    }
}

function shouldFetchCoaches(state) {
    const coaches = state.coaches
    if (!coaches) {
        return true
    } else if (coaches.isFetching) {
        return false
    } else {
        return !coaches.isValid
    }
}

export function fetchCoaches() {
    return async (dispatch, getState) => {
        const state = getState()
        const accessToken = state.user.session.accessToken
        return dispatch(doFetchCoaches(accessToken))
    }
}

export const REQUEST_MINE = 'REQUEST_MINE'
function requestMine() {
    return {
        type: REQUEST_MINE
    }
}

export const RECEIVE_MINE = 'RECEIVE_MINE'
function receiveMine(mine) {
    return {
        type: RECEIVE_MINE,
        payload: {
            mine:mine
        }
    }
}

export const INVALIDATE_MINE = 'INVALIDATE_MINE'
export function invalidateMine() {
    return {
        type: INVALIDATE_MINE
    }
}


function doFetchMine(accessToken) {
    return async dispatch => {
        dispatch(requestMine());
        createHeroApiClient(accessToken).coaches.getMine()
            .then((coaches) => dispatch(receiveMine(coaches)))
            .catch(error => dispatch(createError(error.message)));
    }
}

export function fetchMine() {
    return async (dispatch, getState) => {
        const state = getState()
        const accessToken = state.user.session.accessToken
        return dispatch(doFetchMine(accessToken))
    }
}





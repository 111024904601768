import {
    REQUEST_AUTHENTICATED_USER_GOALS,
    RECEIVE_AUTHENTICATED_USER_GOALS,
    INVALIDATE_AUTHENTICATED_USER_GOALS,
    UPDATE_AUTHENTICATED_USER_GOAL
} from '../actions/userGoals'
import { END_USER_SESSION } from '../actions/userSession'

const initialState = {
    isFetching: false,
    isValid: false,
    items: []
}

export default function userGoals(
    state = initialState,
    action
) {
    switch (action.type) {
        case REQUEST_AUTHENTICATED_USER_GOALS:
            return {
                ...state,
                isFetching: true,
                isValid: true
            }
        case RECEIVE_AUTHENTICATED_USER_GOALS:
            return {
                ...state,
                isFetching: false,
                isValid: true,
                items: action.payload
            }
        case INVALIDATE_AUTHENTICATED_USER_GOALS:
            return {
                ...state,
                isValid: false
            }
        case UPDATE_AUTHENTICATED_USER_GOAL:
            return {
                ...state,
                items: state.items.map(x => ({
                    ...x,
                    value: x.healthMetricId == action.payload.healthMetricId
                        ? action.payload.value
                        : x.value
                }))
            }
        case END_USER_SESSION:
            return initialState
        default:
            return state
    }
}
import { createHeroApiClient } from '../../hero-api-client';
import { createError } from './errors';

export const REQUEST_GROUPS = 'REQUEST_GROUPS'
function requestGroups() {
    return {
        type: REQUEST_GROUPS
    }
}

export const RECEIVE_GROUPS = 'RECEIVE_GROUPS'
function receiveGroups(groups) {
    return {
        type: RECEIVE_GROUPS,
        payload: {
            groups: groups,
            receivedAt: Date.now()
        }
    }
}

export const INVALIDATE_GROUPS = 'INVALIDATE_GROUPS'
export function invalidateGroups() {
    return {
        type: INVALIDATE_GROUPS
    }
}

export const REQUEST_GROUP_USER = 'REQUEST_GROUP_USER'
function requestGroupUser() {
    return {
        type: REQUEST_GROUP_USER
    }
}

export const RECEIVE_GROUP_USER = 'RECEIVE_GROUP_USER'
function receiveGroupUser(groupUser) {
    return {
        type: RECEIVE_GROUP_USER,
        payload: {
            groupUser,
        }
    }
}

export const INVALIDATE_GROUP_USER = 'INVALIDATE_GROUP_USER'
export function invalidateGroupUser() {
    return {
        type: INVALIDATE_GROUP_USER
    }
}

function doFetchGroups(accessToken) {
    return async dispatch => {
        dispatch(requestGroups());
        createHeroApiClient(accessToken).groups.list()
            .then((groups) => dispatch(receiveGroups(groups)))
            .catch(error => dispatch(createError(error.message)));
    }
}

function shouldFetchGroups(state) {
    const groups = state.groups
    if (!groups) {
        return true
    } else if (groups.isFetching) {
        return false
    } else {
        return !groups.isValid
    }
}

export function fetchGroups() {
    return async (dispatch, getState) => {
        const state = getState()
        const accessToken = state.user.session.accessToken
        return dispatch(doFetchGroups(accessToken))
    }
}

export function createGroup(details, userId = null, dmGroup = null) {
    return async (dispatch, getState) => {
        const accessToken = getState().user.session.accessToken;
        createHeroApiClient(accessToken).groups.create({...details, userId, dmGroup})
            .then(() => dispatch(invalidateGroups()))
            .catch(error => dispatch(createError(error.message)));
    }
}

export function inviteUserToGroup(groupId, userId, cb) {
    return async (dispatch, getState) => {
        const accessToken = getState().user.session.accessToken;
        const groupClient = createHeroApiClient(accessToken).groups
            .createGroupClient(groupId);
        groupClient.invitations.create([ userId ])
            .then(()=>{
                if(cb) {
                    cb();
                }
            })
            .catch(error => dispatch(createError(error.message)));
    }
}

export function leaveGroup(groupId, cb) {
    return async (dispatch, getState) => {
        const accessToken = getState().user.session.accessToken;
        const groupClient = createHeroApiClient(accessToken).groups
            .createGroupClient(groupId);
        groupClient.leave()
            .then(() => {
                dispatch(invalidateGroups());
                if (cb) cb();
            })
            .catch(error => dispatch(createError(error.message)));
    }
}

export function getGroupUser(groupId) {
    return async (dispatch, getState) => {
        dispatch(requestGroupUser());
        const accessToken = getState().user.session.accessToken;
        const groupClient = createHeroApiClient(accessToken).groups
            .createGroupClient(groupId);
        groupClient.user.get()
            .then(groupUser => {
                dispatch(receiveGroupUser(groupUser));
            })
            .catch(error => dispatch(createError(error.message)));
    }
}

export function updateGroupUser(groupId, groupUser) {
    return async (dispatch, getState) => {
        const accessToken = getState().user.session.accessToken;
        const groupClient = createHeroApiClient(accessToken).groups
            .createGroupClient(groupId);
        groupClient.user.update(groupUser)
            .then(groupUser => {
                dispatch(receiveGroupUser(groupUser));
            })
            .catch(error => dispatch(createError(error.message)));
    }
}

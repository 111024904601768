import { createAuthenticatedUserClient } from './authenticatedUser';
import { createChallengesClient } from './challenges';
import { createGroupsClient } from './groups';
import {createChatsClient} from './chats'
import {createVideosClient} from './video'
import {createCoachClient} from './coaches'

window.fetching = false;
window.fetchingTimeout = null;

const fetchDone = () => {
    if (window.fetchingTimeout != null) {
        clearTimeout(window.fetchingTimeout);
    }
    window.fetchingTimeout = setTimeout(() => {
        window.fetching = false;
        window.fetchingTimeout = null;
    }, 3000);
}

export const createHeroApiClient = (accessToken, abortSignal) => {
    const sendRequest = async (path = '/', method = 'GET', body = {},bodyPost = false) => {
        window.fetching = true;

        const url = `${process.env.REACT_APP_API_BASE_URL}${path}`;
        const options = {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'hero-token': accessToken
            },
            signal: abortSignal
        };
        if(bodyPost){
            options.headers = {
                'hero-token': accessToken
            };
        }
        if (['POST', 'PUT'].includes(method)) {
            options.body = bodyPost?body:JSON.stringify(body);
            
        }
        const response = await fetch(url, options);
        fetchDone();
        if (!response.ok) {
            const contentType = response.headers.get('Content-Type') || response.headers.get('content-type');
            if (contentType && contentType.includes('application/json')) {
                const error = await response.json();
                throw new Error(error.message || response.statusText);
            } else {
                throw new Error(response.statusText);
            }
        }

        if (method === 'GET'
            || path == '/token'
            || path == '/sso'
            || path.startsWith('/commands/handler')
            || (method === 'PUT' && path.includes('/group') && path.includes('/user'))
            || path.includes('/chime/JoinMeeting')
            || path.includes('/chime/CurrentlyInMeeting')
        ) {
            return await response.json()
        }
    };

    const getCompany = async (inviteCode) => {
        const path = `/company?inviteCode=${inviteCode}`;
        return await sendRequest(path);
    }

    const registerUser = async ({ emailAddress, password, givenName, familyName, companyKey, region, department, inviteCode, x }) => {
        const path = '/register';
        const method = 'POST';
        const body = { emailAddress, password, givenName, familyName, companyKey, region, department, inviteCode, x };
        return await sendRequest(path, method, body);
    };

    const getRegionsList = async (companyKey) => {
        const path = `/app/getRegistrationInputList?companyCode=${companyKey}`;
        const method = 'GET';
        return await sendRequest(path, method);
    }

    const getToken = async (username, x, password, companyKey) => {
        const path = '/token';
        const method = 'POST';
        const body = {
            grant_type: 'password',
            x:x,
            username: username,
            password: password,
            company_key: companyKey
        }
        return await sendRequest(path, method, body);
    }

    const getSSOToken = async (accessToken,companyKey,SsoType,ssoUserInitial) => {
        const path = '/sso';
        const method = 'POST';
        const ssoUser = {
            Id: ssoUserInitial.id,
            Email: ssoUserInitial.emailAddress,
            FirstName: ssoUserInitial.familyName,
            LastName: ssoUserInitial.givenName,
        }
        const body = {
            companyKey,
            accessToken,
            SsoType,
            ssoUser
        }
        return await sendRequest(path, method, body);
    }

    const requestPasswordReset = async({email}) => {
        const path = `/users/reset-password`;
        const method = 'POST';
        const body = {email};
        return await sendRequest(path,method,body);
    }
    const resetPassword = async(email,token,password,confirm_password,x) =>{
        const path=`/users/reset-password/Confirm`
        const method = 'POST';
        const body={email,token,password,confirm_password,x};
        return await sendRequest(path,method,body);
    }

    const getCreators = async(id) => {
        const path=`/creators/`+id;
        return await sendRequest(path);
    }
    
    const updateUser = async ({ id, emailAddress, password, givenName, familyName, gender, avatarUrl, dateOfBirth, region, department, wheelConfig,socialLink1,socialLink2,socialLink3 }) => {
        const path = `/users/${id}`;
        const method = 'POST';
        const body = { givenName, familyName, gender, emailAddress, password, avatarUrl, dateOfBirth, region, department, wheelConfig,socialLink1,socialLink2,socialLink3  };
        return await sendRequest(path, method, body);
    }

    const getHrReport = async (startDate, endDate, userId) => {
        const path = `/reports/hr-report?startDate=${startDate}&endDate=${endDate}&userId=${userId || ''}`;
        return await sendRequest(path);
    }

    const getHealthData = async (userId, normalDate, utcoffsetNew) => {
        const path =`/health-data?userId=${userId}&forDate=${normalDate}&utcoffset=${utcoffsetNew}`;
        return await sendRequest(path);
    }

    const getHealthDataRange = async (userId, fromDate, toDate) => {
        const path =`/health-data/range?userId=${userId}&fromDate=${fromDate}&toDate=${toDate}`;
        return await sendRequest(path);
    }

    const getHealthDataMood = async (userId, fromDate, toDate) => {
        const path =`/health-data/mood?userId=${userId}&fromDate=${fromDate}&toDate=${toDate}`;
        return await sendRequest(path);
    }

    const addHealthData = async (healthMetricId, value, normalDate) => {
        const path =`/health-data`;
        const method = 'POST';
        const body = { healthMetricId, value, forDate: normalDate, source: 'web-app' };
        return await sendRequest(path, method, body);
    }

    const setEnergyConsumedIsCompleted = async (normalDate, value) => {
        const path = `/health-data/set-energy_consumed_is_completed`
        const method = 'POST'
        const body = { forDate: normalDate, value };
        return await sendRequest(path, method, body);
    }

    const createEngagementEvent = async (event) => {
        const path = '/engagement-events';
        const method = 'POST';
        const body = {event};
        return await sendRequest(path, method, body);
    }

    const getHealthMetrics = async () => {
        const path = '/health-metrics';
        return await sendRequest(path);
    }

    const sendCommand = async ({ objectType, objectData }) => {
        const path = `/commands/handler?type=${objectType}`;
        const method = 'POST';
        const body = { objectType, objectData };
        return await sendRequest(path, method, body);
    }

    const getChallengesJoinable = async () => {
        return await sendRequest('/challenges/joinable');
    }

    const getChallengesCurrent = async () => {
        return await sendRequest('/challenges/current');
    }

    const getChallengesPast = async () => {
        return await sendRequest('/challenges/past');
    }

    const getChallengesUpcoming = async () => {
        return await sendRequest('/challenges/upcoming');
    }

    const checkToken = async () => {
        const path = '/Auth/Validate';
        return await sendRequest(path);
    }
    const getChatDetail = async (channelId) =>{
        const path = `/chat/${channelId}`;
        return await sendRequest(path);
    }
    const sendFileToChat = async(channelId,FormData) => {
        const path = `/chat/${channelId}/files`;
        const method = 'POST'
        const body = {File:FormData, FakeName:'test'};
        return await sendRequest(path,method,FormData,true)
    }
    const start1To1 = async(userId) => {
        const path = `/chat/1-1/${userId}`;
        console.log(userId);
        return await sendRequest(path);
    }
    const requestGuestMeeting = async(meetingId) => {
        const path = `/chime/JoinMeetingGuest?MeetingId=${meetingId}`;
        return await sendRequest(path);
    }
    const startMeeting = async(meetingId) => {
        const path = `/chime/JoinMeeting?MeetingId=${meetingId}`;
        const method = 'POST';
        return await sendRequest(path,method);
    }
    const sendFeedback = async(meetingId,feedback,rating) => {
        const path = `/chime/Feedback?meetingId=${meetingId}&feedback=${feedback}&stars=${rating}`;
        const method = 'POST';
        return await sendRequest(path,method);
    }
    const inMeeting = async(meetingId) => {
        const path = `/chime/CurrentlyInMeeting/${meetingId}`;
        return await sendRequest(path);
    }

    const createAvailability = async(start,end,period) => {
        const path = `/chimecalendar/avail/${start}/${end}/${period}`;
        const method = 'POST';
        return await sendRequest(path,method);
    }
    const bookAvailability = async(userId,dateTime,coach=false) => {
        const path = `/chimecalendar/book/${userId}/${dateTime}/${coach}`;
        const method = 'POST';
        return await sendRequest(path,method);
    }
    const cancelAvail = async(dateTime) => {
        const path = `/chimecalendar/cancel/${dateTime}`;
        const method = 'POST';
        return await sendRequest(path,method);
    }
    const createMeeting = async(date, endDate, title, userIds) => {
        const path = `/chime/Meeting?date=${date}&endDate=${endDate}&title=${title}`;
        const body = userIds;
        const method = 'POST';
        return await sendRequest(path,method, body);
    }
    const cancelMeeting = async(meetingId) => {
        const path = `/chime/Meeting?meetingId=${meetingId}`;
        const method = 'DELETE';
        return await sendRequest(path,method);
    }
    const searchUsers = async(searchTerm) => {
        const path = `/user/search/${searchTerm}`;
        return await sendRequest(path);
    }
    const updateAttendees = async(meetingId, userIds) => {
        const path = `/chime/MeetingAttendees/${meetingId}`
        const body = userIds;
        const method = 'PUT';
        return await sendRequest(path,method, body);
    }
    return {
        createAvailability,
        bookAvailability,
        cancelAvail,
        createMeeting,
        cancelMeeting,
        getCompany,
        registerUser,
        getToken,
        updateUser,
        requestPasswordReset,
        getHrReport,
        resetPassword,
        getHealthData,
        searchUsers,
        getHealthDataRange,
        getHealthDataMood,
        addHealthData,
        setEnergyConsumedIsCompleted,
        createEngagementEvent,
        getHealthMetrics,
        sendCommand,
        getSSOToken,
        getChallengesJoinable,
        getChallengesCurrent,
        getChallengesPast,
        getChallengesUpcoming,
        checkToken,
        getRegionsList,
        getCreators,
        getChatDetail,
        sendFileToChat,
        start1To1,
        startMeeting,
        requestGuestMeeting,
        updateAttendees,
        sendFeedback,
        inMeeting,
        authenticatedUser: createAuthenticatedUserClient(sendRequest),
        challenges: createChallengesClient(sendRequest),
        groups: createGroupsClient(sendRequest),
        chats: createChatsClient(sendRequest),
        meetings: createVideosClient(sendRequest),
        coaches: createCoachClient(sendRequest)
    };
};

export const EngagementEvent = {
    LOGIN: 'Login',
    VIEW_DASHBOARD: 'ViewDashboard',
    VIEW_CALENDAR: 'ViewCalendar',
    VIEW_REPORTS: 'ViewReports',
    VIEW_DIARY: 'ViewDiary',
    VIEW_MEALS: 'ViewMeals',
    VIEW_MEAL_PLANS: 'ViewMealPlans',
    VIEW_MEAL_RESOURCES: 'ViewMealResources',
    VIEW_MEAL_BUILDERS: 'ViewMealBuilders',
    VIEW_HR_REPORTS: 'ViewHRReports',
    VIEW_HR_USER_MANAGEMENT: 'ViewHRUserManagement',
    VIEW_HEALTH_CHECK: 'ViewHealthCheck',
    VIEW_HEALTH_ASSESSMENT: 'ViewHealthAssessment',
    VIEW_HEALTH_STORE: 'ViewHealthStore',
    VIEW_CHALLENGES: 'ViewChallenges',
    VIEW_GROUPS: 'ViewGroups',
    VIEW_WELLNESS_LIBRARY: 'ViewWellnessLibrary',
    VIEW_FITNESS_LIBRARY: 'ViewFitnessLibrary',
    VIEW_HEALTH_BENEFITS: 'ViewHealthBenefits',
    VIEW_MY_PROFILE: 'ViewMyProfile',
    VIEW_MY_GOALS: 'ViewMyGoals',
    VIEW_MY_APP_CONNECTIONS: 'ViewMyAppConnections',
    VIEW_MY_SOURCES: 'ViewMySources',
    VIEW_MY_MODULES: 'ViewMyModules',
    VIEW_NUTRITION_CALENDAR: 'ViewNutritionCalendar',
    BASIC_FOOD_SEARCH: 'BasicFoodSearch',
    ADD_CONSUMED_FOOD: 'AddConsumedFood',
    REMOVE_CONSUMED_FOOD: 'RemoveConsumedFood'
};

import { REQUEST_HEALTH_METRICS, RECEIVE_HEALTH_METRICS } from '../actions/healthMetrics'
import { END_USER_SESSION } from '../actions/userSession'

const initialState = {
    isFetching: false,
    isValid: false,
    items: []
}

export default function healthMetrics(state = initialState, action) {
    switch (action.type) {
        case REQUEST_HEALTH_METRICS:
            return {
                ...state,
                isFetching: true,
                isValid: true
            }
        case RECEIVE_HEALTH_METRICS:
            return {
                ...state,
                isFetching: false,
                isValid: true,
                items: action.payload
            }
        case END_USER_SESSION:
            return initialState
        default:
            return state
    }
}
import React, { useState, useEffect } from "react";
import { Button, ButtonGroup, Row, Col } from "react-bootstrap";
import FeedCard from "./feedCard/feedCard";
import HeroOutlineButton from "../../common/Buttons/OutlineButton";
import { connect } from "react-redux";
import AddData from "../quickadd/AddData";
import Collapse from "@kunukn/react-collapse";
import { fetchHealthData, invalidateHealthData, setEnergyConsumedIsCompleted } from '../../redux/actions/healthData';
import { selectHealthDataForUserOnDate } from '../../redux/reducers/healthData'
import moment from 'moment';
import { FaSync, FaPlus } from "react-icons/fa";
import LoadingSpinner from '../controls/LoadingSpinner';

function Feed(props) {
  const [addData, setAddData] = useState({ visible: false });
  const [nutritionIsComplete, setNutritionIsComplete] = useState(false);
  const [abortController, setAbortController] = useState();
  const isVodafone = props.userProfile.companyKey === 'vodafoneglobalchallenge'

  useEffect(() => {
    setAbortController(new AbortController());
  }, []);

  useEffect(() => {
    if(abortController && abortController.abort) {
      return () => abortController.abort();
    }
  }, [abortController]);

  useEffect(() => {
    if (!abortController) {
      return;
    }

    [...Array(7).keys()]
      .map(offset => moment.utc(props.dateToDisplay).subtract(offset, 'days').format('YYYY-MM-DD'))
      .forEach(date => {
        props.dispatch(fetchHealthData(props.userProfile.id, date, abortController.signal))
      });
  }, [props.dateToDisplay, props.healthData]);

  useEffect(() => {
    const healthData = selectHealthDataForUserOnDate(props.healthData, props.userProfile.id, props.dateToDisplay);
    if (!healthData) {
      return;
    }

    const energyConsumedData = healthData.find(x => x.healthMetricType == 'energy_consumed');
    if (!energyConsumedData) {
      return;
    }

    setNutritionIsComplete(energyConsumedData.isComplete);
  }, [props.healthData]);

  function toggleAddData() {
    setAddData({ ...addData, visible: !addData.visible });
  }

  function toggleNutritionComplete() {
    props.dispatch(setEnergyConsumedIsCompleted(props.dateToDisplay, !nutritionIsComplete))
  }

  const feedElements = selectHealthDataForUserOnDate(props.healthData, props.userProfile.id, props.dateToDisplay)
    .filter(x => !props.catFilter || x.healthMetricWheelSectionName == props.catFilter);

  return (
    <>
      <div>
        <Row>
          <Col xs="12">
            <ButtonGroup className="mb-3">
              <Button className="btn--circle" variant="primary-text" onClick={() => props.dispatch(invalidateHealthData(props.userProfile.id, props.dateToDisplay))}>
                <FaSync />
              </Button>
              {/*<Button className="btn--circle" variant="primary-text" onClick={toggleAddData}>*/}
              {/*  <FaPlus />*/}
              {/*</Button>*/}
            </ButtonGroup>
          </Col>
        </Row>

        <Collapse
          transition={`height 200 cubic-bezier(.4, 0, .2, 1)`}
          isOpen={addData.visible}
        >
          <AddData
            forDate={props.dateToDisplay}
            category={props.catFilter}
            toggleAddData={toggleAddData} />
        </Collapse>

        {props.catFilter === "nutrition" && (
          <div className="ac" style={{ boxShadow: "1px 1px 7px 0px rgba(0,0,0,0.3)" }} >
            <HeroOutlineButton
              styles={{ margin: "0.75rem 0rem" }}
              size="large"
              colour={nutritionIsComplete ? "#22B4F1" : "#2CF122"}
              text={nutritionIsComplete ? "Mark as uncomplete" : "Mark as complete"}
              onClick={toggleNutritionComplete}
            />
          </div>
        )}

        {feedElements.length == 0 && (
          <LoadingSpinner />
        )}

        {feedElements.length > 0 && feedElements.map(x => (
          <FeedCard
              isVodafone={isVodafone}
            key={x.healthMetricId}
            userId={props.userProfile.id}
            date={props.dateToDisplay}
            healthMetricId={x.healthMetricId}
          />
        ))}
      </div>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    dateToDisplay: ownProps.dateToDisplay,
    catFilter: ownProps.catFilter,
    userProfile: state.user.profile,
    healthData: state.healthData
  };
}

export default connect(mapStateToProps)(Feed);

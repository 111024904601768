import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from 'lodash';
import "./PersonalProfile.css";
import Collapse from "@kunukn/react-collapse";
import HeroOutlineButton from '../../common/Buttons/OutlineButton';
import HeroInputField from '../../common/Inputs/inputField';
import { Row, Col, Card, Button } from "react-bootstrap";
import { endUserSession } from '../../redux/actions/userSession';
import { fetchUsers, updateUser } from '../../redux/actions/users';
import moment from "moment";
import LoadingSpinner from "../controls/LoadingSpinner";
import dataStores from "../../redux/reducers/dataStores";

function PersonalProfile(props) {
  const { userId, regions, departments, wheelConfigs } = props;
  const [model, setModel] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [profilePic, setProfilePic] = useState(null);
  const [fileTooBig, setIsTooBig] = useState(false);
  const [profPicSuccess, setIsUploaded] = useState(false);
  const [fileUploadExtension, setIsValidExtension] = useState(false);
  const [uploadEnabled, setIsUploadEnabled] = useState(false);


  useEffect(() => {
    if (!props.users.isValid) {
      props.dispatch(fetchUsers())
      return
    }

    const user = props.users.items.find(x => x.id == userId)
    setModel(user || {})
  }, [props.users])

  const companyKey = (window.location.host.split(".")[1] && window.location.host.split(".")[1] !== 'app')
    ? window.location.host.split(".")[0]
    : "app";

  const showVodafoneRegionDepartmentFields = companyKey === 'vodafoneglobalchallenge';

  function handleInputChange(event) {
    setModel({
      ...model,
      [event.target.name]: event.target.value
    });
  }

  function handleFileInputChange(event) {
    const profPicSize = event.target.files[0].size / 1000 / 1000;
    const profPicSizeDec = profPicSize.toFixed(1);
    if (profPicSizeDec > 4) {
      setIsTooBig(true);
      setIsUploadEnabled(false);
    } else {
      setIsTooBig(false);
      setIsUploadEnabled(true);
      setProfilePic(event.target.files[0]);
    }
    if ((event.target.files[0].type === "image/jpeg") || (event.target.files[0].type === "image/png")) {
      setIsValidExtension(false);
      setIsUploadEnabled(true);
    } else {
      setIsValidExtension(true);
      setIsUploadEnabled(false);
    }
  }

  function handleFileUploadButtonClick() {
      const formData = new FormData();
      formData.append('file', profilePic, profilePic.name);

      var requestOptions = {
        method: 'POST',
        body: formData,
        headers: { 'hero-token': props.accessToken }
      };

      fetch(`https://core.herowellbeing.com/FileUpload/UploadAFile`, requestOptions)
        .then(response => response.text())
        .then(fileName => setModel({
          ...model,
          avatarUrl: `https://core.herowellbeing.com/FileUpload/DownloadFile?fileName=${fileName}`
        }));
  }

  function handleFormSubmit() {
    if (showVodafoneRegionDepartmentFields && (!model.region || !model.department)) {
      return;
    }
    setIsUploaded(!profPicSuccess)
    props.dispatch(updateUser(model));
    setIsEditing(!isEditing);
  }

  const avatar = model.avatarUrl || "https://eu.ui-avatars.com/api?name="+model.givenName + " " + model.familyName;

  const isColourFit = _.get(props.user, 'config.objectData.styles.defaultHomePage') === 'my-modules';

  return props.users.isFetching
    ? <LoadingSpinner />
    : (
      <Card className="card--profile">
        <Row className="mb-3">
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", backgroundImage: "" }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>


              <div className={'card__profile__image'} style={{backgroundImage: `url('${avatar}')`, width: "130px", height: "130px", borderRadius: "50%", backgroundColor: "grey", backgroundSize:'cover', boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.1225)" }}></div>

              {/*<img src={model.avatarUrl || `https://eu.ui-avatars.com/api?name=${model.givenName} ${model.familyName}`}*/}
              {/*  style={{ display: "block", margin: "-3.5rem auto 0rem auto", width: "130px", height: "130px", borderRadius: "50%", backgroundColor: "grey", boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.1225)" }} />*/}
              <p style={{ marginTop: "1.3rem", marginBottom: "0rem", fontSize: "1.55rem", color: "#0a5d5e" }}>{`${model.givenName} ${model.familyName}`}</p>
              <p style={{ fontSize: "0.85rem", marginTop: "0.1rem", color: "#9b9b9b" }}>
                {model.dateOfBirth && moment(model.dateOfBirth).year() >= 1900 ? `Age: ${moment().diff(moment(model.dateOfBirth), 'year')} | ` : ''}{model.companyName}
              </p>
            </div>

            <div style={{ display: "flex" }}>
                <Button className="mr-2" variant={"primary"} onClick={() => props.dispatch(endUserSession())}>
                  Log Out
                </Button>
                <Button variant={"primary"} onClick={isEditing ? handleFormSubmit : () => setIsEditing(!isEditing)}>
                  {isEditing ? "Save changes" : "Edit Profile"}
                </Button>
            </div>

            <Collapse
              transition={`height 200 cubic-bezier(.4, 0, .2, 1)`}
              isOpen={isEditing}>

              <div style={{ display: "flex", flexDirection: "column", width: "100%", marginTop: "2.5rem" }}>
                <div className="editProfile__box">
                  <Row>
                    <Col>
                      <div className="c-field">
                        <label className="c-field__label">First name</label>
                        <input
                          className="c-input"
                          type="text"
                          value={model.givenName}
                          name="givenName"
                          onChange={handleInputChange}
                          placeholder="Enter your first name" />
                      </div>
                    </Col>
                    <Col>
                      <div className="c-field">
                        <label className="c-field__label">Last name</label>
                        <input
                          className="c-input"
                          type="text"
                          value={model.familyName}
                          name="familyName"
                          onChange={handleInputChange}
                          placeholder="Enter your last name" />
                      </div>
                    </Col>
                  </Row>
                </div>

               



                <div className="editProfile__box">
                  <p style={{ textAlign: "center", marginBottom: "0.25rem", fontSize: "0.95rem", color: "#717171" }}>
                    Gender:
                </p>
                  <select
                  name="gender"
                    value={model.gender}
                    onChange={handleInputChange}
                    className="select"
                    style={{fontSize: "0.95rem"}}
                  >
                    <option value="">Select gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Transgender">Transgender</option>
                    <option value="Other">Other</option>
                    <option value="Prefer not to say">Prefer not to say</option>
                  </select>
                </div>

                <div className="editProfile__box">
                  <p style={{ textAlign: "center", marginBottom: "0.25rem", fontSize: "0.95rem", color: "#717171" }}>
                    Date of Birth:
                </p>
                  <HeroInputField
                    size="small"
                    type="date"
                    value={moment(model.dateOfBirth).format('YYYY-MM-DD')}
                    name="dateOfBirth"
                    onChange={handleInputChange}
                    styles={{ margin: "0rem 1rem 0rem 1rem" }} />
                </div>

                {!!regions.length && (
                  <>
                    <div className="editProfile__box">
                      <p style={{ textAlign: "center", marginBottom: "0.25rem", fontSize: "0.95rem", color: "#717171" }}>
                        Market / Group Function:
                      </p>
                      <select className="select" name="region" value={model.region} onChange={handleInputChange} required style={{fontSize: "0.95rem"}}>
                        <option>Select a Market / Group Function:</option>
                        {regions.map(reg => <option value={reg}>{reg}</option>)}
                      </select>
                    </div>
                  </>
                )}

                {!!departments.length && (
                  <>
                    <div className="editProfile__box">
                      <p style={{ textAlign: "center", marginBottom: "0.25rem", fontSize: "0.95rem", color: "#717171" }}>
                        Department:
                      </p>
                      <select className="select" name="department" value={model.department} onChange={handleInputChange} required style={{fontSize: "0.95rem"}}>
                        <option>Select a department</option>
                        {departments.map(dep => <option value={dep}>{dep}</option>)}
                      </select>
                    </div>
                  </>
                )}

                {!isColourFit && (
                <div className="editProfile__box">
                  <p style={{ textAlign: "center", marginBottom: "0.25rem", fontSize: "0.95rem", color: "#717171" }}>
                    Navigator Wheel Templates:
                </p>
                    <select className="select" name="wheelConfig" value={model.wheelConfig} onChange={handleInputChange} required style={{fontSize: "0.95rem"}}>
                      {wheelConfigs.map(({type, label}) => <option value={type}>{label}</option>)}
                    </select>
                </div>
                )}

                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                  <p style={{ textAlign: "center", marginTop: "0.7rem", marginBottom: "0.75rem", fontSize: "0.95rem", color: "#717171" }}>
                    Update profile picture: <span style={{ color: "#F00", fontWeight: "bold"}}>(max file size 4mb)</span>
                  </p>
                  <input type="file" style={{ display: "block", margin: "auto auto" }} accept=".jpg,.png" onChange={handleFileInputChange} />
                  {uploadEnabled && (
                    <HeroOutlineButton
                      colour="#21b4f1"
                      classes="card-link prof-pic-btn"
                      text="Upload"
                      size="small"
                      onClick={handleFileUploadButtonClick} />
                  )}
                </div>

                {/* <div className="editProfile__box">
                  <p style={{ textAlign: "center", marginBottom: "0.25rem", fontSize: "0.95rem", color: "#717171" }}>
                    Social link 1 (eg: LinkedIn):
                </p>
                  <input
                  name="socialLink1"
                    value={model.socialLink1}
                    onChange={handleInputChange}
                    style={{fontSize: "0.95rem"}}
                  ></input>
                </div>

                <div className="editProfile__box">
                  <p style={{ textAlign: "center", marginBottom: "0.25rem", fontSize: "0.95rem", color: "#717171" }}>
                    Social link 2:
                </p>
                  <input
                  name="socialLink2"
                    value={model.socialLink2}
                    onChange={handleInputChange}
                    style={{fontSize: "0.95rem"}}
                  ></input>
                </div>

                <div className="editProfile__box">
                  <p style={{ textAlign: "center", marginBottom: "0.25rem", fontSize: "0.95rem", color: "#717171" }}>
                    Social link 3:
                </p>
                  <input
                  name="socialLink3"
                    value={model.socialLink3}
                    onChange={handleInputChange}
                    style={{fontSize: "0.95rem"}}
                  ></input>
                </div> */}
              </div>
            </Collapse>
          </div>
        </Row>
        {fileTooBig && (
        <div style={{ position: "fixed", top: "0", right: "0", height: "100%", width: "calc(100% - 220px)", zIndex: "999", backgroundColor: "rgba(255, 255, 255, 0.5)" }}>
          <p style={{ textAlign: "right", marginTop: "0", marginBottom: "0", fontSize: "16px", fontWeight: "bold", color: "#FFF", position: "absolute", padding: "15px 30px", width: "100%", backgroundColor: "#F00" }}>Your selected image exceeds the max size limit (4mb), please reload and try a smaller image <button className="btn btn-primary" style={{ marginLeft: "20px" }} onClick={() => window.location.reload(false)}>Click to reload!</button></p>
        </div>
        )}
        {fileUploadExtension && (
        <div style={{ position: "fixed", top: "0", right: "0", height: "100%", width: "calc(100% - 220px)", zIndex: "999", backgroundColor: "rgba(255, 255, 255, 0.5)" }}>
          <p style={{ textAlign: "right", marginTop: "0", marginBottom: "0", fontSize: "16px", fontWeight: "bold", color: "#FFF", position: "absolute", padding: "15px 30px", width: "100%", backgroundColor: "#F00" }}>Please only upload files in a jpg or png format <button className="btn btn-primary" style={{ marginLeft: "20px" }} onClick={() => window.location.reload(false)}>Click to reload!</button></p>
        </div>
        )}
        {profPicSuccess && (
        <div style={{ position: "fixed", top: "0", right: "0", width: "calc(100% - 220px)", zIndex: "999", backgroundColor: "rgba(255, 255, 255, 0.5)" }}>
          <p style={{ textAlign: "right", marginTop: "0", marginBottom: "0", fontSize: "16px", fontWeight: "bold", color: "#FFF", position: "absolute", padding: "15px 30px", width: "100%", backgroundColor: "#15CBCE" }}>Profile successfully updated</p>
        </div>
        )}
      </Card>
    );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    users: state.users,
    accessToken: state.user.session.accessToken,
    regions: state.appObject[dataStores.APP_CONFIG].regions || [],
    departments: state.appObject[dataStores.APP_CONFIG].departments || [],
    wheelConfigs: state.appObject[dataStores.APP_CONFIG].wheelConfigs || [],
  }
}

export default connect(mapStateToProps)(PersonalProfile);

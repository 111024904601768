
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import HttpsRedirect from 'react-https-redirect';
import { ThemeProvider } from 'styled-components';

import { Provider } from "react-redux";
import store from "./redux/store";
import { ErrorBoundary } from "./ErrorBoundary";
import Utils from './shared/utils';

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <BrowserRouter>
        <HttpsRedirect>
              <App />
        </HttpsRedirect>
      </BrowserRouter>
    </Provider>
  </ErrorBoundary>,
  document.getElementById("root")
);

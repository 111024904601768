import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import FinanceSliderInput from "../../components/FinanceCalculators/FinanceSliderInput";
import SavingsResults from "./SavingsResults";

const SavingsCalculator = () => {

    const [initialDeposit, setInitialDeposit] = useState(1000);
    const [monthlyContribution, setMonthlyContribution] = useState(100);
    const [interestRate, setInterestRate] = useState(2.5);
    const [loanTerm, setLoanTerm] = useState(15);

    const handleValueSliderChange = (value) => {
        setInitialDeposit(value);
    };

    const handleInterestRateSliderChange = (value) => {
        setInterestRate(value);
    };

    const handleLoanTermSliderChange = (value) => {
        setLoanTerm(value);
    };

    const handleMonthlyContributionSliderChange = (value) => {
        setMonthlyContribution(value);
    };

    return (
      <div>
        <Row>
          <Col>
            <h1 className="pb-3">Savings Calculator</h1>
            <p className="pb-4">
              Welcome to our Savings Calculator! This tool is designed to help you estimate the potential growth of your savings over time based on various factors.
              Simply adjust the sliders below to set your initial deposit, monthly contribution, annual interest rate, and the duration of your savings plan. 
              The calculator will then provide you with a projection of your total savings, including contributions and interest earned.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FinanceSliderInput labelBefore="Initial deposit £" minValue={0} maxValue={25000} step={100} toFixed={0} onValueChange={handleValueSliderChange} defaultValue={initialDeposit} />
            <FinanceSliderInput labelBefore="Monthly deposit £" minValue={0} maxValue={5000} step={10} toFixed={0} onValueChange={handleMonthlyContributionSliderChange} defaultValue={monthlyContribution} />
            <FinanceSliderInput labelBefore="At " labelAfter="% gross annual interest rate" minValue={0.1} maxValue={25.9} step={0.01} toFixed={2} onValueChange={handleInterestRateSliderChange} defaultValue={interestRate} />
            <FinanceSliderInput labelBefore="For " labelAfter=" years" minValue={1} maxValue={30} step={1} toFixed={0} onValueChange={handleLoanTermSliderChange} defaultValue={loanTerm} />
          </Col>
          <Col md={6}>
            <SavingsResults initialDeposit={initialDeposit} monthlyContribution={monthlyContribution} interestRate={interestRate} loanTerm={loanTerm} />
            <p>This calculator is for illustrative purposes only. Always check with a financial advisor before investing your money.</p>
          </Col>
        </Row>
        </div>
    );
    
    
};

export default SavingsCalculator;

import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { fetchNotifications } from '../../redux/actions/notifications'
import Notification from './Notification'
import { Container, Row, Col, Spinner } from 'react-bootstrap'

function Notifications(props) {
    useEffect(() => {
        if (!props.notifications || !props.notifications.isValid) {
            props.dispatch(fetchNotifications())
        }
    }, [props.notifications])

    return (
        <Container>
            <Row>
                <Col>
                    {props.notifications
                        && props.notifications.items
                        && !props.notifications.isFetching
                        && props.notifications.items.map(notification => 
                        <Notification notification={notification} />
                    )}
                    {props.notifications && props.notifications.isFetching &&
                        <Spinner />
                    }
                </Col>
            </Row>
        </Container>
    )
}

const mapStateToProps = state => {
    return {
        notifications: state.notifications
    };
}

export default connect(mapStateToProps)(Notifications)
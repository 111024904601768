import { createHeroApiClient } from '../../hero-api-client';
import { createError } from './errors'
import dataStores from '../reducers/dataStores';

export const REQUEST_COMMAND_RESPONSE = 'REQUEST_COMMAND_RESPONSE'
export function requestCommandResponse(requestKey) {
  return {
    type: REQUEST_COMMAND_RESPONSE,
    payload: { requestKey }
  }
}

export const RECEIVE_COMMAND_RESPONSE = 'RECEIVE_COMMAND_RESPONSE'
export function receiveCommandResponse(requestKey, commandResponse) {
  return {
    type: RECEIVE_COMMAND_RESPONSE,
    payload: { requestKey, commandResponse }
  }
}

export function sendCommand(objectType, objectData) {
  return async (dispatch, getState) => {
    const state = getState();
    const accessToken = state.user.session.accessToken;
    const command = { objectType, objectData, accessToken };
    const requestKey = JSON.stringify(command);

    if (state.appObject['.oustandingRequests'].has(requestKey)) {
      return;
    }

    dispatch(requestCommandResponse(requestKey))
    createHeroApiClient(accessToken).sendCommand(command)
      .then(commandResponse => dispatch(receiveCommandResponse(requestKey, commandResponse)))
      .catch(error => {
        if (objectType === dataStores.APP_CONFIG) {
          // Redirect to the core app
          // window.location.replace(`${process.env.REACT_APP_BAD_COMPANY_KEY_REDIRECT_URL}${window.location.pathname}`)
          // return;
        }
        return dispatch(createError(error.message))
      });
  }
}

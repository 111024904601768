import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import FinanceCalculatorsList from "../components/FinanceCalculators/FinanceCalculatorsList";

const FinanceCalculators = () => {
  return (
    <Container>
      <Row className="mb-3">
        <Col md={12}>
          <h1>Finance Calculators</h1>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="mx-auto">
          <FinanceCalculatorsList />
        </Col>
      </Row>
    </Container>
  );
};

export default FinanceCalculators;

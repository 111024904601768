import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Button } from 'react-bootstrap'
import {
  fetchGroupInvitations,
  acceptGroupInvitation,
  rejectGroupInvitation
} from '../../redux/actions/groupInvitations'

function GroupInvitations(props) {
  useEffect(() => {
    if (!props.groupInvitations || !props.groupInvitations.isValid) {
      props.dispatch(fetchGroupInvitations())
    }
  }, [props.groupInvitations])

  return props.groupInvitations && props.groupInvitations.items && props.groupInvitations.items.length > 0
    ? (
      <div>
        <Row>
          <Col md={12}>
            {props.groupInvitations.items.map(x => (
              <div className="c-alert c-alert--info alert u-mb-medium">
                <span className="c-alert__icon">
                  <i className="far fa-bell"></i>
                </span>

                <div className="c-alert__content">
                  <Row>
                    <Col md={8}>
                      <h4 className="c-alert__title">Group invitation</h4>
                      <p> You have been invited to '{x.groupName}'.</p>
                    </Col>
                    <Col className="text-right" md={4}>
                      <Button variant={"success"} className="btn--circle text-white" onClick={() => props.dispatch(acceptGroupInvitation(x.groupId))}><i className="fal fa-check"></i></Button>
                      <Button variant={"danger"} className="btn--circle" onClick={() => props.dispatch(rejectGroupInvitation(x.groupId))}><i className="fal fa-ban"></i></Button>
                    </Col>
                  </Row>
                </div>
                {/* 
                 */}
              </div>
            ))}
          </Col>
        </Row>
      </div>
    )
    : null
}

const mapStateToProps = state => {
  return {
    groupInvitations: state.groupInvitations
  };
}

export default connect(mapStateToProps)(GroupInvitations)

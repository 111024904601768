import { createError } from './errors'
import dataStores from '../reducers/dataStores'

export const REQUEST_DATA_PROTECTION = 'REQUEST_DATA_PROTECTION'
function requestDataProtection() {
    return {
        type: REQUEST_DATA_PROTECTION
    }
}

export const RECEIVE_DATA_PROTECTION = 'RECEIVE_DATA_PROTECTION'
function receiveDataProtection(dataProtection) {
    return {
        type: RECEIVE_DATA_PROTECTION,
        payload: {
            value: dataProtection,
            receivedAt: Date.now()
        }
    }
}

export const INVALIDATE_DATA_PROTECTION = 'INVALIDATE_DATA_PROTECTION'
export function invalidateDataProtection() {
    return {
        type: INVALIDATE_DATA_PROTECTION
    }
}

function doFetchDataProtection(accessToken) {
    return async dispatch => {
        dispatch(requestDataProtection())
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user/data-protection`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'hero-token': accessToken
                }
            })
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const dataProtection = await response.json()
            return dispatch(receiveDataProtection(dataProtection))
        }
        catch (error) {
            return dispatch(createError(error.message))
        }
    }
}

function shouldFetchDataProtection(state) {
    const dataProtection = state.dataProtection
    if (!dataProtection) {
        return true
    } else if (dataProtection.isFetching) {
        return false
    } else {
        return !dataProtection.isValid
    }
}

export function fetchDataProtection() {
    return (dispatch, getState) => {
        const state = getState()
        if (shouldFetchDataProtection(state)) {
            const accessToken = state.user.session.accessToken
            return dispatch(doFetchDataProtection(accessToken))
        } else {
            return Promise.resolve()
        }
    }
}

export function updateDataProtection(dataProtection) {
    return async (dispatch, getState) => {
        const accessToken = getState().user.session.accessToken
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user/data-protection`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'hero-token': accessToken
                },
                body: JSON.stringify(dataProtection)
            })
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return dispatch(invalidateDataProtection())
        }
        catch (error) {
            return dispatch(createError(error.message))
        }
    }
}
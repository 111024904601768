import React, { useEffect } from "react";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { fetchAppConnections } from '../../redux/actions/appConnections'
import { updateMetricSources } from '../../redux/actions/userHealthMetricSources'

function Metric(props) {
  const { healthMetric: { id, name } } = props

  useEffect(() => {
    props.dispatch(fetchAppConnections())
  }, [props.appConnections])

  const onChange = (event) => {
    var appConnectionType = event.target.value;
    if (!appConnectionType) return;
    var healthMetricIds = [id]
    props.dispatch(updateMetricSources(healthMetricIds, appConnectionType))
  }

  return (
    <Col className="mb-2" sm={6} key={id}>
      <Row className="d-flex justify-content-center align-items-center">
        <Col md={7}><span className="select__label">{name}</span></Col>
        <Col md={5} className="d-flex justify-content-center align-items-center">
          <select className="select" onChange={onChange} value={props.appConnectionType}>
            {!props.appConnectionType && <option value=""></option>}
            {props.appConnections &&
              props.appConnections.items.filter(x => x.connected).map(appConnection => (
                <option value={appConnection.type}>{appConnection.display_name}</option>
              ))
            }
          </select>
        </Col>
      </Row>
    </Col>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    appConnections: state.appConnections,
    appConnectionType: (state.user.healthMetricSources.items.find(x => x.healthMetricId == ownProps.healthMetric.id) || {}).appConnectionType
  }
}

export default connect(mapStateToProps)(Metric);

import React from "react";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";

import PersonalProfile from "../components/profile/PersonalProfile";
import DataProtectionPreferences from "../components/profile/DataProtectionPreferences";

function Profile(props) {
  return (
    <Container>
      <PersonalProfile userId={props.user.profile.id} />
      <DataProtectionPreferences />
    </Container>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps)(Profile);

import React, { useState } from "react";
import { Container, Table, Form, Pagination } from 'react-bootstrap';

const SearchPreHealthCheckResults = ({ preHealthCheckResults, setSelectedUser }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [selectedID, setSelectedID] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage] = useState(10); // Number of results per page

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset current page when searching
  };

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortDirection("asc");
    }
  };

  const handleRowClick = (userID) => {
    setSelectedID(userID);
    setSelectedUser(userID);
  };

  // Pagination
  const indexOfLastResult = currentPage * resultsPerPage;
  const indexOfFirstResult = indexOfLastResult - resultsPerPage;
  const currentResults = preHealthCheckResults.slice(indexOfFirstResult, indexOfLastResult);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <Container fluid style={{ padding: "16px" }}>
      <Form.Control
        className="mb-3"
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={handleSearch}
      />
      <Table striped bordered hover>
        <thead>
          <tr>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => handleSort("firstName")}
            >
              First Name
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => handleSort("lastName")}
            >
              Last Name
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => handleSort("checkDate")}
            >
              Check Date
            </th>
          </tr>
        </thead>
        <tbody>
          {currentResults.map((result, index) => (
            <tr
              key={index}
              onClick={() => handleRowClick(result.userID)}
              className={result.userID === selectedID ? "table-primary" : ""}
              style={{ cursor: "pointer" }}
            >
              <td>{result.firstName}</td>
              <td>{result.lastName}</td>
              <td>
                {new Date(result.checkDate).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "short",
                  year: "2-digit",
                })}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination>
        {Array.from({ length: Math.ceil(preHealthCheckResults.length / resultsPerPage) }).map((_, index) => (
          <Pagination.Item key={index} active={index + 1 === currentPage} onClick={() => paginate(index + 1)}>
            {index + 1}
          </Pagination.Item>
        ))}
      </Pagination>
    </Container>
  );
};

export default SearchPreHealthCheckResults;

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import dataStores from "../../redux/reducers/dataStores";
import { Col, Row, Card, Button, FormControl, OverlayTrigger, Popover, InputGroup } from "react-bootstrap";
import { FaInfo } from "react-icons/fa";

function HealthMetricsSection(props) {
  const [saveText, setSaveText] = useState("Save All");
  const [clientReqId, setClientReqId] = useState(0);

  useEffect(() => {
    if (
      props.dataStores[dataStores.GENERAL_RESPONSES][clientReqId] &&
      props.dataStores[dataStores.GENERAL_RESPONSES][clientReqId].state ==
        "COMPLETE"
    ) {
      //Reload the health data
      setSaveText("Saved!");
    }
  }, [
    saveText,
    clientReqId,
    [props.dataStores[dataStores.GENERAL_RESPONSES][clientReqId]]
  ]);

  function updateItem({ target }) {
    props.onChange(
      target.getAttribute("areaid"),
      target.getAttribute("sectionid"),
      target.getAttribute("healthmetricid"),
      target.value
    );
  }

  /// Renders out the control for the given data type
  function RenderHealthMetricDataType(m) {
    switch (m.displayDataType) {
      case "TEXT":
        return (
          <div>
            <div className="input__label">{m.name}</div>
            <form className="button-inside-input">
              <textarea
                type="text"
                rows="3"
                value={m.userHealthMetric.value}
                name={m.healthMetricId}
                onChange={updateItem}
                className="input__base"
                areaid={m.healthMetricAreaId}
                sectionid={m.healthMetricSectionId}
                healthmetricid={m.healthMetricId}
              />
            </form>
          </div>
        );

      case "DATE":
        return (
          <div>
            <div className="input__label">{m.name}</div>
            <input
              type="date"
              className="input__datepicker"
              value={m.userHealthMetric.value}
              name={m.healthMetricId}
              onChange={updateItem}
              areaid={m.healthMetricAreaId}
              sectionid={m.healthMetricSectionId}
              healthmetricid={m.healthMetricId}
            />
          </div>
        );
      case "GENDER":
        return (
          <div>
            <div className="select__label">{m.name}</div>
            <div className="select__wrapper">
              <select
                name={m.name}
                value={m.userHealthMetric.value}
                onChange={updateItem}
                areaid={m.healthMetricAreaId}
                sectionid={m.healthMetricSectionId}
                healthmetricid={m.healthMetricId}
                className="select"
                >
                <option value="">Select gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Transgender">Transgender</option>
                <option value="Other">Other</option>
                <option value="Prefer not to say">Prefer not to say</option>
              </select>
            </div>
          </div>
        );

      case "NUMBER":
        return (
          <>
            <Row>
              <Col xs={9}>
                <div className="input__label">{m.name}</div>
              </Col>
              <Col xs={3}></Col>
            </Row>
            <Row>
              <Col xs={12}>
                <InputGroup>
                  <FormControl
                    type="number"
                    value={m.userHealthMetric.value}
                    name={m.healthMetricId}
                    onChange={updateItem}
                    areaid={m.healthMetricAreaId}
                    sectionid={m.healthMetricSectionId}
                    healthmetricid={m.healthMetricId}
                  />

                  <InputGroup.Append>
                    <OverlayTrigger
                      trigger="click"
                      key={"right"}
                      placement={"right"}
                      overlay={
                        <Popover id={`popover-positioned-${"right"}`}>
                          <Popover.Title as="h3">Description</Popover.Title>
                          <Popover.Content>{m.description}</Popover.Content>
                        </Popover>
                      }
                    >
                      <button className="thin" variant="outline-secondary">
                        <FaInfo size={10}></FaInfo>
                      </button>
                    </OverlayTrigger>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
            </Row>
          </>
        );

      default:
        return <div>{m.displayDataType}</div>;
    }
  }

  function RenderHealthMetrics() {
    var healthMetrics = [];

    var sortedHealthMetrics = SortHealthMetrics(
      props.healthMetricsSection.healthMetrics
    );

    for (var prop in sortedHealthMetrics) {
      var m = sortedHealthMetrics[prop];
      healthMetrics.push(
        <div className="bm" key={"healthMetricId-" + m.healthMetricId}>
          {RenderHealthMetricDataType(m)}
        </div>
      );
    }

    return healthMetrics;
  }

  function SortHealthMetrics(metricsToSort) {
    var sortedArray = [];
    for (var prop in metricsToSort) {
      sortedArray.push(metricsToSort[prop]);
    }
    sortedArray.sort((a, b) => {
      return a.displayOrder - b.displayOrder;
    });
    return sortedArray;
  }

  return (
    <Card style={{ marginBottom: "2rem" }}>
      <Card.Header as="h4"className="card-header--transparent card-header--navy-text">{props.healthMetricsSection.name}</Card.Header>
      <Card.Body>
        {RenderHealthMetrics()}
      </Card.Body>
    </Card>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    healthMetricsSection: ownProps.healthMetricsSection,
    dataStores: state.appObject,
    user: state.user
  };
}

export default connect(mapStateToProps)(HealthMetricsSection);

import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Table } from "react-bootstrap";

//#region Questions and Data
const medicalQuestions = [
  {
    question: "Do you have a history of heart disease or any cardiovascular conditions?",
    questionID: 1,
    answer: false,
  },
  {
    question: "Have you ever had a heart attack or stroke?",
    questionID: 2,
    answer: false,
  },
  {
    question: "Are you currently taking any medications for heart-related issues?",
    questionID: 3,
    answer: false,
  },
];

const FamilyHistoryQuestions = [
  {
    question: "Do you have a family history of heart disease or any cardiovascular conditions?",
    questionID: 4,
    answer: false,
  },
  {
    question: "Has anyone in your family ever had a heart attack or stroke?",
    questionID: 5,
    answer: false,
  },
];

const LifestyleQuestions = [
  {
    question: "Do you smoke or use any tobacco products?",
    questionID: 6,
    answer: false,
  },
  {
    question: "On a scale of 1-5 (1 being completely inactive - 5 being athletic performance), how would you rate your current activity levels?",
    questionID: 7,
    answer: false,
    type: "range",
  },
  {
    question: "On a scale of 1-5 (1 being very unhealthy - 5 being very healthy), how would you rate your current diet?",
    questionID: 8,
    answer: false,
    type: "range",
  },
];

const StressQuestions = [
  {
    question: "Do you experience high levels of stress on a regular basis?",
    questionID: 9,
    answer: false,
  },
  {
    question: "Have you ever been diagnosed with anxiety or depression?",
    questionID: 10,
    answer: false,
  },
];

const generalHealthQuestions = [
  {
    question: "Are you currently experiencing any health issues or symptoms?",
    questionID: 11,
    answer: false,
  },
  {
    question: "Have you recently undergone any surgeries or medical procedures?",
    questionID: 12,
    answer: false,
  },
];

const allergiesAndMedicationsQuestions = [
  {
    question: "Do you have any known allergies, especially to medications or medical equipment?",
    questionID: 13,
    answer: true,
  },
  {
    question: "Are you currently taking any medications, supplements, or vitamins?",
    questionID: 14,
    answer: false,
  },
];
//#endregion

const DummyUser = [
  { answer: false, questionID: 1 },
  { answer: true, questionID: 99 },
  { answer: false, questionID: 2 },
  { answer: false, questionID: 3 },
  { answer: true, questionID: 5 },
  { answer: 5, questionID: 7 },
  { answer: 5, questionID: 8 },
  { answer: true, questionID: 9 },
  { answer: true, questionID: 14 },
  { answer: "Joe", questionID: "firstName" },
  { answer: "Gaunt", questionID: "lastName" },
  { answer: "5d4b3e23-50f7-4b1e-b91a-9358ce37c619", questionID: "userID" },
  { answer: "2024-02-23T15:49:26.104Z", questionID: "checkDate" },
];

const PreHealthCheckListViewAnswer = ({ userAnswers }) => {
    const [userData, setUserData] = useState(userAnswers);
    const [questions, setQuestions] = useState();

    useEffect(() => {
        //combine all the questions into one array
        const allQuestions = medicalQuestions.concat(FamilyHistoryQuestions, LifestyleQuestions, StressQuestions, generalHealthQuestions, allergiesAndMedicationsQuestions);
        setQuestions(allQuestions);
    }, []);

  
    if (userAnswers === null) {
      return <div>No user selected</div>;
    }

    const getAnswerFromID = (id) => {
      const answer = userAnswers.find((x) => x.questionID === id);
      if(answer?.answer === true) return "Yes";
      if(answer?.answer === false) return "No";
      if(typeof answer?.answer === "number") return answer?.answer;

      return "No";
    }

    const getColorClass = (id) => {
      const answer = userAnswers.find((x) => x.questionID === id);

      if(answer?.answer === true) return "text-success";
      if(answer?.answer === false) return "text-primary";
      if(typeof answer?.answer === "number") return "text-primary";

      return "text-primary";
    }
  
    return (
      <Container>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Card.Title>Pre-Health Check Questions</Card.Title>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Question</th>
                      <th>Answer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {questions &&
                      questions.map((item, index) => (
                        <tr key={index}>
                          <td className={getColorClass(item.questionID)}>{item.question}</td>
                          <td>{getAnswerFromID(item.questionID)}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  };

export default PreHealthCheckListViewAnswer;

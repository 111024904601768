import React, { useState, useEffect } from "react";
import { useParams, Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as commandActions from "../../redux/actions/commands";
import dataStores from "../../redux/reducers/dataStores";
import { Container, Row, Col, Button, Card, Dropdown, Accordion } from "react-bootstrap";
import HeroOutlineButton from '../../common/Buttons/OutlineButton';
import ColourFitPlate from "../../components/nutrition/colour-fit-plate";
import RDIChart from "../../components/nutrition/rdi-chart";
import Helmet from "react-helmet";
import LoadingSpinner from "../../components/controls/LoadingSpinner";

function MealDisplay(props) {
  const [meal, setMeal] = useState({ mealId: 0 });
  let { mealId } = useParams();

  useEffect(() => {
    if (!props.meal.meals || props.meal.meals[0].mealId != mealId) {
      props.commandActions.sendCommand(
        dataStores.MEAL,
        {
          actionType: "GETMEAL",
          mealId: mealId
        }
      )
    }
  }, [mealId]);

  useEffect(() => {
    var element = props.meal.meals ? props.meal.meals[0] : null;
    if (element && element.mealId.toString() === mealId.toString() && element.mealId !== meal.mealId) {
      const newElement = { ...element }
      Object.keys(newElement).forEach(property => {
        if (typeof newElement[property] == "number") {
          newElement[property] = formatFloats(newElement[property]);
        }
      });
      setMeal(newElement);

      window.whisk.queue.push(function () {
        window.whisk.listeners.removeClickListener('whisk-single-recipe');
        window.whisk.listeners.addClickListener('whisk-single-recipe', 'shoppingList.addRecipeToList', {
          recipeUrl: `https://core.herowellbeing.com/meals/whisk/${element.mealId}`,
        });
      });
    }
  }, [props.meal.meals]);

  function changeFavourite() {
    props.commandActions.sendCommand(
      dataStores.MEAL,
      {
        actionType: "FAV",
        mealId: meal.mealId
      }
    );
  }

  function getVideo(videoURL) {
    return (
      <video width="100%" height="100%" controls>
        <source src={videoURL} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    );
  }

  function formatFloats(floatNum) {
    if (floatNum >= 0 && floatNum < 10) {
      return (Math.round(floatNum * 1000) / 1000);
    }
    if (floatNum >= 10) {
      return (Math.round(floatNum * 10) / 10);
    }
  }

  function toFixed(value) {
    return parseFloat(value).toFixed(1).replace(/\.0+$/, '');
  }

  if(meal.mealId == 0){
    return <LoadingSpinner/>
  }

  return (
    <>
      <Helmet>
        <script type='application/ld+json'>{
          `{"@context": "http://schema.org",
          "@type": "Recipe",
          "description": "${meal.description}",
          "image": "${meal.imageUrl}",
          "recipeIngredient": ["${meal.ingredients ? meal.ingredients.map(i => `${i.measurement} ${i.name}`).join(`","`) : ""}"],
          "name": "${meal.title}",
          "nutrition": {
            "@type": "NutritionInformation",
            "calories": "${meal.energyKcal} calories"
          },
          "prepTime": "PT${meal.prepTime}M",
          "recipeYield": "1 Serving",
          "recipeInstructions": "${meal.instructions}"}`
        }</script>
      </Helmet>
      <div
        className="post-heading-container"
        style={{
          backgroundImage: `url(${meal.imageUrl})`
        }}
      >
        <div className="post-heading-actions d-md-none">
          <Link
            title={meal.favourite ? `Remove` : `Favourite`}
            className={`btn btn-white btn--circle btn--transparent float-left`}
            onClick={() => changeFavourite()}>
            <i className={`${meal.favourite ? "fas" : "fal"} fa-heart`}></i>
          </Link>
          <Link to={"/nutrition/meals"} title="Back to Meals" className="btn btn-primary btn--circle float-right">
            <i className="fas fa-chevron-left"></i>
          </Link>
        </div>
        <div className="post-heading-title">
          <div className="content">
            <div className="container">
              <Row>
                <Col>
                  <h1>{meal.title}</h1>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
      <Container>
        <Row className="d-none d-md-block">
          <Col>
            <div className="btn--actions">
              <Link
                title={meal.favourite ? `Remove` : `Favourite`}
                className={`btn btn-white btn--circle btn--transparent mr-2`}
                onClick={() => changeFavourite()}>
                <i className={`${meal.favourite ? "fas" : "fal"} fa-heart`}></i>
              </Link>
              <Link to={"/nutrition/meals"} title="Back to Meals" className="btn btn-primary btn--circle mr-2">
                <i className="fas fa-chevron-left"></i>
              </Link>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <div className="d-inline mr-3">
              <span className="text-bold">Serves:</span> {meal.peopleServed}
            </div>
            <div className="d-inline">
              <span className="text-bold">Prep Time:</span> {meal.prepTime} Mins
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <div
              className="post-content"
              dangerouslySetInnerHTML={{ __html: meal.description }}
            ></div>
          </Col>
        </Row>
        <Row>
          {meal.type && meal.type.findIndex(m => m.id == 6) != -1 ?
            <HeroOutlineButton
              size="large"
              colour="#21b4f1"
              classes="card-link"
              onClick={() => {
                window.open("https://thegoodprep.com/hero-pro/")
              }}
              text={`Purchase Meal`} />
            : undefined
          }
        </Row>
        <Row className="mt-1 mb-4">
          <Col md="3" className="mb-4">
            <div className="meal-plate">
              <ColourFitPlate
                showIcons={false}
                innerData={[
                  { value: window.Utils.round(meal.fuel, 1), name: "Fuel" },
                  { value: window.Utils.round(meal.muscle, 1), name: "Muscle" },
                  { value: window.Utils.round(meal.health, 1), name: "Health" }
                ]}
                outerData={[
                  { value: window.Utils.round(meal.calorieLoad, 1), name: "Calorie Load" },
                  { value: window.Utils.round(100 - meal.calorieLoad, 1), name: "Calorie Load Remaining"  }
                ]}
                scale={1} />
            </div>
          </Col>
          <Col md="9">
            <div className="d-flex h-100">
              <div className="w-100 align-self-center">
                <Row className="mb-4">
                  <Col>
                    <span className="d-inline color-health text-large">Health Score</span> {toFixed(meal.healthScore)}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <div className="bg-white p-2 table-responsive">
                      <table className="w-100 table--default">
                        <thead>
                          <tr>
                            <td className="text-center color-calories">Kcal</td>
                            <td className="text-center color-carbs">Net Carbs</td>
                            <td className="text-center color-carbs">Sugar</td>
                            <td className="text-center color-protein">Protein</td>
                            <td className="text-center color-fat">Fat</td>
                            <td className="text-center color-fat">Sat Fat</td>
                            <td className="text-center color-health">Fibre</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="text-center text-large">
                              {toFixed(meal.energyKcal)}
                            </td>
                            <td className="text-center text-large">
                              {toFixed(meal.netCarbs)}g
                          </td>
                            <td className="text-center text-large">
                              {toFixed(meal.sugarG)}g
                          </td>
                            <td className="text-center text-large">
                              {toFixed(meal.proteinG)}g
                          </td>
                            <td className="text-center text-large">
                              {toFixed(meal.fatG)}g
                          </td>
                            <td className="text-center text-large">
                              {toFixed(meal.saturatedFatG)}g
                          </td>
                            <td className="text-center text-large">
                              {toFixed(meal.fibreG)}g
                          </td>
                          </tr>
                          <tr>
                            <td className="text-center text-large">
                            </td>
                            <td className="text-center text-large">
                            </td>
                            <td className="text-center text-large">
                              <div className="chart-medium">
                                <RDIChart
                                  data={[
                                    { value: meal.sugarsRDI },
                                    { value: 100 - meal.sugarsRDI }
                                  ]}
                                />
                              </div>
                            </td>
                            <td className="text-center text-large">
                            </td>
                            <td className="text-center text-large">
                            </td>
                            <td className="text-center text-large">
                              <div className="chart-medium">
                                <RDIChart
                                  data={[
                                    { value: meal.saturatedRDI },
                                    { value: 100 - meal.saturatedRDI }
                                  ]}
                                />
                              </div>
                            </td>
                            <td className="text-center text-large">
                              <div className="chart-medium">
                                <RDIChart
                                  data={[
                                    { value: meal.fibreRDI },
                                    { value: 100 - meal.fibreRDI }
                                  ]}
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Accordion>
              {
                meal.videoUrl ?
                  <Card>
                    <Card.Header className="card-header--primary p-0">
                      <Accordion.Toggle as={Button} variant="link" className="p-4 w-100" eventKey="0">
                        Video
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <Row>
                          <Col md={12} className="mb-5">{getVideo(meal.videoUrl)}</Col>
                        </Row>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card> : undefined
              }
              <Card>
                <Card.Header className="card-header--primary p-0">
                  <Accordion.Toggle as={Button} variant="link" className="p-4 w-100" eventKey="1">
                    Ingredients and Instructions
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <Row>
                      <Col>
                        {
                          meal.ingredients != undefined ?
                            <div>
                              <h3>Ingredients</h3>
                              {meal.ingredients.map(x => {
                                return (
                                  <Row>
                                    <Col xs={10}>
                                      <p className="mb-1 mt-1">{x.measurement} {x.name}</p>
                                      <hr />
                                    </Col>
                                    <Col>
                                      <input type="checkbox" />
                                    </Col>
                                  </Row>
                                )
                              })}
                            </div>
                            : undefined
                        }
                        <Button id="whisk-single-recipe" className="mt-3">Add recipe to shopping list</Button>
                      </Col>
                      <Col>
                        {
                          meal.instructions != undefined ?
                            <div>
                              <h3>Instructions</h3>
                              {meal.instructions.split(/\r\n|\r|\n/).map(x => {
                                return (
                                  <Row>
                                    <Col>
                                      <p>{x}</p>
                                    </Col>
                                  </Row>
                                )
                              })}
                            </div>
                            : undefined
                        }
                      </Col>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header className="card-header--primary p-0">
                  <Accordion.Toggle as={Button} variant="link" className="p-4 w-100" eventKey="2">
                    Nutritional Information
                      </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    <Row>
                      <Col xs={12} md={6}>
                        <table className="w-100">
                          <thead>
                            <tr>
                              <td className="w-80"></td>
                              <td></td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>Carohydrates</strong></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>Carbs (g)</td>
                              <td>{toFixed(meal.carbsG)}</td>
                            </tr>
                            <tr>
                              <td>Starch (g)</td>
                              <td>{toFixed(meal.starchG)}</td>
                            </tr>
                            <tr>
                              <td>Sugars (g)</td>
                              <td>{toFixed(meal.sugarG)}</td>
                            </tr>
                            <tr>
                              <td>Fiber (g)</td>
                              <td>{toFixed(meal.fibreG)}</td>
                            </tr>
                            <tr>
                              <td><p className="table--title">Fats</p></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>Fat (g)</td>
                              <td>{toFixed(meal.fatG)}</td>
                            </tr>
                            <tr>
                              <td>Monounsaturated (g)</td>
                              <td>{toFixed(meal.monounsaturatedG)}</td>
                            </tr>
                            <tr>
                              <td>Polyunsaturated (g)</td>
                              <td>{toFixed(meal.polyunsaturatedG)}</td>
                            </tr>
                            <tr>
                              <td>Omega-3 (g)</td>
                              <td>{toFixed(meal.omega3G)}</td>
                            </tr>
                            <tr>
                              <td>Omega-6 (g)</td>
                              <td>{toFixed(meal.omega6G)}</td>
                            </tr>
                            <tr>
                              <td>Saturated (g)</td>
                              <td>{toFixed(meal.saturatedG)}</td>
                            </tr>
                            <tr>
                              <td>Trans-Fats (g)</td>
                              <td>{toFixed(meal.transFatsG)}</td>
                            </tr>
                            <tr>
                              <td><p className="table--title">Proteins</p></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>Protein (g)</td>
                              <td>{toFixed(meal.proteinG)}</td>
                            </tr>
                            <tr>
                              <td>Alanine (g)</td>
                              <td>{toFixed(meal.alanineG)}</td>
                            </tr>
                            <tr>
                              <td>Arginine (g)</td>
                              <td>{toFixed(meal.arginineG)}</td>
                            </tr>
                            <tr>
                              <td>Aspartic acid (g)</td>
                              <td>{toFixed(meal.asparticAcidG)}</td>
                            </tr>
                            <tr>
                              <td>Cystine (g)</td>
                              <td>{toFixed(meal.cystineG)}</td>
                            </tr>
                            <tr>
                              <td>Glutamic acid (g)</td>
                              <td>{toFixed(meal.glutamicAcidG)}</td>
                            </tr>
                            <tr>
                              <td>Glycine (g)</td>
                              <td>{toFixed(meal.glycineG)}</td>
                            </tr>
                            <tr>
                              <td>Histidine (g)</td>
                              <td>{toFixed(meal.histidineG)}</td>
                            </tr>
                            <tr>
                              <td>Hydroxyproline (g)</td>
                              <td>{toFixed(meal.hydroxyprolineG)}</td>
                            </tr>
                            <tr>
                              <td>Isoleucine (g)</td>
                              <td>{toFixed(meal.isoleucineG)}</td>
                            </tr>
                            <tr>
                              <td>Leucine (g)</td>
                              <td>{toFixed(meal.leucineG)}</td>
                            </tr>
                            <tr>
                              <td>Lysine (g)</td>
                              <td>{toFixed(meal.lysineG)}</td>
                            </tr>
                            <tr>
                              <td>Methionine (g)</td>
                              <td>{toFixed(meal.methionineG)}</td>
                            </tr>
                            <tr>
                              <td>Phenylalanine (g)</td>
                              <td>{toFixed(meal.phenylalanineG)}</td>
                            </tr>
                            <tr>
                              <td>Proline (g)</td>
                              <td>{toFixed(meal.prolineG)}</td>
                            </tr>
                            <tr>
                              <td>Serine (g)</td>
                              <td>{toFixed(meal.serineG)}</td>
                            </tr>
                            <tr>
                              <td>Threonine (g)</td>
                              <td>{toFixed(meal.threonineG)}</td>
                            </tr>
                            <tr>
                              <td>Tryptophan (g)</td>
                              <td>{toFixed(meal.tryptophanG)}</td>
                            </tr>
                            <tr>
                              <td>Tyrosine (g)</td>
                              <td>{toFixed(meal.tyrosineG)}</td>
                            </tr>
                            <tr>
                              <td>Valine (g)</td>
                              <td>{toFixed(meal.valineG)}</td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                      <Col xs={12} md={6}>
                        <table className="w-100">
                          <thead>
                            <tr>
                              <td className="w-80"></td>
                              <td></td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><p className="table--title">Vitamins</p></td>
                              <td></td>
                              <td className="text-center">% DV</td>
                            </tr>
                            <tr>
                              <td>B1 (Thiamine) (mg)</td>
                              <td>{toFixed(meal.b1ThiamineMg)}</td>
                              <td>
                                <div className="chart-small">
                                  <RDIChart
                                    data={[
                                      { value: meal.b1ThiamineRDI },
                                      { value: 100 - meal.b1ThiamineRDI }
                                    ]}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>B2 (Riboflavin) (mg)</td>
                              <td>{toFixed(meal.b2RiboflavinMg)}</td>
                              <td>
                                <div className="chart-small">
                                  <RDIChart
                                    data={[
                                      { value: meal.b2RiboflavinRDI },
                                      { value: 100 - meal.b2RiboflavinRDI }
                                    ]}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>B3 (Niacin) (mg)</td>
                              <td>{toFixed(meal.b3NiacinMg)}</td>
                              <td>
                                <div className="chart-small">
                                  <RDIChart
                                    data={[
                                      { value: meal.b3NiacinRDI },
                                      { value: 100 - meal.b3NiacinRDI }
                                    ]}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>B5 (Pantothenic Acid) (mg)</td>
                              <td>{toFixed(meal.b5PantothenicAcidMg)}</td>
                              <td>
                                <div className="chart-small">
                                  <RDIChart
                                    data={[
                                      { value: meal.b5PantothenicAcidRDI },
                                      { value: 100 - meal.b5PantothenicAcidRDI }
                                    ]}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>B6 (Pyridoxine) (mg)</td>
                              <td>{toFixed(meal.b6PyridoxineMg)}</td>
                              <td>
                                <div className="chart-small">
                                  <RDIChart
                                    data={[
                                      { value: meal.b6PyridoxineRDI },
                                      { value: 100 - meal.b6PyridoxineRDI }
                                    ]}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>B12 (Cobalamin) (µg)</td>
                              <td>{toFixed(meal.b12CobalaminUg)}</td>
                              <td>
                                <div className="chart-small">
                                  <RDIChart
                                    data={[
                                      { value: meal.b12CobalaminRDI },
                                      { value: 100 - meal.b12CobalaminRDI }
                                    ]}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Folate (µg)</td>
                              <td>{toFixed(meal.folateUg)}</td>
                              <td>
                                <div className="chart-small">
                                  <RDIChart
                                    data={[
                                      { value: meal.folateRDI },
                                      { value: 100 - meal.folateRDI }
                                    ]}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Vitamin A (IU)</td>
                              <td>{toFixed(meal.vitaminAIu)}</td>
                              <td>
                                <div className="chart-small">
                                  <RDIChart
                                    data={[
                                      { value: meal.vitaminARDI },
                                      { value: 100 - meal.vitaminARDI }
                                    ]}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Vitamin C (mg)</td>
                              <td>{toFixed(meal.vitaminCMg)}</td>
                              <td>
                                <div className="chart-small">
                                  <RDIChart
                                    data={[
                                      { value: meal.vitaminCRDI },
                                      { value: 100 - meal.vitaminCRDI }
                                    ]}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Vitamin D (IU)</td>
                              <td>{toFixed(meal.vitaminDIu)}</td>
                              <td>
                                <div className="chart-small">
                                  <RDIChart
                                    data={[
                                      { value: meal.vitaminDRDI },
                                      { value: 100 - meal.vitaminDRDI }
                                    ]}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Vitamin E (mg)</td>
                              <td>{toFixed(meal.vitaminEMg)}</td>
                              <td>
                                <div className="chart-small">
                                  <RDIChart
                                    data={[
                                      { value: meal.vitaminERDI },
                                      { value: 100 - meal.vitaminERDI }
                                    ]}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Vitamin K (µg)</td>
                              <td>{toFixed(meal.vitaminKug)}</td>
                              <td>
                                <div className="chart-small">
                                  <RDIChart
                                    data={[
                                      { value: meal.vitaminKRDI },
                                      { value: 100 - meal.vitaminKRDI }
                                    ]}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td><strong>Minerals</strong></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>Calcium (mg)</td>
                              <td>{toFixed(meal.calciumMg)}</td>
                              <td>
                                <div className="chart-small">
                                  <RDIChart
                                    data={[
                                      { value: meal.calciumRDI },
                                      { value: 100 - meal.calciumRDI }
                                    ]}
                                  />
                                </div>
                              </td>

                            </tr>
                            <tr>
                              <td>Copper (mg)</td>
                              <td>{toFixed(meal.copperMg)}</td>
                              <td>
                                <div className="chart-small">
                                  <RDIChart
                                    data={[
                                      { value: meal.copperRDI },
                                      { value: 100 - meal.copperRDI }
                                    ]}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Iron (mg)</td>
                              <td>{toFixed(meal.ironMg)}</td>
                              <td>
                                <div className="chart-small">
                                  <RDIChart
                                    data={[
                                      { value: meal.ironRDI },
                                      { value: 100 - meal.ironRDI }
                                    ]}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Magnesium (mg)</td>
                              <td>{toFixed(meal.magnesiumMg)}</td>
                              <td>
                                <div className="chart-small">
                                  <RDIChart
                                    data={[
                                      { value: meal.magnesiumRDI },
                                      { value: 100 - meal.magnesiumRDI }
                                    ]}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Manganese (mg)</td>
                              <td>{toFixed(meal.manganeseMg)}</td>
                              <td>
                                <div className="chart-small">
                                  <RDIChart
                                    data={[
                                      { value: meal.manganeseRDI },
                                      { value: 100 - meal.manganeseRDI }
                                    ]}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Phosphorus (mg)</td>
                              <td>{toFixed(meal.phosphorusMg)}</td>
                              <td>
                                <div className="chart-small">
                                  <RDIChart
                                    data={[
                                      { value: meal.phosphorusRDI },
                                      { value: 100 - meal.phosphorusRDI }
                                    ]}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Potassium (mg)</td>
                              <td>{toFixed(meal.potassiumMg)}</td>
                              <td>
                                <div className="chart-small">
                                  <RDIChart
                                    data={[
                                      { value: meal.potassiumRDI },
                                      { value: 100 - meal.potassiumRDI }
                                    ]}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Selenium (µg)</td>
                              <td>{toFixed(meal.seleniumUg)}</td>
                              <td>
                                <div className="chart-small">
                                  <RDIChart
                                    data={[
                                      { value: meal.seleniumRDI },
                                      { value: 100 - meal.seleniumRDI }
                                    ]}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Sodium (mg)</td>
                              <td>{toFixed(meal.sodiumMg)}</td><td>
                                <div className="chart-small">
                                  <RDIChart
                                    data={[
                                      { value: meal.sodiumRDI },
                                      { value: 100 - meal.sodiumRDI }
                                    ]}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Zinc (mg)</td>
                              <td>{toFixed(meal.zincMg)}</td>
                              {/* <td>{toFixed(meal.zincRDI)}</td> */}
                              <td>
                                <div className="chart-small">
                                  <RDIChart
                                    data={[
                                      { value: meal.zincRDI },
                                      { value: 100 - meal.zincRDI }
                                    ]}
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </>
  );
}

function mapStateToProps(state) {
  return {
    meal: state.appObject[dataStores.MEAL]
  };
}

function mapDispatchToProps(dispatch) {
  return {
    commandActions: bindActionCreators(commandActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MealDisplay);

const createChatClient = (sendRequest) => (chatId) => {

    const getChannel = async () => {
        const path = `/chat/${chatId}`;
        return await sendRequest(path);
    }

    

    const createMessage = async (message) => {
        const path = `/chat/${chatId}/messages`;
        const method = 'POST';
        const body = message;
        return await sendRequest(path, method, body);
    };
    const getReceipts = async () => {
        const path = `/chat/${chatId}/read`;
        return await sendRequest(path);
    }
    const getMessages = async ({ before, after, limit, latest }) => {
        const path = `/chat/${chatId}/messages?after=${after || ''}`;
        return await sendRequest(path);
    };

    const createInvitation = async (userIds) => {
        const path = `/chat/${chatId}/members/${userIds[0]}`;
        const method = 'POST';
        return await sendRequest(path, method);
    }

    const leave = async (userId) => {
        const path = `/chat/${chatId}/members/${userId}`;
        const method = 'DELETE';
        return await sendRequest(path, method);
    };

    const getChatUser = async () => {
        const path = `/chat/${chatId}/members`;
        return await sendRequest(path);
    }

    const updateChatUser = async (chatUser) => {
        const path = `/chat/${chatId}/members`;
        const method = 'PUT';
        return await sendRequest(path, method);
    }

    return {
        channel:{
            get: getChannel
        },
        invitations: {
            create: createInvitation
        },
        messages: {
            create: createMessage,
            get: getMessages
        },
        readReceipts:{
            get: getReceipts
        },
        leave,
        user: {
            get: getChatUser,
            update: updateChatUser,
        }
    };
};

export const createChatsClient = (sendRequest) => {
    const create = async (chat) => {
        const path = `/chat`;
        const method = 'POST';
        const body = chat;
        return await sendRequest(path, method, body);
    };

    const list = async () => {
        const path = `/chat`;
        return await sendRequest(path);
    };
    // const start1To1 = async (userId) => {
    //     const path = `/1-1/${userId}`;
    //     return await sendRequest(path);
    // }
    return {
        create,
        list,
        //start1To1,
        createChatClient: createChatClient(sendRequest)
    };
};
import React, { useState, useEffect, useRef } from 'react'
import { connect } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import { Container, Spinner, Button, Modal, Row, Col, Card, Tab, Tabs } from 'react-bootstrap'
import { FaLayerChat, FaUserPlus, FaSignOutAlt } from 'react-icons/fa'
import { IoIosNotificationsOff, IoIosNotifications } from 'react-icons/io'
import { fetchUsers } from '../redux/actions/users'
import { fetchChats, inviteUserToChat, leaveChat, getChatUser, updateChatUser } from '../redux/actions/chats'
import ChatMessages from '../components/Chat/ChatMessages'
import SelectUserModal from '../components/SelectUserModal'
import UserReport from '../components/reports/UserReport';
//import ChatReport from '../components/reports/ChatReport';
import moment from 'moment';
import LoadingSpinner from '../components/controls/LoadingSpinner';
import shortid from 'shortid';
import Chat from './chat'
import { createHeroApiClient } from '../hero-api-client';
import chats from '../redux/reducers/chats';
import {PlusIcon} from '@heroicons/react/24/solid';
import CreateChatModal from '../components/Chat/CreateChatModal';
import './chat.scss'
function ChatFinder(props) {

  let { id: Id, name: chatName } = useParams();
  const [chatId, setChatId] = useState(Id);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [searchChats,setSearchChats] = useState(props.chats);
  const [searchTerm, setSearchTerm] = useState('');

  const [showCreateChatModal, setShowCreateChatModal] = useState(false);

  useEffect(() => {
    props.dispatch(fetchChats());
    // const interval = setInterval(() => {
    //     props.dispatch(fetchChats());
    //   }, 10000);
    //   return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    if(!chatId && props.chats.length){
        changeChatId(props.chats[0]);
    }
    if(searchTerm){
        //console.log(props.chats);
        setSearchChats(props.chats.filter(x=>x.channelSummary.name.toLowerCase().includes(searchTerm.toLowerCase())));
    }
    else{
        setSearchChats(props.chats);
    }
  },[props.chats,searchTerm])
  const changeChatId = (channel) =>{
    
    const arnArr = channel.channelSummary.channelArn.split('/');
    setChatId(arnArr[arnArr.length -1]);
    setSelectedChannel(channel);
    props.dispatch(fetchChats());
  }


  return (
    
    <Row>
         <div className="chat_finder card col-3">
         <Button className="btn--circle expandable createChat" style={{margin:'10px'}} onClick={() => setShowCreateChatModal(true)}>
            <PlusIcon style={{width:'20px'}} />
          </Button>
          <div class={'input_msg_write'} >
            <input placeholder='Search...' 
             value={searchTerm}
             onChange={(event) =>{setSearchTerm(event.target.value)}} />
            {!searchChats.length && (
                <p>No chats found</p>
            )}
            <div className='finder_list mv-5'>
            {searchChats.map(x=>{
                var metadata;
                const selected = x.channelSummary.channelArn.includes(chatId);
                const clsname = selected? ' selected':'';
                const text = selected? 'whiteText':'';
                if(x.channelSummary.metadata){
                  try{
                    metadata = JSON.parse(x.channelSummary.metadata.replace('=',':').replace('users=','"users":'))
                  }
                  catch{

                  }
                  if(metadata){
                  }
                }
                if(metadata?.type === '1-1'){
                    const otherUserId = metadata.users.filter(x=>x != props.user.profile.id)[0];
                    const otherUsers = props.users.filter(x=>x.id === otherUserId);
                    var otherUser;
                    if(otherUsers.length){
                        otherUser = otherUsers[0];
                    }
                    if(otherUser){
                        const source = otherUser.avatarUrl?otherUser.avatarUrl:'https://eu.ui-avatars.com/api?name=' + otherUser.givenName + ' ' + otherUser.familyName;
                        return(
                            <Row onClick={()=>changeChatId(x)} className={'list__selector' + clsname}>
                            <img src={source} className='avatar-xxxsmall' />
                            <p className={text + ' chatName'}>{otherUser.givenName + ' ' + otherUser.familyName}</p>
                            {moment(x.appInstanceUserMembershipSummary.readMarkerTimestamp).isBefore(x.channelSummary.lastMessageTimestamp) && <div className='blueDot'></div>}
                    </Row>
                        )
                    }
                    
                }
                return(
                    
                    <Row onClick={()=>changeChatId(x)} className={'list__selector' + clsname}>
                            <img src={'https://eu.ui-avatars.com/api?name=' + x.channelSummary.name} className='avatar-xxxsmall' />
                            <p className={text + ' chatName'}>{x.channelSummary.name}</p>
                            {moment(x.appInstanceUserMembershipSummary.readMarkerTimestamp).isBefore(x.channelSummary.lastMessageTimestamp) && <div className='blueDot'></div>}
                    </Row>
                )
            })}
            </div>
          </div>
          </div>
        {chatId && <div className='col-9'>
            <Chat chatId={chatId} chat={selectedChannel}></Chat>
        </div>}
        <CreateChatModal
        showCreateChatModal={showCreateChatModal}
        setShowCreateChatModal={setShowCreateChatModal}
       />
    </Row>
  );
}

const mapStateToProps = state => {
  return {
    users: state.users.items,
    user: state.user,
    heroApiClient: createHeroApiClient(state.user.session.accessToken),
    chats: state.chats.items
  };
}

export default withRouter(connect(mapStateToProps)(ChatFinder));


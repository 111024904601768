import React, { useState, useEffect } from "react";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Redux Actions
// 
import * as commandActions from "../../redux/actions/commands";

import { FaPlusCircle } from "react-icons/fa";

//Hero Buttons
import HeroOutlineButton from '../../common/Buttons/OutlineButton';

//Bootstrap
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import FoodSearch from "./food-search";
import { func } from "prop-types";

function FoodFormAdd(props) {
  const [display, setDisplay] = useState(false);

  useEffect(() => { }, [display]);

  function closeAddingFood() {
    setDisplay(false);
    props.reload();
  }
  return (
    <div className="text-center">

      <div className={"show" + display}>
        <FoodSearch
          mealName={props.mealName}
          forDate={props.dateToDisplay}
          reload={closeAddingFood}
          hideMealSelection={true}
          heroApiClient={props.heroApiClient} />
      </div>

      <Button className="btn--circle btn-add-food" onClick={() => {
        setDisplay(!display);
      }}>
        <i className="far fa-plus"></i>
      </Button>

    </div>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    appConfig: state.appConfig,
    dateToDisplay: ownProps.dateToDisplay,
    mealName: ownProps.mealName,
    reload: ownProps.reload
  };
}

function mapDispatchToProps(dispatch) {
  return {
        // healthDataActions: bindActionCreators(healthDataActions, dispatch),
    commandActions: bindActionCreators(commandActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FoodFormAdd);

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, withRouter } from "react-router-dom";
import { Tabs, Tab, Container, Row, Col, Card, Spinner, ProgressBar, Alert, Button, Modal, Dropdown } from 'react-bootstrap';
import { addMemberToChallenge, removeMemberFromChallenge, fetchChallenge, deleteChallenge } from '../redux/actions/challenges';
import { fetchHealthMetrics } from '../redux/actions/healthMetrics';
import HeroOutlineButton from '../common/Buttons/OutlineButton';
import WeeklyContributionCard from '../components/challenges/WeeklyContributionCard';
import SelectUserModal from '../components/SelectUserModal';
import SelectGroupModal from '../components/SelectGroupModal';
import moment from 'moment';
import { toast } from 'react-toastify';
import _ from 'lodash';
import dataStores from "../redux/reducers/dataStores";
import round from "../shared/round";

function Challenge(props) {
  const { regions, departments } = props;
  const { id: challengeId } = useParams();
  const [challenge, setChallenge] = useState();
  const [shareLink, setShareLink] = useState();
  const [healthMetric, setHealthMetric] = useState({});
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [region, setRegion] = useState ("")
  const [department, setDepartment] = useState ("")
  const [showShareModal, setShowShareModal] = useState(false);
  const [showSelectMemberModal, setShowSelectMemberModal] = useState(false);
  const [model, setModel] = useState({});
  const handleSelectMemberFormSubmit = (memberId) => props.dispatch(addMemberToChallenge(challengeId, memberId));

  const today = new Date();
  const utcToday = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate()));
  utcToday.setHours(0, 0, 0, 0);

  useEffect(() => {
    props.dispatch(fetchHealthMetrics());
  }, []);

  useEffect(() => {

    if (props.challenges[challengeId]?.isDeleted) {
      return;
    }

    if (!props.challenges[challengeId] || !props.challenges[challengeId].isValid) {
      props.dispatch(fetchChallenge(challengeId));
      return;
    }

    if (props.challenges[challengeId].isFetching) {
      return;
    }

    if (!props.challenges[challengeId].challenge) {
      props.history.push('/challenges');
      return;
    }

    setChallenge(props.challenges[challengeId].challenge);
  }, [props.challenges]);

  useEffect(() => {
    if (!challenge) {
      return;
    }

    setShareLink(`${window.location.origin}${window.location.pathname}?invite=true`);
    setShowInviteModal(!challenge.userIsInChallenge && window.location.search == '?invite=true');
    window.history.replaceState({}, document.title, window.location.pathname);
  }, [challenge]);

  function formatUnits(value, unit) {
    let newValue = 0;
    let newUnit = "";

    switch (true) {
      case unit === "seconds":
        newUnit = "minutes";
        newValue = value / 60;
        break;
      case unit === "meters":
        newUnit = "km";
        newValue = parseFloat(parseFloat(value / 1000).toFixed(2));
        break;
      case unit === "int":
        newUnit = "";
        newValue = value;
        break;
      case unit === "steps":
        newUnit = "steps";
        newValue = value.toFixed(0);
        break;
      case unit === null:
        newUnit = "";
        newValue = value;
        break;
      default:
        newUnit = unit;
        newValue = value;
        break;
    }

    return [newValue, newUnit];
  }

  const copyToClipboard = (event) => {
    event.preventDefault();
    navigator.clipboard.writeText(shareLink);
    setShowShareModal(false);
    toast.success("Coppied to Clipboard");
  };


  const onRegionFilterChanged = (e) => {
    setRegion(e.currentTarget.value);
  }

  const onDepartmentFilterChanged = (e) => {
    setDepartment(e.currentTarget.value);
  }

  const members = challenge && challenge.members && _.filter(challenge.members,(member)=>{
    let filtered = true;
    if(department) {
      filtered = filtered && member.department === department;
    }
    if (region) {
    filtered = filtered && member.region === region;
    }
    return filtered;
  })

  useEffect(() => {
    if (!!!challenge || !props.healthMetrics.items.length > 0) {
      return;
    }

    const healthMetric = props.healthMetrics.items.find(x => x.id == challenge.healthMetricId);
    if (healthMetric) {
      setHealthMetric(healthMetric);
    }
    const [recordedValue, units] = formatUnits(challenge.totalContributionValue, healthMetric.units);
    const type = healthMetric.name;
    setModel({
      units: units,
      recordedValue: window.Utils.isDurationHealthMetric(type) ? window.Utils.formatDurationHealthMetric(type, recordedValue) : round(recordedValue, window.Utils.getDPForHealthMetricType(type)),
    });
  }, [props.healthMetrics, challenge]);

  const confirmDeleteChallenge = () => {
    if (!window.confirm('Are you sure you want to delete this challenge?')) return;
    props.dispatch(deleteChallenge(challenge.id, () => {
      props.history.replace('/challenges');
    }));
  }

  const SummaryTextCard = () => {
    const challengeType = challenge.challengeType == 'Sum' ? 'sum total' : 'daily average';
    const startDate = moment.utc(challenge.startDate).format('ddd MMM D YYYY');
    const endDate = moment.utc(challenge.endDate).format('ddd MMM D YYYY');
    const targetValue = challenge.targetValue;
    const collectiveTarget = challenge.targetValue * challenge.members.length;
    const contributionAverage = model.recordedValue / challenge.members.length;
    const averageContribution = `Average contribution per user = ${contributionAverage.toFixed(0)} ${model.units}`;
    const summaryText = `Tracking ${healthMetric.name} (${challengeType}) from ${startDate} to ${endDate}. Aiming to hit a target of ${targetValue}. Collectively the target is ${collectiveTarget}.`;
    return (
      <div>
        <p className="mt-3">
          {summaryText}
        </p>
        <p className="mt-3">
          {averageContribution}
        </p>
      </div>
    );
  };

  const Leaderboard = () => {
    const todaysContribution = challenge.memberContributions
    .filter(x => x.memberId == props.user.profile.id && (
        moment(x.date).format("MMM Do YY") ===
        moment(Date.now()).format("MMM Do YY")
    ))
      .reduce((total, contribution) => total + contribution.value, 0);

    const progress = challenge.targetContributionValue == 0 ? 0 : (model.recordedValue / challenge.targetContributionValue * 100);

    const membersWithContributionTotal = members.map(member => ({
      ...member,
      googleFitTotal: challenge.memberContributions
      .filter(x => x.memberId == member.id)
      .filter(x => x.source == "google_fit")
      .reduce((total, contribution) => total + contribution.value / 50, 0),
      appleHealthTotal: challenge.memberContributions
      .filter(x => x.memberId == member.id)
      .filter(x => x.source == "apple_health")
      .reduce((total, contribution) => total + contribution.value, 0),
      fitbitTotal: challenge.memberContributions
      .filter(x => x.memberId == member.id)
      .filter(x => x.source == "fitbit")
      .reduce((total, contribution) => total + contribution.value, 0),
      stravaTotal: challenge.memberContributions
      .filter(x => x.memberId == member.id)
      .filter(x => x.source == "strava")
      .reduce((total, contribution) => total + contribution.value, 0),
      manualTotal: challenge.memberContributions
      .filter(x => x.memberId == member.id)
      .filter(x => x.source == "HERO")
      .reduce((total, contribution) => total + contribution.value, 0),
      garminTotal: challenge.memberContributions
      .filter(x => x.memberId == member.id)
      .filter(x => x.source == "garmin")
      .reduce((total, contribution) => total + contribution.value, 0),
      withingsTotal: challenge.memberContributions
      .filter(x => x.memberId == member.id)
      .filter(x => x.source == "withings")
      .reduce((total, contribution) => total + contribution.value, 0),
      underarmourTotal: challenge.memberContributions
      .filter(x => x.memberId == member.id)
      .filter(x => x.source == "underarmour")
      .reduce((total, contribution) => total + contribution.value, 0),
      polarTotal: challenge.memberContributions
      .filter(x => x.memberId == member.id)
      .filter(x => x.source == "polar")
      .reduce((total, contribution) => total + contribution.value, 0),
      suuntoTotal: challenge.memberContributions
      .filter(x => x.memberId == member.id)
      .filter(x => x.source == "suunto")
      .reduce((total, contribution) => total + contribution.value, 0),
      noSource: challenge.memberContributions
      .filter(x => x.memberId == member.id)
      .filter(x => x.source == null)
      .reduce((total, contribution) => total + contribution.value, 0),
      contributionTotal: challenge.memberContributions
        .filter(x => x.memberId == member.id)
        .reduce((total, contribution) => total + contribution.value, 0),
    })).sort((a, b) => b.contributionTotal - a.contributionTotal);
    
    const reorderContribution = membersWithContributionTotal.map(newMember => ({
      ...newMember,
      newContributionValue: newMember.noSource + newMember.googleFitTotal + newMember.appleHealthTotal + newMember.fitbitTotal + newMember.stravaTotal + newMember.manualTotal + newMember.garminTotal + newMember.withingsTotal + newMember.underarmourTotal + newMember.polarTotal + newMember.suuntoTotal
    })).sort((a, b) => b.newContributionValue - a.newContributionValue);

    return (
      <>
        <Row className="mt-3">
          <Col lg={8}>
            <h2 className="mb-4">Current Ranking</h2>
            <Card>
              <Card.Body>
                <Row>
                  <Col md={12} style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <table className="challenge__leaderboard w-100">
                      <thead>
                        <tr>
                          <th>Rank</th>
                          <th></th>
                          <th>Name</th>
                          <th class="text-center">Total {model.units}</th>
                          {membersWithContributionTotal?.[0]?.region && <th class="text-center">Region</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {reorderContribution.map((x, index) => {
                          if (x.id === props.user.profile.id) {
                            const totalContributionOverall = x.noSource + x.googleFitTotal + x.appleHealthTotal + x.garminTotal + x.stravaTotal + x.manualTotal + x.fitbitTotal + x.withingsTotal + x.underarmourTotal + x.polarTotal + x.suuntoTotal;
                            const challengeContributionTotal = totalContributionOverall;
                            if (model.units === "km") {
                              x.newContributionValue = challengeContributionTotal / 1000;
                            }
                            if (challengeContributionTotal != 0) {
                              x.newContributionValue = totalContributionOverall
                            }
                            return (
                              <tr>
                                <td className={x.name + "-" + index}>{index + 1}</td>
                                <td>
                                  <img src={x.imageUrl || `https://eu.ui-avatars.com/api?name=${x.name}`} style={{ margin: "0px 0.1px", height: "25px", width: "25px", borderRadius: "50%" }} />
                                </td>
                                <td>{x.name}</td>
                                <td className="text-center">{x.newContributionValue.toFixed(0)}</td>
                                {x.region && <td class="text-center">{x.region}</td>}
                              </tr>
                            );
                          }
                        })}
                      </tbody>
                    </table>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <h2 className="mb-4">Leaderboard</h2>
            <Card>
              <Card.Body>
                <Row>
                  <Col md={12} style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <table className="challenge__leaderboard w-100">
                      <thead>
                        <tr>
                          <th>Rank</th>
                          <th></th>
                          <th>Name</th>
                          <th class="text-center">Total {model.units}</th>
                          {membersWithContributionTotal?.[0]?.region && <th class="text-center">Region</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {reorderContribution.map((x, index) => {
                          const totalContributionOverall = x.noSource + x.googleFitTotal + x.appleHealthTotal + x.garminTotal + x.stravaTotal + x.fitbitTotal + x.manualTotal + x.withingsTotal + x.underarmourTotal + x.polarTotal + x.suuntoTotal;
                          const challengeContributionTotal = totalContributionOverall;
                          if ((model.units === "km") && (x.id != props.user.profile.id)) {
                            x.newContributionValue = challengeContributionTotal / 1000;
                          }
                          if ((x.googleFitTotal > 0) && (x.id != props.user.profile.id)) {
                            x.newContributionValue = x.googleFitTotal;
                          }
                          if (challengeContributionTotal != 0) {
                            x.newContributionValue = totalContributionOverall
                          }
                          return (
                            <tr>
                              <td>{index + 1}</td>
                              <td>
                                <img src={x.imageUrl || `https://eu.ui-avatars.com/api?name=${x.name}`} style={{ margin: "0px 0.1px", height: "25px", width: "25px", borderRadius: "50%" }} />
                              </td>
                              <td>{x.name}</td>
                              <td class="text-center">{x.newContributionValue.toFixed(0)}</td>
                              {x.region && <td class="text-center">{x.region}</td>}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4}>
            <h2 className="mb-4">Stats</h2>
            <Card>
              <Card.Body style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <p style={{ fontSize: "1.15rem" }}>Users in Challenge</p>
                <p className="mt-3 text-center">There are a total of <strong>{challenge.members.length}</strong> people in this challenge</p>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <p style={{ fontSize: "1.15rem" }}>Total Progress</p>
                <p className="mt-3 text-center">{`This challenge is measuring ${model.units}`}</p>

                <ProgressBar style={{ width: "100%" }} variant={progress > 99 ? 'primary' : 'primary'} now={progress} label={`${progress.toFixed(0)}%`} />

                <p style={{ marginTop: "1.15rem", fontSize: "1.25rem", marginBottom: "0rem" }}>
                  {`${model.recordedValue} / ${challenge.targetContributionValue.toFixed(0)}`}
                </p>
              </Card.Body>
            </Card>

            <Card>
              <Card.Body style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <p style={{ fontSize: "1.15rem" }}>
                  Today you have contributed:
                </p>

                <p style={{ fontSize: "1.6rem" }}>
                  {todaysContribution.toFixed(0)} {model.units}
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>
    );
  };

  if (!challenge) {
    return (
      <Container className="text-center">
        <Spinner className="m-5" animation="border" role="status" variant="primary">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </Container>
    )
  }
  const isVodafone = props.user.profile.companyKey === 'vodafoneglobalchallenge'

  return (
    <Container>
      {props.challenges.isFetching && (
        <Container className="text-center">
          <Spinner className="m-5" animation="border" role="status" variant="primary" >
            <span className="sr-only">Loading...</span>
          </Spinner>
        </Container>
      )}

      {challenge &&
        <>
          <Row className="title-container">
            <Col>
              <h1 className="page__title mb-0">{challenge.name}</h1>
              <p className="mb-3">{challenge.description}</p>

              {isVodafone && (
                  <Row>
                    <Col md={6}>
                      <div className="mb-4">
                        <div className="select__label mr-3">Filter by region:</div>
                        <select style={{width:250}} onChange={onRegionFilterChanged} value={region}>
                          <option value={""}>Select a region</option>
                          {regions.map((r)=>(
                              <option value={r}>{r}</option>
                          ))}
                        </select>
                      </div>
                    </Col>


                    <Col md={6}>
                      <div className="mb-4">
                        <div className="select__label mr-3">Filter by department:</div>
                        <select style={{width:250}} onChange={onDepartmentFilterChanged} value={department}>
                          <option value={""}>Select a department</option>
                          {departments.map((d)=>(
                              <option value={d}>{d}</option>
                          ))}
                        </select>
                      </div>
                    </Col>

                  </Row>
              )}
            </Col>
            <Col>
              <div className="btn--actions">
                <button className="btn btn--back btn--circle" onClick={() => props.history.goBack()}><i className="fas fa-chevron-left"></i></button>

                <Dropdown>
                  <Dropdown.Toggle className="btn btn-primary btn--circle">
                    <i className="far fa-ellipsis-v"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {(challenge.ownerId == props.user.profile.id || challenge.participantType == 'Group') && (
                      <Dropdown.Item disabled={props.challenges.isFetching} onClick={() => setShowSelectMemberModal(true)}>
                        Add Team
                      </Dropdown.Item>
                    )}

                    {challenge.participantType == 'Individual' && !challenge.userIsInChallenge && (
                      <Dropdown.Item disabled={props.challenges.isFetching} onClick={() => props.dispatch(addMemberToChallenge(challenge.id, props.user.profile.id))}>
                        Join
                      </Dropdown.Item>
                    )}

                    {challenge.participantType == 'Individual' && challenge.userIsInChallenge && (
                      <Dropdown.Item disabled={props.challenges.isFetching} onClick={() => props.dispatch(removeMemberFromChallenge(challenge.id, props.user.profile.id))}>
                        Leave
                      </Dropdown.Item>
                    )}

                    {!challenge.isPrivate &&
                      <Dropdown.Item onClick={() => setShowShareModal(true)}>
                        Share
                      </Dropdown.Item>}

                    {challenge.ownerId == props.user.profile.id && (
                      <Dropdown.Item disabled={props.challenges.isFetching} onClick={confirmDeleteChallenge}>
                        Delete
                      </Dropdown.Item>
                    )}

                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              {challenge.closed &&
                <Alert variant="danger">
                  <p>This challenge has been cancelled</p>
                </Alert>
              }
              {challenge.endDate < utcToday &&
                <Alert variant="danger">
                  <p>This challenge has now ended</p>
                </Alert>
              }
            </Col>
          </Row>

          <Tabs fill defaultActiveKey="challenge" id="uncontrolled-tab-example">
            <Tab eventKey="challenge" title="Challenge">
              <Leaderboard />
            </Tab>
            <Tab eventKey="contribution" title="Contribution Stats">
              <h2 className="mt-3 mb-3">
                My Weekly Contribution
              </h2>
              <WeeklyContributionCard challenge={challenge} healthMetric={healthMetric} />
            </Tab>
            <Tab eventKey="members" title="Members">
              <h2 className="mt-3 mb-3">
                Participants
              </h2>
              <Row>
                <Col md={12}>
                  <Row>
                    {members.map(member => (
                      <Col md={3} className="mb-2">
                        <Card>
                          <Card.Body className="text-center">
                            <img className="mb-2" src={member.imageUrl || `https://eu.ui-avatars.com/api?name=${member.name}`} style={{ height: "50px", width: "50px", borderRadius: "50%" }} />
                            <p>{member.name} {challenge.ownerId == member.id && <span>(admin)</span>}</p>
                            {(challenge.ownerId == props.user.profile.id || member.id == props.user.profile.id) && (
                              <Button disabled={props.challenges.isFetching} onClick={() => props.dispatch(removeMemberFromChallenge(challenge.id, member.id))}>Remove</Button>
                            )}
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="summary" title="Summary">
              <Row>
                <Col>
                  <SummaryTextCard />
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </>}

      {challenge.participantType == 'Individual' && (
        <SelectUserModal
          show={showSelectMemberModal}
          setShow={setShowSelectMemberModal}
          handleFormSubmit={handleSelectMemberFormSubmit}
        />
      )}

      {challenge.participantType == 'Group' && (
        <SelectGroupModal
          show={showSelectMemberModal}
          setShow={setShowSelectMemberModal}
          handleFormSubmit={handleSelectMemberFormSubmit}
        />
      )}

      <Modal centered show={showInviteModal} onHide={() => setShowInviteModal(false)}>
        <Modal.Body>
          <h2>Do you want to join this challenge?</h2>
          <Button onClick={() => props.dispatch(addMemberToChallenge(challengeId, props.user.profile.id)) && setShowInviteModal(false)}>Yes</Button>
          <Button onClick={() => props.history.push('/challenges')}>No</Button>
        </Modal.Body>
      </Modal>

      <Modal centered show={showShareModal} onHide={() => setShowShareModal(false)}>
        <Modal.Body>
          {!challenge.isPrivate && (
            <div>
              <h2>Share link</h2>
              <input className="w-100 p-2 mt-2 mb-2" disabled={true} value={shareLink} />
              <Button className="w-100 btn--rounded" variant={"primary"} onClick={copyToClipboard}>Copy to clipboard</Button>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </Container>
  );
}

const mapStateToProps = state => {
  return {
    user: state.user,
    challenges: state.challenges,
    healthMetrics: state.healthMetrics,
    regions: state.appObject[dataStores.APP_CONFIG].regions || [],
    departments: state.appObject[dataStores.APP_CONFIG].departments || [],
  };
};

export default withRouter(connect(mapStateToProps)(Challenge));

import React from "react";
import { Card, CardDeck } from "react-bootstrap";
import { Link } from "react-router-dom";

const FinanceCalculatorCard = ({ calculator }) => {
  return (
    <Card style={{ width: "18rem" }}>
      <Link to={calculator.link}>
        <Card.Img
          variant="top"
          src={calculator.content.image_url}
          style={{ height: "400px", objectFit: "cover" }}
        />
      </Link>
      <Card.Body>
        <Link to={calculator.link}>
          <Card.Title>{calculator.name}</Card.Title>
        </Link>
        <Card.Text>{calculator.content.body.original}</Card.Text>
      </Card.Body>
    </Card>
  );
};

const FinanceCalculatorsList = () => {
  return (
    <div>
      <CardDeck>
        {calculatorListData.calculators.map((calculator, index) => (
          <FinanceCalculatorCard key={index} calculator={calculator} />
        ))}
      </CardDeck>
    </div>
  );
};

export default FinanceCalculatorsList;


const calculatorListData = {
    calculators: [
      {
        name: 'Savings Calculator',
        selected: "savings",
        link: "/savings-calculator",
        content: {
          image_url: 'https://cdn.nvgtr.io/i/777d1fa6-bff9-47d2-bfc3-cb4e731f1af9-financeSavings.jpg?w=600&h=400',
          body: {
            original: 'Enter your savings and a savings goal and find out how much you could save'
          }
        }
      },
      {
        name: 'Mortgage Calculator',
        selected: "mortgage",
        link: "/mortgage-calculator",
        content: {
          image_url: 'https://cdn.nvgtr.io/i/2549c553-5b9e-426f-bb70-f54334ca64f4-financeMorgage.jpg?w=600&h=400',
          body: {
            original: 'Enter your mortgage details and find out repayments and total interest'
          }
        }
      },
      {
        name: 'Loan Calculator',
        selected: "loan",
        link: "/loan-calculator",
        content: {
          image_url: 'https://cdn.nvgtr.io/i/4552924f-8908-401d-8648-f8d616f15878-financeLoan.jpg?w=600&h=400',
          body: {
            original: 'Enter your loan details and find out additional details on savings and interest'
          }
        }
      }
    ]
  };

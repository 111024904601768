import React, { useState, useEffect } from "react";
import {
  Form,
  Container,
  Row,
  Col,
  Button,
  Accordion,
  Card,
  ButtonGroup,
} from "react-bootstrap";
import axios from "axios";

//#region Questions and Data
const medicalQuestions = [
  {
    question:
      "Do you have a history of heart disease or any cardiovascular conditions?",
    questionID: 1,
    answer: false,
  },
  {
    question: "Have you ever had a heart attack or stroke?",
    questionID: 2,
    answer: false,
  },
  {
    question:
      "Are you currently taking any medications for heart-related issues?",
    questionID: 3,
    answer: false,
  },
];

const FamilyHistoryQuestions = [
  {
    question:
      "Do you have a family history of heart disease or any cardiovascular conditions?",
    questionID: 4,
    answer: false,
  },
  {
    question: "Has anyone in your family ever had a heart attack or stroke?",
    questionID: 5,
    answer: false,
  },
];

const LifestyleQuestions = [
  {
    question: "Do you smoke or use any tobacco products?",
    questionID: 6,
    answer: false,
  },
  {
    question:
      "On a scale of 1-5 (1 being completely inactive - 5 being athletic performance), how would you rate your current activity levels?",
    questionID: 7,
    answer: false,
    type: "range",
  },
  {
    question:
      "On a scale of 1-5 (1 being very unhealthy - 5 being very healthy), how would you rate your current diet?",
    questionID: 8,
    answer: false,
    type: "range",
  },
];

const StressQuestions = [
  {
    question: "Do you experience high levels of stress on a regular basis?",
    questionID: 9,
    answer: false,
  },
  {
    question: "Have you ever been diagnosed with anxiety or depression?",
    questionID: 10,
    answer: false,
  },
];

const generalHealthQuestions = [
  {
    question: "Are you currently experiencing any health issues or symptoms?",
    questionID: 11,
    answer: false,
  },
  {
    question:
      "Have you recently undergone any surgeries or medical procedures?",
    questionID: 12,
    answer: false,
  },
];

const allergiesAndMedicationsQuestions = [
  {
    question:
      "Do you have any known allergies, especially to medications or medical equipment?",
    questionID: 13,
    answer: true,
  },
  {
    question:
      "Are you currently taking any medications, supplements, or vitamins?",
    questionID: 14,
    answer: false,
  },
  {
    question:
      "By selected yes here and submitting this form, you agree to share your answers with our health care professionals.",
    questionID: 99,
    answer: true,
  },
];
//#endregion

const DummyData = [
  {
    answer: true,
    questionID: 1,
  },
  {
    answer: false,
    questionID: 99,
  },
  {
    answer: true,
    questionID: 5,
  },
  {
    answer: true,
    questionID: 6,
  },
  {
    answer: 1,
    questionID: 7,
  },
  {
    answer: 5,
    questionID: 8,
  },
  {
    answer: true,
    questionID: 10,
  },
  {
    answer: true,
    questionID: 11,
  },
  {
    answer: true,
    questionID: 13,
  },
  {
    answer: "Mike",
    questionID: "firstName",
  },
  {
    answer: "Kirk",
    questionID: "lastName",
  },
  {
    answer: "2024-02-20T23:29:32.478Z",
    questionID: "checkDate",
  },
  {
    answer: "6e85b174-8760-4f65-96da-7cc39f15bc5c",
    questionID: "userID",
  },
];

const defaultData = [
  {
    answer: false,
    questionID: 1,
  },
  {
    answer: false,
    questionID: 2,
  },
  {
    answer: false,
    questionID: 3,
  },
  {
    answer: false,
    questionID: 4,
  },
  {
    answer: false,
    questionID: 5,
  },
  {
    answer: false,
    questionID: 6,
  },
  {
    answer: false,
    questionID: 7,
  },
  {
    answer: false,
    questionID: 8,
  },
  {
    answer: false,
    questionID: 9,
  },
  {
    answer: false,
    questionID: 10,
  },
  {
    answer: false,
    questionID: 11,
  },
  {
    answer: false,
    questionID: 12,
  },
  {
    answer: false,
    questionID: 13,
  },
  {
    answer: false,
    questionID: 14,
  },
  {
    answer: false,
    questionID: 99,
  },
];

const PreHealthCheck = ({ userID }) => {
  const [answers, setAnswers] = useState(defaultData);
  const [saveStatus, setSaveStatus] = useState(null);

  useEffect(() => {
    // Load user data from API
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `https://tillo.nvgtr.io/auxData/load?userID=${userID}&auxDataType=prehealthCheck`
        );
        console.log(JSON.stringify(response.data));
        console.log(JSON.stringify(DummyData));
        setAnswers(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchUserData();
  }, [userID]);

  const handleToggleChange = (questionID, answer) => {
    console.log("Saving Answer: " + questionID + " " + answer);
    const question = answers.find(
      (question) => question.questionID === questionID
    );
    if (!question) {
      // Create a new question
      answers.push({ questionID: questionID, answer: answer });
    } else {
      question.answer = answer;
    }
    setAnswers([...answers]);
  };

  const getAnswerFromID = (id) => {
    if (!answers) return false;
    const answer = answers.find((x) => x.questionID === id);
    //if answer is undefined, return false
    if (!answer) return false;
    //if answer.answer is a number return the number
    if (typeof answer?.answer === "number") return answer?.answer;

    if (answer?.answer === true) return true;
    if (answer?.answer === false) return false;
    //if (typeof answer?.answer === "number") return answer?.answer;

    return false;
  };

  const saveUserData = async () => {
    setSaveStatus("Saving...");
    const path = `https://tillo.nvgtr.io/auxData/save?userID=${userID}&auxDataType=prehealthCheck`;
    const method = "POST";
    const body = answers; // Construct the body object dynamically
    const response = await fetch(path, {
      method: method,
      headers: {
        "Content-Type": "application/json", // Specify the content type as JSON
      },
      body: JSON.stringify(body), // Convert the body object to a JSON string
    });
    setSaveStatus("Data Saved");
    return null;
  };

  const createQuestions = (questions) => {
    return questions.map((question) => {
      const isChecked = getAnswerFromID(question.questionID);
      return (
        <tr key={question.questionID}>
          <td>{question.question}</td>
          <td>
            {question.type === "range" ? (
              <ButtonGroup>
                {[1, 2, 3, 4, 5].map((value) => (
                  <Button
                    key={value}
                    variant={isChecked === value ? "primary" : "secondary"}
                    onClick={() =>
                      handleToggleChange(question.questionID, value)
                    }
                    style={{
                      color: isChecked === value ? "white" : "black",
                      backgroundColor:
                        isChecked === value ? "black" : "#f8f9fa",
                      borderColor: isChecked === value ? "#007bff" : "#6c757d",
                    }}
                  >
                    {value}
                  </Button>
                ))}
              </ButtonGroup>
            ) : (
              <Form.Switch
                id={`toggle-${question.questionID}`}
                checked={isChecked}
                onChange={(e) =>
                  handleToggleChange(question.questionID, e.target.checked)
                }
                label={isChecked ? "Yes" : "No"}
              />
            )}
          </td>
        </tr>
      );
    });
  };

  return (
    <Container>
      <Accordion defaultActiveKey="">
        <Card>
          <Accordion.Toggle
            as={Card.Header}
            eventKey="0"
            style={{ cursor: "pointer" }}
          >
            <h3 style={{ marginBottom: 0 }}>
              Click to complete a Pre-Health Check
            </h3>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <th>Question</th>
                    <th>Answer</th>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h2>Medical History</h2>
                    </td>
                  </tr>
                  {createQuestions(medicalQuestions)}
                  <tr>
                    <td colSpan="2">
                      <h2>Family History</h2>
                    </td>
                  </tr>
                  {createQuestions(FamilyHistoryQuestions)}
                  <tr>
                    <td colSpan="2">
                      <h2>Lifestyle</h2>
                    </td>
                  </tr>
                  {createQuestions(LifestyleQuestions)}
                  <tr>
                    <td colSpan="2">
                      <h2>Stress</h2>
                    </td>
                  </tr>
                  {createQuestions(StressQuestions)}
                  <tr>
                    <td colSpan="2">
                      <h2>General Health</h2>
                    </td>
                  </tr>
                  {createQuestions(generalHealthQuestions)}
                  <tr>
                    <td colSpan="2">
                      <h2>Allergies and Medications</h2>
                    </td>
                  </tr>
                  {createQuestions(allergiesAndMedicationsQuestions)}
                </tbody>
              </table>
              <Button variant="primary" onClick={saveUserData}>
                Save Changes
              </Button>
              {saveStatus}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </Container>
  );
};

export default PreHealthCheck;

import { REQUEST_COACHES, RECEIVE_COACHES, INVALIDATE_COACHES, RECEIVE_MINE, REQUEST_MINE, INVALIDATE_MINE } from '../actions/coaches'


const initialState = {
    isFetching: false,
    isValid: false,
    items:[],
    isFetchingMine:false,
    isValidMine:false,
    myItems:[]
}

export default function coaches(state = initialState, action) {
    switch (action.type) {
        case REQUEST_COACHES:
            return {
                ...state,
                isFetching: true,
                isValid: true
            }
        case RECEIVE_COACHES:
            return {
                ...state,
                isFetching: false,
                isValid: true,
                items: action.payload
            }
        case INVALIDATE_COACHES:
            return {
                ...state,
                isValid: false
            }
        case REQUEST_MINE:
            return {
                ...state,
                isFetchingMine: true,
                isValidMine: true
            }
        case RECEIVE_MINE:
            return {
                ...state,
                isFetchingMine: false,
                isValidMine: true,
                myItems: action.payload
            }
        case INVALIDATE_MINE:
            return {
                ...state,
                isValidMine: false
            }
        default:
            return state
    }
}
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// Redux Connections
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//Redux Actions

import * as commandActions from "../../redux/actions/commands";
import dataStores from "../../redux/reducers/dataStores";

// BOOTSTRAP STUFF
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";

//MISC
import { FaUserCircle } from "react-icons/fa";

function HeadCoachPermissions(props) {
  useEffect(() => {
    //Get all the users i have access too
    if (props.dataStores[dataStores.HEADCOACH_PERMISSIONS].success == null) {
      props.commandActions.sendCommand(
        dataStores.HEADCOACH_PERMISSIONS,
        { actionType: "GET" }
      );
    }
  }, [props.dataStores[dataStores.HEADCOACH_PERMISSIONS]]);

  function LogInAsUser(target) {
    props.commandActions.sendCommand(
      dataStores.HEADCOACH_PERMISSIONS,
      target
    );
  }

  function GetUsersIHavePermissionToView() {
    var people = [];
    if (
      props.dataStores[dataStores.HEADCOACH_PERMISSIONS].success == true &&
      props.dataStores[dataStores.HEADCOACH_PERMISSIONS].specialistsWithAccess
    ) {
      props.dataStores[
        dataStores.HEADCOACH_PERMISSIONS
      ].specialistsWithAccess.map(person => {
        people.push(
          <Col md={3}>
            <Link onClick={() => LogInAsUser({
              actionType: "GET_ACCESS",
              delegatedUserId: props.user.profile.id,
              requestForAccessToUserId: person.userId,
              email: props.user.profile.emailAddress,
              userEmail: person.email
            })}>
              <Card key={person.userId}>
                <Card.Body className="text-center">
                  <img className="mb-2" src={person.imageUrl || `https://eu.ui-avatars.com/api?name=${person.email}`} style={{ height: "50px", width: "50px", borderRadius: "50%" }} />
                  <p>{person.firstName} {person.lastName}</p>
                  <span>{person.email}</span>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        );
      });
    }

    return people;
  }

  return props.dataStores[dataStores.HEADCOACH_PERMISSIONS]
    .specialistsWithAccess &&
    props.dataStores[dataStores.HEADCOACH_PERMISSIONS].specialistsWithAccess
      .length > 0 ? (
      <div className="mb-3 mt-3">
        <h3>Head Coach</h3>
        <p className="mb-2">Below is a list of users you have access to.</p>
        <Row>
          {GetUsersIHavePermissionToView()}
        </Row>
      </div>
    ) : (
      <></>
    );
}
function mapStateToProps(state) {
  return {
    coachToken: state.appObject[dataStores.DELEGATED_PERMISSIONS].coachToken != undefined ? state.appObject[dataStores.DELEGATED_PERMISSIONS].coachToken : state.appObject[dataStores.HEADCOACH_PERMISSIONS].coachToken,
    AccessId: state.appObject[dataStores.DELEGATED_PERMISSIONS].uid != undefined ? state.appObject[dataStores.DELEGATED_PERMISSIONS].uid : state.appObject[dataStores.HEADCOACH_PERMISSIONS].uid,
    appConfig: state.appConfig,
    feed: state.feed,
    dataStores: state.appObject,
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    commandActions: bindActionCreators(commandActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeadCoachPermissions);

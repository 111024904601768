import { combineReducers } from 'redux'
import healthMetricsOld from './healthMetricsOld'
import commands from './commands'

import healthData from './healthData'
import healthDataMood from './healthDataMood'
import healthMetrics from './healthMetrics'
import appConnections from './appConnections'
import chats from './chats'
import videos from './videos'
import chatMessages, {reads} from './chatMessages'
import coaches from './Coaches'
import groups from './groups'
import groupMessages from './groupMessages'
import groupInvitations from './groupInvitations'
import groupUser from './groupUser'
import users from './users'
import dataProtection from './dataProtection'
import notifications from './notifications'
import challenges from './challenges'
import challengesJoinable from './challengesJoinable'
import challengesCurrent from './challengesCurrent'
import challengesPast from './challengesPast'
import challengesUpcoming from './challengesUpcoming'
import errors from './errors'
import userSession from './userSession'
import userProfile from './userProfile'
import userGoals from './userGoals'
import userHealthMetricSources from './userHealthMetricSources'
import articleContent from '../../hero-api-client/ArticleContent'

const reducer = combineReducers({
  healthMetricsOld, //TODO - merge in to healthMetrics
  appObject: commands, // TODO - remove. the use of the command pattern is depreciated.
  articleContent,
  healthData,
  healthDataMood,
  healthMetrics,
  appConnections,
  groups,
  chats,
  videos,
  coaches,
  chatMessages,
  reads,
  groupMessages,
  groupInvitations,
  groupUser,
  users,
  dataProtection,
  notifications,
  challenges,
  challengesJoinable,
  challengesCurrent,
  challengesPast,
  challengesUpcoming,
  errors,
  user: combineReducers({
    session: userSession,
    profile: userProfile,
    goals: userGoals,
    healthMetricSources: userHealthMetricSources
  })
});

export default reducer;
import { createHeroApiClient } from '../../hero-api-client';
import { createError } from './errors';

export const RECEIVE_CHAT_MESSAGES = 'RECEIVE_CHAT_MESSAGES'
function receiveChatMessages(chatId, messages) {
    return {
        type: RECEIVE_CHAT_MESSAGES,
        payload: { chatId, messages }
    }
}
export const RECEIVE_READ_RECEIPTS = 'RECEIVE_READ_RECEIPTS'
function receiveMessageReceipts(chatId, receipts){
    return{
        type:RECEIVE_READ_RECEIPTS,
        payload:{chatId,receipts}
    }
}
export function fetchChatMessages(chatId, options) {
    return (dispatch, getState) => {
        const accessToken = getState().user.session.accessToken;
        const chatClient = createHeroApiClient(accessToken).chats
            .createChatClient(chatId);
        chatClient.messages.get(options)
            .then(messages => {
                dispatch(receiveChatMessages(chatId, messages))})
            .catch(error => dispatch(createError(error.message)));
    }
}
export function fetchReadTimes(chatId){
    return(dispatch, getState) =>{
        const accessToken = getState().user.session.accessToken;
        const chatClient = createHeroApiClient(accessToken).chats
            .createChatClient(chatId);
        chatClient.readReceipts.get()
        .then(receipts => {
            dispatch(receiveMessageReceipts(chatId, receipts))
        })
        .catch(error => dispatch(createError(error.message)));

    }
}

export function sendChatMessage(chatId, message) {
    return async (dispatch, getState) => {
        const accessToken = getState().user.session.accessToken;
        const chatClient = createHeroApiClient(accessToken).chats
            .createChatClient(chatId);
        chatClient.messages.create({message})
            .catch(error => dispatch(createError(error.message)));
    }
}

import { HealthApi } from 'hero-api-client'
import NProgress from "nprogress";

export const RECEIVE_HEALTH_METRICS = 'RECEIVE_HEALTH_METRICS'
function receiveHealthMetrics(payload){
  return {
    type: RECEIVE_HEALTH_METRICS,
    payload: payload
  }
}

export function getMyHealthMetrics(date) {
  return function(dispatch, getState) {
    const api = new HealthApi({ basePath: process.env.REACT_APP_API_BASE_URL });
    const accessToken = getState().user.session.accessToken;
    NProgress.start();
    return api
      .healthMetrics({
        headers: { "Content-Type": "application/json", "hero-token": accessToken },
        query: {"reportDate": JSON.stringify(date)}
      })
      .then(payload => {
        NProgress.done();
        dispatch(receiveHealthMetrics(payload));
      });
  };
}

export function onChange(
  existingState,
  areaId,
  sectionId,
  healthMetricId,
  newValue
) {
  var newState = JSON.parse(JSON.stringify(existingState));

  newState.areas[areaId].sections[sectionId].healthMetrics[
    healthMetricId
  ].userHealthMetric.value = newValue;
  newState.areas[areaId].sections[sectionId].healthMetrics[
    healthMetricId
  ].hasChanged = true;
  return receiveHealthMetrics(newState)
}

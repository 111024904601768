import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import { connect } from 'react-redux'
import { Row, Col, Card, Button } from 'react-bootstrap'
import { fetchAppConnections } from '../redux/actions/appConnections'

function AppConnections(props) {
  const [appConnections, setAppConnections] = useState([])

  useEffect(() => {
    props.dispatch(fetchAppConnections())
  }, [])

  useEffect(() => {
    const priorities = ['fitbit', 'strava', 'nokia', 'garmin'];
    const prioritisedAppConnections = props.appConnections.items.sort((a, b) => priorities.indexOf(a) - priorities.indexOf(b)).filter(appConnection => appConnection.type !== 'HERO')
    setAppConnections(prioritisedAppConnections)
  }, [props.appConnections])

  return (
    <Container>
      <h1 className="page__title">App Connections</h1>

      <Row style={{ marginBottom: "0.75rem" }}>
          <Col md={{ span: 5, offset: 1 }}>
            <Card className="bm">
              <Card.Header className="ac">Apple HealthKit</Card.Header>
              <Card.Body className="ac pt0">
                <img style={{ margin: "1.4rem 0 1.4rem 0", height: "auto", width: "90px" }} src={`https://image.flaticon.com/icons/png/512/23/23656.png`} />

                <br />
                <p>Get hero app for your Apple phone!</p>
                <a href="https://apps.apple.com/us/app/hero-wellbeing/id1374862951?ls=1" target="_blank">
                  <img style={{ width: "100px", height: "auto", margin: "1.4rem 0 0" }} src={require("../assets/images/app-store.svg")} />
                </a>
              </Card.Body>
            </Card>
          </Col>

          <Col md={{ span: 5 }}>
            <Card className="bm">
              <Card.Header className="ac">Google Fit</Card.Header>
              <Card.Body className="ac pt0">
                <img style={{ margin: "1.4rem 0 1.4rem 0", height: "auto", width: "122px" }} src={require("../assets/images/google-fit-logo.png")} />
                <br />
                <p>Get hero app for your Android phone!</p>
                <a href="https://play.google.com/store/apps/details?id=hero.wellbeing.tracker" target="_blank">
                  <img style={{ width: "112px", height: "auto", margin: "1.4rem 0 0" }} src={require("../assets/images/google-play-badge.png")} />
                </a>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          {appConnections.map(item => !item.can_connect_on_web ? null : (
            <Col key={item.type} lg={4}>
              <Card className="bm">
                <Card.Header className="ac">{item.display_name}</Card.Header>
                <Card.Body className="ac pt0">
                  <img src={item.logo_url} />
                  <br />

                  {item.connected &&
                    <Button
                        variant="primary-text"
                        href={item.disconnect_url + "&redirect_uri=" + window.location.href}
                    >
                      Disconnect
                    </Button>
                  }

                  {!item.connected &&
                    <Button href={item.connect_url + "&redirect_uri=" + window.location.href}>
                      Connect
                    </Button>
                  }
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
    </Container>
  )
}

function mapStateToProps(state) {
  return {
    appConnections: state.appConnections
  }
}

export default connect(mapStateToProps)(AppConnections)

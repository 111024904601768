import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import Modal from 'react-bootstrap/Modal'
import { fetchGroups } from '../redux/actions/groups'

function SelectGroupModal(props) {
  const { show, setShow, handleFormSubmit } = props;
  const [selectedGroupId, setSelectedGroupId] = useState(null);

  useEffect(() => {
    if(!props.groups || !props.groups.isValid) {
      props.dispatch(fetchGroups());
    }
  }, [props.groups]);

  const onChange = (event) => {
    const groupId = event.target.value;
    setSelectedGroupId(groupId);
  }

  const isValid = () => !!selectedGroupId

  const decoratedHandleFormSubmit = event => {
    event.preventDefault();

    if(!isValid()) {
      alert('Invalid selection');
      return;
    }

    handleFormSubmit(selectedGroupId);

    setShow(false);
    setSelectedGroupId(null);
  }

  return (
    <Modal centered show={show} onHide={() => setShow(false)}>
      <Modal.Body>
        <form onSubmit={decoratedHandleFormSubmit}>
          <label>
            Select a group:
            <select onChange={onChange} value={selectedGroupId}>
              <option></option>
              {props.groups &&
                props.groups.items.map(group => (
                  <option value={group.id}>{group.name}</option>
                ))}
            </select>
          </label>
          <input type="submit" value="Submit" disabled={!isValid()} />
        </form>
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = state => {
  return {
    groups: state.groups
  };
}

export default connect(mapStateToProps)(SelectGroupModal);
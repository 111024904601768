import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import PreCheckList from "../components/Health-Checks/PreCheckList";
import PreHealthCheckListViewAnswer from "../components/Health-Checks/PreHealthCheckListViewAnswer";
import BasicMOTQuestions from "../components/Health-Checks/BasicMOTQuestions";
import axios from "axios";
import PreHealthCheck from "../components/Health-Checks/PreHealthCheck";

import { connect } from "react-redux";

const adminUsers = ["6e85b174-8760-4f65-96da-7cc39f15bc5c", 
"7af850e9-a7dd-4466-a000-c3ec0a9065b7",
"a2791e10-c55c-45d9-914a-11cba81eef48",
"b63dec1a-3382-4fb8-9d93-95231251f573",
"5d4b3e23-50f7-4b1e-b91a-9358ce37c619",
"f84ed0b5-03e7-407d-beb9-6037685d8b7e",
"6ae16b3f-131a-4a4a-ac1c-767a362473c4",
"3fa9436f-875e-4d20-b7cf-71f3ab758c8e",
"930fb8f9-5047-4ab1-87c9-6a82b4dc2e4b"
];

const HealthChecks = (props) => {
  const [selectedPreHealthCheck, setSelectedPreHealthCheck] = useState(null);
  const [selectedUser, setSelectedUser] = useState(props.user.profile.id);
  const [companyList, setCompanyList] = useState();

  //Load data from API with props.user.profile.id

  //Load the configuration from the API and see if this company ID should show the pre-health checks or not
  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const response = await axios.get("https://tillo.nvgtr.io/auxData/load?userID=showPreHealthCheckConfig&auxDataType=prehealthCheck");
        console.log("Company List:" + response.data);
        setCompanyList(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchCompanyData();
  },[]);

  console.log(props.user.profile.id);

  const checkIfAdmin = () => {
    if (adminUsers.includes(props.user.profile.id)) {
      return true;
    }
    return false;
  };

  const updateSelectedPreHealthCheck = (preHealthCheck, userID) => {
    setSelectedPreHealthCheck(preHealthCheck);
    setSelectedUser(userID);
  };

  const adminDisplay = () => {
    if (checkIfAdmin()) {
      return (
        <Row>
          <Col md={5} className="mx-auto">
            <PreCheckList userID={props.user.profile.id} setUser={updateSelectedPreHealthCheck} />
          </Col>
          <Col md={7} className="mx-auto">
            <PreHealthCheckListViewAnswer userAnswers={selectedPreHealthCheck} />
          </Col>
        </Row>
      );
    }
  };

  const preHealthCheckDisplay = () => {
    if (checkIfUserCompanyIsInList())
      return (
        <Row>
          <Col md={12} className="mx-auto">
            <PreHealthCheck userID={selectedUser}/>
          </Col>
        </Row>
      );
  };

  const checkIfUserCompanyIsInList = () => {
    if (!companyList) return false;

    const showData = companyList.filter((data) => {
      return data.companyId === props.user.profile.companyId;
    });

    if (showData.length > 0) return true;
    return false;
  };

  return (
    <Container>
      <Row className="mb-3">
        <Col md={12}>
          <h1>Health Checks</h1>
        </Col>
      </Row>
      {adminDisplay()}
      {preHealthCheckDisplay()}
      <Row>
        <Col md={12} className="mx-auto">
          <BasicMOTQuestions userID={selectedUser} />
        </Col>
      </Row>
    </Container>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(HealthChecks);

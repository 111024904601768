
import dataStores from '../reducers/dataStores'
import { createError } from './errors'

export const REQUEST_NOTIFICATIONS = 'REQUEST_NOTIFICATIONS'
function requestNotifications() {
    return {
        type: REQUEST_NOTIFICATIONS
    }
}

export const RECEIVE_NOTIFICATIONS = 'RECEIVE_NOTIFICATIONS'
function receiveNotifications(notifications) {
    return {
        type: RECEIVE_NOTIFICATIONS,
        payload: {
            notifications: notifications,
            receivedAt: Date.now()
        }
    }
}

export const INVALIDATE_NOTIFICATIONS = 'INVALIDATE_NOTIFICATIONS'
export function invalidateNotifications() {
    return {
        type: INVALIDATE_NOTIFICATIONS
    }
}

function invalidateNotificationsAfterFiveMinutes(dispatch) {
    setTimeout(() => dispatch(invalidateNotifications()), 300000)
}

function doFetchNotifications(accessToken) {
    return async dispatch => {
        dispatch(requestNotifications())
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user/notifications`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'hero-token': accessToken
                }
            })
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const notifications = await response.json()
            dispatch(receiveNotifications(notifications))
            invalidateNotificationsAfterFiveMinutes(dispatch)
        }
        catch (error) {
            return dispatch(createError(error.message))
        }
    }
}

function shouldFetchNotifications(state) {
    const notifications = state.notifications
    if (!notifications) {
        return true
    } else if (notifications.isFetching) {
        return false
    } else {
        return !notifications.isValid
    }
}

export function fetchNotifications() {
    return (dispatch, getState) => {
        const state = getState()
        if (shouldFetchNotifications(state)) {
            const accessToken = state.user.session.accessToken
            return dispatch(doFetchNotifications(accessToken))
        } else {
            return Promise.resolve()
        }
    }
}

export function markNotificationAsRead(notificationId) {
    return async (dispatch, getState) => {
        const accessToken = getState().user.session.accessToken
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user/notifications/${notificationId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'hero-token': accessToken
                },
                body: JSON.stringify({ unread: false })
            })
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return dispatch(invalidateNotifications())
        }
        catch (error) {
            return dispatch(createError(error.message))
        }
    }
}

import { createHeroApiClient } from '../../hero-api-client';
import { createError } from './errors'

export const REQUEST_AUTHENTICATED_USER_PROFILE = 'REQUEST_AUTHENTICATED_USER_PROFILE'
function requestAuthenticatedUserProfile() {
    return {
        type: REQUEST_AUTHENTICATED_USER_PROFILE
    }
}

export const RECEIVE_AUTHENTICATED_USER_PROFILE = 'RECEIVE_AUTHENTICATED_USER_PROFILE'
function receiveAuthenticatedUserProfile(user) {
    return {
        type: RECEIVE_AUTHENTICATED_USER_PROFILE,
        payload: {
            user: user
        }
    }
}

export const INVALIDATE_AUTHENTICATED_USER_PROFILE = 'INVALIDATE_AUTHENTICATED_USER_PROFILE'
export function invalidateAuthenticatedUserProfile() {
    return {
        type: INVALIDATE_AUTHENTICATED_USER_PROFILE
    }
}

function doFetchAuthenticatedUserProfile(accessToken) {
    return async dispatch => {
        dispatch(requestAuthenticatedUserProfile())
        createHeroApiClient(accessToken).authenticatedUser.get()
            .then(user => dispatch(receiveAuthenticatedUserProfile(user)))
            .catch(error => dispatch(createError(error.message)))
    }
}

function shouldFetchAuthenticatedUserProfile(state) {
    const user = state.user.profile
    if (!user) {
        return true
    } else if (user.isFetching) {
        return false
    } else {
        return !user.isValid
    }
}

export function fetchAuthenticatedUserProfile() {
    return (dispatch, getState) => {
        const state = getState()
        if (shouldFetchAuthenticatedUserProfile(state)) {
            return dispatch(doFetchAuthenticatedUserProfile(state.user.session.accessToken))
        } else {
            return Promise.resolve()
        }
    }
}
import React, { Component } from "react";
import { PieChart, Pie, ResponsiveContainer, Cell, Tooltip} from "recharts";
import { FaRunning, FaHeart } from "react-icons/fa"

class LeanMuscle extends Component {
  render() {
      const { x, y, size } = this.props;

      return (
          <image x={x} y={y} height={size} t xlinkHref='https://hero-wellbeing.s3-eu-west-1.amazonaws.com/COLOURFIT_ICONS_BLACK-LEAN_MUSCLE.png' alt="" />
      );
  }
}

function ColourFitPlate(props) {
  var innerData = props.innerData;
  const COLORS = ['#21edc7', '#f3364d', '#ffe000'];
  
  function Icons(index , x, y, value){
    if(props.showIcons) {
      const ICONS = [ <FaRunning x={x} y={y} size={value} />, 
        <LeanMuscle x={x-value/2} y={y-value/2} size={value*2} />, 
        <FaHeart x={x} y={y} size={value} />]
        return(
          <>
          {ICONS[index]}
          </>
        )
    }

    return undefined;
  }
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    index 
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const scaling = innerData[index].value < 45 ? innerData[index].value * props.scale : 45 * props.scale;
    return innerData[index].value != 0 && innerData[index].value != undefined ? Icons(index, x - scaling / 2, y - scaling / 2, scaling): undefined;
  };
  return (
    <ResponsiveContainer width="100%" aspect={1.0 / 1.0}>
      <PieChart>
        <Pie
          data={props.innerData}
          labelLine={false}
          outerRadius="70%"
          startAngle={90}
          endAngle={450}
          dataKey="value"
          strokeWidth={0}
          label={renderCustomizedLabel}>
          {
          	props.innerData.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]}/>)
          }
        </Pie>
        <Pie
          data={props.outerData}
          innerRadius="75%"
          outerRadius="100%"
          startAngle={90}
          endAngle={450}
          strokeWidth={0}
          dataKey="value"
        >
          <Cell fill={"#1e4ae3"} />
          <Cell fill={"#ffffff"} />
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
}

export default ColourFitPlate;

// React libs
import React from 'react';

// Components
import NavDisplay from "../components/NavDisplay";

// BOOTSTRAP STUFF
import Container from "react-bootstrap/Container";

function Modules(props) {

  return (
    <Container>
      <h1 className="page__title">My Modules</h1>

      <NavDisplay />
    </Container>
  );
}

export default Modules;

import React, { Component } from 'react';
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import propTypes from 'prop-types';
import cn from 'classnames';

// Automatically sets relevant head tags for seo, see _app for the remainder tags
const ButtonModule = class extends Component {
  static displayName= 'ButtonModule';

  static propTypes = {
      label: propTypes.string.isRequired,
      icon: propTypes.string.isRequired,
    children: propTypes.node.isRequired,
  }

  render() {
    return (
        <>
            <div className="btn--module-wrapper">
                <Button onClick={this.props.onClick} className={"btn btn--module" + (this.props.active? " active": "")}>
                    {/*<i className="far fa-times"></i>*/}
                    <i className={cn(this.props.iconclassName, '', this.props.icon)} />
                </Button>
                <span className="btn--module-title">{this.props.label}</span>
            </div>
        </>
    );
  }
};

export default ButtonModule;

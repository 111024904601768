import { REQUEST_GROUPS, RECEIVE_GROUPS, INVALIDATE_GROUPS } from '../actions/groups'
import { END_USER_SESSION } from '../actions/userSession'

const initialState = {
    isFetching: false,
    isValid: false,
    items: []
}

export default function groups(state = initialState, action) {
    switch (action.type) {
        case REQUEST_GROUPS:
            return {
                ...state,
                isFetching: true,
                isValid: true
            }
        case RECEIVE_GROUPS:
            return {
                ...state,
                isFetching: false,
                isValid: true,
                items: action.payload.groups,
                lastUpdated: action.payload.receivedAt
            }
        case INVALIDATE_GROUPS:
            return {
                ...state,
                isValid: false
            }
        case END_USER_SESSION:
            return initialState
        default:
            return state
    }
}
import { useEffect, useState, useRef } from "react";
import inBlipFile from "../../assets/audio/blip-notification-high.wav";
import outBlipFile from "../../assets/audio/blip-notification-low.wav";
export const DEFAULT_WEB_SOCKET_TIMEOUT_MS = 10000;
export const messageTypes = {
  chatMessages: "chatMessage",
  announceEntry: "announceEntry",
  updateUser: "updateUser",
  announceDeparture: "announceDeparture",
  commandToAll: "commandToAll",
  commandToOne:"commandToOne",
  disconnected:"disconnected"
};
export const Commands = {
  mute: "mute",
  cameraOff:"cameraOff",
  kick:"kick"
}
var wsStabilizer;
var meetingSocket;
const inBlip = new Audio(inBlipFile);
const outBlip = new Audio(outBlipFile);

const WebSocketService = (meetingId, attendeeId, senderName,setCommand) => {
  const [inMeeting, setInMeeting] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [attendees, setAttendees] = useState([]);
  const [userDetails, setUserDetails] = useState({
    meetingId,
    attendeeId,
    senderName,
  });
  const attendeesRef = useRef(attendees);
  const messagesRef = useRef(chatMessages);
  const inMeetingRef = useRef(inMeeting);
  const userDetailsRef = useRef(userDetails);
  const setUserDetailsState = (data) => {
    userDetailsRef.current = data;
    setUserDetails(data);
  }
  const setInMeetingState = (bool) => {
    inMeetingRef.current = bool;
    setInMeeting(bool);
  }
  const setAttendeeState = (data) => {
    attendeesRef.current = data;
    setAttendees(data);
  };
  const setMessageState = (data) => {
    messagesRef.current = data;
    setChatMessages(data);
  };
  useEffect(() => {
    startWebSocket();
  }, []);

  useEffect(() => {
    if (meetingSocket.readyState === WebSocket.OPEN){
      sendMessage(messageTypes.updateUser, userDetails);
    }
  }, [userDetails]);
  const prevLengthRef = useRef(attendeesRef.current.length);
  useEffect(()=>{
    const currentLength = attendeesRef.current.length;
    const prevLength = prevLengthRef.current;
    prevLengthRef.current = currentLength;
    
    if (currentLength > prevLength) {
      inBlip.play();
    } else if (currentLength < prevLength) {
      outBlip.play();
    }
  },[attendeesRef.current.length])
  const stopWsStabilizer = () => {
    if (wsStabilizer) {
      console.log("No active Websocket to stop!");
    }
    clearInterval(wsStabilizer);
  };

  const startWsStabilizer = () => {
    const seconds = 1000 * 60;
    wsStabilizer = setInterval(() => {
      try {
        sendMessage(messageTypes.updateUser, userDetails);
      } catch (error) {
        console.error("Error sending ping message.");
        stopWsStabilizer();
      }
    }, seconds);
  };

  function publishMessageUpdate(message) {
    setMessageState([...messagesRef.current, message]);
  }
  const startWebSocket = async () => {
    if (!meetingId || !attendeeId) {
      throw new Error(
        "Must provide meetingId and attendeeId to join meeting messaging."
      );
    }

    console.log(`Joining live event messaging with event ID: ${meetingId},
      attendeeId: ${attendeeId}`);
    ///ws?meetingId=${meetingId}&AttendeeId=${attendeeId}
    const messagingUrl = `wss://0h2zoxeha7.execute-api.eu-west-1.amazonaws.com/production`;
    console.log(`Setting up websocket to URL: ${messagingUrl}`);
    meetingSocket = new WebSocket(messagingUrl);

    meetingSocket.addEventListener("open", () => {
      if (meetingSocket.readyState === WebSocket.OPEN){
        if(inMeetingRef.current){
          announceEntry();
        }
        startWsStabilizer();
      }
      else{
        setTimeout(() => {
          startWebSocket();
      }, 5000);
      }
    });
    
    meetingSocket.addEventListener("close", () => {
      console.log('meeting ws closed, re-establishing');
      setTimeout(() => {
        startWebSocket();
    }, 5000);
    });

    meetingSocket.addEventListener("message", (event) => {
      try {
        const data = JSON.parse(event.data);
        // Do not process ping messages.
        if (data?.type === "ping") return;
        switch (data?.type) {
          case messageTypes.chatMessages:
            var date = new Date();
            var dateString = date.toLocaleString("en-GB", {
              hour: "2-digit",
              minute: "2-digit",
            });
            publishMessageUpdate({
              type: data.type,
              payload: data.payload,
              sender: data.senderName,
              timestamp: dateString,
            });
            break;
          case messageTypes.updateUser:
            updateAttendee(data.sender, data.payload);
            break;
          case messageTypes.announceEntry:
           
            updateAttendee(data.sender, data.payload);
            if (inMeetingRef.current) {
              sendMessage(messageTypes.updateUser, userDetails);
            }
            break;
          case messageTypes.announceDeparture:
            setAttendeeState(
              attendeesRef.current.filter((x) => x.attendeeId !== data.sender)
            );
            break;
          case messageTypes.commandToOne:
              setCommand(data.payload.command);
              break;
            case messageTypes.commandToAll:
              setCommand(data.payload.command);
              break;
            case messageTypes.disconnected:
              setAttendeeState(
                attendeesRef.current.filter((x) => x.connectionId !== data.connectionId)
              );
              break;
        }
      } catch (error) {
        console.error(error);
      }
    });

    window.addEventListener("beforeunload", () => {
      announceDeparture();
      stopWsStabilizer();
      console.debug("Closing socket.");
      meetingSocket.close();
      console.debug("Closed socket.");
    });
  };
  const sendChatMessage = (message) => {
    sendMessage(messageTypes.chatMessages, message);
  };
  const announceDeparture = () => {
    if(inMeetingRef.current){
      sendMessage(messageTypes.announceDeparture, null);
      setInMeetingState(false);
    }
  };
  const sendCommandToAll = (body) => {
    sendMessage(messageTypes.commandToAll,body);
  }
  const sendCommandToOne = (body, connectionId) => {
    body.connectionId = connectionId;
    sendMessage(messageTypes.commandToOne,body);
  }
  const announceEntry = () => {
    setInMeetingState(true);
    sendMessage(messageTypes.announceEntry, userDetails);
  };
  const updateAttendee = (attendee, object) => {
      var currentAttendees = attendeesRef.current.filter(
        (x) => x.attendeeId === attendee
      );
      if (currentAttendees.length) {
        let index = attendeesRef.current.findIndex(
          (item) => item.attendeeId === attendee
        );
        let newAttendees = [...attendeesRef.current];
        newAttendees[index] = { ...newAttendees[index], ...object };
        setAttendeeState(newAttendees);
      } else {
        let newAttendees = [...attendeesRef.current, object];
        setAttendeeState([...newAttendees]);
      }
  };
  const updateUserDetails = (object) => {
    setUserDetailsState({ ...userDetailsRef.current, ...object });
  };

  const sendMessage = (type, data) => {
    if (!meetingSocket || !inMeetingRef.current) {
      return;
    }
    const message = {
      type: type,
      payload: data,
      sender: attendeeId,
      senderName: senderName,
      meetingId:meetingId
    };
    try {
      meetingSocket.send(JSON.stringify(message));
    } catch (error) {
      console.error("Error sending Live Event message.");
    }
  };

  return {
    chatMessages,
    sendChatMessage,
    sendMessage,
    announceDeparture,
    announceEntry,
    attendees,
    updateUserDetails,
    sendCommandToAll,
    sendCommandToOne
  };
};

export default WebSocketService;

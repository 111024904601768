import React, { useState } from "react";
import { Link } from "react-router-dom";

// Redux rework
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//Redux Actions
import dataStores from "../../redux/reducers/dataStores";

import * as commandActions from "../../redux/actions/commands";

// BOOTSTRAP STUFF
import Container from "react-bootstrap/Container";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";

// Nutrition Components

import MealCalendar from "../../components/nutrition/meal-calendar";
import FoodQuickAdd from "../../components/nutrition/food-quick-add";
import MealDay from "../../components/nutrition/meal-day";

// Misc
import { format, addDays, subDays, parse } from "date-fns";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";

// hero Core API
import { NutritionApi } from "hero-api-client";

const NuApi = new NutritionApi({ basePath: process.env.REACT_APP_API_BASE_URL });

function Nutrition(props) {
  const [dateToDisplay, setDateToDisplay] = useState(
    format(new Date(), "yyyy-MM-dd")
  );
  const [redirectPath, setRedirectPath] = useState("");
  const [daysFood, setDaysFood] = useState({});

  function loadFoodForDate() {
    if (
      props.heroToken != null &&
      dateToDisplay &&
      dateToDisplay.length > 0 &&
      dateToDisplay !== ""
    ) {
      NuApi.foodConsumedOnDate(dateToDisplay, {
        headers: {
          "hero-token": props.heroToken
        }
      }).then(res => {
        setDaysFood(res);
      });
    }
  }

  return (
    <Container>
      <Row className="title-container">
        <Col>
          <h1 className="page__title">Calendar</h1>
        </Col>
        <Col>
          <div className="btn--actions">
            <Link to={"/nutrition/diary"} className="btn btn--transparent btn--circle">
              <i className="far fa-book-heart"></i>
            </Link>
            <Link to={"/nutrition/calendar"} className="btn btn--transparent btn--circle mr-2">
              <i className="far fa-calendar-alt"></i>
            </Link>
          </div>
        </Col>
      </Row>

      <MealCalendar displayDate={dateToDisplay} />
    </Container>
  );
}
function mapStateToProps(state) {
  return {
    heroToken: state.appObject[dataStores.DELEGATED_PERMISSIONS].coachToken ? state.appObject[dataStores.DELEGATED_PERMISSIONS].coachToken : state.appObject[dataStores.HEADCOACH_PERMISSIONS].coachToken ? state.appObject[dataStores.HEADCOACH_PERMISSIONS].coachToken : state.user.session.accessToken,
    coachView: state.appObject[dataStores.DELEGATED_PERMISSIONS].coachToken || state.appObject[dataStores.HEADCOACH_PERMISSIONS].coachToken ? true : false,
    appConfig: state.appConfig,
    feed: state.feed,
  };
}

function mapDispatchToProps(dispatch) {
  return {
        commandActions: bindActionCreators(commandActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Nutrition);

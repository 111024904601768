import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import { fetchChallenges } from '../redux/actions/challenges';
import ChallengeCardDashboard from '../components/challenges/ChallengeCardDashboard';
import { connect } from "react-redux";
import HealthWheel from "../health-wheel/wheel-mk4";
import Feed from "../components/feed/Feed";
import NavDisplay from "../components/NavDisplay";
import { Container, Row, Col, Button, ButtonGroup, Card, Modal } from "react-bootstrap";
import dataStores from "../redux/reducers/dataStores";
import "../assets/css/animate.css";
import { format, addDays } from "date-fns";
import GroupInvitations from "../components/groups/GroupInvitations";
import { sendCommand } from "../redux/actions/commands";
import SidebarLayout from "react-sidebar";
import AddData from "../components/quickadd/AddData";
import moment from "moment";
import Clock from "react-digital-clock";
import LoadingSpinner from "../components/controls/LoadingSpinner";
import Select from "../components/forms/Select";
import ButtonModule from "../components/forms/ButtonModule";
import MoodCard from '../components/dashboard/MoodCard';
import chartKeyPlaceholder from "../assets/icons/chart-key.png";
import { Link, Redirect } from "react-router-dom";
import CreateGroupModal from '../components/groups/CreateGroupModal';
import { fetchGroups, createGroup } from '../redux/actions/groups';
import {fetchChats, createChat} from '../redux/actions/chats';
import VodafonePie from "../components/dashboard/VodafonePie";
import { fetchHealthDataMood } from "../redux/actions/healthData";
import { fetchChallengesCurrent } from "../redux/actions/challengesCurrent";
import {updateUser} from "../redux/actions/users";
const url = require("url");

function Dashboard(props) {
  const isVodafone = props.user.profile.companyKey === 'vodafoneglobalchallenge'

  const [catFilter, setCatFilter] = useState(isVodafone ? "Activity":"Movement");
  const [addCat, setAddCat] = useState("Movement");
  const [fadeInElements, setFadeInElements] = useState("animated fadeIn customDelay");
  const [localDate, setLocalDate] = useState(props.forDate);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showMood, setShowMood] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [value, setValue] = useState("")
  const [needSelectRegion, setNeedSelectRegion] = useState(props.company?.needSelectRegion);
  const [region, setRegion] = useState(props.user?.profile?.region);
  let { displayForDate } = useParams();
  const currentChallenges = props.challengesCurrent && props.challengesCurrent.items;
  // const upcomingChallenges = props.challenges.items.filter(x => x.userIsInChallenge && moment.utc().isBefore(x.startDate, 'days'));
  // const pastChallenges = props.challenges.items.filter(x => x.userIsInChallenge && moment.utc().isAfter(x.endDate, 'days'));
  // const joinableChallenges = props.challenges.items.filter(x => !x.userIsInChallenge && moment.utc().isBefore(x.endDate, 'days'));

  //challenges

  useEffect(() => {
    props.dispatch(fetchChallengesCurrent())
  }, [])

  const [showCreateChallengeModal, setShowCreateChallengeModal] = useState(false)
  const isColorFit = props.userProfile && props.userProfile.companyKey === 'colour-fit';

  const today = new Date()
  const utcToday = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate()));
  utcToday.setHours(0, 0, 0, 0)

  const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
  const [dmUsers, setDMUsers] = useState();
  const [dmCoaches, setDMCoaches] = useState();
  const [userId, setUserId] = useState();

  useEffect(() => {
    if (!props.groups || !props.groups.isValid) {
      
      props.dispatch(fetchGroups());
      
    }
    if(!props.chats || !props.chats.isValid){
      props.dispatch(fetchChats());
    }
  }, [props.groups,props.chats])

  useEffect(() => {
    if(!dmUsers || !props.groups.isValid){
      const localVarUrlObj = url.parse(process.env.REACT_APP_API_BASE_URL  + "/managementclaims/coach-access", true);
      delete localVarUrlObj.query;
      delete localVarUrlObj.search;
      var callUrl =  url.format(localVarUrlObj)
      const myRequest = new Request(callUrl, {
        method: 'GET',
        headers: {
          "hero-token": props.heroToken
        },
      });

      fetch(myRequest)
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            setDMUsers(data)
          })
    }
  }, [dmUsers, props.groups])


  useEffect(()=>{
    props.dispatch(fetchHealthDataMood(props.userProfile.id, localDate, moment(localDate, "YYYY-MM-DD").add("days", 1).toDate()));
  }, [localDate])


  useEffect(() => {
    if(!dmCoaches || !props.groups.isValid){
      const localVarUrlObj = url.parse(process.env.REACT_APP_API_BASE_URL  + "/managementclaims/coaches", true);
      delete localVarUrlObj.query;
      delete localVarUrlObj.search;
      var callUrl =  url.format(localVarUrlObj)
      const myRequest = new Request(callUrl, {
        method: 'GET',
        headers: {
          "hero-token": props.heroToken
        },
      });

      fetch(myRequest)
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            setDMCoaches(data)
          })
    }
  }, [dmCoaches, props.groups])

  function startDM(userId){
    props.dispatch(createGroup({name: userId}, userId, true))
    setUserId(userId)
  }

  useEffect(() => {
    if (displayForDate && displayForDate != props.forDate) {
      props.changeDateTo(displayForDate);
      setLocalDate(displayForDate);
      setFadeInElements("animated fadeIn customDelay");
    }
  }, []);

  useEffect(() => {
    if (props.userProfile && props.styles) {
      if (props.styles.LockCompanySettings == "false" && props.userProfile.companyId != props.company.companyId) {
        setLoaded(false);
        props.dispatch(sendCommand(
          dataStores.APP_CONFIG,
          { actionType: "GET", companyKey: props.company.companyKey }
        ));
      }
      else {
        setLoaded(true);
      }
    }

  }, [props.userProfile, props.company]);


  function handleValueChange (event) {
    const target = event.target;
    let value = target.value;
    setValue(value)
  }

  function addOneDay() {
    if(moment().isSame(localDate, 'day')) {
      return;
    }

    setLocalDate(format(addDays(new Date(localDate), 1), "yyyy-MM-dd"));

    setTimeout(() => {
      setFadeInElements("animated fadeIn customDelay");
    }, 500);
  }

  function decreaseOneDay() {
    setLocalDate(format(addDays(new Date(localDate), -1), "yyyy-MM-dd"));

    setTimeout(() => {
      setFadeInElements("animated fadeIn customDelay");
    }, 500);
  }

  function segmentClickHandler(seg) {
    setCatFilter(seg);
    setAddCat(seg);
    setSidebarOpen(true);
  }

  function getGreeting() {
    const today = new Date();
    const todayHours = today.getHours();

    let timeOfDay = null;

    switch (true) {
      case (todayHours >= 0 && todayHours < 5) || todayHours >= 18:
        timeOfDay = "evening";
        break;
      case todayHours >= 5 && todayHours < 12:
        timeOfDay = "morning";
        break;
      case todayHours >= 12 && todayHours < 18:
        timeOfDay = "afternoon";
        break;
      default:
        timeOfDay = "day";
        break;
    }

    return `Good ${timeOfDay}, ${props.userProfile.givenName}`;
  }

  function getVideo(videoURL) {
    return (
      <video contextMenu={false} onContextMenu={(e)=> e.preventDefault()} width="100%" controlsList="nodownload" height="50%" controls>
        <source src={videoURL} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    );
  }

  function handleFormSubmit(event) {
    event.preventDefault();

    props.dispatch(updateUser({...props.user?.profile, region}));
    setNeedSelectRegion(false);
  }

  function Sidebar(props) {
    const { catFilter, localDate, setSidebarOpen } = props;

    return (
      <div className="sidebar sidebar--dashboard">

        <div className="sidebar--header">
          <div className="sidebar--close"></div>
          <h6 className="slim-pagetitle">
            <div className="help-container">
              <button className="btn btn--close p-0 tx-20">
                <i className="far fa-times" onClick={e => setSidebarOpen(false)}></i>
              </button>
            </div>
          </h6>
        </div>
        <div className="sidebar--content mg-t-30">
          <div className="p-5">
            <AddData setCategory={setAddCat} forDate={localDate} category={addCat} toggleAddData={()=>setSidebarOpen(false)}/>
          </div>
          {/*<div className="row mg-b-25">*/}
          {/*  <div className="col-lg-12">*/}
          {/*    <div className="p-3">*/}
          {/*      <Feed*/}
          {/*        key={localDate}*/}
          {/*        dateToDisplay={localDate}*/}
          {/*        catFilter={catFilter}*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
        <div className="sidebar--footer">

        </div>
      </div>
    );
  }

  if (!loaded) {
    return <LoadingSpinner />;
  }

  return (
      <Container className={`${fadeInElements}`}>

        <Modal centered show={needSelectRegion} onHide={() => {
          setNeedSelectRegion(false)
        }}>
          <Modal.Body>
            <h3 style={{ paddingBottom: "20px" }}>
              Market / Group Function:
            </h3>
            <form onSubmit={handleFormSubmit}>
              <div className="signUp__inputCol" xs="7">
                <select
                    name="Market / Group Function"
                    value={region}
                    onChange={(event) => setRegion(event.target.value)}
                    required
                >
                  <option>Select a Market / Group Function</option>
                  {props.company?.regions?.map(region => <option value={region}>{region}</option>)}
                </select>
              </div>
              <div style={{ paddingTop: "10px" }}>
                <Button variant={"primary"} onClick={handleFormSubmit}>
                  Submit
                </Button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <Row className="title-container">
          <Col>
            <h1 className="page__title">{getGreeting()}</h1>
          </Col>
          {!isColorFit && (
            <Col>
              <div className="btn--actions">
                {/*<span className="clock d-none d-md-inline">*/}
                {/*  <Clock hour12={false} />*/}
                {/*</span>*/}
                <a className="btn btn-primary btn--square" onClick={e => setSidebarOpen(true)}><i className="fal fa-plus"></i></a>
              </div>
            </Col>
        )}

        </Row>

        <GroupInvitations />
        <div>
          {/* {props.styles.defaultHomePage == "my-modules" && (
              <Row>
                <NavDisplay />
              </Row>
          )}

          {props.styles.defaultHomePage == "dashboard" && ( */}
            <SidebarLayout
              sidebar={<Sidebar catFilter={catFilter} localDate={localDate} setSidebarOpen={setSidebarOpen} />}
              pullRight={true}
              open={sidebarOpen}
              styles={{ content: { overflowY: "visible", position: "relative", height: "100%" }, root: { position: "relative", wheeoverflow: "visible" }, sidebar: { position: "fixed", background: "white", borderTopLeftRadius: 36, borderBottomLeftRadius: 36 } }}>

                <Col md={12} className="pl-0 pr-0">
                  <Card>
                    <Row>
                      <Col md={6}>
                        <Card.Header as="h5" className="card-header--transparent d-flex flex-column">
                          <ButtonGroup size="md">
                            <Button className="thin light" variant="primary-text" onClick={decreaseOneDay}>
                              <i className="fal fa-chevron-left"></i>
                            </Button>
                            <Button className="thin light" onClick={() => props.history.push('/calendar')} variant="navy-text">
                              {format(new Date(localDate), "iiii do MMMM")}
                            </Button>
                            <Button className="thin light" onClick={addOneDay} variant="primary-text">
                              <i className="fal fa-chevron-right"></i>
                            </Button>
                          </ButtonGroup>
                        </Card.Header>
                        <Card.Body>
                          {isVodafone ? <VodafonePie
                              onAvatarClick={()=>{
                                setShowMood(true)
                              }}
                              date={localDate}
                              key={localDate}
                               userId={props.userProfile.id}
                          />: (
                              <HealthWheel
                                  onAvatarClick={()=>{
                                    setShowMood(true)
                                  }}
                                  keyid={localDate}
                                  key={localDate}
                                  displayType="big"
                                  date={localDate}
                                  userId={props.userProfile.id}
                                  segmentClickHandler={segmentClickHandler}
                              />
                          )}

                        </Card.Body>
                      </Col>
                          <Col md={6}>
                                <Row className="mt-3 mb-3">
                              <Col className="pl-4 pl-md-0">
                                {!isVodafone && (
                                    <Row className="mb-3 mt-4">
                                  <Col md={3}>
                                    <ButtonModule onClick={()=>{
                                      setCatFilter("Movement")
                                      setAddCat("Movement")
                                    }} active={catFilter === "Movement"} icon={'fal fa-running'} label={'Movement'}/>
                                  </Col>
                                  <Col md={3}>
                                    <ButtonModule onClick={()=>{
                                      setCatFilter("Recovery")
                                      setAddCat("Recovery")
                                    }} active={catFilter === "Recovery"}  icon={'fal fa-brain'} label={'Recovery'}/>
                                  </Col>
                                  <Col md={3}>
                                    <ButtonModule onClick={()=>{
                                      setAddCat("Activity")
                                      setCatFilter("Activity")
                                    }} active={catFilter === "Activity"} icon={'fal fa-swimmer'} label={'Activity'}/>
                                  </Col>
                                  <Col md={3}>
                                    <ButtonModule onClick={()=>{
                                      setAddCat("Nutrition")
                                      setCatFilter("Nutrition")
                                    }} active={catFilter === "Nutrition"} icon={'fal fa-soup'} label={'Nutrition'}/>
                                  </Col>
                                </Row>
                                )}
                              </Col>
                              <Col className="d-flex justify-content-end align-items-center mr-4">
                                <img style={{height:51}} src={chartKeyPlaceholder} />
                              </Col>
                            </Row>

                            {/*todo: other chart stuff here*/}
                            <div className="feed__container">
                              <Feed
                                  key={localDate}
                                  dateToDisplay={localDate}
                                  catFilter={catFilter}
                              />
                            </div>
                          </Col>
                    </Row>
                  </Card>
                </Col>
              <Row>
               
              {props.styles.defaultHomePage != "my-modules" && (<Col md={6}>
                  <Card>
                    <Card.Body>
                      <Row className="align-items-center pt-0">
                        <Col>
                          <h5>Current Challenges</h5>
                        </Col>
                        <Col className="d-flex justify-content-end">
                          <a href="/challenges">View all</a>
                        </Col>
                      </Row>
                      {props.challengesCurrent.isFetching && <LoadingSpinner/>}
                      {currentChallenges.length > 0 &&
                      <>
                        <Row>
                          {currentChallenges.map(challenge => (
                              <ChallengeCardDashboard key={challenge.id} challenge={challenge} />
                          ))}
                        </Row>
                      </>
                      }
                      {!props.challengesCurrent.isFetching && props.challengesCurrent.length === 0  && (
                          <div className="text-center mt-2">
                            You have no current challenges
                          </div>
                      )}
                    </Card.Body>
                  </Card>
                </Col>)}
              </Row>
            </SidebarLayout>
          {/* )} */}

          {props.styles.dashboardVideoUrl && getVideo(props.styles.dashboardVideoUrl)}
        </div>

        <Modal centered show={showMood} onHide={() => {
          props.dispatch(fetchHealthDataMood(props.userProfile.id, localDate, moment(localDate, "YYYY-MM-DD").add("days", 1).toDate()));
          setShowMood(false)
        }}>
          <Modal.Body>
           <MoodCard

               date={localDate}
               userId={props.userProfile.id}

           />
          </Modal.Body>
        </Modal>


        <CreateGroupModal
            showCreateGroupModal={showCreateGroupModal}
            setShowCreateGroupModal={setShowCreateGroupModal}
        />
      </Container>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    appConfig: state.appObject.APP_CONFIG,
    userProfile: state.user.profile,
    user: state.user,
    forDate: ownProps.forDate,
    changeDateByDays: ownProps.changeDateByDays,
    changeDateTo: ownProps.changeDateTo,
    company: state.appObject[dataStores.APP_CONFIG],
    styles: state.appObject[dataStores.APP_CONFIG].styles,
    dataStores: state.appObject,
    healthMetrics: state.healthMetrics,
    groups: state.groups,
    chats:state.chats,
    heroToken: state.user.session.accessToken,
    challengesCurrent: state.challengesCurrent,
  };
}

export default withRouter(connect(mapStateToProps)(Dashboard));

import React, { useEffect } from 'react';
import Radium from 'radium';

import dataStores from "../../redux/reducers/dataStores";
import { connect } from 'react-redux';


import './OutlineButton.css';

//PROPS TO BE PASSED INTO THIS COMPONENT
// colour - defines button color (COLOR CONVENTION: instead of white => #FFFFFF)
// size - defines fontSize & padding (sizes: "small", "medium", "large", "xlarge")
// text - defines the text that is put into the button
// onClick - defines the function for the click event
// icon - defines font-awesome icons that are passed down to be displayed
// styles - defines the ADDITIONAL inline styling (good for margins, display, etc) (spreads with style object)
// classes - defines the additional classes that are passed down into the button (example: floats) (props.classes goes into className);




function OutlineSubmit(props) {

    useEffect( () => {

    }, [props.styling]);

    let FONT_SIZE = "0.85rem";
    let PADDING = "0.4rem 0.85rem";
    let TEXT_COLOR = "white";
    let HOVER_COLOR = "black";
    let TEXT = "Submit";
    let BUTTON_CLASS = 'hero__outlineButton--medium';

    if (props.size === "small") {
        BUTTON_CLASS = 'hero__outlineButton--small';
    }
    if (props.size === "medium") {
        BUTTON_CLASS = 'hero__outlineButton--medium';
    }
    if (props.size === "large") {
        BUTTON_CLASS = 'hero__outlineButton--large';
    }
    if (props.size === "xlarge") {
        BUTTON_CLASS = 'hero__outlineButton--xlarge';
    }


    if (props.textColor) {
        TEXT_COLOR = props.textColor
    }

    if (props.hoverColor) {
        HOVER_COLOR = props.hoverColor
    }

    if (props.value) {
        TEXT = props.value
    }

    const buttonStyle = {
        color: props.colour ? props.colour : (Object.keys(props.styling).length !== 0 ? props.styling.styles.navBarBackgroundColor : "#4b75ff"),
        border: `1px solid ${props.colour ? props.colour : (Object.keys(props.styling).length !== 0 ? props.styling.styles.navBarBackgroundColor : "#4b75ff")}`,
        ':hover': {
            backgroundColor: props.colour ? `${props.colour}18` : (Object.keys(props.styling).length !== 0 ? `${props.styling.styles.navBarBackgroundColor}18` : "#4b75ff18"),
        }
    }

    return (
        <input
            type={props.type || "submit"}
            onClick={props.onClick}
            value={TEXT}
            className={`hero__outlineButton ${BUTTON_CLASS} ${props.classes ? props.classes : ""}`}
            style={{ ...buttonStyle, ...props.styles }}
            disabled={props.disabled}
        />
    );
}

function mapStateToProps(state) {
    return {
        styling: state.appObject[dataStores.APP_CONFIG]
    };
  }


export default connect(mapStateToProps, null)(Radium(OutlineSubmit));

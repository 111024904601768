export default {
  HEALTH_ASSESSMENT: "HEALTH_ASSESSMENT",
  GENERAL_RESPONSES: "GENERAL_RESPONSES",
  GRANTED_PERMISSIONS: "GRANTED_PERMISSIONS",
  DELEGATED_PERMISSIONS: "DELEGATED_PERMISSIONS",
  APP_CONFIG: "APP_CONFIG",
  HEALTH_METRIC_CATALOGUE: "HEALTH_METRIC_CATALOGUE",
  MEALS: "MEALS",
  MEAL: "MEAL",
  HEADCOACH_PERMISSIONS: "HEADCOACH_PERMISSIONS"
};

import React, { Component } from 'react';
// import { Path, G, TextPath, Defs } from 'react-native-svg';
import * as shape from 'd3-shape';
import { VictoryAnimation } from 'victory';
import isEqual from 'lodash/isEqual';

const degsToRadians = (degrees) => degrees * Math.PI / 180

export default class extends Component {

  static displayName = "PieSlice";

  render() {
    const { origin, slice, style, selectedSlice, numSlices, datum, events, pieChartHeight, innerRadius, background, isVodafone } = this.props;

    const backgroundOuterRadius = this.props.outerRadius;
    let outerRadius = backgroundOuterRadius;
    if (selectedSlice && selectedSlice._id === datum._id) {
      outerRadius += 10;
    }

    return (
        <VictoryAnimation duration={1000} easing="circle" delay={0}
          data={{ outerRadius }}
        >
            {(tweenedProps) => {
                if (datum.filler) return null;

                // Calculate the arc
                const arc = shape.arc()
                  .outerRadius(tweenedProps.outerRadius)
                  .innerRadius(innerRadius)
                  .startAngle(Math.min(slice.startAngle + ((datum.sliceIndex === 0 && datum.recordedValue > 0 && !isVodafone) ? degsToRadians(1) : 0), slice.endAngle))
                  .endAngle(slice.endAngle)
                  .padAngle(typeof this.props.padAngle === 'number' ? slice.padAngle : this.props.padAngle(datum))();

                return (
                    <g transform={`translate(${origin.x} ${origin.y})`}>
                        <path
                          onClick={events ? events.onClick : (() => {})}
                          d={arc}
                          fill={background ? '#efefef' : style.fill}
                        />
                    </g>
                );
            }}
        </VictoryAnimation>
    )
  }
}

import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import AvailabilityForm from './AvailabilityForm';
import './NewMeeting.scss';
import { addMinutes } from '../../assets/methods/timeManipulation';
import _ from 'lodash'
import UserSearch from './UserSearch';

const BookMeetingModal = ({closeModal,details,users, heroApiClient}) => {
  const titleRef = useRef(null);
  const [name, setName] = useState('');
  const [date, setDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [selectedOption, setSelectedOption] = useState('New Meeting');
  const [selectedGuests, setSelectedGuests] = useState([]);
  const [end, setEnd] = useState([]);

  const writeStartTime = (time) => {
    const endTimeNum = new Date(`1970-01-01T${endTime}:00`).getTime();
    const startTimeNum = new Date(`1970-01-01T${time}:00`).getTime();
    if (endTimeNum >= startTimeNum) {
      setStartTime(time);
    }
    else{
      setStartTime(time);
      setEndTime(addMinutes(time,30))
    }
  }
  const writeEndTime = (time) => {
    const endTimeNum = new Date(`1970-01-01T${time}:00`).getTime();
    const startTimeNum = new Date(`1970-01-01T${startTime}:00`).getTime();
    if (endTimeNum >= startTimeNum) {
      setEndTime(time);
    }
    else{
      setEndTime(time);
      setStartTime(addMinutes(time,-30))
    }
  }
  const handleSubmit = event => {
    event.preventDefault();
    var startDate = date + 'T' + startTime;
    var endDate = date + 'T' + endTime;
    heroApiClient.createMeeting(startDate,endDate,name,selectedGuests).then(()=>{
      closeModal(true);
    });
  };
  useEffect(()=>{
    if(titleRef.current && titleRef.current.focus){
    
        titleRef.current.focus();
    }
    
  },[selectedOption])
  
  const newMeetingForm = () => {
    return (
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            className="title-input"
            placeholder="Session Name"
            value={name}
            required
            ref={titleRef}
            onChange={event => setName(event.target.value)}
          />
          <br />
          <label>
            Date:
            <input
              type="date"
              name="date"
              value={date}
              required
              onChange={event => setDate(event.target.value)}
            />
          </label>
          <br />
          <div className='d-flex'>
          <label>
            Start Time:
            <input
              type="time"
              name="startTime"
              value={startTime}
              required
              max={endTime}
              onChange={event => writeStartTime(event.target.value)}
            />
          </label>
          <label>
            End Time:
            <input
              type="time"
              name="endTime"
              value={endTime}
              min={startTime}
              required
              onChange={event => writeEndTime(event.target.value)}
            />
          </label>
          </div>
          <br />
        <UserSearch heroApiClient={heroApiClient} setGuests={setSelectedGuests} users={users} />
         <br />
          <button type="submit">Book Session</button>
          <button type="button" onClick={closeModal}>
            Cancel
          </button>
        </form>
    )
    
  }

  useEffect(()=>{
    if(details){
    if(titleRef.current && titleRef.current.focus){
        titleRef.current.focus();
    }
    var startDate = details.start.toISOString().substring(0, 10);
     setDate(startDate);
     var time = details.start.toLocaleString('en-GB',{hour:'2-digit',minute:'2-digit'});
     var endTime = details.end.toLocaleString('en-GB',{hour:'2-digit',minute:'2-digit'});
     if(time != '00:00' || endTime != '00:00'){
        setStartTime(time);
        if(endTime != time){
            setEndTime(endTime);
        }
     }
     var endDate = details.end.toISOString().substring(0, 10);
     if(startDate != endDate){
        setEnd(endDate);
        setSelectedOption('Schedule Availability');
     }
     else{
        setEnd(null);
     }
    }
   
  },[details]);
  const handleGuestSelection = (event) => {
    setSelectedGuests(event);

  };
  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div>
      <Modal centered show={details}>
        <div className="modal-header">

        <div className="meeting-options">
      <label
        className={`meeting-option ${
          selectedOption === 'New Meeting' ? 'selected' : ''
        }`}
      >
        <input
          type="radio"
          value="New Meeting"
          checked={selectedOption === 'New Meeting'}
          onChange={handleRadioChange}
        />
        New Meeting
      </label>
      <label
        className={`meeting-option ${
          selectedOption === 'Schedule Availability' ? 'selected' : ''
        }`}
      >
        <input
          type="radio"
          value="Schedule Availability"
          checked={selectedOption === 'Schedule Availability'}
          onChange={handleRadioChange}
        />
        Add Availability

      </label>
    </div>

          <button onClick={closeModal}>X</button>
        </div>

        
        {selectedOption==='New Meeting'?newMeetingForm():(<AvailabilityForm close={closeModal} heroApiClient={heroApiClient} startTime={startTime} endTime={endTime} startDate={date} endDate={end}/>)}
        
      </Modal>
    </div>
  );
};

export default BookMeetingModal;

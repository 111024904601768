import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import BreathingPractice from "../components/BreathingPractices/BreathingPractice";
import BreathingPracticesList from "../components/BreathingPractices/BreathingPracticesList";

const BreathingPractices = () => {
  const [showPractice, setShowPractice] = useState(false);
  const [videoType, setVideoType] = useState("");
  const [practiceLength, setPracticeLength] = useState(0);
  const [steps, setSteps] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const handlePopstate = () => {
      resetPracticeDetails();
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  const setPracticeDetails = (videoType, practiceLength, steps) => {
    setShowPractice(true);
    setVideoType(videoType);
    setPracticeLength(practiceLength);
    setSteps(steps);

    history.push({
      pathname: "/breathing-practices",
    });
  };

  const resetPracticeDetails = () => {
    setShowPractice(false);
    setVideoType("");
    setPracticeLength(0);
    setSteps([]);
  };

  return (
    <div>
      {showPractice && (
        <BreathingPractice
          videoType={videoType}
          practiceLength={practiceLength}
          steps={steps}
          reset={resetPracticeDetails}
        />
      )}
      {!showPractice && (
        <BreathingPracticesList setPracticeDetails={setPracticeDetails} />
      )}
    </div>
  );
};

export default BreathingPractices;

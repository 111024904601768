import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { sendCommand } from "../../redux/actions/commands";
import dataStores from "../../redux/reducers/dataStores";
import { Card, Row, Col, Button } from "react-bootstrap";
import HeroOutlineButton from "../../common/Buttons/OutlineButton";
import { invalidateHealthData, addHealthData } from '../../redux/actions/healthData';
import moment from 'moment';
import { FaSave } from "react-icons/fa";
import ButtonModule from "../forms/ButtonModule";
import Select from "../forms/Select";
import _ from 'lodash'
function AddData(props) {
  const [addData, setAddData] = useState({
    visible: false,
    healthMetricId: 0,
    value: 0
  });

  const [clientReqId, setClientReqId] = useState(0);

  const [inputClass, setInputClass] = useState("w100");

  const [maxValue, setMaxValue] = useState(0);

  useEffect(() => {
    if (!props.healthMetrics.items || !props.healthMetrics.items.length) {
      props.dispatch(sendCommand(
        dataStores.HEALTH_METRIC_CATALOGUE,
        { actionType: "GET" }
      ));
    }
  }, []);

  // Gets a dynamic list of metrics and populates the drop down box
  function getOptions() {
    // if data has not bee loaded send a empty array
    if (!props.healthMetrics.items || !props.healthMetrics.items.length) {
      return [];
    }

    const healthMetricsByType = _.groupBy(props.healthMetrics.items, "healthWheelSectionName")
    const metrics = healthMetricsByType[props.category] || []

        return metrics.map((healthMetric)=>(
                <option
                    key={healthMetric.id}
                    value={healthMetric.id}
                    maxvalue={healthMetric.maxValue || 0}
                    id={healthMetric.id}
                >
                  {`${healthMetric.name}${healthMetric.units ? ` (${healthMetric.units})` : ''}`}
                </option>
            ))
  }

  // Handles updates when the user enters data

  function updateDropdown(event) {
    //update the healthMetricId
    setAddData({ ...addData, [event.target.name]: event.target.value });
    if (event.target.value === "187") {
      document.getElementById("km-calculator").style.opacity="1";
    } else {
      document.getElementById("km-calculator").style.opacity="0";
    }
    //extract & set max value
    setMaxValue(
      parseInt(
        event.target.childNodes[event.target.selectedIndex].getAttribute(
          "maxvalue"
        )
      )
    );
  }

  function updateElement(event) {
    setAddData({ ...addData, [event.target.name]: event.target.value });
  }

  function getInputLabel(healthMetricId) {
    switch (true) {
      case parseInt(healthMetricId) === 202:
        return "Hours of sleep";
        break;
      default:
        return "";
        break;
    }
  }

  function updateMetric() {
    // send a command off the update the metrics
    // the command handler should return a updated health data
    // We should feed back to the user that it has been saved

    setInputClass("w100");

    if (addData.value >= 0 && (addData.value <= maxValue || maxValue == 0)) {
      //if inside limit then execute
      var ticks = new Date().getTime();

      // Send the command to update data sets
      props.dispatch(addHealthData(addData.healthMetricId, addData.value, props.forDate));

      // window.setTimeout(() => {
      //   props.dispatch(invalidateHealthData(props.userId, moment.utc(props.forDate).format('YYYY-MM-DD')));
      // }, 3000);

      // Store the details of the request & reset this view
      setClientReqId(ticks);
      setAddData({ ...addData, visible: false, value: 0 });
      props.toggleAddData();
    } else {
      // DO NOT EXECUTE

      //change state to error
      setInputClass("w100 inputError");
    }

    //end of func
  }

  // Render the componet
  return (
      <>
        <h5 className="mb-3">Add data</h5>
        <Row className="mb-3 mt-4">
          <Col md={3}>
            <ButtonModule onClick={()=>props.setCategory("Movement")} active={props.category === "Movement"} icon={'fal fa-running'} label={'Movement'}/>
          </Col>
          <Col md={3}>
            <ButtonModule onClick={()=>props.setCategory("Recovery")} active={props.category === "Recovery"}  icon={'fal fa-brain'} label={'Recovery'}/>
          </Col>
          <Col md={3}>
            <ButtonModule onClick={()=>props.setCategory("Activity")} active={props.category === "Activity"} icon={'fal fa-swimmer'} label={'Activity'}/>
          </Col>
          <Col md={3}>
            <ButtonModule onClick={()=>props.setCategory("Nutrition")} active={props.category === "Nutrition"} icon={'fal fa-soup'} label={'Nutrition'}/>
          </Col>
        </Row>

        <Col md={8} className="pl-0 mb-3">
          <Select
              defaultValue={addData.healthMetricId}
              onChange={updateDropdown}
              label={'Type'}>
            <option key="non" value="" maxvalue={0}>
              Please Select...
            </option>
            {getOptions()}
          </Select>
        </Col>

        <Col md={12} className="pl-0 mb-3">
          <label>
            Value
            <input
                className={inputClass}
                type="number"
                name="value"
                value={addData.value}
                onChange={updateElement}
                style={{ textAlign: "center" }}
            />
          </label>
          <label class="mr-0">
            <span id="km-calculator">= {addData.value / 1000} KM</span>
          </label>
        </Col>
        <Button disabled={!addData.value || !addData.healthMetricId} onClick={updateMetric} className="btn-block mt-5">Save data</Button>
        </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    healthMetrics: state.healthMetrics,
    forDate: ownProps.forDate,
    category: ownProps.category,
    toggleAddData: ownProps.toggleAddData,
    userId: state.user.profile.id
  };
}

export default connect(mapStateToProps)(AddData);

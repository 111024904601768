import React, { useState, useEffect } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import SavingsCalculatorBarChart from './SavingsCalculatorBarChart';

const SavingsResults = ({ initialDeposit, monthlyContribution, interestRate, loanTerm }) => {
  const round = (value, decimals = 2) => {
    const factor = 10 ** decimals;
    return Math.round(value * factor) / factor;
  };

  const [output, setOutput] = useState({
    finalAmount: 0,
    totalInterestEarned: 0,
    totalContributions: 0,
  });

  useEffect(() => {
    const monthlyInterestRate = interestRate / 100 / 12;
    const numberOfMonths = loanTerm * 12;

    const finalAmount =
      initialDeposit * Math.pow(1 + monthlyInterestRate, numberOfMonths) +
      (monthlyContribution * ((Math.pow(1 + monthlyInterestRate, numberOfMonths) - 1) / monthlyInterestRate));

    const totalInterestEarned = finalAmount - (initialDeposit + monthlyContribution * numberOfMonths);

    setOutput({
      finalAmount: round(finalAmount),
      totalInterestEarned: round(totalInterestEarned),
      totalContributions: initialDeposit + monthlyContribution * numberOfMonths,
    });
  }, [initialDeposit, monthlyContribution, interestRate, loanTerm]);

  return (
    <Card>
  <Card.Body>
    <Row>
      <Col>
        <p>
          After <strong>{loanTerm}</strong> years of saving, with an initial deposit of £<strong>{output.totalContributions.toLocaleString()}</strong> and a monthly
          contribution of £<strong>{monthlyContribution.toLocaleString()}</strong>, here are your savings results:
        </p>
      </Col>
    </Row>
    <Row>
      <Col>
        <p>
          <strong>Total Contributions:</strong> £{output.totalContributions.toLocaleString(undefined, { minimumFractionDigits: 2 })}
        </p>
      </Col>
    </Row>
    <Row>
      <Col>
        <p>
          <strong>Total Interest Earned:</strong> £{output.totalInterestEarned.toLocaleString(undefined, { minimumFractionDigits: 2 })}
        </p>
      </Col>
    </Row>
    <Row>
      <Col>
        <p>
          <strong>Final Amount:</strong> £{output.finalAmount.toLocaleString(undefined, { minimumFractionDigits: 2 })}
        </p>
      </Col>
    </Row>
    <Card>
      <Card.Body>
        <SavingsCalculatorBarChart output={output} />
      </Card.Body>
    </Card>
  </Card.Body>
</Card>

    
  );
};

export default SavingsResults;

import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { useParams, withRouter } from "react-router-dom";
//import ChatReport from '../components/reports/ChatReport';
import { createHeroApiClient } from "../hero-api-client";
import LoadingSpinner from "../components/controls/LoadingSpinner";
import "./chat.scss";
import {
  Container,
  Spinner,
  Button,
  Row,
  Col,
  Card,
  Tab,
  Tabs,
} from "react-bootstrap";
import {
  Button as ChimeButton,
  PrimaryButton,
  VideoTileGrid,
  useMeetingManager,
  PreviewVideo,
  CameraSelection,
  QualitySelection,
  MicSelection,
  SpeakerSelection,
  useBackgroundBlur,
  useVideoInputs,
  VideoInputBackgroundBlurControl,
  AudioOutputControl,
  ControlBar,
  AudioInputControl,
  ContentShareControl,
  useUserActivityState,
  Phone as PhoneIcon,
  Chat as ChatIcon,
  Camera,
  Microphone,
  LeaveMeeting,
  HandRaise,
  ControlBarButton,
  Textarea,
  Attendees,
  RosterGroup,
  RosterCell,
  RosterAttendee,
  lightTheme,
  useLocalVideo,
  useToggleLocalMute,
  MeetingStatus,
  useMeetingStatus,
  SecondaryButton
} from "amazon-chime-sdk-component-library-react";
import {
  MeetingSessionConfiguration,
  isVideoTranformDevice,
  VideoTransformDevice,
} from "amazon-chime-sdk-js";
import MuteKickButton from "../components/video/MuteKickButton";
import ReactStars from "react-rating-stars-component";
import MeetingMessagingService from "../../src/components/video/WebSocketHandler";
import { ThemeProvider } from "styled-components";
import WebSocketService, {
  Commands,
} from "../components/video/WebSocketService";
import { leaveMeeting } from "../redux/actions/video";
import MeetingGrid from "../components/video/MeetingGrid";

function Meeting(props) {
  const meetingStatus = useMeetingStatus();
  const { isUserActive } = useUserActivityState();
  const meetingManager = useMeetingManager();
  const { selectedDevice } = useVideoInputs();
  const { isBackgroundBlurSupported, createBackgroundBlurDevice } =
    useBackgroundBlur();
  const { isVideoEnabled, toggleVideo } = useLocalVideo();
  const { muted, toggleMute } = useToggleLocalMute();
  const [screen, setScreen] = useState(0);
  const [rating, setRating] = useState(null);
  const [feedback, setFeedback] = useState(null);
  const [isVideoBlurred, setIsVideoBlurred] = useState(false);
  const [LoadingMessage, setLoadingMessage] = useState("Loading Meeting...");
  const [showChat, setShowChat] = useState(false);
  const [startupVideo, setStartupVideo] = useState(true);
  const [startupMic, setStartupMic] = useState(true);
  const [showAttendees, setShowAttendees] = useState(false);
  const [raiseHand, setRaiseHand] = useState(false);
  const [unreadChats, setUnreadChats] = useState(0);
  const [command, setCommand] = useState(null);
  const [endSure, setEndSure] = useState(false);
  const {
    chatMessages,
    sendChatMessage,
    updateUserDetails,
    attendees,
    announceDeparture,
    announceEntry,
    sendCommandToOne,
    sendCommandToAll
  } = WebSocketService(
    props.meetingId,
    props.meetingDetails.attendee.attendeeId,
    props.username,
    setCommand
  );
  const ToggleChat = () => {
    if (!showChat) {
      setUnreadChats(0);
    }
    setShowChat(!showChat);
  };
  const ToggleAttendees = () => {
    setShowAttendees(!showAttendees);
  };
  useEffect(() => {
    if (!showChat && chatMessages.length) {
      setUnreadChats(unreadChats + 1);
    }
  }, [chatMessages]);
  useEffect(() => {
    if(props.EndMeeting){
      EndMeeting();
    }
  }, [props.EndMeeting]);
  useEffect(()=>{
    if(command=== Commands.mute){
      if(!muted){
        toggleMute();
        setCommand(null);
      }
    }
    else if(command === Commands.kick){
      EndMeeting();
      setCommand(null);
    }
  },[command])
  useEffect(() => {
    async function toggleBackgroundBlur() {
      if (!selectedDevice) {
        return;
      }
      try {
        let current = selectedDevice;
        if (isVideoBlurred) {
          current = await createBackgroundBlurDevice(selectedDevice);
        } else {
          current = await selectedDevice.intrinsicDevice();
        }
        await meetingManager.startVideoInputDevice(current);
      } catch (error) {
        // Handle device selection failure here
        console.error("Failed to toggle BackgroundBlur");
      }
    }
    toggleBackgroundBlur();
  }, [isVideoBlurred]);

  useEffect(() => {
    if (meetingStatus === MeetingStatus.Succeeded) {
      if (startupVideo) {
        toggleVideo();
      }
      if (muted === startupMic) {
        toggleMute();
      }
    }
  }, [meetingStatus]);

  useEffect(() => {
    joinMeeting();
  }, [props.meetingId]);
  const joinMeeting = async () => {
    try {
      var meeting = props.meetingDetails;
      const Meeting = meeting.meetingId;
      const meetingSessionConfiguration = new MeetingSessionConfiguration(
        Meeting,
        meeting.attendee
      );
      console.log("meetingSessionConfiguration", meetingSessionConfiguration);
      await meetingManager.join(meetingSessionConfiguration);

      setScreen(1);
    } catch (e) {
      setLoadingMessage(
        "Error: failed to load. Refresh the page to try again. If you have come here via a link make sure it is valid." + e
      );
    }
  };
  const startMeeting = async () => {
    await meetingManager.start();
    announceEntry();
    setScreen(2);
  };
  const ToggleHandRaise = () => {
    updateUserDetails({ hand: !raiseHand });
    setRaiseHand(!raiseHand);
  };
  const EndForAll = () => {
    sendCommandToAll({command:Commands.kick});
    EndMeeting();
  }
  const EndMeeting = async () => {
    announceDeparture();
    await meetingManager.leave();
    setScreen(3);
  };
  const submitFeedback = async () => {
    props.heroApiClient.sendFeedback(props.meetingId, feedback, rating);
    setScreen(4);
    props.dismiss();
  };
  
  const MeetingPreview = () => {
    return (
      <div
        className={"modal100"}
        style={{
          height: window.innerHeight,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Row style={{ minWidth: window.innerWidth, minHeight: window.innerHeight, padding:30, marginLeft:-24, backgroundColor:'#172537' }}>
          <Col md={6} style={{ margin: 0, padding: 0 }}>
            <PreviewVideo />
          </Col>
          <Col md={4} style={{ margin: 0, padding: 0 }}>
            <CameraSelection />
            {isBackgroundBlurSupported && (
              <ChimeButton
                css={"padding:0;"}
                onClick={() => setIsVideoBlurred(!isVideoBlurred)}
                label="Toggle background blur"
              />
            )}
            <QualitySelection />
            <MicSelection />
            {/* <SpeakerSelection /> */}
            <div style={{ display: "flex" }}>
              <Microphone
                width="1.5rem"
                height="1.5rem"
                color={"white"}
                muted={!startupMic}
                onClick={() => setStartupMic(!startupMic)}
                title="Microphone icon"
              />
              <Camera
                width="1.5rem"
                height="1.5rem"
                color={"white"}
                disabled={!startupVideo}
                onClick={() => setStartupVideo(!startupVideo)}
                title="Camera icon"
              />
            </div>
            <ChimeButton
              onClick={() => startMeeting()}
              label={"Start Meeting"}
            />
            {!props.noLeave && (
               <ChimeButton
               onClick={() => props.dismiss()}
               label={"Cancel"}
               variant="secondary"
             />
            )}
           
          </Col>
        </Row>
      </div>
    );
  };
  const FeedbackScreen = () => {
    if(screen === 4){

      return (
        <div className="callScreen flexCentered">
          <div className="form400">
            <p>Thank you for your feedback!</p>
            {!props.noLeave && (
              <div className="flexCentered">
              <PrimaryButton
                label="Close"
                onClick={props.dismiss}
              />
            </div>
              )}
            
          </div>
        </div>
      );
    }
    return (
      <div className="callScreen flexCentered">
        <div className="form400">
          <ReactStars
            count={5}
            onChange={setRating}
            size={50}
            activeColor="#15cbce"
            classNames="m-auto"
          />
          <Textarea
            placeholder="Any feedback..."
            value={feedback}
            onInput={(obj) => setFeedback(obj.target.value)}
          />
          <div className="flexCentered">
            <PrimaryButton
              label="Send Feedback"
              onClick={submitFeedback}
              disabled={rating == null}
            />
            { !props.noLeave && (
               <ChimeButton label="Skip" onClick={props.dismiss} />
              )}
          </div>
        </div>
      </div>
    );
  };
  const hangUpButtonProps = {
    icon: <PhoneIcon className={"svgRed"} />,
    onClick: () => EndMeeting(),
    label: "End",
  };
  const handRaiseButtonProps = {
    icon: (
      <HandRaise
        className="handFill"
        style={{ color: raiseHand ? "black" : "white" }}
        isRaised={raiseHand}
      />
    ),
    onClick: () => ToggleHandRaise(),
    label: "Raise Hand",
  };
  const chatButtonProps = {
    icon: (
      <div>
        <ChatIcon />
        <p style={{ color: "pink" }}>{unreadChats}</p>
      </div>
    ),
    onClick: () => ToggleChat(),
    label: "Chat",
  };
  const attendeesButtonProps = {
    icon: <Attendees />,
    onClick: () => ToggleAttendees(),
    label: "Attendees",
  };
  const MainMeeting = () => {
    return (
      <Row style={{ minWidth: window.innerWidth, minHeight: window.innerHeight - 130 }}>
        <Col md={showChat ? 9 : 12} xs={12} style={{ margin: 0, padding: 0 }}>
          <MeetingGrid style={{
              position: "absolute",
              top: 0,
            }} meetingSession={meetingManager.meetingSession} attendees={attendees} />
          {/* <VideoTileGrid
            style={{
              minHeight: window.innerHeight - 80,
              position: "absolute",
              top: 0,
            }}
          /> */}
          {!showChat && (
            <RosterGroup
              className="videoHeightScroll"
              style={{
                minHeight: window.innerHeight - 130,
                position: "absolute",
                left: 20,
                top: 20,
                opacity: showAttendees ? 0.8 : 0.4,
                padding: 3,
              }}
            >

            <div style={{padding:3, fontSize:14}}>
              <div onClick={()=>setShowAttendees(!showAttendees)}>
                {showAttendees? "Hide ▲" : "Show Attendees ▼"}
              </div>
              </div>
              {attendees && showAttendees &&
                attendees.map((attendee) => {
                  return (
                    <>
                      <div className="flexEdges">
                        {props.role === 1 && 
                           <MuteKickButton onMute={()=>sendCommandToOne({command:Commands.mute},attendee.connectionId)} onKick={()=>sendCommandToOne({command:Commands.kick},attendee.connectionId)}/>
                        }
                        <RosterCell name={attendee.senderName} />
                        <div className="flexEdges" style={{width:120}}>
                          <RosterAttendee style={{paddingRight:0}} attendeeId={attendee.attendeeId} />

                          <div
                            style={{ width: 40, height: 30 }}
                            className="flexCentered"
                          >
                            {attendee.hand && (
                              <HandRaise
                                width="1.2rem"
                                height="1.2rem"
                                className={"handFill"}
                                style={{ color: "black" }}
                                isRaised
                                title="Raised Hand"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
                {props.role === 1 && 
                 <div className="flexCentered">
                 <SecondaryButton label="Mute All" onClick={()=>{sendCommandToAll({command:Commands.mute})}} icon={<Microphone muted />} />
                 <SecondaryButton label="End Meeting" onClick={()=>setEndSure(true)} icon={<LeaveMeeting />}  />
                </div>
                }
                
               <Modal show={endSure}>
                    <div className="meeting-card">
                      <div className="modal-header">
                        <h3 className="meeting-card-title">End Session?</h3>
                      </div>
                      <div className="meeting-card-title textCentered">
                        This will kick all users out of the current session. You are the session host.
                      </div>
                      <div className="flexCentered">
                        <button type="submit" onClick={EndForAll}>
                          Yes
                        </button>
                        <a onClick={() => setEndSure(false)}>No</a>
                      </div>
                    </div>
                </Modal>
            </RosterGroup>
          )}
        </Col>
                {showChat && (
                  <Col md={3} xs={11} style={{ margin: 0, padding: 0, marginBottom:40 }}>
                    <ThemeProvider theme={lightTheme}>
                      <MeetingMessagingService
                        meetingId={props.meetingId}
                        messages={chatMessages}
                        sendChatMessage={sendChatMessage}
                        attendeeId={props.user.profile.id}
                        senderName={
                          props.name
                        }
                        marginBottom={80}
                      />
                    </ThemeProvider>
                  </Col>
                )}
          <ControlBar 
          
          style={{marginLeft:10}} 
          layout="bottom" showLabels={isUserActive}>
            {/* <ControlBarButton className="noFocus" {...attendeesButtonProps} /> */}
            <AudioInputControl className="noFocus" />
            <VideoInputBackgroundBlurControl className="noFocus" />
            <ControlBarButton className="noFocus" {...handRaiseButtonProps} />
            <ControlBarButton className="noFocus" {...hangUpButtonProps} />
            <ControlBarButton className="noFocus" {...chatButtonProps} />
          </ControlBar>
       
      </Row>
    );
  };
  return (
    <Modal show={true} dialogClassName="modal100" style={{height:window.innerHeight, width:window.innerWidth, margin:0}}>
        <div style={{height:50, backgroundColor:'#2e2f34'}}>
        </div>
        {screen == 0 && (
          <div className={"modal100"}>
            <LoadingSpinner className={"modal100"} />
            <p style={{ textAlign: "center" }}>{LoadingMessage}</p>
          </div>
        )}
        {screen == 1 && MeetingPreview()}
        {screen == 2 && MainMeeting()}
        {screen >= 3 && FeedbackScreen()}
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    users: state.users.items,
    user: state.user,
    heroApiClient: createHeroApiClient(state.user.session.accessToken),
    meetings: state.videos.items,
  };
};

export default withRouter(connect(mapStateToProps)(Meeting));


import dataStores from "../reducers/dataStores";
import { sendCommand } from './commands'
import { toast } from "react-toastify";
import { createError } from './errors'

export function toggleFavourite(appModuleId) {
    return async (dispatch, getState) => {
        const state = getState()
        const accessToken = state.user.session.accessToken
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user/modules/${appModuleId}/toggle`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'hero-token': accessToken
                }
            })

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            toast.success("Module Favourited");

            const companyKey = state.appObject[dataStores.APP_CONFIG].companyKey
            return dispatch(sendCommand(dataStores.APP_CONFIG, {
                actionType: "GET",
                companyKey: companyKey
            }))
        }
        catch (error) {
            return dispatch(createError(error.message))
        }
    }
}
import React from "react";
import { PieChart, Pie, ResponsiveContainer, Cell} from "recharts";

function RDIChart(props) {
  var data = props.data;
  if(data[1].value < -300){
    data[1].value = -65
  }
  else if(data[1].value < 0){
    data[1].value = data[1].value/4.6
  }
  const COLORS = ['#f5c31d', '#d2d2d2'];
  function toFixed(value) {
    return parseFloat(value).toFixed(1).replace(/\.0+$/, '');
  }

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    index 
  }) => {
    return (
      <text x={cx} y={cy} fill="#3d405c" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
         <tspan x={cx} dy={-5} fontSize="18">{toFixed(data[0].value)}</tspan>
         <tspan x={cx} dy={+15} fontSize="10"> % RDI</tspan>
      </text>
     )
  };

  return (
    <ResponsiveContainer  width="100%" aspect={1.0 / 1.0}>
      <PieChart>
        <Pie
          isAnimationActive={false}
          data={props.data}
          innerRadius="75%"
          outerRadius="100%"
          startAngle={-120}
          endAngle={-420}
          strokeWidth={0}
          dataKey="value"
          label={renderCustomizedLabel}>
        >
          {props.data.map((entry, index) => entry.value >= 0 ? <Cell fill={COLORS[index % COLORS.length]}/> : undefined)}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}

export default RDIChart;

import React, {useState, useReducer, useEffect} from "react";
import {withRouter} from "react-router";
import HealthWheel from "../health-wheel/wheel-mk4";
import {Container, Button, Card, ButtonGroup} from "react-bootstrap";
import {FaChevronCircleLeft, FaChevronCircleRight} from "react-icons/fa";
import {format, addMonths, subMonths} from "date-fns";
import CalendarFunctions from "../shared/calendarFunctions";
import { connect } from "react-redux";
import { fetchHealthDataRange, fetchHealthDataMood } from '../redux/actions/healthData';

function Calendar(props) {
  const [displayMonth, setDisplayMonth] = useState(new Date());
  const [dates, setDates] = useState();

  useEffect(() => {
    const matrix = CalendarFunctions.getCalDatMatrixByDate(displayMonth);
    setDates(matrix);
    props.dispatch(fetchHealthDataRange(props.userId, matrix[0][0].dateStr, matrix[matrix.length - 1][matrix[matrix.length - 1].length - 1].dateStr));
    props.dispatch(fetchHealthDataMood(props.userId, matrix[0][0].dateStr, matrix[matrix.length - 1][matrix[matrix.length - 1].length - 1].dateStr));
  }, [displayMonth]);

    return (
        <Container>
            <h1 className="page__title">Calendar</h1>

            <Card>
                <Card.Header as="h5" className="card-header--transparent d-flex flex-column">
                    <ButtonGroup size="lg">
                        <Button
                        className="light"
                            variant="primary-text"
                            onClick={() => setDisplayMonth(subMonths(displayMonth, 1))}
                        >
                          <i className="fal fa-chevron-left"></i>
                        </Button>
                        <Button
                        className="light"
                            variant="primary-text"
                            onClick={() => {
                                alert(
                                    "This might show a date picker one day... but not right now"
                                );
                            }}
                        >
                            {format(new Date(displayMonth), "MMMM yyyy")}
                        </Button>
                        <Button
                        className="light"
                            onClick={() => setDisplayMonth(addMonths(displayMonth, 1))}
                            variant="primary-text"
                        >
                          <i className="fal fa-chevron-right"></i>
                        </Button>
                    </ButtonGroup>
                </Card.Header>
                <Card.Body>
                    {dates && dates.map((week, index) => (
                        <div className="cal-week" key={"week-start-" + week[0].dateFor}>
                            {week.map(day => {
                                return (
                                    <div
                                        key={"day-" + day.dateFor}
                                        onClick={() => props.history.push(`/dashboard/${day.dateStr}`)}
                                        className={"cal-day " + (day.activeDay ? "" : "cal-day-not-current")}
                                    >
                                        <div className="cal-day-name-display">
                                            {day.dayName}
                                            <br/>
                                            {format(day.dateFor, "do MMMM")}
                                        </div>

                                        <div className="cal-wheel">
                                            <HealthWheel
                                                key={day.dateStr}
                                                keyid={day.dateStr}
                                                delay={index * 50}
                                                displayType="small"
                                                date={day.dateStr}
                                                userId={props.userId}
                                                calendarWheel
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ))}
                </Card.Body>
            </Card>
        </Container>
    );
}

function mapStateToProps(state) {
  return {
    userId: state.user.profile.id
  };
}

export default withRouter(connect(mapStateToProps)(Calendar));

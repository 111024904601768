import React, { useState, useEffect } from "react";
import AsyncSelect from "react-select/async";
import _ from "lodash";

const UserSearch = ({ heroApiClient, setGuests, users, setFullGuests, text, values, multi=true }) => {
 const [options, setOptions] = useState([]);
  const searchUsers = (searchTerm) => {
    return heroApiClient.searchUsers(searchTerm).then((res) => {
      return res;
    });
  };
  const selectOption = (selectedOptions) => {
    if(selectedOptions){
      if(Array.isArray(selectedOptions)){
        if(setGuests){
           setGuests(selectedOptions.map(x=>x.id)); 
        }
        if(setFullGuests){
          setFullGuests(selectedOptions);  
        }
      }
      else{
        setGuests(selectedOptions);
      }  
        
    }
    else{
        if(setGuests){
          setGuests([]);  
        }
        if(setFullGuests){
           setFullGuests([]); 
        }
    }
  }
  const loadOptions = (inputValue, callback) => {
    const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!inputValue) {
      callback(users);
    } else {
      searchUsers(inputValue).then((usersSearch) => {
        const filteredUsers = [
          ...users.filter(
            (x) =>
              x.name.includes(inputValue) || x.emailAddress.includes(inputValue)
          ),
          ...usersSearch,
        ];
        if (emailRegex.test(inputValue)) {
          filteredUsers.push({
            name: "Invite as guest",
            emailAddress: inputValue,
            id: "guest:" + inputValue,
          });
        }
        callback(filteredUsers);
      }).catch(()=>{
        callback(users);
      });
    }
  };
  const loadOptionsDebounce = _.debounce(loadOptions, 500, {
    trailing: true,
    leading: true,
  });

  return (
    <AsyncSelect
      className="search-input"
      isMulti={multi}
      
      placeholder={text}
      getOptionLabel={(guest) => guest.name + " - " + guest.emailAddress}
      getOptionValue={(guest) => guest.id}
      defaultOptions={users}
      value={values}
      loadOptions={loadOptionsDebounce}
      onChange={(selectedOptions) => selectOption(selectedOptions)}
    />
  );
};

export default UserSearch;

import React from "react";
import { Row } from "react-bootstrap";
import BreathingVideo from "./BreathingVideo";
import BreathingSteps from "./BreathingSteps";

const BreathingPractice = ({videoType, practiceLength, steps, reset}) => {
        
        return (
            <Row>
                <div className="col-5 p-3">
                    <BreathingVideo videoType={videoType} practiceLength={practiceLength} />
                </div>
                <div className="col-7 p-3 align-items-center flex-column">
                    <BreathingSteps steps={steps} />
                    
                </div>
            </Row>
        );
        }

export default BreathingPractice;
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card } from 'react-bootstrap';
import Metric from './Metric'
import { fetchAppConnections } from '../../redux/actions/appConnections'
import { updateMetricSources } from '../../redux/actions/userHealthMetricSources'

function MetricSection(props) {
  const { name, healthMetrics } = props

  useEffect(() => {
      props.dispatch(fetchAppConnections())
  }, [props.appConnections])

  const bulkUpdateMetricSources = (event) => {
    var appConnectionType = event.target.value;
    if(!appConnectionType){
      return;
    }
    var healthMetricsIds = healthMetrics.map(x => x.id)
    props.dispatch(updateMetricSources(healthMetricsIds, appConnectionType))
  }

  return (
    <div key={name}>
      <Card className="bm">
        <Card.Header className="card-header--transparent">
          <Row>
            <Col md={8}><h3>{name}</h3></Col>
            <Col md={4} className="d-flex justify-content-end">
              <div className="d-flex justify-content-center align-items-center">
                <span className="select__label mr-3">Set All to:</span>
                <select className="select" onChange={bulkUpdateMetricSources}>
                  <option></option>
                  {props.appConnections &&
                    props.appConnections.items.filter(x => x.connected).map(appConnection => (
                      <option value={appConnection.type}>{appConnection.display_name}</option>
                    ))}
                </select>
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Row>
          {healthMetrics.filter(m=>m.sourceEnabled).map(healthMetric => (
            <Metric healthMetric={healthMetric} />
          ))}
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    appConnections: state.appConnections
  }
}

export default connect(mapStateToProps)(MetricSection);

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { Container, Row, Col, Modal } from "react-bootstrap";
import DayPicker from "react-day-picker/DayPicker";
import "react-day-picker/lib/style.css";
import * as moment from "moment";
import dataStores from "../redux/reducers/dataStores";
import { sendCommand } from "../redux/actions/commands";
import HealthMetricsArea from "../components/healthmetrics/healthMetricsArea";
const url = require("url");

function HealthCheck(props) {
  const [dateToDisplay, setDateToDisplay] = useState(new Date())
  const [clientReqId, setClientReqId] = useState(0);
  const [useUrl, setUseUrl] = useState(true);
  const [saveText, setSaveText] = useState("Save");
  const [showDateModal, setShowDateModal] = useState(false);
  const [reportDates, setReportDates] = useState();
  const [healthMetrics, setHealthMetrics] = useState();


  let { displayForDate } = useParams();

  if (displayForDate && displayForDate !== dateToDisplay && useUrl) {
      setDateToDisplay(displayForDate);
  }

  useEffect(() => {

    if (props.user.session.accessToken != null && dateToDisplay != null) {

      const localVarUrlObj = url.parse(process.env.REACT_APP_API_BASE_URL  + "/health/health-metrics", true);
      const localVarQueryParameter = {};
      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, {"reportDate": JSON.stringify(moment(dateToDisplay).format("YYYY-MM-DD"))});
      delete localVarUrlObj.search;
      var callUrl =  url.format(localVarUrlObj)
      const myRequest = new Request(callUrl, {
        method: 'GET',
        headers: {
          "hero-token": props.user.session.accessToken
        },
      });

      fetch(myRequest)
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        setHealthMetrics(data)
      })
    }
    else {
      setDateToDisplay(new Date())
    }
  }, [dateToDisplay]);

  useEffect(() => {
    if (
      props.user.session.accessToken != null &&
      !reportDates
    ) {
      const myRequest = new Request(process.env.REACT_APP_API_BASE_URL  + "/health/report-dates", {
        method: 'GET',
        headers: {
          "hero-token": props.user.session.accessToken
        },
      });

      fetch(myRequest)
      .then((response) => {
        return response.json()
      })
      .then((data) => setReportDates(data))
    }
  }, [reportDates]);

  function displayAreas() {
    var areas = [];
    for (var prop in healthMetrics.areas) {
      var area = healthMetrics.areas[prop];

      if (area.name == "Health Check") {
        areas.push(
          <HealthMetricsArea
            key={"areaId-" + area.areaId}
            healthMetricsArea={area}
            healthMetric={healthMetrics}
            onChange={onChange}
            date={dateToDisplay}
          ></HealthMetricsArea>
        );
      }
    }

    function onChange(
      areaId,
      sectionId,
      healthMetricId,
      newValue
    ) {
      var Update = JSON.parse(JSON.stringify(healthMetrics));
      Update.areas[areaId].sections[sectionId].healthMetrics[
        healthMetricId
      ].userHealthMetric.value = newValue;
      Update.areas[areaId].sections[sectionId].healthMetrics[
        healthMetricId
      ].hasChanged = true;

      setHealthMetrics(Update);
    }
    return (
      <>
        {areas}
      </>
    );
  }

  function SaveHealthArea({ target }) {
    setSaveText("Saving....");
    var ticks = new Date().getTime();
    var healthMetricsArea = { ...healthMetrics.areas[1] };
    healthMetricsArea.date = dateToDisplay
    props.dispatch(sendCommand(
      "HealthMetricAreaResponse",
      { ...healthMetricsArea, clientReqId: ticks },
      props.user.session.accessToken
    )).then(() => {
      setSaveText("Save");
    })
    setClientReqId(ticks);
  }

  function removeHealthData() {
    props.dispatch(sendCommand(
      dataStores.HEALTH_ASSESSMENT,
      { actionType: "CLEAR", reportDate: dateToDisplay }
    ));
  }

  function handleDayClick (day) {
    setDateToDisplay(day);
    setUseUrl(false);
  }

  function renderData(day){
    const date = day;
    const dateStyle = {
      position: 'absolute',
      bottom: 0,
      right: 0,
    };

    const dataStyle = {fontSize: '0.8em', textAlign: 'left'};
    const cellStyle = {
      height: 25,
      width: 25,
      position: 'relative'
    };


    return (
      <div style={cellStyle}>
        <div style={dateStyle}>{date.getDate()}</div>
        {reportDates.findIndex( RDate => moment(RDate, "YYYY-MM-DD").isSame(moment(date, "YYYY-MM-DD"), 'day')) != -1 &&
          <div style={dataStyle}><i className="fal fa-plus-square"></i></div>}
      </div>
    )
  }

  return (
    <div>
      {dateToDisplay && healthMetrics ?
        <Container>
          <Row className="title-container">
            <Col>
              <h1 className="page__title">Health Check</h1>
            </Col>
            <Col>
              <div className="btn--actions">
                <Link to={"/health-assessment"} title="Health Assessment" className="btn btn--transparent btn--circle">
                  <i className="far fa-chart-area"></i>
                </Link>
                <Link to={"/health-calendar"} title="Health Calendar" className="btn btn--transparent btn--circle mr-2">
                  <i className="far fa-history"></i>
                </Link>
                <button className="btn btn-success btn--circle mr-2 color-white" onClick={SaveHealthArea}>
                  <i className="far fa-save"></i>
                </button>
                {healthMetrics.data && <button className="btn btn-danger btn--circle mr-2" onClick={removeHealthData}>
                  <i className="fas fa-ban"></i>
                </button>}
                <a className="btn btn-primary btn--circle" onClick={e => setShowDateModal(true)}><i className="far fa-calendar-alt"></i></a>
              </div>
            </Col>
          </Row>

          {!healthMetrics.data && healthMetrics.reportDate && <label className="text__grey">Latest Report: {moment(healthMetrics.reportDate).format("DD-MM-YYYY")}</label>}
          {healthMetrics.areas && displayAreas()}

          <Modal centered show={showDateModal} className="calendar-month" onHide={e => setShowDateModal(false)}>
            <Modal.Dialog style={{ width: "280px" }}>
              <Row>
                <Col>
                  <p>Please select the start of the week you want to see</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <DayPicker
                    firstDayOfWeek={1}
                    selectedDays={[moment(dateToDisplay).toDate()]}
                    month={moment(dateToDisplay).toDate()}
                    value={moment(dateToDisplay).format("DD-MM-YYYY")}
                    format="DD-MM-YYYY"
                    placeholder="DD-MM-YYYY"
                    formatDate={(d, f) => moment(d).format(f)}
                    renderDay={renderData}
                    onDayClick={day => handleDayClick(day) } />
                </Col>
              </Row>
            </Modal.Dialog>
          </Modal>

        </Container>
        :
        <></>}
    </div>
  );
}


function mapStateToProps(state) {
  return {
    user: state.user,
  };
}


export default connect(mapStateToProps)(HealthCheck);

import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { Card, ProgressBar, Row } from "react-bootstrap";
import FeedBarChart from "../feedCharts/feedBarChart";
import FeedLineChart from "../feedCharts/feedLineChart";
import Collapse from "@kunukn/react-collapse";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import HeroOutlineButton from "../../../common/Buttons/OutlineButton";
import { fetchHealthData } from '../../../redux/actions/healthData';
import { fetchHealthMetrics } from '../../../redux/actions/healthMetrics';
import moment from 'moment';
import _ from 'lodash'
import round from "../../../shared/round";

function getProgressBar(score) {
  if (score && score > 0) {
    var variant = "primary";
    if (score > 99) {
      variant = "success";
    }

    return <ProgressBar variant="accent" now={score} label={`${round(score,0)}%`} />;
  } else {
    return <ProgressBar variant="accent" now={0} label={`0%`} />;
  }
}

function FeedCard(props) {
  const { userId, date, healthMetricId } = props;
  const [healthMetric, setHealthMetric] = useState({});
  const [model, setModel] = useState({});
  const [chartDisplayed, setChartDisplayed] = useState(false);
  const [whichChartDisplayed, setWhichChartDisplayed] = useState(true);
  const [weekObject, setWeekObject] = useState([]);

  useEffect(() => {
    props.dispatch(fetchHealthMetrics());
    props.dispatch(fetchHealthData(userId, date));
  }, []);

  useEffect(() => setChartDisplayed(false), [date]);

  useEffect(() => {
    if (!props.healthMetrics || !props.healthMetrics.items) {
      return;
    }

    setHealthMetric(props.healthMetrics.items.find(x => x.id == healthMetricId));
  }, [healthMetricId, props.healthMetrics]);

  useEffect(() => {
    if (!props.healthData
      || !props.healthData[userId]
      || !props.healthData[userId][date]
      || !props.healthData[userId][date].items
      || !healthMetric) {
      return;
    }

    const healthData = props.healthData[userId][date].items
      .find(x => x.healthMetricId == healthMetric.id);

    if (!healthData) {
      return;
    }

    const healthDataCategory = props.healthData[userId][date].items
      .find(x => x.healthMetricWheelSectionName == healthMetric.healthWheelSectionName)

    if(!healthDataCategory) {
      return;
    }

    const cardIds = props.healthData[userId][date].items
    .sort(((x,y) => (y.healthMetricId==191)-(x.healthMetricId==191) || (y.healthMetricId==187)-(x.healthMetricId==187) || (y.healthMetricId==202)-(x.healthMetricId==202) || (y.healthMetricId==184)-(x.healthMetricId==184) || (y.healthMetricId==185)-(x.healthMetricId==185) || (y.healthMetricId==189)-(x.healthMetricId==189) || y.healthMetricId - x.healthMetricId));

    const [recordedValue, units] = formatUnits(healthData.recordedValue, healthMetric.units);
    const [goalValue] = formatUnits(healthData.goalValue, healthMetric.units);
    const type = healthData.healthMetricType;
    setModel({
      name: healthMetric.name,
      recordedValue: window.Utils.isDurationHealthMetric(type) ? window.Utils.formatDurationHealthMetric(type, recordedValue) : round(recordedValue, window.Utils.getDPForHealthMetricType(type)),
      goalValue: goalValue,
      units: units,
      progressPercentage: parseFloat(parseFloat(healthData.recordedValue / healthData.goalValue * 100).toFixed(2)),
    })

    const dates = [...Array(7).keys()]
      .map(offset => moment.utc(date).subtract(offset, 'days').format('YYYY-MM-DD'))
      .reverse()
      .filter(dateKey => props.healthData[userId][dateKey]);
    const _weekObject = dates
      .map(dateKey => {
        const healthDataForDate = props.healthData[userId][dateKey].items && props.healthData[userId][dateKey].items.find(y => y.healthMetricId == healthMetric.id) || {};
        const [recordedValue, units] = formatUnits(healthDataForDate.recordedValue || 0, healthMetric.units);
        const [goalValue] = formatUnits(healthDataForDate.goalValue || 0, healthMetric.units);
        const type = healthDataForDate.healthMetricType;
        return {
          unit: units,
          date: dateKey,
          value: round(recordedValue, window.Utils.getDPForHealthMetricType(type)),
          name: moment.utc(dateKey).format('dd'),
          goal: goalValue
        };
      });

    setWeekObject(_weekObject);
  }, [props.healthData, healthMetric]);

  function formatUnits(value, unit) {
    let newValue = 0;
    let newUnit = "";

    switch (true) {
      case unit === "seconds":
        newUnit = "minutes";
        newValue = value / 60;
        break;
      case unit === "meters":
        newUnit = "KM";
        newValue = parseFloat(parseFloat(value / 1000).toFixed(2));
        break;
      case unit === "int":
        newUnit = "";
        newValue = value;
        break;
      case unit === null:
        newUnit = "";
        newValue = value;
        break;
      default:
        newUnit = unit;
        newValue = value;
        break;
    }

    return [newValue, newUnit];
  }

  const total = _.sum(weekObject&&weekObject.map((v)=>v.value)) || "";
  const showWeekly = props.isVodafone && model.name === 'Active Duration'
  const feedItemName = model.name;
  const stepsSelect = document.getElementById('Steps');
  
  return (
    <Card className="card--transparent feed-list-item" id={feedItemName} style={{ marginBottom: "15px" }}>
      <Card.Body className="pl-3 pl-md-0" style={{ display: "flex", flexDirection: "column" }}>
        <Card.Title className="feed-list-item__title">
          {model.name}
          {weekObject.some(x => x.value > 0) && (
            <HeroOutlineButton
              classes="float-right mt0 light"
              onClick={() => setChartDisplayed(!chartDisplayed)}
              size="medium"
              text="My Progress"
              colour="#15CBCE"
              icon={
                chartDisplayed ? (
                  <FaChevronUp style={{ marginRight: "0.3rem" }} />
                ) : (
                  <FaChevronDown style={{ marginRight: "0.3rem" }} />
                )
              }
            />
          )}
          <a href="/goals" class="btn btn-primary light float-right feed-card-btn">Set/Edit Your Goal</a>
        </Card.Title>
        <Card.Text className="feed-list-item__text">
          {showWeekly ? total : model.recordedValue} {model.units} {showWeekly && " this week"}
        </Card.Text>

        {getProgressBar(showWeekly ? (total/150)*100 : model.progressPercentage)}

        <Collapse transition={`height 200 cubic-bezier(.4, 0, .2, 1)`} isOpen={chartDisplayed}>
          {whichChartDisplayed && <FeedBarChart goalValue={showWeekly? 150/7 : (weekObject[0] || {}).goal || 0} dataObject={
            showWeekly? weekObject.map((item)=>({...item,goal:150/7})) : weekObject
          } />}
          {!whichChartDisplayed && <FeedLineChart dataObject={weekObject[0]} />}
          {/* <Row style={{ height: "33px", display: "flex", justifyContent: "center" }}>
            <HeroOutlineButton
              size="small"
              colour="#21B4F1"
              onClick={() => setWhichChartDisplayed(!whichChartDisplayed)}
              text={whichChartDisplayed === true ? "Today" : "My week"}
            />
          </Row> */}
        </Collapse>
      </Card.Body>
    </Card>
  );
}

function mapStateToProps(state) {
  return {
    healthData: state.healthData,
    healthMetrics: state.healthMetrics
  }
}

export default connect(mapStateToProps)(FeedCard);

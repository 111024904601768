import * as commandActions from "../../redux/actions/commands";
import dataStores from "../../redux/reducers/dataStores";
import React, { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Select from 'react-select';
const distinct = (value, index, self) => {
  return self.indexOf(value) === index;
};
const AllUsersICoach = (props) => {
  

  useEffect(() => {
    //Get all the users i have access too
    if (props.dataStores[dataStores.HEADCOACH_PERMISSIONS].success == null) {
      props.commandActions.sendCommand(dataStores.HEADCOACH_PERMISSIONS, {
        actionType: "GET",
      });
    }
  }, []);

  const [data, setData] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selected, setSelected] = useState({});
  useEffect(() => {
    if (props.dataStores[dataStores.DELEGATED_PERMISSIONS].success == null) {
      props.commandActions.sendCommand(dataStores.DELEGATED_PERMISSIONS, {
        actionType: "GET",
      });
    }
    if (props.dataStores[dataStores.HEADCOACH_PERMISSIONS].success == null) {
      props.commandActions.sendCommand(dataStores.HEADCOACH_PERMISSIONS, {
        actionType: "GET",
      });
    }

    if (
      props.dataStores[dataStores.DELEGATED_PERMISSIONS].specialistsWithAccess
    ) {
      var data = props.dataStores[
        dataStores.DELEGATED_PERMISSIONS
      ].specialistsWithAccess.map((x) => {
        return {
          name: x.firstName + " " + x.lastName,
          email: x.email,
          id: x.userId,
        };
      });
      var data2 = [];
      if (
        props.dataStores[dataStores.HEADCOACH_PERMISSIONS].specialistsWithAccess
      ) {
        data2 = props.dataStores[
          dataStores.HEADCOACH_PERMISSIONS
        ].specialistsWithAccess.map((x) => {
          return {
            name: x.firstName + " " + x.lastName,
            email: x.email,
            id: x.userId,
          };
        });
        data = data.concat(data2).filter(distinct);
      }
      setData(data);
    }
  }, [
    props.dataStores[dataStores.DELEGATED_PERMISSIONS],
    props.dataStores[dataStores.HEADCOACH_PERMISSIONS],
  ]);
  const filteredData =
    data &&
    data.filter((item) => {
      return (
        item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.email.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });

  const handleSelect = (item) => {
    setSelected(item);
    props.setSelection && props.setSelection(item);
  };

  return (
    <>
      {filteredData? (
        <Select
          options={filteredData}
          getOptionLabel={(guest) => {
            return guest.name + ' - ' + guest.email;
          }}
          getOptionValue={(guest) => guest.id}
          onChange={handleSelect}
          placeholder="Select a person"
        />
      ):(<p>Loading...</p>)}
    </>
  );
};

function mapStateToProps(state) {
  return {
    coachToken: state.appObject[dataStores.DELEGATED_PERMISSIONS].coachToken,
    AccessId: state.appObject[dataStores.DELEGATED_PERMISSIONS].uid,
    appConfig: state.appConfig,
    feed: state.feed,
    dataStores: state.appObject,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    commandActions: bindActionCreators(commandActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AllUsersICoach);

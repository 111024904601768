import { v4 as uuidv4 } from 'uuid';

export const CREATE_ERROR = 'CREATE_ERROR'
export function createError(message) {
    return {
        type: CREATE_ERROR,
        payload: {
            id: uuidv4(),
            message: message,
            createdAt: Date.now(),
            dismissed: false
        }
    }
}

export const DISMISS_ERROR = 'DISMISS_ERROR'
export function dismissError(id) {
    return {
        type: DISMISS_ERROR,
        payload: {
            id: id
        }
    }
}
import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import Modal from 'react-bootstrap/Modal'
import { Row, Col } from "react-bootstrap";
import { createChallenge } from '../../redux/actions/challenges'
import { fetchHealthMetrics } from '../../redux/actions/healthMetrics'
import { fetchGroups } from '../../redux/actions/groups'
import { fetchUsers } from '../../redux/actions/users'

import { Wizard, Steps, Step } from "react-albus";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import moment from "moment";

function CreateChallengeModal(props) {
  const { showModal, setShowModal } = props
  const now = new Date();
  const [model, setModel] = useState({
    isPrivate: false,
    description: '',
    startDate: now,
    endDate: now,
  });

  useEffect(() => {
    props.dispatch(fetchHealthMetrics())
    props.dispatch(fetchGroups())
    props.dispatch(fetchUsers())
  }, []);

  useEffect(() => {
    model.userIds = []
    model.groupIds = []
    model.createGroup = false
    model.newGroupName = ''
  }, [model.participantType])

  const handleInputChange = event => {
    const target = event.target;
    const name = target.name;
    let value = target.value;

    if (['userIds', 'groupIds'].includes(name)) {
      value = [...target.selectedOptions].map(o => o.value)
    }

    if (['createGroup', 'isPrivate'].includes(name)) {
      value = target.checked
    }

    setModel({
      ...model,
      [name]: value
    })
  }

  const handleStartDateChange = day => {
    setModel({
      ...model,
      startDate: day
    })
  }

  const handleEndDateChange = day => {
    setModel({
      ...model,
      endDate: day
    })
  }

  const isValid = () => model.name
    && model.healthMetricId
    && model.targetType
    && model.targetValue > 0
    && model.participantType
    && new Date(model.startDate) >= new Date().setHours(0, 0, 0, 0)
    && new Date(model.endDate) >= new Date().setHours(0, 0, 0, 0)
    && new Date(model.startDate) < new Date(model.endDate)
    && (model.participantType != 'INDIVIDUAL' || !model.createGroup || model.newGroupName);

  const handleFormSubmit = event => {
    event.preventDefault()

    if (!isValid()) {
      return
    }

    props.dispatch(createChallenge(model))

    setShowModal(false)
    setModel({})
  }

  return (
    <Modal centered show={showModal} onHide={() => setShowModal(false)} dialogClassName="modal-competition">
      <Modal.Body>
        <form onSubmit={handleFormSubmit}>
          <Wizard>
            <Steps>
              <Step
                id="description"
                render={({ next }) => (
                  <Row>
                    <Col xs={12} className="mb-3">
                      <h3>Details</h3>
                      <div className="c-field mt-2">
                        <label className="c-field__label">Name</label>
                        <input className="c-input" name="name" type="text" value={model.name} onChange={handleInputChange} required />
                      </div>
                      <div className="c-field mt-2">
                        <label className="c-field__label">Description</label>
                        <input className="c-input" name="description" type="text" value={model.description} onChange={handleInputChange} required />
                      </div>
                    </Col>
                    <Col xs={6}></Col>
                    <Col xs={6}>
                      <button className="btn btn-primary" onClick={next}>Next</button>
                    </Col>
                  </Row>
                )}
              />
              <Step
                id="targets"
                render={({ next, previous }) => (
                  <Row>
                    <Col xs={12} className="mb-3">
                      <h3>Targets</h3>
                      <div className="c-field mt-2">

                        <label className="c-field__label">
                          Metric
                        </label>
                        <div className="select">
                          <select className="c-select__input" name="healthMetricId" value={model.healthMetricId} onChange={handleInputChange} required>
                            <option></option>
                            {props.healthMetrics.map(healthMetric => (
                              <option value={healthMetric.id}>{healthMetric.name} ({healthMetric.units})</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="c-field mt-2">
                        <label className="c-field__label">
                          Target Type
                        </label>
                        <div className="select">
                          <select className="c-select__input" name="targetType" value={model.targetType} onChange={handleInputChange} required>
                            <option></option>
                            <option value="SUM">Sum total</option>
                            <option value="AVERAGE">Daily average</option>
                          </select>
                        </div>
                      </div>
                      <div className="c-field mt-2">
                        <label className="c-field__label">Target Value</label>
                        <input className="c-input" name="targetValue" type="number" step="1" value={model.targetValue} onChange={handleInputChange} required />
                        {props.healthMetrics.map(healthMetric => (
                          <p>{healthMetric.units}</p>
                        ))}
                      </div>
                    </Col>
                    <Col xs={6}>
                      <button className="btn btn-gray-300" onClick={previous}>Previous</button>
                    </Col>
                    <Col xs={6}>
                      <button className="btn btn-primary" onClick={next}>Next</button>
                    </Col>
                  </Row>
                )}
              />
              <Step
                id="members"
                render={({ next, previous }) => (
                  <Row>
                    <Col xs={12} className="mb-3">
                      <h3>Members</h3>
                      <div>
                        <label>
                          Individual Challenge
                          <input name="participantType" type="radio" checked={model.participantType == "INDIVIDUAL"} value="INDIVIDUAL" onChange={handleInputChange} />
                        </label>
                        <label>
                          Group Challenge
                          <input name="participantType" type="radio" checked={model.participantType == "GROUP"} value="GROUP" onChange={handleInputChange} />
                        </label>
                      </div>

                      {model.participantType == 'INDIVIDUAL' && (
                        <>
                          <label>
                            Users:
                            <select name="userIds" value={model.userIds} onChange={handleInputChange} multiple>
                              {props.users.items.map(user => (
                                <option value={user.id}>{user.name}</option>
                              ))}
                            </select>
                          </label>

                          <label>
                            Create a group for these users
                            <input name="createGroup" type="checkbox" checked={model.createGroup} onChange={handleInputChange} />
                          </label>

                          {model.createGroup &&
                            <label>
                              New Group Name:
                              <input name="newGroupName" type="text" value={model.newGroupName} onChange={handleInputChange} required />
                            </label>
                          }
                        </>
                      )}

                      {model.participantType == 'GROUP' && (
                        <label>
                          Teams:
                          <select name="groupIds" value={model.groupIds} onChange={handleInputChange} multiple>
                            {props.groups.items.map(group => (
                              <option value={group.id}>{group.name}</option>
                            ))}
                          </select>
                        </label>
                      )}
                      <label>
                        Private Challenge:
                        <input name="isPrivate" type="checkbox" checked={model.isPrivate} onChange={handleInputChange} />
                      </label>
                    </Col>
                    <Col xs={6}>
                      <button className="btn btn-gray-300" onClick={previous}>Previous</button>
                    </Col>
                    <Col xs={6}>
                      <button className="btn btn-primary" onClick={next}>Next</button>
                    </Col>
                  </Row>
                )}
              />
              <Step
                id="dates"
                render={({ previous }) => (
                  <Row>
                    <Col xs={12}>
                      <h3>Dates</h3>
                    </Col>
                    <Col xs={6}>
                      <div className="c-field mt-2">
                        <label className="c-field__label">
                          Start
                        </label>
                        <DayPicker
                          selectedDays={[moment(model.startDate).toDate()]}
                          value={moment(model.startDate).format("DD-MM-YYYY")}
                          format="DD-MM-YYYY"
                          placeholder="DD-MM-YYYY"
                          formatDate={(d, f) => moment(d).format(f)}
                          onDayClick={handleStartDateChange}
                        />
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="c-field mt-2">
                        <label className="c-field__label">
                          End
                        </label>
                        <DayPicker
                          selectedDays={[moment(model.endDate).toDate()]}
                          value={moment(model.endDate).format("DD-MM-YYYY")}
                          format="DD-MM-YYYY"
                          placeholder="DD-MM-YYYY"
                          formatDate={(d, f) => moment(d).format(f)}
                          onDayClick={handleEndDateChange}
                        />
                      </div>
                    </Col>
                    <Col xs={6}>
                      <button className="btn btn-gray-300" onClick={previous}>Previous</button>
                    </Col>
                    <Col xs={6}>
                      <input className="btn btn-primary w-100" type="submit" value="Submit" disabled={!isValid()} />
                    </Col>
                  </Row>
                )}
              />
            </Steps>
          </Wizard>

        </form>
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = state => ({
  healthMetrics: state.healthMetrics.items.filter(x => x.canBeUsedAsChallengeTarget),
  groups: state.groups,
  users: state.users
})

export default connect(mapStateToProps)(CreateChallengeModal);

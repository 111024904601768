import {
    REQUEST_HEALTH_DATA_MOOD, RECEIVE_HEALTH_DATA_MOOD,
} from '../actions/healthData'
import { END_USER_SESSION } from '../actions/userSession'
import moment from 'moment';

const initialState = {}

export default function healthData(state = initialState, action) {
    switch (action.type) {
        case REQUEST_HEALTH_DATA_MOOD: {
            const dates = {};
            for (let date = moment(action.payload.fromDate, 'YYYY-MM-DD'); date.valueOf() <= moment(action.payload.toDate, 'YYYY-MM-DD').valueOf(); date = date.clone().add(1, 'day')) {
                const dateStr = date.format('YYYY-MM-DD');
                dates[dateStr] = {
                    isFetching: true,
                    isValid: true,
                    items: []
                }
            }
            return {
                ...state,
                [action.payload.userId]: {
                    ...state[action.payload.userId],
                    [action.payload.date]: {
                        isFetching: true,
                        isValid: true,
                        items: []
                    }
                }
            }
        }
        case RECEIVE_HEALTH_DATA_MOOD: {
            const dates = {};
            for (let date = moment(action.payload.fromDate, 'YYYY-MM-DD'); date.valueOf() <= moment(action.payload.toDate, 'YYYY-MM-DD').valueOf(); date = date.clone().add(1, 'day')) {
                const dateStr = date.format('YYYY-MM-DD');
                dates[dateStr] = {
                    isFetching: false,
                    isValid: true,
                    items: action.payload.healthDataMood[dateStr] ? [action.payload.healthDataMood[dateStr]] : []
                }
            }
            return {
                ...state,
                [action.payload.userId]: {
                    ...state[action.payload.userId],
                    ...dates,
                }
            }
        }
        case END_USER_SESSION:
            return initialState
        default:
            return state
    }
}

export function selectHealthDataForUserOnDate(state, userId, date) {
    if(!state
        || !state[userId] 
        || !state[userId][date]
        || !state[userId][date].isValid
        || state[userId][date].isFetching) {
        return [];
    }

    return state[userId][date].items;
}
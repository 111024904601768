import {
    RECEIVE_GROUP_MESSAGES
} from '../actions/groupMessages';
import { END_USER_SESSION } from '../actions/userSession';
import moment from 'moment';

const initialState = {}

export default function groups(state = initialState, action) {
    switch (action.type) {
        case RECEIVE_GROUP_MESSAGES:
            var m = state[action.payload.groupId]
            if(m && m.items && action.payload.messages.every(pm => m.items.some(i => pm.outgoing == i.outgoing && pm.sender.id == i.sender.id && pm.sentAt == i.sentAt))){
                return {
                    ...state,
                    [action.payload.groupId]: {
                        ...state[action.payload.groupId],
                        items: ((state[action.payload.groupId] || {}).items || [])
                            .sort((a, b) => moment(a.sentAt).diff(b.sentAt) )
                    }
                }
            }
            return {
                ...state,
                [action.payload.groupId]: {
                    ...state[action.payload.groupId],
                    items: ((state[action.payload.groupId] || {}).items || [])
                        .concat(action.payload.messages)
                        .sort((a, b) => moment(a.sentAt).diff(b.sentAt) )
                }
            }
            
        case END_USER_SESSION:
            return initialState
        default:
            return state
    }
}
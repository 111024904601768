import React, { useState } from 'react'
import { connect } from "react-redux";
import { sendGroupMessage } from '../../redux/actions/groupMessages'

function SendGroupMessageInput({ groupId, name, groups, dispatch }) {
  const [newMessage, setNewMessage] = useState('')
  const group = groups.items.find(x => x.id == groupId);

  const handleInputChange = event => {
    setNewMessage(event.target.value)
  }

  const isValid = () => !!newMessage

  const handleFormSubmit = event => {
    event.preventDefault()

    if(!isValid()) {
      alert('Invalid message')
      return
    }
    
    dispatch(sendGroupMessage(group.id, encodeURIComponent(newMessage)))
    setNewMessage('')
  }

  return (
    <form onSubmit={handleFormSubmit}>
      <input
        type="text"
        placeholder={`Send a message to the members of ${name}`}
        value={newMessage}
        onChange={handleInputChange}
      />
     
      <button type="submit" className="msg_send_btn" disabled={!isValid()}><i className="fa fa-paper-plane" aria-hidden="true"></i></button>
    </form>
  );
}

function mapStateToProps(state) {
  return {
    groups: state.groups
  }
}

export default connect(mapStateToProps)(SendGroupMessageInput);
import React from 'react';
import './newStyle.scss';

const NewStylePage = () => {
    return (
        <div className="container">
            <h1 className="title">Welcome to the New Style Page</h1>
            <p className="description">This is an example page for the newStyle.scss styling.</p>
            <button>Click Me</button>
            <button className="light">Click Me</button>
            <a href="https://www.google.com">Google</a>
            
        </div>
    );
};

export default NewStylePage;

import { 
    REQUEST_GROUP_INVITATIONS, 
    RECEIVE_GROUP_INVITATIONS, 
    INVALIDATE_GROUP_INVITATIONS 
} from '../actions/groupInvitations'
import { END_USER_SESSION } from '../actions/userSession'

const initialState = {
    isFetching: false,
    isValid: false,
    items: []
}

export default function groupInvitations(state = initialState, action) {
    switch (action.type) {
        case REQUEST_GROUP_INVITATIONS:
            return {
                ...state,
                isFetching: true,
                isValid: true
            }
        case RECEIVE_GROUP_INVITATIONS:
            return {
                ...state,
                isFetching: false,
                isValid: true,
                items: action.payload.groupInvitations,
                lastUpdated: action.payload.receivedAt
            }
        case INVALIDATE_GROUP_INVITATIONS:
            return {
                ...state,
                isValid: false
            }
        case END_USER_SESSION:
            return initialState
        default:
            return state
    }
}
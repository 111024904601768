import { createHeroApiClient } from '../../hero-api-client';
import { createError } from './errors';
import NProgress from "nprogress";

export const REQUEST_CHALLENGES_CURRENT = 'REQUEST_CHALLENGES_CURRENT'
function requestChallengesCurrent() {
    return {
        type: REQUEST_CHALLENGES_CURRENT
    }
}

export const RECEIVE_CHALLENGES_CURRENT = 'RECEIVE_CHALLENGES_CURRENT'
function receiveChallengesCurrent(challenges, userId) {
    return {
        type: RECEIVE_CHALLENGES_CURRENT,
        payload: {
            challenges: challenges,
            receivedAt: Date.now(),
            userId: userId
        }
    }
}

function doFetchChallengesCurrent(accessToken, userId) {
    return async dispatch => {
        NProgress.start();
        dispatch(requestChallengesCurrent())
        createHeroApiClient(accessToken).getChallengesCurrent()
            .then(challenges => dispatch(receiveChallengesCurrent(challenges, userId)))
            .catch(error => dispatch(createError(error.message)))
            .finally(() => NProgress.done());
    }
}

function shouldFetchChallengesCurrent(state) {
    const challenges = state.challengesCurrent
    if (!challenges) {
        return true
    } else if (challenges.isFetching) {
        return false
    } else {
        return !challenges.isValid
    }
}

export function fetchChallengesCurrent() {
    return (dispatch, getState) => {
        const state = getState()
        if (!shouldFetchChallengesCurrent(state)) {
            return Promise.resolve()
        }
        
        const accessToken = state.user.session.accessToken
        const userId = state.user.profile.id
        return dispatch(doFetchChallengesCurrent(accessToken, userId))
    }
}

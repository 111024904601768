import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import FinanceSliderInput from '../../components/FinanceCalculators/FinanceSliderInput';
import MortgageResults from '../../components/FinanceCalculators/MortgateResults';

const MortgageCalculator = () => {
  const [loanAmount, setLoanAmount] = useState(100000);
  const [interestRate, setInterestRate] = useState(5.5);
  const [loanTerm, setLoanTerm] = useState(25);
  const [mortgageDetails, setMortgageDetails] = useState([]);
  const [monthlyPayment, setMonthlyPayment] = useState(0);

  const [output, setOutput] = useState({
    monthlyPayment: 0,
    totalInterest: 0,
    totalRepayment: 0,
    totalBorrowed: 0,
  });

  useEffect(() => {
    handleCalculate();
  }, [loanAmount, interestRate, loanTerm]);

  const handleValueSliderChange = (value) => {
    setLoanAmount(value);
  };

  const handleInterestRateSliderChange = (value) => {
    setInterestRate(value);
  };

  const handleLoanTermSliderChange = (value) => {
    setLoanTerm(value);
  };

  const handleCalculate = () => {
    const monthlyInterestRate = (interestRate / 100) / 12;
    const payments = loanTerm * 12;
    const equate = Math.pow(1 + monthlyInterestRate, payments);

    const monthlyPayment = (loanAmount * equate * monthlyInterestRate) / (equate - 1);

    let remainingBalance = loanAmount;
    let mortgageData = [];

    for (let year = 1; year <= loanTerm; year++) {
      const interestPayment = remainingBalance * monthlyInterestRate;
      const principalPayment = monthlyPayment - interestPayment;

      remainingBalance -= principalPayment;

      mortgageData.push({
        year,
        balance: round(remainingBalance),
        principalPayment: round(principalPayment),
        interestPayment: round(interestPayment)
      });
    }

    setOutput({
      monthlyPayment: round(monthlyPayment).toFixed(0),
      totalInterest: round((monthlyPayment * payments) - loanAmount).toFixed(0),
      totalRepayment: round(monthlyPayment * payments).toFixed(0),
      totalBorrowed: round(loanAmount).toFixed(0)
    });

    setMortgageDetails(mortgageData);
    setMonthlyPayment(round(monthlyPayment).toFixed(0));
  };

  function round(value) {
    return Math.round(value * 100) / 100;
  }

  return (
    <Container fluid className="p-4">
        <Row>
  <Col>
    <h1 className="pb-3">Mortgage Calculator</h1>
    <p className="pb-4">
      Welcome to our Mortgage Calculator! This tool is designed to assist you in estimating the monthly mortgage payment, total interest, and total repayment based on various factors.
      Simply adjust the sliders below to set the mortgage amount, annual interest rate, and the duration of the mortgage. 
      The calculator will provide you with a projection of your monthly payment, total interest paid, total repayment, and the total borrowed amount.
    </p>
  </Col>
</Row>
        <Row className="p-4">
            <Col md={6}>
          <FinanceSliderInput labelBefore="Mortgage Amount £" minValue={20000} maxValue={500000} step={1000} toFixed={0} onValueChange={handleValueSliderChange} defaultValue={loanAmount} />
          <FinanceSliderInput labelBefore="Interest Rate " labelAfter="%" minValue={0.5} maxValue={14.9} step={0.1} toFixed={1} onValueChange={handleInterestRateSliderChange} defaultValue={interestRate} />
          <FinanceSliderInput labelBefore="Over " labelAfter=" years" minValue={2} maxValue={40} step={1} toFixed={0} onValueChange={handleLoanTermSliderChange} defaultValue={loanTerm} />
          </Col>
          
          <Col md={6}>
          <MortgageResults monthlyPayment={monthlyPayment} totalInterest={output.totalInterest} totalRepayment={output.totalRepayment} totalBorrowed={output.totalBorrowed} mortgageDetails={mortgageDetails} />
            This calculator is for illustrative purposes only. The actual interest rate you will pay will depend upon your circumstances. Always check with your loan provider.
          </Col>
      </Row>
    </Container>
  );
}

export default MortgageCalculator;

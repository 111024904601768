import { BEGIN_USER_SESSION, END_USER_SESSION } from '../actions/userSession'

const initialState = {
    isAuthenticated: false
};

export default function userSession(
    state = initialState,
    action
) {
    switch (action.type) {
        case BEGIN_USER_SESSION:
            return {
                ...state,
                accessToken: action.payload.access_token,
                isAuthenticated: true
            }
        case END_USER_SESSION:
            return initialState
        default:
            return state
    }
}
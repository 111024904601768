import React, { useState, useEffect, useRef } from 'react'
import { connect } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import { Container, Spinner, Button, Modal, Row, Col, Card, Tab, Tabs } from 'react-bootstrap'
import { FaLayerGroup, FaUserPlus, FaSignOutAlt } from 'react-icons/fa'
import { IoIosNotificationsOff, IoIosNotifications } from 'react-icons/io'
import { fetchUsers } from '../redux/actions/users'
import { fetchGroups, inviteUserToGroup, leaveGroup, getGroupUser, updateGroupUser } from '../redux/actions/groups'
import GroupMessages from '../components/groups/GroupMessages'
import SelectUserModal from '../components/SelectUserModal'
import UserReport from '../components/reports/UserReport';
import GroupReport from '../components/reports/GroupReport';
import moment from 'moment';
import LoadingSpinner from '../components/controls/LoadingSpinner';
import shortid from 'shortid';

function Group(props) {

  let { id: groupId, name: groupName } = useParams()
  const [group, setGroup] = useState(null)

  const [showSelectUserModal, setShowSelectUserModal] = useState(false)
  const handleSelectUserFormSubmit = (userId) => props.dispatch(inviteUserToGroup(groupId, userId, ()=>{
    props.dispatch(fetchGroups())
  }));

  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedReport, setSelectedReport] = useState(null);
  const [showReportModal, setShowReportModal] = useState(false);
  const [activityLog, setActivityLog] = useState(null);
  const [userName, setUserName] = useState(null)

  useEffect(() => {
    props.dispatch(fetchUsers())
    props.dispatch(getGroupUser(groupId))
  }, []);

  useEffect(()=>{
    if(props.users && props.users.length > 0 && group && !userName){
      if(group.ownerId === props.user.profile.id){
        var user = props.users.find(u => u.id == group.name);
        if (user && user.givenName && user.familyName) setUserName(`${user.givenName} ${user.familyName}`);
      }
      else{
        var user = props.users.find(u => u.id == group.ownerId);
        if (user && user.givenName && user.familyName) setUserName(`${user.givenName} ${user.familyName}`);
      }

    }
  }, [group, props.users])
  useEffect(() => {
    if (!props.groups.isValid) {
      props.dispatch(fetchGroups())
      return
    }
    setGroup(props.groups.items.find(x => x.id == groupId))
  }, [props.groups])

  useEffect(() => {
    setGroup(props.groups.items.find(x => x.id == groupId))
  }, [props.groups])

  useEffect(() => {
    if (
      props.user.session.accessToken != null &&
      group
    ) {
      var body = { userIds: group.userIds, endDate: new Date() }
      const myRequest = new Request(process.env.REACT_APP_API_BASE_URL + "/useractivitydata", {
        method: 'POST',
        headers: {
          "hero-token": props.user.session.accessToken,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });

      fetch(myRequest)
        .then((response) => {
          return response.json()
        })
        .then((data) => setActivityLog(data))
    }
  }, [group]);

  function openModal(report, userId) {
    setSelectedReport(report);
    setSelectedUserId(userId);
    setShowReportModal(true);
  }

  function handleModalHide() {
    setSelectedUserId(null);
    setSelectedReport(null);
    setShowReportModal(false);
  }

  const doLeaveGroup = () => {
    if (!window.confirm(`Are you sure you want to leave group ${group.name}?`)) return;
    props.dispatch(leaveGroup(group.id, () => {
      props.history.replace('/groups');
    }));
  }

  const toggleMessageNotifications = () => {
    const groupUser = { ...props.groupUser.items[groupId], sendGroupMessageNotifications: !props.groupUser.items[groupId].sendGroupMessageNotifications };
    props.dispatch(updateGroupUser(groupId, groupUser));
  }

  function renderActivityLog() {
    var log = [];

    if (activityLog) {
      if (activityLog.length == 0) {
        return (
          <Col>
            <p>No group activity</p>
          </Col>)
      }

      for (var key in activityLog) {
        activityLog[key].map((activity) => {
          log.push(
            <Col key={shortid.generate()} md={3}>
              <Card>
                <Card.Body>
                  <Row className="mb-2" className="text-center">
                    <Col md={12} className="text-center">
                      {
                        activity.type === 1 &&
                        <div className="user-goal ml-auto mr-auto mb-3">
                          <i className="far fa-trophy"></i>
                        </div>
                      }
                    </Col>
                    <Col md={12} className="text-center">
                      <p className="text-bold">{activity.userName}</p>
                      <p>{activity.title}</p>
                    </Col>
                    <Col md={12} className="text-center">
                      <p>{moment(activity.timestamp).fromNow()}</p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>)
        })
      }
      return log;
    }

    return undefined;
  }

  return (
    <Container>
      {group && (
        <Container>
          <Row className="title-container">
            <Col>
              <h1 className="page__title mb-0">{group.dmGroup ? userName : group.name}</h1>
            </Col>
            <Col>
              <div className="btn--actions">
                {/*<Button className="btn--circle mr-2" variant={"primary"} onClick={() => openModal('GroupReport')}>*/}
                {/*  <FaLayerGroup />*/}
                {/*</Button>*/}
                {group.ownerId === props.user.profile.id && !group.dmGroup && (
                  <Button className="btn--circle" onClick={() => setShowSelectUserModal(true)}>
                    <FaUserPlus />
                  </Button>
                )}
                {props.groupUser.items[groupId] && (
                  <Button className="btn--circle ml-1" onClick={toggleMessageNotifications}>
                    {props.groupUser.items[groupId].sendGroupMessageNotifications ? <IoIosNotifications /> : <IoIosNotificationsOff />}
                  </Button>
                )}
                <Button className="btn--circle ml-1" onClick={doLeaveGroup}>
                  <FaSignOutAlt />
                </Button>
              </div>
            </Col>
          </Row>

          <h1 className="page__title"></h1>

          <Tabs variant="pills" fill defaultActiveKey="messages" id="uncontrolled-tab-example">
            {/* <Tab eventKey="messages" title="Messages">
              <h3 className="mt-3 mb-3">
                Messages
              </h3>
              <Row>
                <Col>
                  <Card>
                    <GroupMessages groupId={groupId} name={group.dmGroup ? userName : group.name}/>
                  </Card>
                </Col>
              </Row>
            </Tab> */}

            <Tab eventKey="members" title="Members">
              <h3 className="mt-3 mb-3">
                Members
              </h3>
              <Row>
                {props.users ? <>
                  {props.users.filter(u => group.userIds.indexOf(u.id) >= 0).map(u => (
                    <Col key={`group-member-userid-${u.id}`} md={3}>
                      <Card>
                        <Card.Body className="text-center">
                          <img className="mb-2" src={u.avatarUrl || `https://eu.ui-avatars.com/api?name=${u.name}`} style={{ height: "50px", width: "50px", borderRadius: "50%" }} />
                          <p>{u.name}</p>
                          <Row>
                            <Col className="text-center">
                              <Button className="btn btn--transparent btn--circle" onClick={() => openModal('UserReport', u.id)}><i className="far fa-eye"></i></Button>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </> : undefined}
              </Row>
            </Tab>
            <Tab eventKey="activity" title="Achievements">
              <h3 className="mt-3 mb-3">
                Achievements
              </h3>
              <Row>
                {activityLog && <>{renderActivityLog()}</>}
              </Row>
            </Tab>
          </Tabs>
        </Container>
      )}

      {!group && <LoadingSpinner/>}

      <SelectUserModal
        show={showSelectUserModal}
        setShow={setShowSelectUserModal}
        handleFormSubmit={handleSelectUserFormSubmit}
      />

      <Modal centered show={showReportModal} onHide={handleModalHide}>
        {selectedReport == 'UserReport' && <UserReport userId={selectedUserId} />}
        {selectedReport == 'GroupReport' && <GroupReport userIds={group.userIds} />}
      </Modal>
    </Container>
  );
}

const mapStateToProps = state => {
  return {
    users: state.users.items,
    groups: state.groups,
    user: state.user,
    groupUser: state.groupUser,
  };
}

export default withRouter(connect(mapStateToProps)(Group));

import React, { useEffect, useState } from 'react';
import { useRemoteVideoTileState } from 'amazon-chime-sdk-component-library-react';
import VideoGrid from './VideoGrid';

const MeetingGrid = ({ meetingSession, attendees, style }) => {
  
    const videoTiles = useRemoteVideoTileState({
        meetingManager: meetingSession?.audioVideo,
        tileProps: { attendeeId: true },
        viewMode: 'tile'
      });

  return (<VideoGrid style={style} meetingSession={meetingSession} videoTiles={videoTiles} attendees={attendees} />);
};

export default MeetingGrid;

import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux';
import { fetchGroupMessages } from '../../redux/actions/groupMessages';
import SendGroupMessageInput from './SendGroupMessageInput';
import moment from 'moment';
import shortid from 'shortid';


function GroupMessages({ groupId, name, groupMessages, dispatch }) {

  const [messages, setMessages] = useState([]);
  const [firstMessageDate, setFirstMessageDate] = useState(null);
  const [lastMessageDate, setLastMessageDate] = useState(null);
  const [intervalId, setIntervalId] = useState(null);

  const messageContainerLastElement = useRef(null);

  useEffect(() => {
    dispatch(fetchGroupMessages(groupId, { latest: true }));
  }, []);

  useEffect(() => {
    const state = groupMessages[groupId];
    if(!state || !state.items) {
      return;
    }
    setMessages(state.items);
  }, [groupMessages]);

  useEffect(() => {
    return () => window.clearInterval(intervalId);
  }, [intervalId])

  useEffect(() => {
    if(!lastMessageDate){
      return;
    }

    window.clearInterval(intervalId);
    const id = window.setInterval(() => dispatch(fetchGroupMessages(groupId, { after: lastMessageDate })), 3000);
    setIntervalId(id);
  }, [lastMessageDate]);

  useEffect(() => {

    if(!messages || messages.length == 0) {
      dispatch(fetchGroupMessages(groupId, { limit: 20 }));
      return;
    }

    if(messages.length > 0){
      setFirstMessageDate(messages[0].sentAt);
      setLastMessageDate(messages[messages.length-1].sentAt);

    }
  }, [messages]);

  useEffect(() => {
    if(!messageContainerLastElement.current) {
      return;
    }
    messageContainerLastElement.current.scrollIntoView({behavior: 'smooth'})

  }, [messages])

  const handleLoadPreviousMessages = (event) => {
    event.preventDefault();
    dispatch(fetchGroupMessages(groupId, { limit: 20, before: firstMessageDate }));
  };

  return (
    <>
          <div id="chat-message-fetch" onClick={handleLoadPreviousMessages} >
            <a onClick={handleLoadPreviousMessages}>Click to load previous messages</a>
          </div>
          <div className="chat-container msg_history">
          <div id="chat-message-list" className="chat-message-list">
        {messages.map((message, index) => {
          const decodedMessage = decodeURIComponent(message.message);
          const isUrl = decodedMessage.match(window.Utils.urlRegex);
          let isImageUrl = false;
          if (isUrl) {
            const urlWithoutParameters = decodedMessage.split('?')[0];
            isImageUrl = ['gif', 'jpg', 'jpeg', 'png'].includes(urlWithoutParameters.substr(urlWithoutParameters.lastIndexOf('.') + 1));
          }
          return (
          <div key={`chat-msg-id-${index}`}>

              {
                message.outgoing
                ? (
                    <div className="message-row you-message">
                        <div className="message-content">
                            {isImageUrl ? <img className="message-image" src={decodedMessage} /> : <div className="message-text">{decodedMessage}</div>}
                            <div className="message-time">{moment(message.sentAt).format("HH:mm")} | {moment(message.sentAt).format("MMMM DD")}</div>
                        </div>
                    </div>
                ) : (
                    <div className="message-row other-message">
                        <div className="message-content">
                            <img className="avatar__img c-avatar c-avatar--menu c-avatar--xsmall dropdown-toggle" src={message.sender.avatarUrl || `https://eu.ui-avatars.com/api?name=${message.sender.name}`} alt="message.sender.name" title={message.sender.name} />
                            {isImageUrl ? <img className="message-image" src={decodedMessage} /> : <div className="message-text">{decodedMessage}</div>}
                            <div className="message-time">{moment(message.sentAt).format("HH:mm")} | {moment(message.sentAt).format("MMMM DD")}</div>
                        </div>
                    </div>
                )
              }
               <div ref={messageContainerLastElement}></div>
            </div>
          );
        }) }
          </div>
        </div>
        <div className="input_msg_write">
            <SendGroupMessageInput groupId={groupId} name={name}/>
        </div>
</>

  );
}

function mapStateToProps(state) {
  return {
    groupMessages: state.groupMessages
  }
}

export default connect(mapStateToProps)(GroupMessages);

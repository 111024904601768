import { RECEIVE_HEALTH_METRICS } from "../actions/healthMetricsOld";
import { END_USER_SESSION } from '../actions/userSession'

const initialState = {}

export default function healthMetrics(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_HEALTH_METRICS:
      return {
        ...state,
        ...action.payload
      };
    case END_USER_SESSION:
        return initialState
    default:
      return state;
  }
}

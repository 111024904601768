import React from "react";

function TextInput(props) {
  return (
    <div>
      {
        props.label ? <label>{props.label}</label> : undefined
      }
      <input
        type="text"
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
      />
    </div>
  );
}

export default TextInput;

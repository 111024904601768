import { createHeroApiClient } from '../../hero-api-client';
import { createError } from './errors'

export const REQUEST_AUTHENTICATED_USER_GOALS = 'REQUEST_AUTHENTICATED_USER_GOALS'
function requestAuthenticatedUserGoals() {
    return {
        type: REQUEST_AUTHENTICATED_USER_GOALS
    }
}

export const RECEIVE_AUTHENTICATED_USER_GOALS = 'RECEIVE_AUTHENTICATED_USER_GOALS'
function receiveAuthenticatedUserGoals(goals) {
    return {
        type: RECEIVE_AUTHENTICATED_USER_GOALS,
        payload: goals
    }
}

export const INVALIDATE_AUTHENTICATED_USER_GOALS = 'INVALIDATE_AUTHENTICATED_USER_GOALS'
export function invalidateAuthenticatedUserGoals() {
    return {
        type: INVALIDATE_AUTHENTICATED_USER_GOALS
    }
}

function doFetchAuthenticatedUserGoals(accessToken) {
    return async dispatch => {
        dispatch(requestAuthenticatedUserGoals())
        createHeroApiClient(accessToken).authenticatedUser.goals.get()
            .then(goals => dispatch(receiveAuthenticatedUserGoals(goals)))
            .catch(error => dispatch(createError(error.message)))
    }
}

function shouldFetchAuthenticatedUserGoals(state) {
    const userGoals = state.user.goals
    if (!userGoals) {
        return true
    } else if (userGoals.isFetching) {
        return false
    } else {
        return !userGoals.isValid
    }
}

export function fetchAuthenticatedUserGoals() {
    return (dispatch, getState) => {
        const state = getState()
        if (shouldFetchAuthenticatedUserGoals(state)) {
            return dispatch(doFetchAuthenticatedUserGoals(state.user.session.accessToken))
        } else {
            return Promise.resolve()
        }
    }
}

export const UPDATE_AUTHENTICATED_USER_GOAL = 'UPDATE_AUTHENTICATED_USER_GOAL'
function updateAuthenticatedUserGoal(healthMetricId, value) {
    return {
        type: UPDATE_AUTHENTICATED_USER_GOAL,
        payload: { healthMetricId, value }
    }
}

export function updateUserGoals(goalValues) {
    return async (dispatch, getState) => {
        const accessToken = getState().user.session.accessToken;
        const heroApiClient = createHeroApiClient(accessToken);
        return Promise.all(Object.keys(goalValues).map(healthMetricId => {
            return heroApiClient.authenticatedUser.goals.update(healthMetricId, goalValues[healthMetricId])
                .then(() => dispatch(updateAuthenticatedUserGoal(healthMetricId, goalValues[healthMetricId])))
                .catch(error => dispatch(createError(error.message)))
        }))
    }
}

import React, { useState, useEffect } from "react";

// Redux
import { connect } from "react-redux";
import { createHeroApiClient,  EngagementEvent } from '../../hero-api-client';

// Redux Actions
import dataStores from "../../redux/reducers/dataStores";
import {sendCommand} from "../../redux/actions/commands";

// Bootstrap
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Hero Componenents
import HeroOutlineButton from "../../common/Buttons/OutlineButton";

// Other imports
// hero Core API
import { NutritionApi } from "hero-api-client";


const NuApi = new NutritionApi({ basePath: process.env.REACT_APP_API_BASE_URL });

function FoodServingSelection(props) {
  // This is the object that will be submitted to the API when adding food
  const [itemToAdd, setItemToAdd] = useState({
    foodId: props.foodId,
    entryName: props.entryName,
    units: 1,
    servingId: props.servings[0].serving_id,
    forDate: props.forDate,
    meal: props.meal
  });

  useEffect(() => {
    // itemToAdd.entryName = props.entryName;
  }, []);

  function updateItem({ target }) {
    setItemToAdd({ ...itemToAdd, [target.name]: target.value });
  }

  function saveItem() {
    props.heroApiClient.createEngagementEvent(EngagementEvent.ADD_CONSUMED_FOOD)
    NuApi.foodConsumedAdd(itemToAdd, {
      headers: { "hero-token": props.heroToken }
    }).then(res => {
      props.foodAddedComplete();
    });
  }

  function GetMealSelector() {
    if (props.hideMealSelection) {
      return;
    }
    return (
      <Row>
        <Col xs={4}>Meal</Col>
        <Col xs={6}>
          <select name="meal" onChange={updateItem} value={itemToAdd.meal}>
            <option value="Breakfast">Breakfast</option>
            <option value="Lunch">Lunch</option>
            <option value="Dinner">Dinner</option>
            <option value="Other">Snacks/Other</option>
          </select>
        </Col>
      </Row>
    );
  }

  return (
    <div>
      <Row>
        <Col xs={12}><h2 className="mb-2">{props.entryName}</h2></Col>
      </Row>
      <Row className="mb-2 mt-4">
        <Col xs={4}>
          <span className="text__grey">Description</span>
        </Col>
        <Col xs={8}>
          <input
            type="text"
            className="input__base w-100"
            name="entryName"
            value={itemToAdd.entryName}
            onChange={updateItem}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={4}>
          <span className="text__grey">Quantity</span>
        </Col>
        <Col xs={8}>
          <input
            className="input__base w-100 mb-2"
            type="text"
            name="units"
            value={itemToAdd.units}
            onChange={updateItem}
          />
          <select
            className="select w-100 mb-2"
            name="servingId"
            onChange={updateItem}
            value={itemToAdd.servingId}
          >
            {props.servings.map(item => {
              return (
                <option key={item.serving_id} value={item.serving_id}>
                  {item.measurement_description}{" "}
                </option>
              );
            })}
          </select>
        </Col>
      </Row>

      {GetMealSelector()}

      <Row className="mt-3 pr-3">
        <Col/>
        <Col xs={2}>
          <Button className="mr-3" variant="primary-text" onClick={props.foodCancel ? props.foodCancel : props.foodAddedComplete}>
            Cancel
          </Button>
        </Col>
        <Col xs={2}>
          <Button variant={"primary"} onClick={saveItem}>
            Add
          </Button>
        </Col>
      </Row>
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    heroToken: state.appObject[dataStores.DELEGATED_PERMISSIONS].coachToken ? state.appObject[dataStores.DELEGATED_PERMISSIONS].coachToken : state.appObject[dataStores.HEADCOACH_PERMISSIONS].coachToken ? state.appObject[dataStores.HEADCOACH_PERMISSIONS].coachToken : state.user.session.accessToken,
    feed: state.feed,
    foodId: ownProps.foodId,
    entryName: ownProps.entryName,
    meal: ownProps.meal,
    servings: ownProps.servings,
    forDate: ownProps.forDate,
    foodAddedComplete: ownProps.foodAddedComplete,
    hideMealSelection: ownProps.hideMealSelection,
    heroApiClient: createHeroApiClient(state.user.session.accessToken),
    foodCancel: ownProps.foodCancel
  };
}

export default connect(
  mapStateToProps
)(FoodServingSelection);

import React, { Component } from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
// import { G } from 'react-native-svg';
import { VictoryAnimation, VictoryLabel } from 'victory';

export class FlyoutContent extends React.Component {
    render() {
      const { x, y, datum: { label,y:_y,type } } = this.props;
      return (
          <div style={styles.tooltipRow}>
              <span style={styles.tooltipTextLabel}>
                  {label}
              </span>
              <span style={styles.tooltipText}>
                  {typeof _y === 'number' ? window.Utils.isDurationHealthMetric(type) ? window.Utils.formatDurationHealthMetric(type, _y) : window.Utils.round(_y,window.Utils.getDPForHealthMetricType(type)) : _y}
              </span>
          </div>
      )
    }
  }

export class Flyout extends Component {
    render() {
      const { x, y, datum: { label,y:_y } } = this.props;
      return (
        <div style={styles.tooltip}>
            <FlyoutContent {...this.props}/>
        </div>
      );
    }
  }

const victor = require('victor');

export default class extends Component {
  static displayName = "PieLabel";

  render() {
    const { datum,  slice, selectedSlice, showPercentageLabel, pieChartHeight } = this.props;
    // const amountStyle = Object.assign({}, style, { fontWeight: 'bold', fontSize: 14 });

    const origin = new victor(pieChartHeight / 2, pieChartHeight / 2);

    let labelRadius = this.props.outerRadius - ((this.props.outerRadius - this.props.innerRadius) / 2);
    const selected = selectedSlice && selectedSlice._id === datum._id;
    if (selected) {
      labelRadius += 10;
    }

    // Calculate the mid point angle of the background slice
    const midPointAngle = slice.startAngle + ((slice.endAngle - slice.startAngle) / 2)

    const labelPos = {
      x: origin.x + labelRadius * Math.sin(midPointAngle),
      y: origin.y + labelRadius * -Math.cos(midPointAngle)
    }

    return (
        <g>
            <VictoryAnimation
              duration={1000}
              easing="circle"
              delay={0}
              data={{ x: labelPos ? labelPos.x : this.props.x, y: labelPos ? labelPos.y : this.props.y, opacity: selected ? 1 : 0 }}
            >
                {({ x, y, opacity }) => {{
                  const tooltip = (
                    <foreignObject x={x * 0.5} y={y} width="200" height="20" display={opacity === 0 ? 'none' : ''}>
                      <div style={{ position:"absolute", opacity }}>
                          <div style={{ width:200 }}>
                              <Flyout datum={{ label:datum._id, y:datum.recordedValue, type: datum.healthMetricType }}/>
                          </div>
                      </div>
                    </foreignObject>
                  )
                  if (showPercentageLabel) {
                    return (
                        <>
                            <VictoryLabel
                              {...this.props}
                              x={x} y={y}
                              textAnchor="middle"
                              verticalAnchor="middle"
                              text={Math.round(datum.y) + '%'}
                              style={{ ...this.props.style, fill: this.props.fill|| 'white' }}
                              angle={0}
                            />
                            {tooltip}
                        </>
                    )
                  }
                  return tooltip;
                }}}
            </VictoryAnimation>
        </g>
    )
  }
}

const styles = {
    tooltipRow: {
        display: 'flex',
        flexWrap: 'wrap',
        lineHeight: "24px",
        paddingLeft: 5,
        paddingRight: 5,
    },
    tooltip: {
      borderRadius:2,
      backgroundColor:"rgba(89,97,125,0.90)",
      position:"absolute",
      zIndex:10000000
    },
    tooltipTextLabel: {
      height:24,
      color:"rgba(255,255,255,.4)",
      fontSize: '0.75rem',
      marginRight: 10,
    },
    tooltipText: {
      height:24,
      color:"white",
      fontSize: '0.75rem',
    }
  };

import React, { useState } from "react";
import { useEffect } from "react";
import "./NewMeeting.scss";
import _ from 'lodash'

export const meetingType = {
    meeting:'meeting',
    availability:'availability',
    myAvailability:'myAvailability'
}
const colours = ['#ED4D28',
'#11DEED',
'#EDA128',
'#05ED9A',
'#8552F0',
'#B8F03A',
'#4656F0'
]

const YourCoaches = ({ coaches, addCoachAvail, removeCoachAvail }) => {
  const [selectedCoaches, setSelectedCoaches] = useState([]);

  const handleCheckboxClick = (coach) => {
    if (selectedCoaches.includes(coach)) {
      setSelectedCoaches(selectedCoaches.filter((c) => c !== coach));
      removeCoachAvail(coach.userId);
      
    } else {
      setSelectedCoaches([...selectedCoaches, coach]);
      var color = _.sample(colours);
      var availabilities = coach.availability.map((avail) => {
        avail.start = new Date(avail.start);
        avail.end = new Date(avail.end);
        avail.userId = coach.userId;
        avail.title = 'Availability: ' + coach.firstName + ' ' + coach.lastName;
        avail.photoUrl = coach.photoUrl;
        avail.bgColor = color;
        avail.type = meetingType.availability;
        
        return avail;
      });
      addCoachAvail(availabilities);

    }
  };
  return (
    <>
      {coaches && coaches.coaches && coaches.coaches.length && (
        <div className="top-menu-bar">
          <p className="top-menu-bar__title">
            Select a coach to show their availability
          </p>
          <ul className="top-menu-bar__list">
            {coaches.coaches?.map((coach) => (
              <li key={coach.id}>
                <label>
                <img
                  className="top-menu-bar__image"
                  src={
                    coach.photoUrl
                      ? coach.photoUrl
                      : `https://eu.ui-avatars.com/api?name=` +
                        coach.firstName +
                        coach.lastName
                  }
                  alt={`${coach.firstName} ${coach.lastName}`}
                />
                <span className="top-menu-bar__name">
                  {coach.firstName} {coach.lastName}{" "}
                  <input
                    type="checkbox"
                    disabled={!coach.availability.length}
                    className="top-menu-bar__checkbox"
                    onClick={() => handleCheckboxClick(coach)}
                  />
                  <p>Available future sessions: {coach.availability.length}</p>
                </span>
                </label>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default YourCoaches;

import React, { useState } from "react";
import { useEffect } from "react";
import "./NewMeeting.scss";
import { addMinutes } from "../../assets/methods/timeManipulation";
import moment from "moment";
import { round } from "lodash";

const sessionLengthOptions = [10, 15, 30, 60, 90, 120];

const AvailabilityForm = (props) => {
  const [startTime, setStartTime] = useState(props.startTime);
  const [endTime, setEndTime] = useState(props.endTime);
  const [sessionLength, setSessionLength] = useState(sessionLengthOptions[2]);
  const [startDate, setStartDate] = useState(props.startDate);
  const [endDate, setEndDate] = useState(props.endDate);
  const [scheduleView, setScheduleView] = useState(false);
  const [daysOfWeek, setDaysOfWeek] = useState([
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
  ]);

  const writeStartTime = (time) => {
    const endTimeNum = new Date(`1970-01-01T${endTime}:00`).getTime();
    const startTimeNum = new Date(`1970-01-01T${time}:00`).getTime();
    if (endTimeNum >= startTimeNum) {
      setStartTime(time);
    } else {
      setStartTime(time);
      setEndTime(addMinutes(time, sessionLength));
    }
  };
  const writeEndTime = (time) => {
    const endTimeValue = moment(`1970-01-01 ${time}`, "YYYY-MM-DD HH:mm");
    const startTimeValue = moment(
      `1970-01-01 ${startTime}`,
      "YYYY-MM-DD HH:mm"
    );
    if (endTimeValue.isAfter(startTimeValue)) {
      const roundedMinutes =
        Math.ceil(
          endTimeValue.diff(startTimeValue, "minutes") / sessionLength
        ) * sessionLength;
      const newEndTime = startTimeValue
        .clone()
        .add(roundedMinutes, "minutes")
        .format("HH:mm");
      setEndTime(newEndTime);
    } else {
      setEndTime(time);
      if(endTimeValue.isAfter(endTimeValue.clone().hour(2))){
        setStartTime(addMinutes(time, -sessionLength));
      }
      
    }
  };

  useEffect(() => {
    setStartTime(props.startTime);
    setEndTime(props.endTime);
    setStartDate(props.startDate);
    if (props.endDate) {
      setEndDate(props.endDate);
      setScheduleView(true);
    } else {
      setScheduleView(false);
    }
  }, [props]);
  const handleStartTimeChange = (event) => {
    writeStartTime(event.target.value);
  };

  const handleEndTimeChange = (event) => {
    writeEndTime(event.target.value);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleDaysOfWeekChange = (event) => {
    const days = [...daysOfWeek];
    if (event.target.checked) {
      days.push(event.target.value);
    } else {
      const index = days.indexOf(event.target.value);
      if (index > -1) {
        days.splice(index, 1);
      }
    }
    setDaysOfWeek(days);
  };

  const handleSubmit = (event) => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    event.preventDefault();
    if (scheduleView) {
      var end = new Date(endDate);
      for (
        let date = new Date(startDate);
        date <= end;
        date.setUTCDate(date.getUTCDate() + 1)
      ) {
        if (daysOfWeek.includes(days[date.getUTCDay()])) {
          var justDate = date.toISOString().slice(0, 10);
          var start = justDate + "T" + startTime;
          var finish = justDate + "T" + endTime;
          props.heroApiClient.createAvailability(start, finish, sessionLength);
        }
      }
    } else {
      var start = startDate + "T" + startTime;
      var end = startDate + "T" + endTime;
      props.heroApiClient.createAvailability(start, end, sessionLength);
    }
    props.close(true);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Session Length:
        <select
         required
          value={sessionLength}
          onChange={(event) => setSessionLength(event.target.value)}
        >
          {sessionLengthOptions.map((length) => (
            <option key={length} value={length}>
              {length}
            </option>
          ))}
        </select>
        mins
      </label>
      <br />
      <div className="d-flex">
        <label>
          Start Time:
          <input
            required
            type="time"
            value={startTime}
            onChange={handleStartTimeChange}
          />
        </label>
        <label>
          End Time:
          <input
            required
            type="time"
            value={endTime}
            pattern="[0-9]{2}:[0-(5)]{1}[0]"
            onChange={handleEndTimeChange}
          />
        </label>
      </div>
      <br />
      
      <label>
        {scheduleView ? "Start Date" : "Date"}
        <input required type="date" value={startDate} onChange={handleStartDateChange} />
      </label>
      <label className="align-checkbox">
        Make this a regular session:
        <input
          type="checkbox"
          checked={scheduleView}
          onChange={() => {
            setScheduleView(!scheduleView);
          }}
        />
      </label>
      {scheduleView && (
        <>
          <label>
            End Date:
            <input
              required={scheduleView}
              type="date"
              value={endDate}
              min={startDate}
              onChange={handleEndDateChange}
            />
          </label>
          <br />

          <label>Days of Week:</label>
          <br />
          <div className="day-of-week-container">
            <label>
              <input
                type="checkbox"
                checked={daysOfWeek.includes("Monday")}
                value="Monday"
                onChange={handleDaysOfWeekChange}
              />
              Monday
            </label>
            <br />
            <label>
              <input
                type="checkbox"
                checked={daysOfWeek.includes("Tuesday")}
                value="Tuesday"
                onChange={handleDaysOfWeekChange}
              />
              Tuesday
            </label>
            <br />
            <label>
              <input
                type="checkbox"
                value="Wednesday"
                checked={daysOfWeek.includes("Wednesday")}
                onChange={handleDaysOfWeekChange}
              />
              Wednesday
            </label>
            <br />
            <label>
              <input
                type="checkbox"
                value="Thursday"
                checked={daysOfWeek.includes("Thursday")}
                onChange={handleDaysOfWeekChange}
              />
              Thursday
            </label>
            <br />
            <label>
              <input
                type="checkbox"
                checked={daysOfWeek.includes("Friday")}
                value="Friday"
                onChange={handleDaysOfWeekChange}
              />
              Friday
            </label>
            <br />
            <label>
              <input
                type="checkbox"
                value="Saturday"
                checked={daysOfWeek.includes("Saturday")}
                onChange={handleDaysOfWeekChange}
              />
              Saturday
            </label>
            <br />
            <label>
              <input
                type="checkbox"
                value="Sunday"
                checked={daysOfWeek.includes("Sunday")}
                onChange={handleDaysOfWeekChange}
              />
              Sunday
            </label>
          </div>
        </>
      )}

      <br />
      <button type="submit">Confirm Availability</button>
    </form>
  );
};

export default AvailabilityForm;

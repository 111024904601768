import { createHeroApiClient } from '../../hero-api-client';
import { createError } from './errors'

export const REQUEST_HEALTH_METRICS = 'REQUEST_HEALTH_METRICS'
function requestHealthMetrics() {
    return {
        type: REQUEST_HEALTH_METRICS
    }
}

export const RECEIVE_HEALTH_METRICS = 'RECEIVE_HEALTH_METRICS'
function receiveHealthMetrics(healthMetrics) {
    return {
        type: RECEIVE_HEALTH_METRICS,
        payload: healthMetrics
    }
}

function doFetchHealthMetrics(accessToken) {
    return dispatch => {
        dispatch(requestHealthMetrics())
        createHeroApiClient(accessToken).getHealthMetrics()
            .then(healthMetrics => dispatch(receiveHealthMetrics(healthMetrics)))
            .catch(error => dispatch(createError(error.message)))
    }
}

function shouldFetchHealthMetrics(state) {
    const healthMetrics = state.healthMetrics
    if (!healthMetrics) {
        return true
    } else if (healthMetrics.isFetching) {
        return false
    } else {
        return !healthMetrics.isValid
    }
}

export function fetchHealthMetrics() {
    return (dispatch, getState) => {
        const state = getState()
        if (shouldFetchHealthMetrics(state)) {
            const accessToken = state.user.session.accessToken
            return dispatch(doFetchHealthMetrics(accessToken))
        } else {
            return Promise.resolve()
        }
    }
}


import axios from 'axios';

// Action types
const FETCH_ARTICLES_REQUEST = 'FETCH_ARTICLES_REQUEST';
const FETCH_ARTICLES_SUCCESS = 'FETCH_ARTICLES_SUCCESS';
const FETCH_ARTICLE_SUCCESS = 'FETCH_ARTICLE_SUCCESS';
const FETCH_ARTICLES_FAILURE = 'FETCH_ARTICLES_FAILURE';

// Action creators
const fetchArticlesRequest = () => ({
  type: FETCH_ARTICLES_REQUEST,
});

const fetchArticlesSuccess = (articles) => ({
  type: FETCH_ARTICLES_SUCCESS,
  payload: articles,
});

const fetchArticleSuccess = (article) => ({
    type: FETCH_ARTICLE_SUCCESS,
    payload: article,
});

const fetchArticlesFailure = (error) => ({
  type: FETCH_ARTICLES_FAILURE,
  payload: error,
});



// Async action creator
export const fetchArticles = (companyId) => async (dispatch) => {
  dispatch(fetchArticlesRequest());
  try {
    const response = await axios.get('https://9y7aulz4uc.execute-api.eu-west-1.amazonaws.com/Prod/');
    var articles = response.data;
    articles = articles.sort(function(a, b) {
        return a.firstPublished < b.firstPublished ? 1 : -1;
    })
    .filter(article=>!article.companies || article.companies.length === 0 || article.companies.includes(String(companyId)));
    dispatch(fetchArticlesSuccess(articles));
  } catch (error) {
    dispatch(fetchArticlesFailure(error.message));
  }
};
export const fetchArticle = (id) => async (dispatch) => {
    dispatch(fetchArticlesRequest());
    try {
        const response = await axios.get(`https://9y7aulz4uc.execute-api.eu-west-1.amazonaws.com/Prod/${id}`);
        dispatch(fetchArticleSuccess(response.data));
    } catch (error) {
        dispatch(fetchArticlesFailure(error.message));
    }
};


// Reducer
const initialState = {
  articles: [],
  article: {},
  loading: false,
  error: null,
};

const articleContentReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ARTICLES_REQUEST:
      return {
        ...state,
        loadingArticles: true,
        error: null,
      };
    case FETCH_ARTICLES_SUCCESS:
      return {
        ...state,
        articles: action.payload,
        loadingArticles: false,
        errorArticles: null,
      };
    case FETCH_ARTICLE_SUCCESS:
        return {
            ...state,
            article: action.payload,
            loadingArticles: false,
            errorArticles: null,
        };
    case FETCH_ARTICLES_FAILURE:
      return {
        ...state,
        loadingArticles: false,
        errorArticles: action.payload,
      };
    default:
      return state;
  }
};

export default articleContentReducer;


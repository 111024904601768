import React, { useState, useRef,useEffect } from "react";
import { Caret } from "amazon-chime-sdk-component-library-react";

function MuteKickButton({onMute,onKick}) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const handleOutsideClick = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  }
  const kick = () => {
    onKick();
    setIsOpen(false);
  }
  const mute = () => {
    onMute();
    setIsOpen(false);
  }
  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleOutsideClick);
    } else {
      document.removeEventListener('click', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
},[isOpen])
  return (
    <span style={{width:30}} data-testid="popover">
    <div ref={ref} className="sc-dkrFOg hSvpyl noFocus">
      {/* <button 
        className="ch-control-bar-item-caret sc-gswNZR ktCRLg" 
        aria-label="Mute/Kick" 
        aria-haspopup="true" 
        aria-expanded={isOpen}
        
      > */}
        <span class="ch-icon" data-testid="button-icon">
        <Caret width={35} onClick={() => setIsOpen(!isOpen)} direction="down"/>
        </span>
      {/* </button> */}
      {isOpen && (
        <ul className="sc-ipEyDJ wwZSb ch-popover-menu" >
          <li className="jdcsjj">
            <button onClick={mute} className="ch-content">Mute</button>
          </li>
          <li className="jdcsjj">
            <button onClick={kick} className="ch-content">Kick</button>
          </li>
        </ul>
      )}
    </div></span>
  );
}

export default MuteKickButton;

import React from 'react';
import HeadImage from './HeadImage';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Carousel, Card } from 'react-bootstrap';

const SimilarArticles = ({ tags, articles, excludeId }) => {
  const sortedArticlesfilteredArticles = articles.filter(article =>
    article.tags.some(tag => tags.includes(tag)) && article.id !== excludeId
  );
  const chunkedArticles = chunkArray(sortedArticlesfilteredArticles.sort((a, b) => {
    const matchingTagsA = a.tags.filter(tag => tags.includes(tag)).length;
    const matchingTagsB = b.tags.filter(tag => tags.includes(tag)).length;

    if (matchingTagsA !== matchingTagsB) {
      return matchingTagsB - matchingTagsA;
    }

    if (Math.random() > 0.1) {
      return new Date(b.datePublished) - new Date(a.datePublished);
    }
    else{
        return Math.random() - 0.5;
    }
  }).slice(0, 9), 3);
  if (chunkedArticles.length === 0) return null;
  return (
    <div style={{marginTop:100}}>
      <h2 className="text-2xl font-bold bold mb-4 text-black">Similar Articles</h2>
      <Carousel interval={8000} wrap={true} controls={true} indicators={false}>
      {chunkedArticles.map((articleGroup, index) => (
        <Carousel.Item key={index}>
          <div className="d-flex">
            {articleGroup.map((article) => (
              <Link key={article.id} to={`/article/${article.id}`} className="flex-grow-1">
                <div className="card card-image hero-card-parent mb-30">
                <div
                  className="hero-card-child"
                  style={{ backgroundImage: `url(${article.headImage})`, zIndex: "1" }}>
                </div>
                <div className="card-title__sub"><p>{article.title}</p></div>
              </div>
              </Link>
            ))}
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
      {/* <Carousel interval={1000} wrap={true} controls={true}>
                       
    
      {sortedArticles.map(article => (
        <Carousel.Item key={article.id}>
          <Link to={`/article/${article.id}`}>
              <div className="card card-image hero-card-parent mb-30">
                <div
                  className="hero-card-child"
                  style={{ backgroundImage: `url(${article.headImage})`, zIndex: "1" }}>
                </div>
                <div className="card-title"><p>{article.title}</p></div>
              </div>
          </Link>
        </Carousel.Item>
      ))} 
       </Carousel> */}
    </div>
  );
};


function chunkArray(arr, size) {
  return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  );
}

export default SimilarArticles;

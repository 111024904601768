import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux';
import { fetchChatMessages, fetchReadTimes } from '../../redux/actions/chatMessages.js';
import SendChatMessageInput from './SendChatMessageInput';
import moment from 'moment';
import shortid from 'shortid';
import { Link, useParams } from "react-router-dom";
import {CloudArrowDownIcon, MinusCircleIcon, CheckIcon} from '@heroicons/react/24/solid'



function ChatMessages({ chatId, name, chatMessages,user, users, dispatch, readReceipts}) {

  const [messages, setMessages] = useState([]);
  const [receipts, setReceipts] = useState([])
  const [firstMessageDate, setFirstMessageDate] = useState(null);
  const [lastMessageDate, setLastMessageDate] = useState(null);
  const [intervalId, setIntervalId] = useState(null);

  const messageContainerLastElement = useRef(null);
  useEffect(() => {
    refresh();
    const interval = setInterval(() => {
        refresh();
      }, 10000);
      return () => clearInterval(interval);
    
  }, [chatId]);
  const refresh = () => {
    dispatch(fetchChatMessages(chatId, { latest: true }));
    dispatch(fetchReadTimes(chatId));
  }
  useEffect(() => {
    const state = chatMessages[chatId];
    if(!state || !state.items) {
      return;
    }
    setMessages(state.items);
  }, [chatMessages]);

  useEffect(() => {
    const state = readReceipts?readReceipts[chatId]:null;
    if(!state || !state.items) {
      return;
    }
    setReceipts(state.items);
  }, [readReceipts]);

  useEffect(() => {
    return () => window.clearInterval(intervalId);
  }, [intervalId])


  const readReceipt = (created) => {
    const readMessage = receipts.filter(x=>moment(x.readMarkerTimestamp).isAfter(created));
    if(readMessage.length == 0){
      return <span className='tooltippop'><span class="tooltiptext">Unread</span><MinusCircleIcon style={{height:'15px',color:'grey'}} ></MinusCircleIcon></span>
    }
    if(readMessage.length<receipts.length){
      return <span className='tooltippop'><span class="tooltiptext">Read by some</span><CheckIcon style={{height:'15px',color:'grey'}} ></CheckIcon></span>
    }
    return <span className='tooltippop'><span class="tooltiptext">Read by everyone</span><CheckIcon  style={{height:'15px',color:'green'}}></CheckIcon></span>
  }

  useEffect(() => {
    if(!lastMessageDate){
      return;
    }
    
    window.clearInterval(intervalId);
    const id = window.setInterval(() => {
      
      dispatch(fetchChatMessages(chatId, { after: lastMessageDate }));
      dispatch(fetchReadTimes(chatId));

  }, 3000);
    setIntervalId(id);
  }, [lastMessageDate]);

  useEffect(() => {

    if(!messages || messages.length == 0) {
      dispatch(fetchChatMessages(chatId, { limit: 20 }));
      dispatch(fetchReadTimes(chatId));
      return;
    }

    if(messages.length > 0){
      setFirstMessageDate(messages[0].sentAt);
      setLastMessageDate(messages[messages.length-1].sentAt);

    }
  }, [messages]);

  useEffect(() => {
    if(!messageContainerLastElement.current) {
      return;
    }
    messageContainerLastElement.current.scrollIntoView()

  }, [messages])

  const handleLoadPreviousMessages = (event) => {
    event.preventDefault();
    dispatch(fetchChatMessages(chatId, { limit: 20, before: firstMessageDate }));
    dispatch(fetchReadTimes(chatId));
  };
  const resourceLink = (obj) => {
    if(obj.type=='resource'){
    return(
        <Link className="message-text" style={{width:400}}  to={{pathname:`/${obj.full_slug}`}}>
            <h4 className="message-text">Article Shared</h4>
            <img src={obj.image} width={400}  />
            <div className="message-text">{obj.title}</div>
        </Link>
    )
}
else if(obj.type=='meal'){
    var description = obj.description.replace(/&amp;/g , "&");
    if(description.length>80){description = description.substring(0,80) + '...'}
    return(
        <Link className="message-text" style={{width:300}} target='_blank' rel='noopener noreferrer'  to={{pathname:`/nutrition/meal/${obj.mealId}`}}>
            <h4 className="message-text">Recipe Shared</h4>
            
             <img className="message-image" src={obj.image} width={300} height={200}  />
            <div className="message-text">{obj.title}
            <p>{description}</p>
            </div>
        </Link>
    )
}
    if(obj.fakeName){
        return(
       <a href={obj.uri} download={obj.fakeName} target='_blank' rel='noopener noreferrer' >
            <h4 className="message-text">File Shared</h4>
            <div className="message-text">
                <CloudArrowDownIcon color='grey' size={20} />
                <p className="message-text">{obj.fakeName}</p>
            </div>
        </a>
        )
    }
  }

  return (
    <>
          {/* <div id="chat-message-fetch" onClick={handleLoadPreviousMessages} >
            <a onClick={handleLoadPreviousMessages}>Click to load previous messages</a>
          </div> */}
          <div className="chat-container msg_history">
          <div id="chat-message-list" className="chat-message-list">
        {messages.map((message, index) => {
            var messageObj;
            if(message.content && message.content.startsWith('{')){
               try {
                    messageObj = JSON.parse(message.content)
                }
                catch(e){
                    console.error(e);
                }
            }
            
            const arnArr = message.sender.arn.split('/');
            const senderList = users.filter(x => x.id === arnArr[arnArr.length -1]);
            const sender = senderList.length?senderList[0]:null;
            var decodedMessage = '';

           try{decodedMessage = decodeURIComponent(message.content);}
           catch{decodedMessage= message.content;}
          if(messageObj && messageObj.uri){
            decodedMessage = decodeURIComponent(messageObj.uri);
          }
          const isUrl = decodedMessage.match(window.Utils.urlRegex);
          let isImageUrl = false;
          if (isUrl) {
            const urlWithoutParameters = decodedMessage.split('?')[0];
            isImageUrl = ['gif', 'jpg', 'jpeg', 'png'].includes(urlWithoutParameters.substr(urlWithoutParameters.lastIndexOf('.') + 1));
          }
          return (
          <div key={`chat-msg-id-${index}`}>

              {
                sender.id == user.profile.id
                ? (
                    <div className="message-row you-message">
                        <div className="message-content">
                            {(messageObj && !isImageUrl)?resourceLink(messageObj):isImageUrl ? <img className="message-image" src={decodedMessage} /> : <div className="message-text">{decodedMessage}</div>}
                            
                            <div className="message-time">{readReceipt(message.createdTimestamp)} | {moment(message.createdTimestamp).format("HH:mm")} | {moment(message.createdTimestamp).format("MMMM DD")}</div>
                        </div>
                    </div>
                ) : (
                    <div className="message-row other-message">
                        <div className="message-content">
                            <img className="avatar__img c-avatar c-avatar--menu c-avatar--xsmall dropdown-toggle" src={sender.avatarUrl || `https://eu.ui-avatars.com/api?name=${message.sender.name}`} alt="message.sender.name" title={message.sender.name} />
                            {(messageObj && !isImageUrl)?resourceLink(messageObj):isImageUrl ? <img className="message-image" src={decodedMessage} /> : <div className="message-text">{decodedMessage}</div>}
                            <div className="message-time">{moment(message.createdTimestamp).format("HH:mm")} | {moment(message.createdTimestamp).format("MMMM DD")}</div>
                        </div>
                    </div>
                )
              }
               <div ref={messageContainerLastElement}></div>
            </div>
          );
        }) }
          </div>
        </div>
        <div className="input_msg_write">
            <SendChatMessageInput refresh={refresh} chatId={chatId} name={name}/>
        </div>
</>

  );
}

function mapStateToProps(state) {
  return {
    chatMessages: state.chatMessages,
    users: state.users.items,
    user:state.user,
    readReceipts: state.reads
  }
}

export default connect(mapStateToProps)(ChatMessages);

const createGroupClient = (sendRequest) => (groupId) => {
    const createMessage = async (message) => {
        const path = `/groups/${groupId}/messages`;
        const method = 'POST';
        const body = message;
        return await sendRequest(path, method, body);
    };

    const getMessages = async ({ before, after, limit, latest }) => {
        const path = `/groups/${groupId}/messages?before=${before || ''}&after=${after || ''}&limit=${limit || ''}&latest=${latest || ''}`;
        return await sendRequest(path);
    };

    const createInvitation = async (userIds) => {
        const path = `/groups/${groupId}/invitations`;
        const method = 'POST';
        const body = { userIds: userIds };
        return await sendRequest(path, method, body);
    }

    const leave = async () => {
        const path = `/groups/${groupId}/user`;
        const method = 'DELETE';
        return await sendRequest(path, method);
    };

    const getGroupUser = async () => {
        const path = `/groups/${groupId}/user`;
        return await sendRequest(path);
    }

    const updateGroupUser = async (groupUser) => {
        const path = `/groups/${groupId}/user`;
        const method = 'PUT';
        return await sendRequest(path, method, groupUser);
    }

    return {
        invitations: {
            create: createInvitation
        },
        messages: {
            create: createMessage,
            get: getMessages
        },
        leave,
        user: {
            get: getGroupUser,
            update: updateGroupUser,
        }
    };
};

export const createGroupsClient = (sendRequest) => {
    const create = async (group) => {
        const path = `/groups`;
        const method = 'POST';
        const body = group;
        return await sendRequest(path, method, body);
    };

    const list = async () => {
        const path = `/groups`;
        return await sendRequest(path);
    };

    return {
        create,
        list,
        createGroupClient: createGroupClient(sendRequest)
    };
};
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import DataProtectionCheckbox from "./DataProtectionCheckbox";
import { fetchDataProtection, updateDataProtection } from "../../redux/actions/dataProtection";
import { Row, Col, Card, Container } from "react-bootstrap";

function DataProtectionPreferences(props) {
    const [dataProtection, setDataProtection] = useState(props.dataProtection)

    useEffect(() => {
        if (!props.dataProtection || !props.dataProtection.isValid) {
            props.dispatch(fetchDataProtection())
            return
        }
        setDataProtection(props.dataProtection)
    }, [props.dataProtection])

    const handleSubmit = event => {
        event.preventDefault();

        const segments = Object.keys(dataProtection.value.privacy)
        const audiences = Object.keys(dataProtection.value.privacy[segments[0]])

        props.dispatch(updateDataProtection({
            notifications: dataProtection.value.notifications,
            communications: dataProtection.value.communications,
            privacy: segments.flatMap(segment =>
                audiences.map(function (audience) {
                    return {
                        segment,
                        audience,
                        visible: dataProtection.value.privacy[segment][audience]
                    }
                })
            )
        }));
    }

    const handleInputChange = event => {
        const value = event.target.checked
        const [section, name] = event.target.name.split('.')
        setDataProtection({
            ...dataProtection,
            value: {
                ...dataProtection.value,
                [section]: {
                    ...dataProtection.value[section],
                    [name]: value
                }
            }
        });
    }

    return dataProtection && dataProtection.isValid && dataProtection.value
        ? (
            <>
                <form onSubmit={handleSubmit}>
                <Container>
                    <Row className="title-container">
                        <Col>
                            <h3 className="mt-5 mb-3">Settings</h3>
                        </Col>
                        <Col>
                            <div className="btn--actions">
                                <button className="btn btn-success btn--circle mr-2 color-white" type="submit">
                                <i className="far fa-save"></i>
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Container>
                    <PrivacySettings
                        dataProtection={dataProtection}
                        setDataProtection={setDataProtection} />

                    <Card>
                        <Card.Body>
                            <h4 className="mb-3">Communication Preferences</h4>
                            <Row>
                                <Col md={3}>
                                    <DataProtectionCheckbox
                                        label="Marketing"
                                        name="communications.marketing"
                                        value={dataProtection.value.communications.marketing}
                                        handleInputChange={handleInputChange} />
                                </Col>
                                <Col md={3}>
                                    <DataProtectionCheckbox
                                        label="Updates"
                                        name="communications.updates"
                                        value={dataProtection.value.communications.updates}
                                        handleInputChange={handleInputChange} />
                                </Col>
                                <Col md={3}>
                                    <DataProtectionCheckbox
                                        label="Weekly Summary"
                                        name="communications.weeklySummary"
                                        value={dataProtection.value.communications.weeklySummary}
                                        handleInputChange={handleInputChange} />
                                </Col>
                                <Col md={3}>
                                    <DataProtectionCheckbox
                                        label="Monthly Summary"
                                        name="communications.monthlySummary"
                                        value={dataProtection.value.communications.monthlySummary}
                                        handleInputChange={handleInputChange} />
                                </Col>
                                <Col md={3}>
                                    <DataProtectionCheckbox
                                        label="Goals"
                                        name="communications.sendGoalNotifications"
                                        value={dataProtection.value.communications.sendGoalNotifications}
                                        handleInputChange={handleInputChange} />
                                </Col>
                                <Col md={3}>
                                    <DataProtectionCheckbox
                                        label="Group Invites"
                                        name="communications.sendGroupInviteNotifications"
                                        value={dataProtection.value.communications.sendGroupInviteNotifications}
                                        handleInputChange={handleInputChange} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                    <Card>
                        <Card.Body>
                            <h4 className="mb-3">Notification Preferences</h4>
                            <Row>
                                <Col md={3}>
                                    <DataProtectionCheckbox
                                        label="Email"
                                        name="notifications.email"
                                        value={dataProtection.value.notifications.email}
                                        handleInputChange={handleInputChange} />
                                </Col>
                                <Col md={3}>
                                    <DataProtectionCheckbox
                                        label="App"
                                        name="notifications.app"
                                        value={dataProtection.value.notifications.app}
                                        handleInputChange={handleInputChange} />
                                </Col>
                                <Col md={3}>
                                    <DataProtectionCheckbox
                                        label="Browser"
                                        name="notifications.browser"
                                        value={dataProtection.value.notifications.browser}
                                        handleInputChange={handleInputChange} />
                                </Col>
                                <Col md={3}>
                                    <DataProtectionCheckbox
                                        label="SMS"
                                        name="notifications.sms"
                                        value={dataProtection.value.notifications.sms}
                                        handleInputChange={handleInputChange} />
                                </Col>
                                <Col md={3}>
                                    <DataProtectionCheckbox
                                        label="Social Media"
                                        name="notifications.socialMedia"
                                        value={dataProtection.value.notifications.socialMedia}
                                        handleInputChange={handleInputChange} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </form>
            </>
        )
        : null
}

function mapStateToProps(state) {
    const reducer = (accumulator, currentValue) => {
        accumulator[currentValue.segment] = {
            ...accumulator[currentValue.segment],
            [currentValue.audience]: currentValue.visible
        }
        return accumulator
    }

    if (!state.dataProtection.value) {
        return {
            dataProtection: state.dataProtection
        }
    }

    return {
        dataProtection: {
            ...state.dataProtection,
            value: {
                ...state.dataProtection.value,
                privacy: state.dataProtection.value.privacy.reduce(reducer, {})
            }
        }
    }
}

export default connect(mapStateToProps)(DataProtectionPreferences)

function PrivacySettings(props) {
    const setDataProtection = props.setDataProtection
    const dataProtection = props.dataProtection
    const segments = Object.keys(dataProtection.value.privacy)
    const audiences = Object.keys(dataProtection.value.privacy[segments[0]])

    const handleInputChange = event => {
        const [section, segment, audience] = event.target.name.split('.')
        setDataProtection({
            ...dataProtection,
            value: {
                ...dataProtection.value,
                privacy: {
                    ...dataProtection.value.privacy,
                    [segment]: {
                        ...dataProtection.value.privacy[segment],
                        [audience]: event.target.checked
                    }
                }
            }
        })
    }

    return (
        <Card>
            <Card.Body>
                <h4>Privacy Settings</h4>

                <table className="w-100">
                    <tr>
                        <th></th>
                        {audiences.map(audience => (<th>{audience}</th>))}
                    </tr>
                    {segments.map(segment => (
                        <tr>
                            <th>{segment}</th>
                            {audiences.map(audience => (
                                <td>
                                    <DataProtectionCheckbox
                                        label=""
                                        name={`privacy.${segment}.${audience}`}
                                        value={dataProtection.value.privacy[segment][audience]}
                                        handleInputChange={handleInputChange} />
                                </td>
                            ))}
                        </tr>
                    ))}
                </table>
            </Card.Body>
        </Card>
    )
}

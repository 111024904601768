import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { format, addDays, parse, startOfWeek, endOfWeek } from 'date-fns'
import round from "../shared/round";
import { Card, Container, Row, Col } from 'react-bootstrap'
import moodChartPlaceholder from "../assets/images/Bar-Chart.png";
import pageChartPlaceholder from "../assets/images/page-views.png";
import _ from 'lodash'
import {
    LineChart,
    Line,
    PieChart,
    Pie,
    Legend,
    BarChart,
    CartesianGrid,
    XAxis,
    ReferenceLine,
    YAxis,
    Tooltip,
    Bar,
    ResponsiveContainer,
    RadarChart,
    PolarGrid,
    Radar,
    PolarRadiusAxis,
    PolarAngleAxis, ComposedChart, Cell,
} from 'recharts'
import { createError } from '../redux/actions/errors'
import { createHeroApiClient } from '../hero-api-client';
import chartKeyPlaceholder from "../assets/icons/chart-key.png";
import { VictoryLabel, VictoryPie, VictoryTooltip } from "victory";

function insertSpaces(string) {
    string = string.replace(/([a-z])([A-Z])/g, '$1 $2');
    string = string.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
    return string;
}


const getMoodIcon = (moodValue) => {
    if (moodValue <= 1) {
        return `far fa-sad-tear large-icon very-sad`
    } else if (moodValue <= 2) {
        return `far fa-frown large-icon sad`
    } else if (moodValue <= 3) {
        return `far fa-meh large-icon ok`
    } else if (moodValue <= 4) {
        return `far fa-smile large-icon happy`
    }
    return `far fa-grin-squint large-icon very-happy`
}

const getMoodText = (moodValue) => {
    if (moodValue <= 1) {
        return `Very Sad`
    } else if (moodValue <= 2) {
        return `Sad`
    } else if (moodValue <= 3) {
        return `OK`
    } else if (moodValue <= 4) {
        return `Happy`
    }
    return `Very Happy`
}

const CustomYAxisTick = props => {
    return (
            <foreignObject style={{transform:'translate(-10px,-10px)'}} {...props}>
                <i xmlns="http://www.w3.org/1999/xhtml" style={{fontSize:12, position:'relative'}} className={getMoodIcon(props.payload.value)}></i>
            </foreignObject>
    );
};
const PIE_CHART_HEIGHT = 250;



const COLORS = [
    "#15CBCE", "#2DD0D3", "#44D5D8",
    "#5CDBDD", "#73E0E2", "#8BE5E7",
    "#A1EAEB", "#B9F0F0", "#D0F5F5",
    "#E8FAFA"
]


function HrReports(props) {
    const { userId } = props
    const now = new Date(Date.now())
    const [parameters, setParameters] = useState({
        startDate: format(startOfWeek(now,{weekStartsOn: 1}), "yyyy-MM-dd"),
        endDate: format(endOfWeek(now, {weekStartsOn: 1}), "yyyy-MM-dd")
    })
    const [model, setModel] = useState({})
    const [isLoading, setIsLoading] = useState(false)


    function customTooltip(event) {
        if (event.payload && event.payload[0]) {
            let tooltipColor = 'rgba(89,97,125,0.90)';
            const tooltipStyle = {
                backgroundColor: tooltipColor,
                borderRadius: 6,
                border: `1px solid ${tooltipColor}`,
                boxShadow: "1px 1px 3px 0px rgba(0,0,0,0.125)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            };

            const textStyle = {
                color: 'white',
                padding: ".25em .5em",
                margin: "0 0"
            };

            const faintStyle = {
                marginLeft:5,
                color: "rgba(255,255,255,.5)"
            };
            return (
                <div style={tooltipStyle}>
                    <p style={textStyle}>
                        {`${event.payload[0].payload.value}`} <span style={faintStyle}>{getMoodText(event.payload[0].payload.value)}</span>
                    </p>
                </div>
            );
        } else {
            return null;
        }
    }

    function customPagesTooltip(event) {
        if (event.payload && event.payload[0]) {
            let tooltipColor = 'rgba(89,97,125,0.90)';
            const tooltipStyle = {
                backgroundColor: tooltipColor,
                borderRadius: 6,
                border: `1px solid ${tooltipColor}`,
                boxShadow: "1px 1px 3px 0px rgba(0,0,0,0.125)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            };

            const textStyle = {
                color: 'white',
                padding: ".25em .5em",
                margin: "0 0"
            };

            const faintStyle = {
                marginLeft:5,
                color: "rgba(255,255,255,.5)"
            };
            return (
                <div style={tooltipStyle}>
                    <p style={textStyle}>
                        {`${event.payload[0].payload.value}`} <span style={faintStyle}>Page Views</span>
                    </p>
                </div>
            );
        } else {
            return null;
        }
    }

    function customTooltip2(event) {
        const label = event.payload && event.payload[0] && event.payload[0].name
        const value = event.payload && event.payload[0] && event.payload[0].value

        let tooltipColor = 'rgba(89,97,125,0.90)';
        const tooltipStyle = {
            backgroundColor: tooltipColor,
            borderRadius: 6,
            border: `1px solid ${tooltipColor}`,
            boxShadow: "1px 1px 3px 0px rgba(0,0,0,0.125)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
        };

        const textStyle = {
            color: 'white',
            padding: ".25em .5em",
            margin: "0 0"
        };

        const faintStyle = {
            marginLeft:5,
            color: "rgba(255,255,255,.5)"
        };
        return (
            <div style={tooltipStyle}>
                <p style={textStyle}>
                    {`${label}`} <span style={faintStyle}>{value}%</span>
                </p>
            </div>
        );
    }

    useEffect(() => {
        setIsLoading(true);
        props.heroApiClient.getHrReport(parameters.startDate, parameters.endDate, userId)
            .then(response => {
                let bestValue = -1;
                let worstValue = 9999;
                let bestDay;
                let worstDay;
                if (response.averageMoodByDate) {
                    response.averageMoodByDate.map((d) => {
                        if (d.value) {
                            if (d.value > bestValue) {
                                bestDay = { day: format(new Date(d.key), "eeee"), value: d.value }
                                bestValue = d.value;
                            }
                            if (d.value < worstValue) {
                                worstDay = { day: format(new Date(d.key), "eeee"), value: d.value }
                                worstValue = d.value;
                            }
                        }

                    })
                }
                if (response.eventsByType) {
                    let totalEvents = 0;
                    response.eventsByType.map((v) => {
                        totalEvents += v.value;
                    })
                    response.eventsByType.map((v) => {
                        v.displayName = insertSpaces(v.key)
                        v.percent = ((v.value / totalEvents) * 100).toFixed(2)
                    })
                    response.eventsByType = _.sortBy(response.eventsByType, "value").reverse()
                }

                setModel({ ...response, worstDay, bestDay })
            })
            .catch(error => props.dispatch(createError(error.message)))
            .finally(() => setIsLoading(false));
    }, [parameters]);

    function handleInputChange(event) {
        const result = {
            ...parameters,
            [event.target.name]: event.target.value
        }

        if (new Date(result.endDate) < new Date(result.startDate)) {
            return
        }

        setParameters(result)
    }



    const moodComponent = () => {
        if (!model.averageMood) {
            return ""
        }
        return (
            <div>
                <i className={getMoodIcon(model.averageMood) + " large-icon"}></i>
                <p className={'muted mt-3'}>Average Mood</p>
                <h6>{getMoodText(model.averageMood)} ({parseInt(model.averageMood)})</h6>
            </div>
        )
    }
    const analyticsData = model && model.eventsByType && model.eventsByType.slice(0,10);
    return (
        <Container>
            <div className={'d-flex flex-row mb-5'}>
                <h1 className="page__title mb-0 mt-0">HR Reports</h1>
                {/*<div className={'ml-5'}>*/}
                {/*    <div className={'segmented-control'}>*/}
                {/*        <btn className={'segmented-control_item active'}>Active Users</btn>*/}
                {/*        <btn className={'segmented-control_item '}>All Users</btn>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <Row className="ml-auto">
                    <input className="input__datepicker" type="date" name="startDate" onChange={handleInputChange}
                           value={parameters.startDate} disabled={isLoading}
                    />
                    <input className="input__datepicker ml-md-3" type="date" name="endDate" onChange={handleInputChange}
                           value={parameters.endDate} disabled={isLoading}
                    />
                </Row>
            </div>

            <Row>
                <div className={' col-md-9'}>
                    <div className={'card p-4'}>
                        <h3>Company Happiness</h3>
                        <Row className={'mt-4 align-items-end'}>
                            <Col className={'text-center'}>
                                {moodComponent()}
                            </Col>
                            <div>
                                {model.averageMoodByDate && (

                                    <div>
                                        <BarChart width={400} height={156} data={model.averageMoodByDate}>
                                            <XAxis
                                                dy={3}
                                                fontFamily="sans-serif"
                                                stroke="#bebebe"
                                                interval={0}
                                                strokeWidth={1}
                                                tick={{ fontSize: "0.5rem" }}
                                                tickLine={{ stroke: 'transparent' }}
                                                tickFormatter={(v,i)=>{
                                                    const date = parse(v, 'MM/dd/yyyy', new Date())
                                                    return format(date,"E")
                                                }}
                                                dataKey="key"/>
                                            <ReferenceLine strokeWidth={2} y={model.averageMood} label="" stroke="#709DB7" strokeDasharray="3 3" />
                                           
                                            <YAxis
                                                width={50}
                                                interval={0}
                                                stroke="transparent"
                                                dataKey="value"
                                                tickFormatter={(v)=>getMoodIcon(v)}
                                                ticks={[1,2,3,4,5]}
                                                tickLine={{ stroke: 'transparent' }}
                                                tick={(
                                                    <CustomYAxisTick/>
                                                )}
                                                domain={[1,2,3,4,5]}
                                                
                                            />
                                            <Tooltip content={customTooltip} />
                                            <Bar barSize={25} dataKey="value" fill="#15CBCE"/>
                                        </BarChart>
                                    </div>
                                )}
                            </div>
                            {/*<div style={{width:400,height:150}}>*/}
                            {/*    {*/}
                            {/*        model.averageMoodByDate && (*/}
                            {/*            <div>*/}
                            {/*                    <BarChart width={356} height={136} data={model.averageMoodByDate}>*/}
                            {/*                        <CartesianGrid strokeDasharray="3 3"/>*/}
                            {/*                        <XAxis dataKey="key"/>*/}
                            {/*                        <YAxis dataKey="value"/>*/}
                            {/*                        <Tooltip/>*/}
                            {/*                        <Bar dataKey="value" fill="#15CBCE"/>*/}
                            {/*                    </BarChart>*/}
                            {/*            </div>*/}
                            {/*        )*/}
                            {/*    }*/}
                            {/*</div>*/}
                            <Col className={'space-between ml-4 pb-4'}>
                                    <div className={'d-flex flex-row mb-3'}>
                                        <div className={'badge-container d-flex'}>
                                            <i className={getMoodIcon(5) + " small-icon"}></i>
                                        </div>
                                        <Col>
                                            <p className={'muted'}>Avg. Best Day</p>
                                            <h6>{model.moodBestDay}</h6>
                                        </Col>
                                    </div>

                                    <div className={'d-flex flex-row '}>
                                        <div className={'badge-container d-flex'}>
                                            <i className={getMoodIcon(1) + " small-icon"}></i>
                                        </div>
                                        <Col>
                                            <p className={'muted'}>Avg. Worst Day</p>
                                            <h6>{model.moodWorstDay}</h6>
                                        </Col>
                                    </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className={'col-md-3 pr-0 d-flex align-items-stretch'}>
                    <div className={'card text-center p-4 flex-1'}>
                        <h6 className={'muted'}>Registered Users</h6>
                        <h1 className={'hero-text'}>{model.totalRegistrations || ""}</h1>
                    </div>
                </div>
            </Row>

            <Row>
                <div className={'col-md-12 pr-0'}>
                    <div className={'card p-4'}>
                        <div className={'d-flex flex-row'}>
                            <Col className={'col-md-4'}>
                                <div className={'d-flex flex-row'}>
                                    <div>
                                        <h6 className={'muted mb-2'}>Engagements</h6>
                                        <h2>{model.totalEngagements}</h2>
                                    </div>
                                    <div className={'pl-4'}>
                                        <h6 className={'muted mb-2'}>Engagements per Day</h6>
                                        <h2>{model.averageEngagements}</h2>
                                    </div>
                                </div>
                                <h6 className={'muted mt-4 mb-2'}>Active Pages</h6>
                                <div>
                                    <BarChart width={330} height={136} data={model.eventsByDate}>
                                        <XAxis
                                            dy={3}
                                            fontFamily="sans-serif"
                                            stroke="#bebebe"
                                            interval={0}
                                            strokeWidth={1}
                                            tick={{ fontSize: "0.5rem" }}
                                            tickLine={{ stroke: 'transparent' }}
                                            tickFormatter={(v,i)=>{
                                                const date = parse(v, 'MM/dd/yyyy', new Date())
                                                return format(date,"E")
                                            }}
                                            dataKey="key"/>

                                        <YAxis
                                            stroke="#bebebe"
                                            tickLine={{ stroke: 'transparent' }}
                                            dataKey="value"
                                        />
                                        <Tooltip content={customPagesTooltip} />
                                        <Bar barSize={25} dataKey="value" fill="#15CBCE"/>
                                    </BarChart>
                                </div>
                            </Col>
                            <Col className={'col-md-4'}>
                                {/*header row*/}
                                <div className={'d-flex flex-row'}>
                                    <h6 className={'muted mb-0'}>Engagements</h6>
                                    <div className={'d-flex ml-auto'}>
                                        <p className={'muted text-small'}>Impressions</p>
                                    </div>
                                </div>
                                {/*content row 1*/}
                                {
                                    analyticsData && analyticsData.map((v, i) => (
                                        <div key={v.key} className={'d-flex flex-row item-row-border-bottom pb-1'}>
                                            <p className={'muted mb-0'}>{i + 1}. {v.displayName.replace("View ","")}</p>
                                            <div className={'d-flex ml-auto'}>
                                                <div className={'d-flex flex-row align-items-center'}>
                                                    <h6>{v.percent}%</h6>
                                                    <span style={{opacity:1 - (0.1*i) }} className={'dot primary ml-1'}/>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </Col>
                            <Col className={'col-md-4 text-center'}>
                                <h6 className={'muted mb-2'}>Engagements</h6>

                                {analyticsData && (
                                    <div style={{margin:"auto"}}>
                                        <PieChart
                                            style={{margin:"auto"}}
                                            height={PIE_CHART_HEIGHT}
                                            width={PIE_CHART_HEIGHT}
                                        >
                                            <Pie
                                                cx="50%"
                                                cy="50%"
                                                labelLine={false}
                                                outerRadius={PIE_CHART_HEIGHT/2}
                                                fill="#8884d8"
                                                dataKey="value"
                                                data={
                                                    analyticsData.map((v)=>({ name: v.displayName.replace("View ",""), value: parseFloat(v.percent) }))
                                                }

                                            >
                                                {analyticsData.map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                ))}
                                            </Pie>
                                            <Tooltip content={customTooltip2}/>
                                        </PieChart>
                                    </div>

                                )}

                            </Col>
                        </div>
                    </div>
                </div>
            </Row>

            <Row>
                <div className={'col-md-12 pr-0'}>
                    <div className={'card p-4'}>
                        <div className={'d-flex flex-row'}>
                            <Col className={'col-md-3'}>
                                <div className={'d-flex flex-row align-items-center'}>
                                    <div className={'badge-container large d-flex'}>
                                        <i className={`fal fa-swimmer`}></i>
                                    </div>
                                    <div className={'pl-3'}>
                                        <h4>{model.averageActiveMinutes ? model.averageActiveMinutes.toFixed() : 0}</h4>
                                        <p className={'muted'}>Total Active Minutes</p>
                                    </div>
                                </div>
                            </Col>
                            <Col className={'col-md-3'}>
                                <div className={'d-flex flex-row align-items-center'}>
                                    <div className={'badge-container large d-flex'}>
                                        <i className={`fal fa-running`}></i>
                                    </div>
                                    <div className={'pl-3'}>
                                        <h4>{model.totalSteps ? model.totalSteps.toFixed() : 0}</h4>
                                        <p className={'muted'}>Total Steps</p>
                                    </div>
                                </div>
                            </Col>
                            <Col className={'col-md-3'}>
                                <div className={'d-flex flex-row align-items-center'}>
                                    <div className={'badge-container large d-flex'}>
                                        <i className={`fal fa-running`}></i>
                                    </div>
                                    <div className={'pl-3'}>
                                        <h4>{model.totalDistance ? (round(model.totalDistance/1000, 2)) : 0}</h4>
                                        <p className={'muted'}>Total Distance (km)</p>
                                    </div>
                                </div>
                            </Col>
                            <Col className={'col-md-3'}>
                                <div className={'d-flex flex-row align-items-center'}>
                                    <div className={'badge-container large d-flex'}>
                                        <i className={`fal fa-fire`}></i>
                                    </div>
                                    <div className={'pl-3'}>
                                        <h4>{model.averageCalorieBurn ? model.averageCalorieBurn.toFixed() : 0}</h4>
                                        <p className={'muted'}>Total Calories Burned</p>
                                    </div>
                                </div>
                            </Col>
                        </div>
                        <div className={'d-flex flex-row mt-4'}>
                            <Col className={'col-md-3'}>
                                <div className={'d-flex flex-row align-items-center'}>
                                    <div className={'badge-container large d-flex'}>
                                        <i className={`fal fa-brain`}></i>
                                    </div>
                                    <div className={'pl-3'}>
                                        <h4>{model.averageDailySleep ? model.averageDailySleep.toFixed() : 0}</h4>
                                        <p className={'muted'}>Avg. Daily Sleep</p>
                                    </div>
                                </div>
                            </Col>
                            <Col className={'col-md-3'}>
                                <div className={'d-flex flex-row align-items-center'}>
                                    <div className={'badge-container large d-flex'}>
                                        <i className={`fal fa-brain`}></i>
                                    </div>
                                    <div className={'pl-3'}>
                                        <h4>{model.averageConcentratedBreathingMinutes ? model.averageConcentratedBreathingMinutes.toFixed(0) : 0}</h4>
                                        <p className={'muted'}>Total Concentrated Breathing</p>
                                    </div>
                                </div>
                            </Col>
                        </div>
                    </div>
                </div>
            </Row>
            {/*<p>{JSON.stringify(model.eventsByDate)}</p>*/}
            {/*<p>{Math.round(totalMood / 7)}</p>*/}


            {/*<h1>OLD</h1>*/}
            {/*<h1 className="page__title">HR Reports</h1>*/}
            {/*<input className="input__datepicker" type="date" name="startDate" onChange={handleInputChange}*/}
            {/*       value={parameters.startDate} disabled={isLoading}/>*/}
            {/*<input className="input__datepicker ml-md-3" type="date" name="endDate" onChange={handleInputChange}*/}
            {/*       value={parameters.endDate} disabled={isLoading}/>*/}

            {/*{isLoading &&*/}
            {/*<p>Loading...</p>*/}
            {/*}*/}

            {/*{!isLoading &&*/}
            {/*<>*/}
            {/*    <Row className="mt-5 mb-5">*/}
            {/*        <Col md={4}>*/}
            {/*            <Card className="text-center">*/}
            {/*                <Card.Body>*/}
            {/*                    <h2>{model.totalSteps ? model.totalSteps.toFixed() : 0}</h2>*/}
            {/*                    <p>Total Steps</p>*/}
            {/*                </Card.Body>*/}
            {/*            </Card>*/}
            {/*        </Col>*/}
            {/*        <Col md={4}>*/}
            {/*            <Card className="text-center">*/}
            {/*                <Card.Body>*/}
            {/*                    <h2>{model.totalDistance ? model.totalDistance.toFixed() : 0}</h2>*/}
            {/*                    <p>Total distance</p>*/}
            {/*                </Card.Body>*/}
            {/*            </Card>*/}
            {/*        </Col>*/}
            {/*        <Col md={4}>*/}
            {/*            <Card className="text-center">*/}
            {/*                <Card.Body>*/}
            {/*                    <h2>{model.averageCaloryBurn ? model.averageCaloryBurn.toFixed() : 0}</h2>*/}
            {/*                    <p>Avg. calory burn</p>*/}
            {/*                </Card.Body>*/}
            {/*            </Card>*/}
            {/*        </Col>*/}
            {/*        <Col md={4}>*/}
            {/*            <Card className="text-center">*/}
            {/*                <Card.Body>*/}
            {/*                    <h2>{model.averageSleep ? model.averageSleep.toFixed() : 0}</h2>*/}
            {/*                    <p>Avg. sleep</p>*/}
            {/*                </Card.Body>*/}
            {/*            </Card>*/}
            {/*        </Col>*/}
            {/*        <Col md={4}>*/}
            {/*            <Card className="text-center">*/}
            {/*                <Card.Body>*/}
            {/*                    <h2>{model.averageActiveMinutes ? model.averageActiveMinutes.toFixed() : 0}</h2>*/}
            {/*                    <p>Avg. active minutes</p>*/}
            {/*                </Card.Body>*/}
            {/*            </Card>*/}
            {/*        </Col>*/}
            {/*    </Row>*/}

            {/*    {(model.eventsByDate || []).length > 0 &&*/}
            {/*    <Card>*/}
            {/*        <Card.Body>*/}
            {/*            <Row className="mt-5 mb-5">*/}
            {/*                <Col md={12}>*/}
            {/*                    <h2>User Engagements</h2>*/}
            {/*                    <ResponsiveContainer width="100%" aspect={4.0 / 1.0}>*/}
            {/*                        <BarChart width={730} height={250} data={model.eventsByDate}>*/}
            {/*                            <CartesianGrid strokeDasharray="3 3"/>*/}
            {/*                            <XAxis dataKey="key"/>*/}
            {/*                            <YAxis dataKey="value"/>*/}
            {/*                            <Tooltip/>*/}

            {/*                            <Bar dataKey="value" fill="#15CBCE"/>*/}
            {/*                        </BarChart>*/}
            {/*                    </ResponsiveContainer>*/}
            {/*                </Col>*/}
            {/*            </Row>*/}
            {/*        </Card.Body>*/}
            {/*    </Card>*/}
            {/*    }*/}

            {/*    {(model.averageMoodByDate || []).length > 0 && (*/}
            {/*        <Card>*/}
            {/*            <Card.Body>*/}
            {/*                <Row className="mt-5 mb-5">*/}
            {/*                    <Col md={12}>*/}
            {/*                        <h2>How people are feeling</h2>*/}
            {/*                        <ResponsiveContainer width="100%" aspect={4.0 / 1.0}>*/}
            {/*                            <LineChart data={model.averageMoodByDate}>*/}
            {/*                                <XAxis dataKey="key"/>*/}
            {/*                                <YAxis/>*/}
            {/*                                <Tooltip/>*/}
            {/*                                <Line type="monotone" dataKey="value" stroke="#8884d8"/>*/}
            {/*                            </LineChart>*/}
            {/*                        </ResponsiveContainer>*/}
            {/*                    </Col>*/}
            {/*                </Row>*/}
            {/*            </Card.Body>*/}
            {/*        </Card>*/}
            {/*    )}*/}

            {/*    {([model.moodDataCountByMood] || []).length > 0 && (*/}
            {/*        <Card>*/}
            {/*            <Card.Body>*/}
            {/*                <Row className="mt-5 mb-5">*/}
            {/*                    <Col md={12}>*/}
            {/*                        <h2>How people are feeling</h2>*/}
            {/*                        <ResponsiveContainer width="100%" aspect={4.0 / 1.0}>*/}
            {/*                            <PieChart width={730} height={250}>*/}
            {/*                                <Pie data={model.moodDataCountByMood} dataKey="value" nameKey="key" cx="50%"*/}
            {/*                                     cy="50%"/>*/}
            {/*                                <Tooltip/>*/}
            {/*                                <Legend/>*/}
            {/*                            </PieChart>*/}
            {/*                        </ResponsiveContainer>*/}
            {/*                    </Col>*/}
            {/*                </Row>*/}
            {/*            </Card.Body>*/}
            {/*        </Card>*/}
            {/*    )}*/}

            {/*    {(model.eventsByType || []).length > 0 &&*/}
            {/*    <Card>*/}
            {/*        <Card.Body>*/}
            {/*            <Row className="mt-5 mb-5">*/}
            {/*                <Col md={12}>*/}
            {/*                    <h2>What are people doing</h2>*/}
            {/*                    <ResponsiveContainer width="100%" aspect={4.0 / 3.0}>*/}
            {/*                        <RadarChart data={model.eventsByType}>*/}
            {/*                            <PolarGrid/>*/}
            {/*                            <PolarAngleAxis dataKey="key"/>*/}
            {/*                            <PolarRadiusAxis angle={30}*/}
            {/*                                             domain={[0, Math.max.apply(null, model.eventsByType.map(x => x.value))]}/>*/}
            {/*                            <Radar*/}
            {/*                                dataKey="value"*/}
            {/*                                stroke="#8884d8"*/}
            {/*                                fill="#8884d8"*/}
            {/*                                fillOpacity={0.6}*/}
            {/*                            />*/}
            {/*                        </RadarChart>*/}
            {/*                    </ResponsiveContainer>*/}
            {/*                </Col>*/}
            {/*            </Row>*/}
            {/*        </Card.Body>*/}
            {/*    </Card>*/}
            {/*    }*/}

            {/*    {(model.registrationsByDate || []).length > 0 &&*/}
            {/*    <Card>*/}
            {/*        <Card.Body>*/}
            {/*            <Row className="mt-5 mb-5">*/}
            {/*                <Col md={12}>*/}
            {/*                    <h2>User Registrations</h2>*/}
            {/*                    <ResponsiveContainer width="100%" aspect={4.0 / 1.0}>*/}
            {/*                        <BarChart width={730} height={250} data={model.registrationsByDate}>*/}
            {/*                            <CartesianGrid strokeDasharray="3 3"/>*/}
            {/*                            <XAxis dataKey="key"/>*/}
            {/*                            <YAxis dataKey="value"/>*/}
            {/*                            <Tooltip/>*/}

            {/*                            <Bar dataKey="value" fill="#15CBCE"/>*/}
            {/*                        </BarChart>*/}
            {/*                    </ResponsiveContainer>*/}
            {/*                </Col>*/}
            {/*            </Row>*/}
            {/*        </Card.Body>*/}
            {/*    </Card>*/}
            {/*    }*/}
            {/*</>*/}
            {/*}*/}

        </Container>
    );
}

function mapStateToProps(state) {
    return {
        heroApiClient: createHeroApiClient(state.user.session.accessToken)
    }
}

export default connect(mapStateToProps)(HrReports);

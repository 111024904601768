// React
import React from "react";



// Charts
import {
  Bar,
  Legend,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
  ComposedChart,
  Line
} from "recharts";

function feedBarChart(props) {
  let maxValArr = [1];
  let maxVal = 1;

  if (props.dataObject) {
    maxValArr = props.dataObject.map(el => el.value);
    maxVal = Math.max(...maxValArr);
  }
  const achievementColor = (entry) => {
      return (entry.value >= props.goalValue) ? "#15CBCE" : 'rgba(33,83,123,0.60)';
  }
  function customTooltip(event) {
    if (event.payload && event.payload[0]) {
      let tooltipColor = 'rgba(89,97,125,0.90)';

      if (event.payload[0].value > props.goalValue) {
        tooltipColor = "#15CBCE";
      }

      const tooltipStyle = {
        backgroundColor: tooltipColor,
        borderRadius: 6,
        border: `1px solid ${tooltipColor}`,
        boxShadow: "1px 1px 3px 0px rgba(0,0,0,0.125)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      };

      const textStyle = {
        color: 'white',
        padding: ".25em .5em",
        margin: "0 0"
      };

      return (
        <div style={tooltipStyle}>
          <p style={textStyle}>{`${event.payload[0].payload.value} 
             ${
               event.payload[0].payload.value
                 ? event.payload[0].payload.unit
                 : ""
             }`}</p>
        </div>
      );
    } else {
      return null;
    }
  }


  return (
    <div style={{ marginTop: "1rem" }}>
      <ResponsiveContainer width="100%" aspect={4.0 / 2.0}>
        <ComposedChart width={150} height={40} data={props.dataObject}>
          <Tooltip content={customTooltip} />
          <XAxis
            dataKey="name"
            dy={3}
            fontFamily="sans-serif"
            stroke="#bebebe"
          />

          <YAxis
            type="number"
            stroke="#bebebe"
            tick={{ fontSize: "0.875rem" }}
            dx={-5}
            domain={[0, props.goalValue * 1.25]}
          />
          <Legend layout="vertical" verticalAlign="middle" align="right" payload={
            [
              { id : 'goal', value: 'goal', type:'line', color: '#21537B'},
              { id : 'target', value: 'hit goal', type:'square', color: '#15CBCE'},
              { id : 'progress', value: 'progress', type:'square', color: '#818BAC'},
            ]
          } />
          <Tooltip />

          <Line type="monotone" dataKey="goal" name="goal" stroke="#e7edf0">
            {props.dataObject.map((entry, index) => {
                return (
                  <Cell
                    key={`cell-${index}`}
                    fill={`#15cbce`}
                  />
                );
              })
            }
          </Line>

          <Bar barSize={30} barCategoryGap={5} dataKey="value" name="progress">
            {props.dataObject.map((entry, index) => {

                return (
                  <Cell
                    key={`cell-${index}`}
                    fill={achievementColor(entry)}
                    strokeWidth={index === 2 ? 4 : 1}
                  />
                );

            })}
          </Bar>
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}

export default feedBarChart;

import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Container, Row, Col, Card, Spinner } from 'react-bootstrap'
import { fetchChallengesJoinable } from '../redux/actions/challengesJoinable'
import { fetchChallengesCurrent } from '../redux/actions/challengesCurrent'
import { fetchChallengesPast } from '../redux/actions/challengesPast'
import { fetchChallengesUpcoming } from '../redux/actions/challengesUpcoming'
import ChallengeCard from '../components/challenges/ChallengeCard'
import HeroOutlineButton from '../common/Buttons/OutlineButton'
import CreateChallengeModal from '../components/challenges/CreateChallengeModal'
import moment from 'moment';
import LoadingSpinner from '../components/controls/LoadingSpinner'

function Challenges({ dispatch, challengesJoinable, challengesCurrent, challengesPast, challengesUpcoming }) {
  useEffect(() => {
    dispatch(fetchChallengesJoinable())
    dispatch(fetchChallengesCurrent())
    dispatch(fetchChallengesPast())
    dispatch(fetchChallengesUpcoming())
  }, [challengesJoinable, challengesCurrent, challengesPast, challengesUpcoming])

  const challengesFetch = challengesJoinable.isFetching || challengesCurrent.isFetching || challengesPast.isFetching || challengesUpcoming.isFetching;

  const [showCreateChallengeModal, setShowCreateChallengeModal] = useState(false)

  const today = new Date()
  const utcToday = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate()));
  utcToday.setHours(0, 0, 0, 0)

  return (
    <Container>
      <Row className="title-container row">
        <Col>
          <h1 className="page__title">Challenges</h1>
        </Col>
        <Col>
          {!challengesFetch &&
            <div className="btn--actions" onClick={() => setShowCreateChallengeModal(true)}>
              <a class="btn btn-primary btn--square"><i class="far fa-plus"></i></a>
            </div>
          }
        </Col>
      </Row>

      {challengesCurrent.isFetching && <LoadingSpinner/>}
      {challengesCurrent.items.length > 0 &&
        <>
          <h2 style={headerStyle}>Current</h2>

          <Row>
            {challengesCurrent.items.map(challenge => (
              <ChallengeCard key={challenge.id} challenge={challenge} />
            ))}
          </Row>
        </>
      }

      {challengesUpcoming.isFetching && <LoadingSpinner/>}
      {challengesUpcoming.items.length > 0 &&
        <>
          <h2 style={headerStyle}>Upcoming</h2>

          <Row>
            {challengesUpcoming.items.map(challenge => (
              <ChallengeCard challenge={challenge} />
            ))}
          </Row>
        </>
      }

      {challengesPast.isFetching && <LoadingSpinner/>}
      {challengesPast.items.length > 0 &&
        <>
          <h2 style={headerStyle}>Previous</h2>

          <Row>
            {challengesPast.items.map(challenge => (
              <ChallengeCard challenge={challenge} />
            ))}
          </Row>
        </>
      }

      {challengesJoinable.isFetching && <LoadingSpinner/>}
      {challengesJoinable.items.length > 0 &&
        <>
          <h2 style={headerStyle}>Joinable</h2>

          <Row>
            {challengesJoinable.items.map(challenge => (
              <ChallengeCard challenge={challenge} />
            ))}
          </Row>
        </>
      }

      <CreateChallengeModal
        showModal={showCreateChallengeModal}
        setShowModal={setShowCreateChallengeModal}
      />
    </Container>
  );
}

const headerStyle = { textAlign: 'center', marginBottom: '2rem' };
const bottomRowStyle = {
  display: "flex",
  justifyContent: "center",
  marginBottom: "3rem",
  marginTop: "0.75rem"
};

const mapStateToProps = state => {
  return {
    challenges: state.challenges,
    challengesJoinable: state.challengesJoinable,
    challengesCurrent: state.challengesCurrent,
    challengesPast: state.challengesPast,
    challengesUpcoming: state.challengesUpcoming,
  };
}

export default connect(mapStateToProps)(Challenges);

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { fetchUsers } from '../redux/actions/users';
import PersonalProfile from '../components/profile/PersonalProfile';
import HrReports from './hr-reports';

function HrUserManagement(props) {
  const [userId, setUserId] = useState(null);
  const [action, setAction] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    props.dispatch(fetchUsers())
  }, [props.users]);

  function openModal(userId, action) {
    setUserId(userId);
    setAction(action);
    setShowModal(true);
  }

  function handleModalHide() {
    setUserId(null);
    setAction(null);
    setShowModal(false);
  }

  return (
    <Container>
      <h1 className="page__title">HR User Management</h1>


    <Row>
      {props.users && props.users.items && props.users.items.map(user => (
            <Col md={3}>
              <Card key={user.userId}>
                <Card.Body className="text-center">
                  <img className="mb-2" src={user.imageUrl || `https://eu.ui-avatars.com/api?name=${user.emailAddress}`} style={{ height: "50px", width: "50px", borderRadius: "50%" }} />
                  <p>{user.givenName} {user.familyName}</p>
                  {/* <span>{user.emailAddress}</span> */}
                  <Row>
                    {/* <Col>
                      <Button className="btn btn--transparent btn--circle" onClick={() => openModal(user.id, 'HR_REPORT')}><i className="far fa-user-chart"></i></Button>
                    </Col> */}
                    <Col>
                      <Button className="btn btn--transparent btn--circle" onClick={() => openModal(user.id, 'EDIT_PROFILE')}><i className="far fa-edit"></i></Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
      ))}
      </Row>

 {/* <Row>
            <Col xs={1}>
              <img src={user.imageUrl || `https://eu.ui-avatars.com/api?name=${user.name}`}
                style={{ display: "block", width: "50px", height: "50px", borderRadius: "50%", backgroundColor: "grey", boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.1225)" }} />
            </Col>
            <Col xs={3}>
              <label>{user.name}</label>
            </Col>
            <Col xs={3}>
              <label>{user.email}</label>
            </Col>
            <Col xs={1}>
            </Col>
            <Col xs={1}>

            </Col>
          </Row> */}

      <Modal centered show={showModal} onHide={handleModalHide}>
        {action === 'EDIT_PROFILE' && <PersonalProfile userId={userId} />}
        {/* action === 'HR_REPORT' && <HrReports userId={userId} /> */}
      </Modal>
    </Container>
  );
}

function mapStateToProps(state) {
  return {
    users: state.users
  };
}

export default connect(mapStateToProps)(HrUserManagement);

import moment from 'moment';
import { INVALIDATE_CHALLENGES } from '../actions/challenges'
import { REQUEST_CHALLENGES_PAST, RECEIVE_CHALLENGES_PAST } from '../actions/challengesPast'
import { END_USER_SESSION } from '../actions/userSession'

const initialState = {
    isFetching: false,
    isValid: false,
    items: []
}

export default function challengesPast(state = initialState, action) {
    switch (action.type) {
        case REQUEST_CHALLENGES_PAST:
            return {
                ...state,
                isFetching: true,
                isValid: true
            }
        case RECEIVE_CHALLENGES_PAST:
            const challenges = action.payload.challenges
                .map(challenge => {
                    const totalContributionValue = challenge.memberContributions.reduce((total, contribution) => total + contribution.value, 0);
                    let targetContributionValue = challenge.targetValue * challenge.members.length;
                    if (challenge.targetType == 'Average') {
                        targetContributionValue = targetContributionValue * moment(challenge.endDate).diff(challenge.startDate, 'days');
                    }
                    return {
                        ...challenge,
                        totalContributionValue,
                        targetContributionValue
                    }
                });
            return {
                ...state,
                isFetching: false,
                isValid: true,
                items: challenges,
                lastUpdated: action.payload.receivedAt
            }
        case INVALIDATE_CHALLENGES:
            return {
                ...state,
                isValid: false
            }
        case END_USER_SESSION:
            return initialState
        default:
            return state
    }
}
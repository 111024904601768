import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {Row, Col, Card, Badge, Container, Spinner} from "react-bootstrap";
import FoodFormAdd from "./food-form-add";
import LoadingSpinner from "../controls/LoadingSpinner";

function MealDay(props) {
    useEffect(() => {
        props.reload();
    }, [props.dateToDisplay]);

    useEffect(() => {
    }, [props.daysFood]);
    //
    // HELP Displays the meals that a person has eaten on the give date
    //
    function getMeals() {
        let displayElements = [];

        let dayTotals = null;

        //this function adds up all of the calories/nutrients.
        //mealType can be breakfast/lunch/dinner.
        //nutritionalValue is the nutrition value e.g: calories, protein, fat.

        //find total calories for the whole day
        let totalDayValueObj = {
            calories: 0,
            carbohydrate: 0,
            fat: 0,
            protein: 0
        };

        for (var propertyName in props.daysFood.mealTimes) {
            //find total calories for each meal (breakfast, lunch, dinner)
            let totalValueObj = {
                calories: null,
                carbohydrate: null,
                fat: null,
                protein: null
            };

            //extract the object keys
            const objKeys = Object.keys(totalValueObj);

            //find the total sum for each nutrient
            //.map extracts values into an array and .reduce reduces it into one number
            objKeys.forEach(key => {
                if (props.daysFood.mealTimes[propertyName].length > 0) {
                    totalValueObj[key] =
                        Math.round(
                            props.daysFood.mealTimes[propertyName]
                                .map(el => parseFloat(parseFloat(el[key]).toFixed(1)))
                                .reduce((acc, cur) => acc + cur) * 100
                        ) / 100;
                }
            });

            //finding the total for the whole day adding
            objKeys.forEach(key => {
                if (totalValueObj[key] !== null) {
                    totalDayValueObj[key] += Math.round(totalValueObj[key] * 100) / 100;
                }
            });

            //generate component based on the data from the API
            displayElements.push(
                <div key={propertyName} className="mb10">
                    <Card className="mb-5">
                        <Card.Header as="h5" className="d-flex flex-column">
                            <Row>
                                <Col xs={12} md={4} className="mb-3 mb-md-0">{propertyName}</Col>
                                <Col xs={3} md={2} className="text-small text-md-large color-calories">
                                    {totalValueObj.calories !== null
                                        ? `${totalValueObj.calories} kcal (${Math.round(
                                            (totalValueObj.calories / props.caloricGoal) * 10000
                                        ) / 100}%)`
                                        : ""}
                                </Col>
                                <Col xs={3} md={2} className="text-small text-md-large color-carbs">
                                    {totalValueObj.carbohydrate !== null
                                        ? `${totalValueObj.carbohydrate} g`
                                        : ""}
                                </Col>
                                <Col xs={3} md={2} className="text-small text-md-large color-fat">
                                    {totalValueObj.fat !== null
                                        ? `${totalValueObj.fat} g`
                                        : ""}
                                </Col>
                                <Col xs={3} md={2} className="text-small text-md-large color-protein">
                                    {totalValueObj.protein !== null
                                        ? `${totalValueObj.protein} g`
                                        : ""}
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <div className="mb-3">
                                {getMealLines(props.daysFood.mealTimes[propertyName])}
                            </div>
                            <FoodFormAdd
                                mealName={propertyName}
                                dateToDisplay={props.dateToDisplay}
                                reload={props.reload}
                                heroApiClient={props.heroApiClient}/>
                        </Card.Body>
                    </Card>
                </div>
            );
        }

        dayTotals = (
            <Row>
                <Col xs={12} md={4}>
                    <span className="text__grey">Daily %</span>
                </Col>
                <Col xs={3} md={2}>
                   <span className="text__grey">
                  {`${Math.round(totalDayValueObj.calories * 100) /
                  100} (${Math.round(
                      (totalDayValueObj.calories / props.caloricGoal) * 10000
                  ) / 100}%)`}
                   </span>
                </Col>
                <Col xs={3} md={2}>
                   <span className="text__grey">
                    {Math.round(totalDayValueObj.carbohydrate * 100) / 100}
                   </span>
                </Col>
                <Col xs={3} md={2}>
                   <span className="text__grey">
                  {Math.round(totalDayValueObj.fat * 100) / 100}
                   </span>
                </Col>
                <Col xs={3} md={2}>
                   <span className="text__grey">
                  {Math.round(totalDayValueObj.protein * 100) / 100}
                   </span>
                </Col>
            </Row>
        );

        return [displayElements, dayTotals];
    }

    function removeFoodEntry({target}) {
        props.removeFoodEntry(target.getAttribute("name"));
    }

    ///
    /// HELP This is each item that has been consumed to form a meal
    ///
    function getMealLines(mealItems) {
        let mealLines = null;

        mealLines = mealItems.map(element => {
            //return JSX for each "element"
            return (
                <Row key={element.food_entry_id}>
                    <Col xs={12} md={4}>
                        <span className="text__grey">{element.food_entry_description}</span>
                        <Badge
                            pill
                            variant="light"
                            name={element.food_entry_id}
                            className="ml-2 c-badge--navy"
                            onClick={removeFoodEntry}>x</Badge>
                    </Col>
                    <Col xs={3} md={2}>  <span className="text__grey">{element.calories}</span></Col>
                    <Col xs={3} md={2}>  <span className="text__grey">{element.carbohydrate}</span></Col>
                    <Col xs={3} md={2}>  <span className="text__grey">{element.fat}</span></Col>
                    <Col xs={3} md={2}>  <span className="text__grey">{element.protein}</span></Col>
                </Row>
            );
        });

        return mealLines;
    }

    //generating the JSX before render
    //renderedElements[0] = Data for each meal
    //renderedElements[1] = Data for the whole day
    const [singleMeals, dayTotal] = getMeals();

    // Render the section
    return (
        <div>
            {props.isLoading && <LoadingSpinner/>}

            {!props.isLoading &&
            <div className="meal-days">
                <Card className="mb10 mb-4">
                    <Card.Header as="div" className="d-flex flex-column">
                        <Row>
                            <Col xs={12} md={4}></Col>
                            <Col xs={3} md={2} className="text-small text-md-large color-calories">Kcals</Col>
                            <Col xs={3} md={2} className="text-small text-md-large color-carbs">Carbs</Col>
                            <Col xs={3} md={2} className="text-small text-md-large color-fat">Fat</Col>
                            <Col xs={3} md={2} className="text-small text-md-large color-protein">Protein</Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        {
                            //THIS IS WHERE DAILY REPORT IS PLUGGED IN TO RENDER
                            dayTotal
                        }
                    </Card.Body>
                </Card>
                <div>
                    {
                        // THIS IS WHERE SINGLE MEALS ARE PLUGGED IN TO RENDER
                        singleMeals
                    }
                </div>
            </div>
            }
        </div>
    );
}

function mapStateToProps(state, ownProps) {
    return {
        appConfig: state.appConfig,
        feed: state.feed,
        dateToDisplay: ownProps.dateToDisplay,
        isLoading: ownProps.isLoading,
        reload: ownProps.reload,
        daysFood: ownProps.daysFood,
        removeFoodEntry: ownProps.removeFoodEntry,
        caloricGoal: ((state.healthData[state.user.profile.id] || {})[ownProps.dateToDisplay] || {}).caloricGoal || 2000
    };
}

export default connect(mapStateToProps)(MealDay);

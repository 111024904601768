import React, {useState} from "react";
import { Row, Card } from "react-bootstrap";

const BreathingPracticesList = ({setPracticeDetails}) => {

  const [displayData, setDisplayData] = useState(breathingExercisesListData);

      const handleBeginPractice = (type, steps, length) => {
        if(type === "course") {
          setDisplayData(sleepQuickList);
        }
        else {
          setPracticeDetails(type, length, steps);
        }
      }

    
      return (
        <div className="container">
          <div className="row">
            {displayData.exercises &&
              displayData.exercises.map((exercise, index) => (
                <div key={index} className="col-md-3 mb-4">
                  <Card className="w-100">
                    <Card.Img
                      variant="top"
                      src={exercise.content.image_url}
                      alt="Exercise Image"
                      style={{
                        height: '15rem',
                        objectFit: 'cover', // Crop the image to cover the specified dimensions
                      }}
                    />
                    <Card.Body>
                      <Card.Title>{exercise.name}</Card.Title>
                      <Card.Text style={{ height: '6rem', overflow: 'hidden' }}>
                        {exercise.content.body.original}
                      </Card.Text>
                      <button
                        className="btn btn-primary w-100 p-2 m-2"
                        onClick={() => { handleBeginPractice(exercise.type, exercise.steps, exercise.length); }}
                      >
                        {exercise.buttonText}
                      </button>
                    </Card.Body>
                  </Card>
                </div>
              ))}
          </div>
        </div>
      );
      
      

    }

export default BreathingPracticesList;




  
  const diaphragmaticSteps = [
    {
      step: "a",
      description: "Find a comfortable and quiet place to sit or lie down.",
    },
    {
      step: "b",
      description: "Place one hand on your chest and the other hand on your abdomen (just below your ribcage).",
    },
    {
      step: "c",
      description: "Inhale slowly and deeply through your nose, expanding your diaphragm and feeling your abdomen rise. Keep your chest relatively still during this phase.",
    },
    {
      step: "d",
      description: "Exhale slowly and completely through your mouth, feeling your abdomen fall as you release the air.",
    },
    {
      step: "e",
      description: "Continue this deep breathing pattern for several minutes, focusing on the sensation of your breath and keeping a steady rhythm.",
    },
  ];

  const boxSteps = [
    {
      step: "a",
      description: "Sit comfortably with your back straight or lie down in a relaxed position",
    },
    {
      step: "b",
      description: "Inhale deeply through your nose for a count of 4 seconds. Feel your lungs expand fully",
    },
    {
      step: "c",
      description: "Hold your breath for another count of 4 seconds. Keep your body relaxed during this phase",
    },
    {
      step: "d",
      description: "Exhale slowly and completely through your mouth for another count of 4 seconds, emptying your lungs",
    },
    {
      step: "e",
      description: "Hold your breath again for 4 seconds before starting the next inhalation",
    },
    {   
        step: "f",
        description: "Repeat this box breathing pattern (4-4-4-4), gradually increasing the duration if you feel comfortable.",
    }
  ];

  const alternateNostrilSteps = [
    {
      step: "a",
      description: "Sit comfortably with your spine straight and shoulders relaxed",
    },
    {
      step: "b",
      description: "Use your right thumb to close off your right nostril",
    },
    {
      step: "c",
      description: "Inhale deeply and slowly through your left nostril",
    },
    {
      step: "d",
      description: "Close your left nostril with your right ring finger and release the closure of the right nostril",
    },
    {
      step: "e",
      description: "Exhale completely through your right nostril",
    },
    {
        step: "f",
        description: "Inhale deeply and slowly through your right nostril",
    },
    {
        step: "g",
        description: "Close your right nostril with your right thumb and release the closure of the left nostril",
    },
    {
        step: "h",
        description: "Exhale completely through your left nostril",
    },
    {
        step: "i",
        description: "This completes one round, repeat rounds while focusing on the breath flow and maintaining a smooth rhythm",
    }
  ];

  const fourSevenEightSteps = [
    {
      step: "a",
      description: "Sit or lie down in a comfortable position.",
    },
    {
      step: "b",
      description: "Close your mouth and inhale quietly through your nose to a mental count of 4 seconds",
    },
    {
      step: "c",
      description: "Hold your breath for a count of 7 seconds",
    },
    {
      step: "d",
      description: "Exhale completely through your mouth to a count of 8 seconds, making a soft 'whoosh' sound",
    },
    {
      step: "e",
      description: "This completes one breath cycle. Now, inhale again and repeat the 4-7-8 gradually increasing the duration if you feel comfortable",
    },
];

const sleepQuickList = {
  exercises: [{
    name: 'Day 1: Introduction to Box Breathing (3 minutes)',
    type: 'box',
    buttonText: 'Begin Day 1',
    steps: boxSteps,
    selected: "day1sleep",
    length: 180,
    content: {
      image_url: 'https://cdn.nvgtr.io/i/660d07cf-cced-4587-adfd-75986414fb30-pexels-ketut-subiyanto-4473864.jpeg?w=200',
      body: {
        original: 'Focus on slow, deep breaths and maintaining a steady rhythm.'
      }
    }
  },
    {
      name: 'Day 2: Box Breathing (5 minutes)',
      type: 'box',
      buttonText: 'Begin Day 2',
      steps: boxSteps,
      selected: "day2sleep",
      length: 300,
      content: {
        image_url: 'https://cdn.nvgtr.io/i/18ea4826-11da-4555-887b-8b3bc66eb31c-Stacked_Rocks.jpg?w=200',
        body: {
          original: 'Focus on relaxation and consistency in your breathing.'
        }
      }
    },
    {
      name: 'Day 3: Introduction to Circular Breathing (3 minutes)',
      type: 'circle',
      buttonText: 'Begin Day 3', 
      length: 180,
      steps: diaphragmaticSteps,
      selected: "day3sleep",
      content: {
        image_url: 'https://cdn.nvgtr.io/i/e83c4354-9507-465e-a4c7-32345437f21b-5%20tips%20sleeping%20hot%20weather.png?w=200',
        body: {
          original: 'Focus on maintaining a smooth, unbroken breath cycle.'
        }
      }
    },
    {
      name: 'Day 4: Circular Breathing (5 minutes)',
      steps: diaphragmaticSteps,
      buttonText: 'Begin Day 4',
      length: 300,
      type: 'circle',
      selected: "day4sleep",
      content: {
        image_url: 'https://cdn.nvgtr.io/i/69affa12-04fe-46cf-9141-550c0cfdaf18-Man_sat_on_rock.jpg?w=200',
        body: {
          original: 'Continue with circular breathing, paying attention to any tension in the body and releasing it with each breath.'
        }
      }
    },
    {
      name: 'Day 5: Introduction to 4-7-8 Breathing (2 minutes)',
      type: '478',
      buttonText: 'Begin Day 5',
      length: 120,
      steps: fourSevenEightSteps,
      selected: "day5sleep",
      content: {
        image_url: 'https://cdn.nvgtr.io/i/e6510c17-161e-4a6a-ac0d-27fbef7a82b1-justin-aikin-klt5f9clnv8-unsplash.jpeg?w=200',
        body: {
          original: 'Inhale for 4 seconds, hold for 7 seconds, and exhale for 8 seconds.'
        }
      }
    },
    {
      name: 'Day 6: 4-7-8 Breathing (4 minutes)',
      type: '478',
      buttonText: 'Begin Day 6',
      length: 240,
      steps: fourSevenEightSteps,
      selected: "day6sleep",
      content: {
        image_url: 'https://cdn.nvgtr.io/i/84eb455a-bd1d-420b-82bb-9c2321ad2ce8-rajvinder-singh-zdzjlxlbeti-unsplash.jpeg?w=200',
        body: {
          original: 'Focusing on elongating the exhale for better relaxation.'
        }
      }
    },
    {
      name: 'Day 7: Box Breathing (5 minutes)',
      type: 'box',
      buttonText: 'Begin Day 7', 
      length: 300,
      steps: boxSteps,
      selected: "day7sleep",
      content: {
        image_url: 'https://cdn.nvgtr.io/i/ff6b48b5-a28c-401f-97ff-0f6c105b13f8-Sleep.png?w=200',
        body: {
          original: 'Focusing on elongating the exhale for better relaxation.'
        }
      }
    }
  ]
  };

  const breathingExercisesListData = {
    exercises: [{
      name: 'Quick Relax',
      type: 'circle',
      buttonText: 'Begin Quick Relax',
      steps: diaphragmaticSteps,
      selected: "quick",
      length: 180,
      content: {
        image_url: 'https://cdn.nvgtr.io/i/18ea4826-11da-4555-887b-8b3bc66eb31c-Stacked_Rocks.jpg?w=200',
        body: {
          original: 'Quick access breathing exersise to help you relax and calm down.'
        }
      }
    },
    {
      name: 'Better Sleep Course',
      type: 'course',
      buttonText: 'View Course',
      selected: "sleepCourse",
      length: 180,
      content: {
        image_url: 'https://cdn.nvgtr.io/i/660d07cf-cced-4587-adfd-75986414fb30-pexels-ketut-subiyanto-4473864.jpeg?w=200',
        body: {
          original: 'Follow this 7 day course just before you go to sleep to create a more relaxing sleep routine.'
        }
      }
    },
      {
        name: 'Box Breathing',
        type: 'box',
        buttonText: 'Begin Box Breathing',
        steps: boxSteps,
        selected: "box",
        length: 180,
        content: {
          image_url: 'https://cdn.nvgtr.io/i/15323b9e-501d-4a1d-9a44-6cfc69186a62-boxBreathing.jpg?w=200',
          body: {
            original: 'Can help regulate the autonomic nervous system, reduce stress and anxiety, and enhance focus and concentration.'
          }
        }
      },
      {
        name: '4-7-8 Breathing',
        selected: "478",
        buttonText: 'Begin 4-7-8 Breathing',
        steps: fourSevenEightSteps,
        type: '478',
        length: 180,
        content: {
          image_url: 'https://cdn.nvgtr.io/i/922a01a3-e57a-4366-abb4-156ccfc73e50-4-7-8-Breathing.jpg?w=200',
          body: {
            original: 'Can induce a state of calmness, reduce anxiety, and improve sleep quality.'
          }
        }
      },
      {
        name: 'Diaphragmatic Breathing',
        selected: "diaphragmatic",
        buttonText: 'Begin Diaphragmatic Breathing',
        steps: diaphragmaticSteps,
        type: 'circle',
        length: 180,
        content: {
          image_url: 'https://cdn.nvgtr.io/i/bb9f603d-3800-40af-9fe1-cfd8fecf86ce-diagraphamaticBreathing.jpg?w=200',
          body: {
            original: 'Promotes relaxation, reduces stress and anxiety, improves lung capacity, and helps lower blood pressure.'
          }
        }
      },
      {
        name: 'Alternate Nostril Breathing',
        steps: alternateNostrilSteps,
        buttonText: 'Begin Alternate Nostril Breathing',
        selected: "alternateNostril",
        type: 'circle',
        length: 180,
        content: {
          image_url: 'https://cdn.nvgtr.io/i/8345a4ed-3542-43b6-adb3-73946f6564c2-alternativeNostril.jpg?w=200',
          body: {
            original: 'Helps balance the left and right hemispheres of the brain, reduces stress, and enhances mental clarity.'
          }
        }
      },
    ]
  };

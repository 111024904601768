import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Col, Card, ProgressBar, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { fetchHealthMetrics } from '../../redux/actions/healthMetrics'
import { FaLock } from 'react-icons/fa';
import round from "../../shared/round";

function ChallengeCardDashboard(props) {
  const { challenge: { id, name, description, endDate, totalContributionValue, targetContributionValue, members, closed, healthMetricId, userIsInChallenge, isPrivate } } = props
  const [units, setUnits] = useState("")
  const [model, setModel] = useState({});

  useEffect(() => {
    props.dispatch(fetchHealthMetrics())
  }, [])

  useEffect(() => {
    const healthMetrics = props.healthMetrics.items.find(x => x.id == healthMetricId)
    if(healthMetrics) {
      setUnits(healthMetrics.units)
      const [recordedValue, units] = formatUnits(totalContributionValue, healthMetrics?.units);
      const type = healthMetrics.name;
      setModel({
        units: units,
        recordedValue: window.Utils.isDurationHealthMetric(type) ? window.Utils.formatDurationHealthMetric(type, recordedValue) : round(recordedValue, window.Utils.getDPForHealthMetricType(type)),
      });
    }
  }, [props.healthMetrics])

  const progressBar = () => {
    const progress = targetContributionValue == 0 ? 0 : (model.recordedValue / targetContributionValue * 100)
    return <ProgressBar variant={'accent'} now={progress} label={`${progress.toFixed(0)}%`} />;
  }

  function formatUnits(value, unit) {
    let newValue = 0;
    let newUnit = "";

    switch (true) {
      case unit === "seconds":
        newUnit = "minutes";
        newValue = value / 60;
        break;
      case unit === "meters":
        newUnit = "KM";
        newValue = parseFloat(parseFloat(value / 1000).toFixed(2));
        break;
      case unit === "int":
        newUnit = "";
        newValue = value;
        break;
      case unit === null:
        newUnit = "";
        newValue = value;
        break;
      default:
        newUnit = unit;
        newValue = value;
        break;
    }

    return [newValue, newUnit];
  }

  return (
    <Col md={12}>
      {/*<Link to={{ pathname: `/challenges/${id}/${name}` }}>*/}
        <Card className="card--transparent bm" style={{ boxShadow: "0px 0px 15px 0px rgba(0,0,0,0.15)" }}>
          <Card.Header className="card-header--transparent p-0">
            <div className="mb-2 mt-4">
              {members.slice(0, 5).map(member => {
                return (
                    <img src={member.imageUrl || `https://eu.ui-avatars.com/api?name=${member.name}`} style={{ margin: "0px 5px", height: "40px", width: "40px", borderRadius: "50%" }} />
                );
              })}
            </div>
            <Row className="align-items-center">
              <Col>
                <h6>{name}</h6>
              </Col>
              <Col className="d-flex justify-content-end">
                {isPrivate && <FaLock />}
              </Col>
            </Row>
          </Card.Header>
            {!closed && userIsInChallenge &&
              <>
                <p className="mt-2">{description}</p>
                <p style={{ fontSize: "0.75rem", color: '#336795' }}>{`${model.recordedValue} / ${targetContributionValue.toFixed(0)} ${model.units||""}`}</p>
                <div style={{ width: "100%" }}>{progressBar()}</div>
              </>
            }
        </Card>
      {/*</Link>*/}
    </Col>
  )
}

const mapStateToProps = state => {
  return {
    healthMetrics: state.healthMetrics
  };
}

export default connect(mapStateToProps)(ChallengeCardDashboard);

import React, { useState, useEffect, useRef } from "react";
import { connect, shallowEqual } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import {
  Container,
  Spinner,
  Button,
  Row,
  Col,
  Card,
  Tab,
  Tabs,
} from "react-bootstrap";
import { fetchMeetings } from "../redux/actions/video";
//import ChatReport from '../components/reports/ChatReport';
import moment from "moment";
import LoadingSpinner from "../components/controls/LoadingSpinner";
import NewMeetingPopup from "../components/video/NewMeetingPopup";
import shortid from "shortid";
import { createHeroApiClient } from "../hero-api-client";
import chats from "../redux/reducers/chats";
import { PlusIcon } from "@heroicons/react/24/solid";
import CreateChatModal from "../components/Chat/CreateChatModal";
import "./chat.scss";
import { fetchUsers } from "../redux/actions/users";
import { fetchCoaches, fetchMine } from "../redux/actions/coaches";
import MeetingModal from "./Meeting";
import MeetingCard from "../components/video/MeetingCard";
import YourCoaches, { meetingType } from "../components/video/YourCoaches";
import {
  BackgroundBlurProvider,
  MeetingProvider,
  darkTheme,
  lightTheme,
  UserActivityProvider,
  NotificationProvider,
} from "amazon-chime-sdk-component-library-react";
import { ThemeProvider } from "styled-components";

import BigCalendar from "react-big-calendar-like-google";
import "react-big-calendar-like-google/lib/css/react-big-calendar.css";
import { useCallback } from "react";

moment.locale("en-GB");
BigCalendar.momentLocalizer(moment);

function Meetings(props) {
  let { id: Id } = useParams();
  const [meetingId, setMeetingId] = useState(Id);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [newMeeting, setNewMeeting] = useState(null);
  const [meetingDetails, setMeetingDetails] = useState(null);
  const [coachAvailability, setCoachAvailability] = useState([]);
  const [role, setRole] = useState(0);
  
  var today = new Date();
  today.setHours(today.getHours() - 2);

  useEffect(() => {
    if (!props.users.length) {
      props.dispatch(fetchUsers());
    }
    props.dispatch(fetchCoaches());
    props.dispatch(fetchMine());
    props.dispatch(fetchMeetings());
  }, []);
  useEffect(() => {
    if (meetingId) {
      props.heroApiClient.startMeeting(meetingId).then((details) => {
        setMeetingDetails(details);
      });
      window.history.pushState({}, '', `/meetings/${meetingId}`);
    }
    else {
      setMeetingDetails(null);
    }
  }, [meetingId]);
  useEffect(() => {
    if(props.meetings && props.meetings.meetings && meetingId)
    try{
      setRole(
      props.meetings.meetings.filter(x=>x.meetingId === meetingId)[0].attendeeInfo.filter((x) => x.userId == props.user.profile.id)[0]
        .attendeeType
    );
    }
    catch{
      console.log('Role not found');
    }
    
  },[meetingId,props.meetings])
  const selectSlot = (slot) => {
    const allEvents = allevents();
    var filteredEvent = allEvents.find(x=>x.start.getTime()<=slot.start.getTime() && x.end.getTime()>=slot.end.getTime());
    if(filteredEvent){
      setSelectedEvent(filteredEvent);
    }
    else{
      setNewMeeting(slot);
    }
    
  }
  const addCoachAvail = (availability) => {
    setCoachAvailability(...coachAvailability, availability);
  };
  const removeCoachAvail = (userId) => {
    setCoachAvailability(coachAvailability?.filter((x) => x.userId != userId));
  };
  const update = () => {
    props.dispatch(fetchMeetings());
    props.dispatch(fetchMine());
    props.dispatch(fetchCoaches());
  };
  const closeModal = (refresh = false) => {
    setSelectedEvent(null);
    if (refresh) {
      update();
    }
  };
  const closeNewMeeting = (refresh=false) => {
    setNewMeeting(null);
    if(refresh){
      update();
    }
    
  };
  const allevents = useCallback(() => {
    var output = [];
    if (props.meetings?.meetings) {
      output = props.meetings?.meetings
        ?.map((meeting) => {
          meeting.start = new Date(meeting.dateTime);
          meeting.end = new Date(meeting.meetingEnd);
          meeting.type = meetingType.meeting;
          return meeting;
        })
        .concat(coachAvailability);
    } else {
      output = coachAvailability;
    }
    if (props.myAvail && props.myAvail.mine) {
      var availabilities = props.myAvail.mine.map((availability) => {
        availability.start = new Date(availability.start);
        availability.end = new Date(availability.end);
        availability.title = "My Availability";
        availability.bgColor = "#9B61B0";
        return availability;
      });
      output = output.concat(availabilities);
    }
    return output;
  }, [props.meetings, props.myAvail, coachAvailability]);

  return (
    <>
      <YourCoaches
        coaches={props.coaches}
        addCoachAvail={addCoachAvail}
        removeCoachAvail={removeCoachAvail}
      />
      <BigCalendar
        selectable
        events={allevents()}
        defaultView="week"
        scrollOffsetMinutes={480}
        scrollToTime={today}
        weekStartsOn={1}
        defaultDate={new Date()}
        onSelectEvent={(event) => setSelectedEvent(event)}
        onSelectSlot={(slotInfo) => {
          selectSlot(slotInfo);
        }}
      />
      <MeetingCard
        meeting={selectedEvent}
        users={props.users}
        currentUserId={props.user.profile.id}
        remove={closeModal}
        setMeetingId={setMeetingId}
        heroApiClient={props.heroApiClient}
        availabilityEvents={props.myAvail.mine}
      />
      <NewMeetingPopup
        details={newMeeting}
        closeModal={closeNewMeeting}
        users={props.users}
        heroApiClient={props.heroApiClient}
      />

      {meetingDetails && (
        <BackgroundBlurProvider>
          <NotificationProvider>
            <UserActivityProvider>
              <ThemeProvider theme={darkTheme}>
                <MeetingProvider>
                  <MeetingModal
                    dismiss={() => {
                      setMeetingId(null);
                      setMeetingDetails(null);
                    }}
                    meetingId={meetingId}
                    meetingDetails={meetingDetails}
                    role={role}
                  />
                </MeetingProvider>
              </ThemeProvider>
            </UserActivityProvider>
          </NotificationProvider>
        </BackgroundBlurProvider>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    users: state.users.items,
    user: state.user,
    heroApiClient: createHeroApiClient(state.user.session.accessToken),
    meetings: state.videos.items,
    coaches: state.coaches.items,
    myAvail: state.coaches.myItems,
  };
};

export default withRouter(connect(mapStateToProps)(Meetings));

import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Table, Form } from "react-bootstrap";
import axios from "axios";

const dummyData = {
  checkDate: 0,
  height: 0,
  weight: 0,
  waistcm: 0,
  feet: 0,
  inches: 0,
  pounds: 0,
  stones: 0,
  waistinches: 0,
  isMetric: true,
  restingHeartRate: 0,
  bloodPressureSystolic: 0,
  bloodPressureDiastolic: 0,
  bmi: 0,
};

const questions = [
  {
    question: "Height",
    questionID: "height",
    type: "number",
  },
  {
    question: "Weight",
    questionID: "weight",
    type: "number",
  },
  {
    question: "Waist (cm)",
    questionID: "waistcm",
    type: "number",
  },
  {
    question: "Resting Heart Rate",
    questionID: "restingHeartRate",
    type: "number",
  },
  {
    question: "Blood Choleseterol (mmol/L)",
    questionID: "bloodCholesterol",
    type: "number",
  },
  {
    question: "Blood Pressure (Systolic)",
    questionID: "bloodPressureSystolic",
    type: "number",
  },
  {
    question: "Blood Pressure (Diastolic)",
    questionID: "bloodPressureDiastolic",
    type: "number",
  },
  {
    question: "BMI",
    questionID: "bmi",
    type: "number",
  },
//   {
//     question: "Information Box 1",
//     questionID: "infoBox1",
//     type: "multiline",
//   },
//   {
//     question: "Information Box 2",
//     questionID: "infoBox2",
//     type: "multiline",
//   },
];

const BasicMOTQuestions = ({userID}) => {
  const [userAnswers, setUserAnswers] = useState(dummyData);
  const [saveStatus, setSaveStatus] = useState(null);

  useEffect(() => {
    // Load user data from API
    const fetchUserData = async () => {
        try {
            const response = await axios.get(`https://tillo.nvgtr.io/auxData/load?userID=${userID}&auxDataType=basicHealthCheck`);
            setUserAnswers(response.data);
        } catch (error) {
            console.error(error);
        }
    }
    fetchUserData();

    }, [userID]);

  useEffect(() => {
    // Calculate BMI whenever height or weight changes
    const calculateBMI = () => {
      const heightInMeters = userAnswers.height / 100; // Convert height to meters
      const weightInKg = userAnswers.weight;
      const bmi = weightInKg / (heightInMeters * heightInMeters);
      setUserAnswers((prevState) => ({ ...prevState, bmi: bmi.toFixed(2) }));
    };

    calculateBMI();
  }, [userAnswers.height, userAnswers.weight]);

  
  const saveUserData = async () => {
    setSaveStatus("Saving...");
    const path = `https://tillo.nvgtr.io/auxData/save?userID=${userID}&auxDataType=basicHealthCheck`;
    const method = 'POST';
    const body = userAnswers; // Construct the body object dynamically
    const response = await fetch(path, {
      method: method,
      headers: {
        'Content-Type': 'application/json', // Specify the content type as JSON
      },
      body: JSON.stringify(body), // Convert the body object to a JSON string
    });
    setSaveStatus("Data Saved");
    return null;
    }


  if(userID === null) { return <div>No user selected</div>; }

  const handleInputChange = (e, questionID) => {
    setSaveStatus(null);
    const value = e.target.value;
    setUserAnswers((prevState) => ({
      ...prevState,
      [questionID]: value,
    }));
  };

  return (
    <Container>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Health MOT</Card.Title>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Question</th>
                    <th>Answer</th>
                  </tr>
                </thead>
                <tbody>
                  {questions.map((item, index) => (
                    <tr key={index}>
                      <td>{item.question}</td>
                      <td>
                        {item.questionID === "bmi" ? (
                          userAnswers[item.questionID]
                        ) : (
                          <Form.Control
                            as={item.type === "multiline" ? "textarea" : "input"}
                            type={item.type}
                            value={userAnswers[item.questionID]}
                            onChange={(e) => handleInputChange(e, item.questionID)}
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td></td>
                    <td>
                      <button onClick={saveUserData}>Save Changes</button>{saveStatus}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default BasicMOTQuestions;

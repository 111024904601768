import React, { useState, useEffect } from "react";
import { Card, Badge, Button } from "react-bootstrap";

const BreathingCountDownDisplay = ({ practiceLength, pauseVideo, startVideo }) => {
  const [timeRemaining, setTimeRemaining] = useState(practiceLength);
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    let timeoutId;

    if (isRunning && timeRemaining > 0) {
      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => {
        setTimeRemaining(timeRemaining - 1);
      }, 1000);
    }

    return () => clearTimeout(timeoutId);
  }, [isRunning, timeRemaining]);

  const handleToggle = () => {
    if (isRunning) {
      setIsRunning(false);
      pauseVideo();
    } else {
      setIsRunning(true);
      startVideo();
    }
  };

  const minutes = Math.floor(timeRemaining / 60);
  const seconds = timeRemaining % 60;

  return (
    <Card className="text-center w-100">
      <Card.Body>
        <h1><Badge className="display-1 w-50">
          {minutes.toString().padStart(2, "0")} :{" "}
          {seconds.toString().padStart(2, "0")}
        </Badge></h1>
        <div className="mt-3">
          <Button className="w-50" onClick={handleToggle}>
            Start/Pause
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};

export default BreathingCountDownDisplay;

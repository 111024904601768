import React, { useState, useEffect, useRef, useMemo } from "react";
import { VictoryPie, VictoryLabel, VictoryTooltip } from 'victory';
import _ from 'lodash';
import { connect } from "react-redux";
import { Tooltip } from "react-svg-tooltip";

import { fetchHealthData } from '../redux/actions/healthData';
import { fetchHealthMetrics } from '../redux/actions/healthMetrics';

import PieSlice from './mk4-components/PieSlice';
import TooltipLabel from './mk4-components/TooltipLabel';

const PIE_CHART_HEIGHT = 350;
const LABEL_RADIUS = PIE_CHART_HEIGHT / 3.25;
const SLICE_OUTER_RADIUS = PIE_CHART_HEIGHT / 2.5;
const SLICE_INNER_RADIUS = PIE_CHART_HEIGHT / 4.5;

const degsToRadians = (degrees) => degrees * Math.PI / 180
export const moodColor = ["", "#7a28c4", "#3a3ec0", "#0ea7c6", "#7fba20", "#03a906"];

const HealthWheel = (props) => {
    const { keyid, displayType, segmentClickHandler, date, userId, calendarWheel, healthMetrics, user } = props;
    const moodRef = useRef(null);
    const [isFetching, setIsFetching] = useState(true);
  const [abortController, setAbortController] = useState();

    const easingTypes = {
        linear: "linear",
        bounce: "bounce",
        easein: "easein",
        easeinout: "easeinout",
        easeout: "easeout",
        elastic: "elastic",
        backout: "backout"
    };
    const wheelSettings = {
        time: 750,
        profilePicRadius: 30,
        wheelSize: 350,
        crossHairWidth: 50,
        crossHairStrokeWidth: 10,
        easing: easingTypes.easeinout,
        centerPoint: { x: 400, y: 400 },
        segTitleRange: PIE_CHART_HEIGHT / 2 - 20,
        majorFontSize: "30px",
        segments: ["Mindfulness", "Nutrition", "Activity", "Movement"], // TODO: not hard-code this
        segGrads: {
          quarters: [
            { x: "0%", y: "100%", name: "topRight" },
            { x: "0%", y: "0%", name: "bottomRight" },
            { x: "100%", y: "0%", name: "bottomLeft" },
            { x: "100%", y: "100%", name: "topLeft" }
          ],
          notCompleteColorsSteps: [
            { offset: "0%", stopColor: "#21B4F1" },
            { offset: "100%", stopColor: "#91FFFE" }
          ],
          completeColorsSteps: [
            { offset: "0%", stopColor: "#00FF8C" },
            { offset: "100%", stopColor: "#00FF8C" }
          ]
        }
      };

      const [svgData, setSvgData] = useState({ wheel: [] });
      const [pieChartData, setPieChartData] = useState({});
      const [selectedSlice, setSelectedSlice] = useState();

      useEffect(() => {
        setAbortController(new AbortController());
      }, []);

      useEffect(() => {
        if(abortController && abortController.abort) {
          return () => abortController.abort();
        }
      }, [abortController]);

      useEffect(() => {
        if(!abortController || calendarWheel){
          return;
        }
        props.dispatch(fetchHealthData(userId, date, abortController.signal));

        if (props.healthData && props.healthData[userId] && props.healthData[userId][date] && props.healthData[userId][date].items.length > 0) {
          setIsFetching(false);
          // NProgress.done();
        } else {
          setIsFetching(true);
          // NProgress.start();
        }
      }, [props.healthData, abortController])

      useEffect(() => {
        props.dispatch(fetchHealthMetrics());
      }, [props.healthMetrics]);

    function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
        var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

        return {
          x: centerX + radius * Math.cos(angleInRadians),
          y: centerY + radius * Math.sin(angleInRadians)
        };
      }

      function polarToCartesianInvert(centerX, centerY, radius, angleInDegrees) {
        var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

        return {
          x: centerX + radius * Math.cos(angleInRadians),
          y: centerY + radius * Math.sin(angleInRadians)
        };
      }

    function describeArc(x, y, radius, startAngle, endAngle) {
        var start = polarToCartesian(x, y, radius, endAngle);
        var end = polarToCartesian(x, y, radius, startAngle);

        var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

        var d = [
          "M",
          start.x,
          start.y,
          "A",
          radius,
          radius,
          0,
          largeArcFlag,
          0,
          end.x,
          end.y
        ].join(" ");

        return d;
      }

      function describeArcInvert(x, y, radius, startAngle, endAngle) {
        var start = polarToCartesianInvert(y, x, radius, endAngle);
        var end = polarToCartesianInvert(y, x, radius, startAngle);

        var largeArcFlag = startAngle - endAngle <= 180 ? "0" : "1";

        var d = [
          "M",
          end.x,
          end.y,
          "A",
          radius * -1,
          radius * -1,
          0,
          0,
          1,
          start.x,
          start.y
        ].join(" ");

        return d;
      }

    function addText(backgroundElements) {
        // ********** Adding Text around each sector
        var mindDefPath = React.createElement(
          "defs",
          { key: "defs" + keyid },
          React.createElement("path", {
            id: "textpath",
            key: "textpath",
            d: describeArcInvert(PIE_CHART_HEIGHT / 2, PIE_CHART_HEIGHT / 2, wheelSettings.segTitleRange, 0, 90)
          })
        );
        backgroundElements.push(mindDefPath);

        var segMindTitle = React.createElement(
          "text",
          { key: "segTitleMindfulness" + keyid },
          React.createElement(
            "textPath",
            {
              className: "seg-title-text-mk4",
              key: "segTitleMindfulness" + keyid,
              href: "#textpath",
              textAnchor: "middle",
              startOffset: "50%",
              fill:'#768093',
              side: "left"
            },
            "Recovery"
          )
        );
        backgroundElements.push(segMindTitle);

        // ********** Adding Text - Nutration
        var nutDefPath = React.createElement(
          "defs",
          { key: "defsNutration" + keyid },
          React.createElement("path", {
            id: "nutDefPath",
            key: "textpath",
            d: describeArc(PIE_CHART_HEIGHT / 2, PIE_CHART_HEIGHT / 2, wheelSettings.segTitleRange + 23, 90, 180)
          })
        );
        backgroundElements.push(nutDefPath);
        var segNutTitle = React.createElement(
          "text",
          { key: "segTitleNutrition" + keyid },
          React.createElement(
            "textPath",
            {
              className: "seg-title-text-mk4",
              key: "segTitleNutrition" + keyid,
              href: "#nutDefPath",
              fill:'#768093',
              textAnchor: "middle",
              startOffset: "50%",
              side: "left"
            },
            "Nutrition"
          )
        );
        backgroundElements.push(segNutTitle);

        var activityDefPath = React.createElement(
          "defs",
          { key: "defsSctivity" + keyid },
          React.createElement("path", {
            id: "activityDefPath",
            key: "textpath",
            d: describeArc(PIE_CHART_HEIGHT / 2, PIE_CHART_HEIGHT / 2, wheelSettings.segTitleRange + 23, 180, 270)
          })
        );
        backgroundElements.push(activityDefPath);
        var segActTitle = React.createElement(
          "text",
          { key: "segTitleActivity" + keyid },
          React.createElement(
            "textPath",
            {
              className: "seg-title-text-mk4",
              key: "actTitleTextActivity" + keyid,
              href: "#activityDefPath",
              textAnchor: "middle",
              startOffset: "50%",
              fill:'#768093',
              side: "left"
            },
            "Activity"
          )
        );
        backgroundElements.push(segActTitle);

        // ********** Adding Text - movement
        var movementDefPath = React.createElement(
          "defs",
          { key: "defsMovement" + keyid },
          React.createElement("path", {
            id: "movementDefPath",
            key: "textpath",
            d: describeArcInvert(PIE_CHART_HEIGHT / 2, PIE_CHART_HEIGHT / 2, wheelSettings.segTitleRange, 270, 360)
          })
        );
        backgroundElements.push(movementDefPath);
        var segMovementTitle = React.createElement(
          "text",
          { key: "segTitleMovement" + keyid },
          React.createElement(
            "textPath",
            {
              className: "seg-title-text-mk4",
              key: "movementTitleText",
              href: "#movementDefPath",
                fill:'#768093',
              textAnchor: "middle",
              startOffset: "50%",
              side: "left"
            },
            "Movement"
          )
        );
        backgroundElements.push(segMovementTitle);
      }

      function loadTheWheel() {
        var backgroundElements = [];

        // addBackgroundRadarSections(backgroundElements);

        // // These are the segments that spread out
        // addSegments(backgroundElements);

        // getCrossHairs(backgroundElements);

        if (displayType == "big") {
        //   addFigures(backgroundElements);
          addText(backgroundElements);
        //   addProfilePic(backgroundElements);
        }

        setSvgData({ wheel: backgroundElements });
      }

      useEffect(() => {
        // Reset Everything
        // setSvgData({ wheel: [] });
        // setWheelNumbers({});
        loadTheWheel();
        // clearInterval(segScoreInt);
        // if (props.data && !props.data.isFetching && props.data.isValid) {
        //   aniState > -1 ? setAniState(-1) : setAniState(aniState - 1);
        // }
        // else {
        //   setAniState(3)
        // }

        const items = props.data?.items;
        if (!items || !items.length) return;

        const healthMetricsByType = _.groupBy(healthMetrics.items, "healthWheelSectionName");
        if (!healthMetricsByType) return;

        // Activity metrics
      const activeDuration = _.find(items, { name: 'Active Duration' });

      const activityPercentages = [];
      _.each(healthMetricsByType.Activity, healthMetric => {
        const healthDataMetric = _.find(items, { healthMetricId: healthMetric.id });
        if (healthDataMetric?.goalValue > 0) activityPercentages.push({ percentage: Math.min((healthDataMetric.recordedValue / healthDataMetric.goalValue) * 100, 100), label: healthDataMetric.name, recordedValue: healthDataMetric.recordedValue, healthMetricType: healthDataMetric.healthMetricType });
      })

      // Movement metrics
      const movementPercentages = [];
      _.each(healthMetricsByType.Movement, healthMetric => {
        const healthDataMetric = _.find(items, { healthMetricId: healthMetric.id });
        if (healthDataMetric?.goalValue > 0) movementPercentages.push({ percentage: Math.min((healthDataMetric.recordedValue / healthDataMetric.goalValue) * 100, 100), label: healthDataMetric.name, recordedValue: healthDataMetric.recordedValue, healthMetricType: healthDataMetric.healthMetricType });
      })

      // Recovery metrics
      const mindfulnessPercentages = [];
      _.each(healthMetricsByType.Recovery, healthMetric => {
        const healthDataMetric = _.find(items, { healthMetricId: healthMetric.id });
        if (healthDataMetric?.goalValue > 0) mindfulnessPercentages.push({ percentage: Math.min((healthDataMetric.recordedValue / healthDataMetric.goalValue) * 100, 100), label: healthDataMetric.name, recordedValue: healthDataMetric.recordedValue, healthMetricType: healthDataMetric.healthMetricType });
      })

      // Nutrition metrics
      const nutritionPercentages = [];
      _.each(healthMetricsByType.Nutrition, healthMetric => {
        const healthDataMetric = _.find(items, { healthMetricId: healthMetric.id });
        if (healthDataMetric?.goalValue > 0) nutritionPercentages.push({ percentage: Math.min((healthDataMetric.recordedValue / healthDataMetric.goalValue) * 100, 100), label: healthDataMetric.name, recordedValue: healthDataMetric.recordedValue, healthMetricType: healthDataMetric.healthMetricType });
      })

      // Mood
      const moodMetric = _.find(items, { name: 'Mood' });
      const moodValue = moodMetric ? moodMetric.recordedValue : 0;

      setPieChartData({ activityPercentages, movementPercentages, mindfulnessPercentages, nutritionPercentages, mood: moodValue, activeDuration:activeDuration?.recordedValue });

      }, [props.data]);

    const { activityPercentages, movementPercentages, mindfulnessPercentages, nutritionPercentages, mood, activeDuration } = pieChartData;

    const activitySlices = _.map(activityPercentages, ({ percentage, label, recordedValue, ...rest }, index) => ({ x: label, y: percentage / activityPercentages.length, _id: label, type: 'activity', recordedValue, sliceIndex: index, ...rest }))
    activitySlices.push({ x: 'Filler', y: 100 - _.sumBy(activityPercentages, ({ percentage }) => percentage / activityPercentages.length), filler: true });

    const movementSlices = _.map(movementPercentages, ({ percentage, label, recordedValue, ...rest }, index) => ({ x: label, y: percentage / movementPercentages.length, _id: label, type: 'movement', recordedValue, sliceIndex: index, ...rest }));
    movementSlices.push({ x: 'Filler', y: 100 - _.sumBy(movementPercentages, ({ percentage }) => percentage / movementPercentages.length), filler: true });

    const mindfulnessSlices = _.map(mindfulnessPercentages, ({ percentage, label, recordedValue, ...rest }, index) => ({ x: label, y: percentage / mindfulnessPercentages.length, _id: label, type: 'mindfulness', recordedValue, sliceIndex: index, ...rest }));
    mindfulnessSlices.push({ x: 'Filler', y: 100 - _.sumBy(mindfulnessPercentages, ({ percentage }) => percentage / mindfulnessPercentages.length), filler: true });

    const nutritionSlices = _.map(nutritionPercentages, ({ percentage, label, recordedValue, ...rest }, index) => ({ x: label, y: percentage / nutritionPercentages.length, _id: label, type: 'nutrition', recordedValue, sliceIndex: index, ...rest }));
    nutritionSlices.push({ x: 'Filler', y: 100 - _.sumBy(nutritionPercentages, ({ percentage }) => percentage / nutritionPercentages.length), filler: true });

    const totalActivityPercentage = _.sumBy(activityPercentages, ({ percentage }) => {
      return percentage / activityPercentages.length;
    })

    const totalMovementPercentage = _.sumBy(movementPercentages, ({ percentage }) => {
      return percentage / movementPercentages.length;
    })

    const totalMindfulnessPercentage = _.sumBy(mindfulnessPercentages, ({ percentage }) => {
      return percentage / mindfulnessPercentages.length;
    })

    const totalNutritionPercentage = _.sumBy(nutritionPercentages, ({ percentage }) => {
      return percentage / nutritionPercentages.length;
    })

    const origin = { x: PIE_CHART_HEIGHT / 2, y: PIE_CHART_HEIGHT / 2 };

    const onSelectSlice = (evt, props) => {
        if (selectedSlice && props.datum._id === selectedSlice._id) {
          setSelectedSlice(null);
        } else if (props.datum.percentage !== 0) {
          setSelectedSlice(props.datum);
        }
    }

    function getMoodColor() {
        var value = props.moodData && props.moodData.items.some(x => x.healthMetricType == "mood") ? props.moodData.items.find(x => x.healthMetricType == "mood").recordedValue : 0
        if (value > 0) {
            var title = "";
            var icon = "";

            switch (value) {
                case 1:
                    title = "Sad";
                    icon = "far fa-sad-tear";
                    break;
                case 2:
                    title = "Unhappy";
                    icon = "far fa-frown";
                    break;
                case 3:
                    title = "Ok";
                    icon = "far fa-meh";
                    break;
                case 4:
                    title = "Happy";
                    icon = "far fa-smile";
                    break;
                case 5:
                    title = "Very Happy";
                    icon = "far fa-grin-squint";
                    break;
                default:
                    break;
            }

            return { color: moodColor[value], title, icon };
        }

        return { color: "white", title };
    }

    let pieData;
    let targetPercentage;
    const wheelConfig = user.wheelConfig;
    switch (wheelConfig) {
      case 'nutrition':
        pieData = nutritionSlices;
        targetPercentage = totalNutritionPercentage
        break;
      case 'physical-health':
        pieData = _.concat(activitySlices,  movementSlices);
        targetPercentage = (totalActivityPercentage + totalMovementPercentage) / 2
        break;
      case 'recovery':
        pieData = mindfulnessSlices;
        targetPercentage = totalMindfulnessPercentage
        break;
      default: {
        pieData = _.concat(mindfulnessSlices, nutritionSlices, activitySlices, movementSlices);
        break
      }
    }

    return (
        <svg
            id="wheel"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width="100%"
            viewBox={`0,0,${PIE_CHART_HEIGHT},${PIE_CHART_HEIGHT}`}
            onClick={() => {}}
        >
            <defs>
                <linearGradient id="gradient1" gradientTransform="rotate(90)">
                    <stop offset="0%"  stop-color="#82DFE3" />
                    <stop offset="100%" stop-color="#15CBCE" />
                </linearGradient>
            </defs>
            {svgData.wheel}
            <rect width={PIE_CHART_HEIGHT} height={PIE_CHART_HEIGHT} fill="rgba(0, 0, 0, 0)" onClick={() => setSelectedSlice(null)} />
            {displayType == "big" && (
                <foreignObject
                    x={origin.x - wheelSettings.profilePicRadius}
                    y={origin.y - wheelSettings.profilePicRadius}

                    width={wheelSettings.profilePicRadius * 2} height={wheelSettings.profilePicRadius * 2}>

                
                </foreignObject>
        )} <>
        <rect
                fill="rgba(0, 0, 0, 0)"
                  width={wheelSettings.profilePicRadius * 2}
                  height={wheelSettings.profilePicRadius * 2}
                  x={origin.x - wheelSettings.profilePicRadius}
                  y={origin.y - wheelSettings.profilePicRadius}
                  onClick={props.onAvatarClick}
            />
            <foreignObject ref={moodRef}
                           x={origin.x - wheelSettings.profilePicRadius}
                           y={origin.y - wheelSettings.profilePicRadius}
                           onClick={props.onAvatarClick}
                           width="61" height="61">
              <i xmlns="http://www.w3.org/1999/xhtml" className={getMoodColor().icon} style={{fontSize:displayType == "big"? 62: 61, color:getMoodColor().color}}></i>
              <p style={{fontSize: "41px", textTransform: "uppercase", color: "#15cbce", fontWeight: "400", marginBottom: "0", textAlign: "center", cursor: "pointer"}}><i class="fal fa-plus"></i></p>
            </foreignObject>
            <Tooltip triggerRef={moodRef}>
              <text x={0} y={-10} fontSize={16} fill='black'>{getMoodColor().title}</text>
            </Tooltip>

          </>
            <VictoryPie
                standalone={false}
                height={PIE_CHART_HEIGHT}
                labelRadius={LABEL_RADIUS}
                width={PIE_CHART_HEIGHT}
                style={{ labels: { fontSize: 12, fill: 'black' } }}
                labels={() => null}
                data={wheelConfig? [
                  { x: wheelConfig, y: 100, _id: 1 }
                ] : [
                    { x: 'Recovery', y: 100, _id: 1 },
                    { x: 'Nutrition', y: 100, _id: 2 },
                    { x: 'Activity', y: 100, _id: 4 },
                    { x: 'Movement', y: 100, _id: 3 },
                ]}
                padAngle={2}
                dataComponent={(
                    <PieSlice
                        numSlices={4}
                        pieChartHeight={PIE_CHART_HEIGHT}
                        outerRadius={SLICE_OUTER_RADIUS}
                        innerRadius={SLICE_INNER_RADIUS}
                        background
                    />
                )}
                animate={{ duration: 400 }}
                colorScale={['#efefef']}
            />
            {activityPercentages && (
                <VictoryPie
                    standalone={false}
                    height={PIE_CHART_HEIGHT}
                    labelRadius={LABEL_RADIUS}
                    width={PIE_CHART_HEIGHT}
                    style={{ labels: { fontSize: 8, fill: '#768093' } }}
                    data={pieData}
                    padAngle={() => degsToRadians(1)}
                    labels={({ datum }) => datum._id}
                    labelPlacement="perpendicular"
                    labelComponent={(
                      <TooltipLabel
                        outerRadius={SLICE_OUTER_RADIUS}
                        innerRadius={SLICE_INNER_RADIUS}
                        pieChartHeight={PIE_CHART_HEIGHT}
                        selectedSlice={selectedSlice}
                      />
                    )}
                    dataComponent={(
                        <PieSlice
                        numSlices={4}
                        pieChartHeight={PIE_CHART_HEIGHT}
                        selectedSlice={selectedSlice}
                        outerRadius={SLICE_OUTER_RADIUS}
                        innerRadius={SLICE_INNER_RADIUS}
                        />
                        )}
                    animate={{ duration: 400 }}
                    colorScale={[
                        'url(#gradient1)',
                    ]}
                    events={[{
                        target: 'data',
                        eventHandlers: {
                            onClick: onSelectSlice,
                        },
                    }]}
                />
            )}
            {!calendarWheel && wheelConfig ? (
                                <VictoryLabel text={`${targetPercentage ? targetPercentage.toFixed(0) : 0}%`}
                                  x={origin.x}
                                  y={origin.y/2}
                                  textAnchor="middle" verticalAnchor="end" style={{ fontSize: 12, fill: '#768093', cursor: 'default', userSelect: 'none' }}
                                  orientation="bottom"
                                />
                          ) : (
              <>
                <VictoryLabel text={`${totalMindfulnessPercentage ? totalMindfulnessPercentage.toFixed(0) : 0}%`} x={origin.x + SLICE_INNER_RADIUS} y={origin.y - SLICE_INNER_RADIUS + 5}
                    textAnchor="middle" verticalAnchor="end" style={{ fontSize: 12, fill: '#768093', cursor: 'default', userSelect: 'none' }}
                    orientation="bottom"
                />
                <VictoryLabel text={`${totalMovementPercentage ? totalMovementPercentage.toFixed(0) : 0}%`} x={origin.x - SLICE_INNER_RADIUS} y={origin.y - SLICE_INNER_RADIUS + 5}
                    textAnchor="middle" verticalAnchor="end" style={{ fontSize: 12, fill: '#768093', cursor: 'default', userSelect: 'none' }}
                    orientation="top"
                />
                <VictoryLabel text={`${totalNutritionPercentage ? totalNutritionPercentage.toFixed(0) : 0}%`} x={origin.x + SLICE_INNER_RADIUS} y={origin.y + SLICE_INNER_RADIUS - 5}
                    textAnchor="middle" verticalAnchor="start" style={{ fontSize: 12, fill: '#768093', cursor: 'default', userSelect: 'none' }}
                    orientation="bottom"
                />
                <VictoryLabel text={`${totalActivityPercentage ? totalActivityPercentage.toFixed(0) : 0}%`} x={origin.x - SLICE_INNER_RADIUS} y={origin.y + SLICE_INNER_RADIUS - 5}
                    textAnchor="middle" verticalAnchor="start" style={{ fontSize: 12, fill: '#768093', cursor: 'default', userSelect: 'none' }}
                    orientation="bottom"
                />
              </>
            )}
            
        </svg>
    )
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.user,
        data: (state.healthData[ownProps.userId] || {})[ownProps.date] || null,
        moodData: (state.healthDataMood[ownProps.userId] || {})[ownProps.date] || null,
        healthData: state.healthData,
        healthDataMood: state.healthDataMood,
        healthMetrics: state.healthMetrics
    };
}

export default connect(mapStateToProps)(HealthWheel);

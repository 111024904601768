import moment from 'moment';

const momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment);

const Utils = window.Utils = {
    urlRegex: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]+(:[0-9]{1,5})?(\/.*)?$/,
    getDPForHealthMetricType: (healthMetricType) => {
        switch (healthMetricType) {
            case 'steps':
            case 'active_duration':
            case 'energy_burned':
            case 'carbohydrate':
            case 'fat':
            case 'protein':
            case 'energy_consumed':
                return 0;
            default:
                return 1;
        }
    },
    round(number, decimalPlaces) {
        if (!number) {
          return 0
        }
          // @ts-ignore
        return Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces)
    },
    isDurationHealthMetric: (healthMetricType) => {
        switch (healthMetricType) {
            case 'sleep_duration':
            case 'in_bed_duration':
                return true;
            default:
                return false;
        }
    },
    formatDurationHealthMetric: (healthMetricType, value) => {
        switch (healthMetricType) {
            case 'sleep_duration':
            case 'in_bed_duration':
                return moment.duration(value, 'hours').format('h:mm');
            default:
                return value;
        }
    },
}

export default Utils;

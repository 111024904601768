import { REQUEST_USERS, RECEIVE_USERS, INVALIDATE_USERS } from '../actions/users'
import { END_USER_SESSION } from '../actions/userSession'

const initialState = {
    isFetching: false,
    isValid: false,
    items: []
}

export default function users(state = initialState, action) {
    switch (action.type) {
        case REQUEST_USERS:
            return {
                ...state,
                isFetching: true,
                isValid: true
            }
        case RECEIVE_USERS:
            return {
                ...state,
                isFetching: false,
                isValid: true,
                items: action.payload.users.map(user => ({
                    ...user,
                    name: `${user.givenName} ${user.familyName}`
                })),
                lastUpdated: action.payload.receivedAt
            }
        case INVALIDATE_USERS:
            return {
                ...state,
                isValid: false
            }
        case END_USER_SESSION:
            return initialState
        default:
            return state
    }
}
import { format as dfFormat, addDays, subDays, addWeeks, addMonths, subMonths } from "date-fns";

var CalendarFunctions = {
  getDayName: function(dateStr, locale) {
    var date = new Date(dateStr);
    return date.toLocaleDateString(locale, { weekday: "long" });
  },

  buildWeek: function(wrkDate, activeMonth) {
    var week = [];

    for (let index = 0; index < 7; index++) {
      var thisDate = addDays(wrkDate, index);
      week.push({
        dateFor: thisDate,
        dateStr: dfFormat(thisDate, "yyyy-MM-dd"),
        dayName: dfFormat(thisDate, "eeee"),
        dateOd: dfFormat(thisDate, "Do"),
        activeDay: activeMonth - 1 == thisDate.getMonth() ? true : false
      });
    }

    return week;
  },

  getCalDatMatrixByMonth: function(year, month) {
    //Settings:
    var calSettings = {
      firstDayOfWeek: "Mon"
    };

    // Define the start date
    var monthToDisplay = new Date(year, month - 1, 1);
    var wrkDate = new Date(year, month - 1, 1);

    var lastDay = dfFormat(wrkDate, "E");

    while (lastDay != calSettings.firstDayOfWeek) {
      wrkDate = addDays(wrkDate, 1);
      lastDay = dfFormat(wrkDate, "E");
    }

    // Wrk date is now set to the start of the week
    // If the date is not 1 then work it backwards

    while (dfFormat(wrkDate, "d") != 1) {
      wrkDate = subDays(wrkDate, 1);
    }

    // Now work it backwards again to the start of the week
    while (dfFormat(wrkDate, "E") != calSettings.firstDayOfWeek) {
      wrkDate = addDays(wrkDate, -1);
    }

    // Now add days until the end of the month/week
    var weeks = [];
    while (addMonths(monthToDisplay, 1) > wrkDate) {
      weeks.push(CalendarFunctions.buildWeek(wrkDate, month));
      wrkDate = addWeeks(wrkDate, 1);
    }

    return weeks;
  },

  /// The argument needs to be a actual date and NOT a string
  getCalDatMatrixByDate: function(date) {
    var year = dfFormat(date, "yyyy");
    var month = dfFormat(date, "M");

    return CalendarFunctions.getCalDatMatrixByMonth(year, month);
  },

  convertDateStringToDate: function(dateString) {
    var dateParts = dateString.split("-");

    return new Date(dateParts[0], dateParts[1] - 1, dateParts[2], 0, 0, 0, 0);
  }
};
export default CalendarFunctions;

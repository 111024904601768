import { connect } from "react-redux";
import React, { useEffect, useState} from "react";
import "./chat.scss";
import { Link,useParams } from "react-router-dom";
import { createHeroApiClient } from "../hero-api-client";
import {
  Button as ChimeButton,
  PrimaryButton,
  Input,
} from "amazon-chime-sdk-component-library-react";
import { ThemeProvider } from "styled-components";
import {
  MeetingProvider,
  UserActivityProvider,
  NotificationProvider,
  BackgroundBlurProvider,
  darkTheme,
} from "amazon-chime-sdk-component-library-react";
import Meeting from "./Meeting";

const GuestMeeting = ({heroApiClient}) => {
  let { id: Id } = useParams();
  const [entered, setEntered] = useState(false);
  const [meetingDetails, setMeetingDetails] = useState(false);
  const [userName, setUserName] = useState("Guest");
  const [accepted, setAccepted] = useState(false);

  useEffect(()=>{
    console.log('accepted')
    if(accepted){
      var interval = setInterval(()=>{
        heroApiClient.requestGuestMeeting(Id).then(res=>{
        if(res.meeting){
          setMeetingDetails(res);
          setEntered(true);
          clearInterval(interval);
        }
          });
        },5000);
        return () => {clearInterval(interval);}
      }
  },[accepted])
  const GuestEntryForm = () => {
    return <>
      <div className="callScreen flexCentered">
        <div className="form400">
          {accepted ? (
            <>
              <h2>Thank you {userName}.</h2>
              <h4> Waiting for start of meeting...</h4>

              Guests cannot start the meeting. You will gain access to the meeting pre-screen as soon as a registered user joins the meeting. If you think something is wrong please
              check your internet connection and refresh the page.
              <div>
                <ChimeButton label="Change my name" onClick={()=>setAccepted(false)} />
              </div>
            </>
          ) : (
            <>
              Hello Guest, please enter your name:
              <Input
                value={userName}
                onInput={(obj) => setUserName(obj.target.value)} />
              <div className="flexCentered">
                <PrimaryButton
                  label="Go to meeting"
                  disabled={!userName}
                  onClick={() => {
                    setAccepted(true);
                  } } />
              </div>
              If you have a Navigator account log in first.
              <Link to={"/login?r=/meetings/"+Id}>
                <ChimeButton label="Go to Log-In" />
              </Link>
            </>
          )}
        </div>
      </div>
    </>;
  }





  return (
    <ThemeProvider theme={darkTheme}>
      <MeetingProvider>
       {entered ? (
        <BackgroundBlurProvider>
        <NotificationProvider>
        <UserActivityProvider>
          <Meeting meetingDetails={meetingDetails} username={userName} meetingId={Id} />
          </UserActivityProvider>
          </NotificationProvider>
          </BackgroundBlurProvider>
        ) : (
          GuestEntryForm()
        )}
      </MeetingProvider>
    </ThemeProvider>
  );

  
};


const mapStateToProps = (state) => {
  return {
    heroApiClient: createHeroApiClient(state.user.session.accessToken)
  };
};

export default connect(mapStateToProps)(GuestMeeting);

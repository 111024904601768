import React from 'react';
import { Carousel } from 'react-bootstrap';

const HeadImage = ({article}) => {
    return (
        <>
        <div style={{
            backgroundImage: `url(${article.headImage})`,
            //background: `linear-gradient(to bottom, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0.98)), url(${article.headImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            height: '500px',
            width: '100%',
            marginBottom: 20
        }}
        className="relative h-full w-full ">
        
           
        </div>
         <div style={{marginLeft:20}}>
            <h1 className="text-4xl font-bold bold mb-4 text-black">{article.title}</h1>
        </div>
        </>
    );
}

export default HeadImage;
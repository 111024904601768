import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Col, Card, ProgressBar } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { fetchHealthMetrics } from '../../redux/actions/healthMetrics'
import { FaLock } from 'react-icons/fa';
import round from "../../shared/round";

function ChallengeCard(props) {
  const { challenge: { id, name, description, endDate, totalContributionValue, targetContributionValue, members, closed, healthMetricId, userIsInChallenge, isPrivate } } = props
  const [units, setUnits] = useState()
  const [model, setModel] = useState({});

  useEffect(() => {
    props.dispatch(fetchHealthMetrics())
  }, [])

  useEffect(() => {
    const healthMetrics = props.healthMetrics.items.find(x => x.id == healthMetricId)
    if(healthMetrics) {
      setUnits(healthMetrics.units)
      const [recordedValue, units] = formatUnits(totalContributionValue, healthMetrics.units);
      const type = healthMetrics.name;
      setModel({
        units: units,
        recordedValue: window.Utils.isDurationHealthMetric(type) ? window.Utils.formatDurationHealthMetric(type, recordedValue) : round(recordedValue, window.Utils.getDPForHealthMetricType(type)),
      });
    }
  }, [props.healthMetrics])

  const progressBar = () => {
    const progress = targetContributionValue == 0 ? 0 : (model.recordedValue / targetContributionValue * 100)
    return <ProgressBar variant={'accent'} now={progress} label={`${progress.toFixed(0)}%`} />;
  }

  function formatUnits(value, unit) {
    let newValue = 0;
    let newUnit = "";

    switch (true) {
      case unit === "seconds":
        newUnit = "minutes";
        newValue = value / 60;
        break;
      case unit === "meters":
        newUnit = "KM";
        newValue = parseFloat(parseFloat(value / 1000).toFixed(2));
        break;
      case unit === "int":
        newUnit = "";
        newValue = value;
        break;
      case unit === null:
        newUnit = "";
        newValue = value;
        break;
      default:
        newUnit = unit;
        newValue = value;
        break;
    }

    return [newValue, newUnit];
  }

  return (
    <Col md={4}>
      <Link to={{ pathname: `/challenges/${id}/${name}` }}>
        <Card className={`bm challenge-${id}`} style={{ boxShadow: "0px 0px 15px 0px rgba(0,0,0,0.15)" }}>
          <Card.Header className="card-header--transparent" as="h4">
            {name}
            {isPrivate && <FaLock />}
          </Card.Header>
          <Card.Body className="pt0">
            {!closed && userIsInChallenge &&
              <>
                <div>
                  {members.slice(0, 5).map(member => {
                    return (
                      <img src={member.imageUrl || `https://eu.ui-avatars.com/api?name=${member.name}`} style={{ margin: "0px 0.1px", height: "25px", width: "25px", borderRadius: "50%" }} />
                    );
                  })}
                </div>
                <p style={{ marginTop: "0.8rem" }}>{description}</p>
                <p style={{ marginTop: "0.65rem", fontSize: "0.75rem"}}>{`${model.recordedValue} / ${targetContributionValue.toFixed(0)} ${model.units}`}</p>
                <div style={{ width: "80%" }}>{progressBar()}</div>

                <p className="meta-text--large mt-5">{new Date(endDate).toDateString()}</p>
                <p className="meta-text--small">Ends</p>
              </>
            }

            {!closed && !userIsInChallenge &&
              <p style={{ color: "#909090", marginTop: "0.8rem" }}>Click to join this challenge</p>
            }

            {closed &&
              <p style={{ color: "#909090", marginTop: "0.8rem" }}>This challenge has been cancelled</p>
            }
          </Card.Body>
        </Card>
      </Link>
    </Col>
  )
}

const mapStateToProps = state => {
  return {
    healthMetrics: state.healthMetrics
  };
}

export default connect(mapStateToProps)(ChallengeCard);

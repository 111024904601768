import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { useParams, withRouter } from "react-router-dom";
//import ChatReport from '../components/reports/ChatReport';
import { createHeroApiClient } from "../hero-api-client";
import LoadingSpinner from "../components/controls/LoadingSpinner";
import "./chat.scss";
import {
  Container,
  Spinner,
  Button,
  Row,
  Col,
  Card,
  Tab,
  Tabs,
} from "react-bootstrap";
import {
  Button as ChimeButton,
  PrimaryButton,
  VideoTileGrid,
  useMeetingManager,
  PreviewVideo,
  CameraSelection,
  QualitySelection,
  MicSelection,
  SpeakerSelection,
  useBackgroundBlur,
  useVideoInputs,
  VideoInputBackgroundBlurControl,
  AudioOutputControl,
  ControlBar,
  AudioInputControl,
  ContentShareControl,
  useUserActivityState,
  Phone as PhoneIcon,
  Chat as ChatIcon,
  Camera,
  Microphone,
  LeaveMeeting,
  HandRaise,
  ControlBarButton,
  Textarea,
  Attendees,
  RosterGroup,
  RosterCell,
  RosterAttendee,
  lightTheme,
  useLocalVideo,
  useToggleLocalMute,
  MeetingStatus,
  useMeetingStatus,
  SecondaryButton
} from "amazon-chime-sdk-component-library-react";
import {
  MeetingSessionConfiguration,
  isVideoTranformDevice,
  VideoTransformDevice,
} from "amazon-chime-sdk-js";
import MuteKickButton from "../components/video/MuteKickButton";
import ReactStars from "react-rating-stars-component";
import MeetingMessagingService from "../../src/components/video/WebSocketHandler";
import { ThemeProvider } from "styled-components";
import WebSocketService, {
  Commands,
} from "../components/video/WebSocketService";
import { leaveMeeting } from "../redux/actions/video";
import MeetingGrid from "../components/video/MeetingGrid";

function Meeting(props) {
  const guest = !props.user.session.isAuthenticated;
  const meetingStatus = useMeetingStatus();
  const { isUserActive } = useUserActivityState();
  const meetingManager = useMeetingManager();
  const { selectedDevice } = useVideoInputs();
  const { isBackgroundBlurSupported, createBackgroundBlurDevice } =
    useBackgroundBlur();
  const { isVideoEnabled, toggleVideo } = useLocalVideo();
  const { muted, toggleMute } = useToggleLocalMute();
  const [screen, setScreen] = useState(0);
  const [rating, setRating] = useState(null);
  const [feedback, setFeedback] = useState(null);
  const [isVideoBlurred, setIsVideoBlurred] = useState(false);
  const [LoadingMessage, setLoadingMessage] = useState("Loading Meeting...");
  const [showChat, setShowChat] = useState(false);
  const [startupVideo, setStartupVideo] = useState(true);
  const [startupMic, setStartupMic] = useState(true);
  const [showAttendees, setShowAttendees] = useState(true);
  const [raiseHand, setRaiseHand] = useState(false);
  const [unreadChats, setUnreadChats] = useState(0);
  const [command, setCommand] = useState(null);
  const [endSure, setEndSure] = useState(false);
  const {
    chatMessages,
    sendChatMessage,
    updateUserDetails,
    attendees,
    announceDeparture,
    announceEntry,
    sendCommandToOne,
    sendCommandToAll
  } = WebSocketService(
    props.meetingId,
    guest
      ? props.meetingDetails.attendee.attendee.attendeeId
      : props.meetingDetails.attendee.attendeeId,
    guest
      ? props.username
      : props.user.profile.givenName + " " + props.user.profile.familyName,
     setCommand
  );
  const ToggleChat = () => {
    if (!showChat) {
      setUnreadChats(0);
    }
    setShowChat(!showChat);
  };
  const ToggleAttendees = () => {
    setShowAttendees(!showAttendees);
  };
  useEffect(() => {
    if (!showChat && chatMessages.length) {
      setUnreadChats(unreadChats + 1);
    }
  }, [chatMessages]);
  useEffect(()=>{
    if(command=== Commands.mute){
      if(!muted){
        toggleMute();
        setCommand(null);
      }
    }
    else if(command === Commands.kick){
      EndMeeting();
      setCommand(null);
    }
  },[command])
  useEffect(() => {
    async function toggleBackgroundBlur() {
      if (!selectedDevice) {
        return;
      }
      try {
        let current = selectedDevice;
        if (isVideoBlurred) {
          current = await createBackgroundBlurDevice(selectedDevice);
        } else {
          current = await selectedDevice.intrinsicDevice();
        }
        await meetingManager.startVideoInputDevice(current);
      } catch (error) {
        // Handle device selection failure here
        console.error("Failed to toggle BackgroundBlur");
      }
    }
    toggleBackgroundBlur();
  }, [isVideoBlurred]);

  useEffect(() => {
    if (meetingStatus === MeetingStatus.Succeeded) {
      if (startupVideo) {
        toggleVideo();
      }
      if (muted === startupMic) {
        toggleMute();
      }
    }
  }, [meetingStatus]);

  useEffect(() => {
    joinMeeting();
  }, [props.meetingId]);
  const joinMeeting = async () => {
    try {
      var meeting = guest
        ? props.meetingDetails
        : await props.heroApiClient.startMeeting(props.meetingId);
      const Meeting = guest ? meeting.meeting : meeting.meetingId;
      const meetingSessionConfiguration = new MeetingSessionConfiguration(
        Meeting,
        meeting.attendee
      );
      await meetingManager.join(meetingSessionConfiguration);

      setScreen(1);
    } catch (e) {
      setLoadingMessage(
        "Error: failed to load. Refresh the page to try again. If you have come here via a link make sure it is valid."
      );
    }
  };
  const startMeeting = async () => {
    await meetingManager.start();
    announceEntry();
    setScreen(2);
  };
  const ToggleHandRaise = () => {
    updateUserDetails({ hand: !raiseHand });
    setRaiseHand(!raiseHand);
  };
  const EndForAll = () => {
    sendCommandToAll({command:Commands.kick});
    EndMeeting();
  }
  const EndMeeting = async () => {
    announceDeparture();
    await meetingManager.leave();
    setScreen(3);
  };
  const submitFeedback = async () => {
    await props.heroApiClient.sendFeedback(props.meetingId, feedback, rating);
    props.dismiss();
  };
  
  const MeetingPreview = () => {
    return (
      <div
        className={"modal100"}
        style={{
          height: window.innerHeight,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Row>
          <Col md={6}>
            <PreviewVideo />
          </Col>
          <Col md={4}>
            <CameraSelection />
            {isBackgroundBlurSupported && (
              <ChimeButton
                css={"padding:0;"}
                onClick={() => setIsVideoBlurred(!isVideoBlurred)}
                label="Toggle background blur"
              />
            )}
            <QualitySelection />
            <MicSelection />
            <SpeakerSelection />
            <div style={{ display: "flex" }}>
              <Microphone
                width="1.5rem"
                height="1.5rem"
                color={"white"}
                muted={!startupMic}
                onClick={() => setStartupMic(!startupMic)}
                title="Microphone icon"
              />
              <Camera
                width="1.5rem"
                height="1.5rem"
                color={"white"}
                disabled={!startupVideo}
                onClick={() => setStartupVideo(!startupVideo)}
                title="Camera icon"
              />
            </div>
            <PrimaryButton
              onClick={() => startMeeting()}
              label={"Start Meeting"}
            />
          </Col>
        </Row>
      </div>
    );
  };
  const FeedbackScreen = () => {
    return guest ? (
      <div className="callScreen flexCentered">
        <div className="form400">
          <h2>Thank you for coming!</h2>
          <div>
            You need to be logged onto HealthNav to give feedback on the
            meeting.
          </div>
          <div>We'd love to help you with that.</div>
          <div>
            <a
              href={"https://herowellbeing.com/wellness-powered-by-navigator/"}
              target="_blank"
            >
              Find out what HealthNav does.
            </a>
          </div>
          <div>
            <a href={"https://herowellbeing.com/contact-us/"} target="_blank">
              How do I get a professional account for me and my colleagues?
            </a>
          </div>
        </div>
      </div>
    ) : (
      <div className="callScreen flexCentered">
        <div className="form400">
          <ReactStars
            count={5}
            onChange={setRating}
            size={50}
            activeColor="#15cbce"
            classNames="m-auto"
          />
          <Textarea
            placeholder="Any feedback..."
            value={feedback}
            onInput={(obj) => setFeedback(obj.target.value)}
          />
          <div className="flexCentered">
            <PrimaryButton
              label="Send Feedback"
              onClick={submitFeedback}
              disabled={rating == null}
            />
            <ChimeButton label="Skip" onClick={props.dismiss} />
          </div>
        </div>
      </div>
    );
  };
  const hangUpButtonProps = {
    icon: <PhoneIcon className={"svgRed"} />,
    onClick: () => EndMeeting(),
    label: "End",
  };
  const handRaiseButtonProps = {
    icon: (
      <HandRaise
        className="handFill"
        style={{ color: raiseHand ? "black" : "white" }}
        isRaised={raiseHand}
      />
    ),
    onClick: () => ToggleHandRaise(),
    label: "Raise Hand",
  };
  const chatButtonProps = {
    icon: (
      <div>
        <ChatIcon />
        <p style={{ color: "pink" }}>{unreadChats}</p>
      </div>
    ),
    onClick: () => ToggleChat(),
    label: "Chat",
  };
  const attendeesButtonProps = {
    icon: <Attendees />,
    onClick: () => ToggleAttendees(),
    label: "Attendees",
  };
  const MainMeeting = () => {
    return (
      <Row style={{ minWidth: window.innerWidth, minHeight: window.innerHeight - 80 }}>
        <Col md={showChat ? 9 : 12} xs={12}>
          <MeetingGrid style={{
              position: "absolute",
              top: 0,
            }} meetingSession={meetingManager.meetingSession} attendees={attendees} />
          {/* <VideoTileGrid
            style={{
              minHeight: window.innerHeight - 80,
              position: "absolute",
              top: 0,
            }}
          /> */}
          {showAttendees && (
            <RosterGroup
              className="videoHeightScroll"
              style={{
                minHeight: window.innerHeight - 80,
                position: "absolute",
                left: 20,
                top: 20,
                opacity: 0.8,
                padding: 3,
              }}
            >
             
              {attendees &&
                attendees.map((attendee) => {
                  return (
                    <>
                      <div className="flexEdges">
                        {props.role === 1 && 
                           <MuteKickButton onMute={()=>sendCommandToOne({command:Commands.mute},attendee.connectionId)} onKick={()=>sendCommandToOne({command:Commands.kick},attendee.connectionId)}/>
                        }
                        <RosterCell name={attendee.senderName} />
                        <div className="flexEdges" style={{width:120}}>
                          <RosterAttendee style={{paddingRight:0}} attendeeId={attendee.attendeeId} />

                          <div
                            style={{ width: 40, height: 30 }}
                            className="flexCentered"
                          >
                            {attendee.hand && (
                              <HandRaise
                                width="1.2rem"
                                height="1.2rem"
                                className={"handFill"}
                                style={{ color: "black" }}
                                isRaised
                                title="Raised Hand"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
                {props.role === 1 && 
                 <div className="flexCentered">
                 <SecondaryButton label="Mute All" onClick={()=>{sendCommandToAll({command:Commands.mute})}} icon={<Microphone muted />} />
                 <SecondaryButton label="End Meeting" onClick={()=>setEndSure(true)} icon={<LeaveMeeting />}  />
                </div>
                }
                
               <Modal show={endSure}>
                    <div className="meeting-card">
                      <div className="modal-header">
                        <h3 className="meeting-card-title">End Session?</h3>
                      </div>
                      <div className="meeting-card-title textCentered">
                        This will kick all users out of the current session. You are the session host.
                      </div>
                      <div className="flexCentered">
                        <button type="submit" onClick={EndForAll}>
                          Yes
                        </button>
                        <a onClick={() => setEndSure(false)}>No</a>
                      </div>
                    </div>
                </Modal>
            </RosterGroup>
          )}

          <ControlBar layout="bottom" showLabels={isUserActive}>
            <ControlBarButton className="noFocus" {...attendeesButtonProps} />
            <ContentShareControl className="noFocus" />
            <AudioInputControl className="noFocus" />
            <VideoInputBackgroundBlurControl className="noFocus" />
            <ControlBarButton className="noFocus" {...handRaiseButtonProps} />
            <AudioOutputControl className="noFocus" />
            <ControlBarButton className="noFocus" {...hangUpButtonProps} />
            <ControlBarButton className="noFocus" {...chatButtonProps} />
          </ControlBar>
        </Col>
        {showChat && (
          <Col md={3} xs={12}>
            <ThemeProvider theme={lightTheme}>
              <MeetingMessagingService
                meetingId={props.meetingId}
                messages={chatMessages}
                sendChatMessage={sendChatMessage}
                attendeeId={props.user.profile.id}
                senderName={
                  props.user.profile.givenName +
                  " " +
                  props.user.profile.familyName
                }
              />
            </ThemeProvider>
          </Col>
        )}
      </Row>
    );
  };
  return (
    <Modal fullscreen show={true} dialogClassName="modal100">
      {screen == 0 && (
        <div className={"modal100"}>
          <LoadingSpinner className={"modal100"} />
          <p style={{ textAlign: "center" }}>{LoadingMessage}</p>
        </div>
      )}
      {screen == 1 && MeetingPreview()}
      {screen == 2 && MainMeeting()}
      {screen == 3 && FeedbackScreen()}
      {/* </Modal.Body> */}
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    users: state.users.items,
    user: state.user,
    heroApiClient: createHeroApiClient(state.user.session.accessToken),
    meetings: state.videos.items,
  };
};

export default withRouter(connect(mapStateToProps)(Meeting));

import React, { useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import dataStores from "../redux/reducers/dataStores";
import { connect } from "react-redux";
import { Row, Col, Button, Card } from "react-bootstrap";
import { FaStar } from 'react-icons/fa'
import { toggleFavourite } from '../redux/actions/appModules';

function NavDisplay(props) {
  useEffect(() => { }, [props.appConfig]);

  const toggle = (elementId) => props.dispatch(toggleFavourite(elementId))

  function BuildNavCard(element) {
    return (
      <Col key={element.id} md={6}>
        <Card className="">
          {/* <Card.Header>
            <Button>
              
            </Button>
          </Card.Header> */}
          <Card.Body>
            
              <Row className="align-items-center u-min-height-100">
                <Col xs={2} className="text-center">
                  <Link to={element.url} className=""><i className={`c-sidebar__icon ${element.iconName}`} style={{ fontSize: "30px" }} /></Link>
                </Col>
                <Col xs={8}>
                  <h4><Link to={element.url} className="text-secondary">{element.displayText}</Link></h4>
                  <p>{element.description}</p>
                </Col>
                <Col xs={2} className={"align-middle"}>
                  <Button variant={element.favourite ? "primary" : "secondary"} className={`btn--transparent btn--circle`} onClick={() => toggle(element.id)}>
                    <FaStar />
                  </Button>
                </Col>
              </Row>
          </Card.Body>
        </Card>
      </Col>
    );
  }

  function GetElements() {
    var displayElement = [];

    if (props.appConfig.elements) {
      {
        props.appConfig.elements.map(element => {
          // We only want to add links that go to pages and not the top level menu items
          if (element.children.length === 0) {
            displayElement.push(BuildNavCard(element));
          } else {
            element.children.map(childEle => {
              displayElement.push(BuildNavCard(childEle));
            });
          }
        });
      }
    }

    return displayElement;
  }

  return (
    <div>
      <Row>{GetElements()}</Row>
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    appConfig: state.appObject[dataStores.APP_CONFIG]
  };
}

export default connect(mapStateToProps)(NavDisplay);

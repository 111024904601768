import React from 'react';
import { connect } from 'react-redux';
import { Card } from 'react-bootstrap';
import {
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ComposedChart
} from 'recharts';
import moment from 'moment';

function WeeklyContributionCard(props) {
  const { challenge: { memberContributions, targetValue, participantType }, healthMetric: { units } } = props

  if (participantType != 'Individual') {
    return null;
  }

  const userContributions = memberContributions
    .filter(x => x.memberId == props.user.profile.id);

  const chartData = [...Array(7).keys()]
    .map(key => {
      const date = moment.utc().subtract(key, 'days');
      const value = userContributions.filter(uc => date.isSame(uc.date, 'days'))
        .reduce((acc, cur) => acc + cur.value, 0);
      return { date: date.format('ddd'), value, unit: units };
    })
    .reverse()

  const customTooltip = (event) => {
    if (!event.payload[0]) {
      return null
    }

    const tooltipColor = event.payload[0].value < targetValue
      ? "#21b4f1"
      : "#00FF8C";
    const tooltipStyle = {
      backgroundColor: "white",
      border: `1px solid ${tooltipColor}`,
      boxShadow: "1px 1px 3px 0px rgba(0,0,0,0.125)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    };
    const textStyle = {
      color: tooltipColor,
      padding: "0.3rem 0.3rem 0.6rem 0.6rem",
      margin: "0 0"
    };

    return (
      <div style={tooltipStyle}>
        <p style={textStyle}>{`${event.payload[0].payload.value} 
            ${event.payload[0].payload.value
            ? event.payload[0].payload.unit
            : ""}`}</p>
      </div>
    );
  }

  return (
    <Card>
      <Card.Body
        style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <div style={{ width: "100%" }}>
          <div style={{ marginTop: "1rem" }}>
            <ResponsiveContainer width="100%" aspect={4.0 / 2.0}>
              <ComposedChart width={150} height={40} data={chartData}>
                <Tooltip content={customTooltip} />
                <XAxis
                  dataKey="date"
                  dy={3}
                  fontFamily="sans-serif"
                  stroke="#bebebe"
                />
                <YAxis
                  type="number"
                  stroke="#bebebe"
                  tick={{ fontSize: "0.875rem" }}
                  dx={-5}
                  domain={[0, targetValue * 1.25]}>
                </YAxis>
                <Bar barSize={30} barCategoryGap={5} dataKey="value" fill="#15cbce" />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps)(WeeklyContributionCard);
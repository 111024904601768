import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Container, Row } from "react-bootstrap";
import GoalsSection from "../components/goals/GoalsSection";
import { fetchHealthMetrics } from '../redux/actions/healthMetrics';
import { fetchAuthenticatedUserGoals } from '../redux/actions/userGoals';
import LoadingSpinner from "../components/controls/LoadingSpinner";

function Goals(props) {
  const [goals, setGoals] = useState([])

  useEffect(() => {
    if (!props.healthMetrics.isValid) {
      props.dispatch(fetchHealthMetrics());
      return;
    }

    if (!props.goals.isValid) {
      props.dispatch(fetchAuthenticatedUserGoals());
      return;
    }

    const _goals = props.goals.items.reduce((acc, cur) => {
      const healthMetric = props.healthMetrics.items.find(x => x.id == cur.healthMetricId);
      if(!healthMetric){
        return acc;
      }
      const sectionName = healthMetric.healthWheelSectionName || 'Other';
      return [...acc, {
        ...cur,
        name: healthMetric.name,
        sectionName: sectionName,
        units: healthMetric.units
      }]
    }, []);
    setGoals(_goals);
  }, [props.healthMetrics, props.goals]);

  return (
    <Container>
      <h1 className="page__title">Goals</h1>

      {(props.healthMetrics.isFetching || props.goals.isFetching) && <LoadingSpinner/>}

      {goals.length > 0 && (
        <Row>
          {[...new Set(goals.map(x => x.sectionName))].map(sectionName => (
            <GoalsSection
              name={sectionName}
              goals={goals.filter(x => x.sectionName == sectionName)}
            />
          ))}
        </Row>
      )}
    </Container>
  );
}

function mapStateToProps(state) {
  return {
    healthMetrics: state.healthMetrics,
    goals: state.user.goals
  };
}

export default connect(mapStateToProps)(Goals);
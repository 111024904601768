import { 
    REQUEST_METRIC_SOURCES,
    RECEIVE_METRIC_SOURCES, 
    INVALIDATE_METRIC_SOURCES 
} from '../actions/userHealthMetricSources'
import { END_USER_SESSION } from '../actions/userSession'

const initialState = {
    isFetching: false,
    isValid: false,
    items: []
}

export default function userHealthMetricSources(state = initialState, action) {
    switch (action.type) {
        case REQUEST_METRIC_SOURCES:
            return {
                ...state,
                isFetching: true,
                isValid: true
            }
        case RECEIVE_METRIC_SOURCES:
            return {
                ...state,
                isFetching: false,
                isValid: true,
                items: action.payload.metricSources,
                lastUpdated: action.payload.receivedAt
            }
        case INVALIDATE_METRIC_SOURCES:
            return {
                ...state,
                isValid: false
            }
        case END_USER_SESSION:
            return initialState
        default:
            return state
    }
}
import { createHeroApiClient } from '../../hero-api-client';
import { createError } from './errors';

export const RECEIVE_GROUP_MESSAGES = 'RECEIVE_GROUP_MESSAGES'
function receiveGroupMessages(groupId, messages) {
    return {
        type: RECEIVE_GROUP_MESSAGES,
        payload: { groupId, messages }
    }
}

export function fetchGroupMessages(groupId, options) {
    return (dispatch, getState) => {
        const accessToken = getState().user.session.accessToken;
        const groupClient = createHeroApiClient(accessToken).groups
            .createGroupClient(groupId);
        groupClient.messages.get(options)
            .then(messages => {
                dispatch(receiveGroupMessages(groupId, messages))})
            .catch(error => dispatch(createError(error.message)));
    }
}

export function sendGroupMessage(groupId, message) {
    return async (dispatch, getState) => {
        const accessToken = getState().user.session.accessToken;
        const groupClient = createHeroApiClient(accessToken).groups
            .createGroupClient(groupId);
        groupClient.messages.create({message})
            .catch(error => dispatch(createError(error.message)));
    }
}

import React from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';


function Post(props) {
  const { story, bio } = props;
  
  return (
    <>
      <div
        className="post-heading-container"
        style={{ backgroundImage: `url(${story.content.image_url})` }}>
        <div className="post-heading-title">
          <div className="content">
            <div className="container">
              <h1>{story.name}</h1>
            </div>
          </div>
        </div>
      </div>

      <Container>
        <Row className="mb-2">
          <Col>
            <div className="btn--actions">
              <a onClick={() => props.history.goBack()}><i className="fas fa-chevron-left"></i></a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div
              className="post-content"
              dangerouslySetInnerHTML={{ __html: story.content.body.html }}>
            </div>
          </Col>
        </Row>
        {story.content.video_url && (
          <Row>
            <video width="100%" height="50%" controls>
              <source src={story.content.video_url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Row>
        )}
        {
          bio.imageUrl &&(
            <div style={{margin:20}}>
         <img src={bio.imageUrl} style={{borderRadius:'50%',height:80,borderRight:'solid 2px grey',borderBottom:'solid 2px grey',display:'inline',margin:5,marginTop:0}} alt='Img' />
         <div style={{borderLeft:'solid 1px black', display:'inline-block',verticalAlign:'top',paddingLeft:10, fontFamily: 'Helvetica, sans-serif',fontSize:10,color:'#769EBC'}}>
          <p style={{fontWeight:'bolder', color:'#5386AC'}}>{bio.name}</p>
            <ul>
              <li style={{fontWeight:'bolder', color:'#5386AC'}}>{bio.note1}</li>
              <li>{bio.note2}</li>
              <li>{bio.note3}</li>
              <li>{bio.note4}</li>
            </ul>
        </div>
        </div>
          )
          
        }
        
      </Container>
    </>
  )
}

export default withRouter(Post);

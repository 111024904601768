import React, { useEffect, useState } from "react";

// Redux rework
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//Redux Actions
import * as commandActions from "../redux/actions/commands";
import dataStores from "../redux/reducers/dataStores";



import HeroOutlineButton from '../common/Buttons/OutlineButton';
import HeroInputField from '../common/Inputs/inputField';

// BOOTSTRAP STUFF
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import UserManagementAccess from "./user-management-access-list";
import { Modal, Button, Accordion, Card, Spinner } from "react-bootstrap";
import Select from "react-select";
import { IoMdThermometer } from "react-icons/io";
import DayPickerInput from "react-day-picker/DayPickerInput";
import moment from "moment";
const url = require("url");

function UserManagement(props) {
  const [showEditAccess, setEditAccess] = useState(false);
  const [showNewUser, setNewUser] = useState(false);
  const [permissionData, setpermissionData] = useState({user:{}, claimName:""});
  const [insightData, setInsightData] = useState({});
  const [insightIndex, setInsightIndex] = useState(-1 );
  const [errorMessage, setErrorMessage] = useState('');
  const [users, setUsers] = useState(null);
  //state for the user data
  const [userSignUpData, setUserSignUpData] = useState({
    inviteCode: "",
    emailAddress: "",
    password: "",
    confirmPassword: "",
    givenName: "",
    familyName: "",
    gender: "",
    dateOfBirth: new Date(),
  });
  useEffect(() => {
    if(!users && props.companyId){
      LoadData();
    }
  }, [users, props.companyId])
  /* useEffect(() => {
    props.dispatch(fetchUsers())
  }, [props.users]); */
  /* 
  useEffect(() => {
    if(insightData.usersHealthInsight == null || insightData.usersHealthInsight.length == 0){
      LoadAllUserInsightData(props.companyId)
    }
  },[insightData])*/

  function LoadData(){
    const localVarUrlObj = url.parse(process.env.REACT_APP_API_BASE_URL  + "/managementclaims", true);
    const localVarQueryParameter = {};
    localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, {"companyId": JSON.stringify(props.companyId)});
    delete localVarUrlObj.search;
    var callUrl =  url.format(localVarUrlObj)
    const myRequest = new Request(callUrl, {
      method: 'GET',
      headers: {
        "hero-token": props.heroToken
      },
    });

    fetch(myRequest)
    .then((response) => {
      return response.json()
    })
    .then((data) => {
      setUsers(data)
    })
  }

  /*function LoadUserInsightData(userId){
    const myRequest = new Request(process.env.REACT_APP_API_BASE_URL  + "/userhealth/userinsight", {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, *//*',
        'Content-Type': 'application/json',
        "hero-token": props.heroToken
      },
      body: JSON.stringify({"userId": userId})
    });

    fetch(myRequest)
    .then((response) => {
      return response.json()
    })
  }
 */
  /*function LoadAllUserInsightData(companyId){
    const myRequest = new Request(process.env.REACT_APP_API_BASE_URL  + "/userhealth/userinsight/all", {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, *//*',
        'Content-Type': 'application/json',
        "hero-token": props.heroToken
      },
      body: JSON.stringify({"companyId": companyId})
    });

    fetch(myRequest)
    .then((response) => {
      return response.json()
    })
    .then((data) => {
      setInsightData(data)
    })
  }*/

  function SaveNewUser(){
    setUserSignUpData({ ...userSignUpData, ["confirmPassword"]: userSignUpData.password });
    try {
      const request = {
        ...userSignUpData,
        companyKey: props.companyKey
      };
      props.heroApiClient.registerUser(request)
      .then(setNewUser(false));
    } catch(error) {
      setErrorMessage(error.message);
    }
  }

  function MakeUserCoach(userId){
    const myRequest = new Request(process.env.REACT_APP_API_BASE_URL  + "/managementclaims/coach", {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        "hero-token": props.heroToken
      },
      body: JSON.stringify({"userId": userId})
    });

    fetch(myRequest)
    .then((response) => {
      return response.json()
    })
    .then((data) => {LoadData()})
  }

  function MakeUserHeadCoach(userId){
    const myRequest = new Request(process.env.REACT_APP_API_BASE_URL  + "/managementclaims/headcoach", {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        "hero-token": props.heroToken,
      },
      body: JSON.stringify({"userId": userId})
    });

    fetch(myRequest)
    .then((response) => {
      return response.json()
    })
    .then((data) => {LoadData()})
  }

  function MakeUserCompanyHR(userId){
    const myRequest = new Request(process.env.REACT_APP_API_BASE_URL  + "/managementclaims/companyhr", {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        "hero-token": props.heroToken
      },
      body: JSON.stringify({"userId": userId})
    });

    fetch(myRequest)
    .then((response) => {
      return response.json()
    })
    .then((data) => {LoadData()})
  }

  function DeleteClaim(userId, claimName){
    const myRequest = new Request(process.env.REACT_APP_API_BASE_URL  + "/managementclaims/delete", {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        "hero-token": props.heroToken,
      },
      body: JSON.stringify({"userId" : userId, "claimName" : claimName})
    });

    fetch(myRequest)
    .then((response) => {
      return response.json()
    })
    .then((data) => {LoadData()})
  }

  function onChangeUsers(event) {
    setInsightIndex(event.value)
  }

  function changeUserInfoHandler(event, propertyName) {
    setUserSignUpData({ ...userSignUpData, [propertyName]: event.target.value });
  }

  function changeUserDOBHandler(day, propertyName) {
    setUserSignUpData({ ...userSignUpData, [propertyName]: day });
  }

  function BuildList(){
    var usersRender = [];
    if(users){
      users.forEach(user => {
        usersRender.push(
        <div>
          <Row>
            <Col xs={1}>
              <img src={user.avatarUrl ? user.avatarUrl : props.styles ? props.styles.logoUrl : undefined}
              style={{display: "block", width: "50px", height: "50px", borderRadius: "50%", backgroundColor: "grey", boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.1225)"}} />
            </Col>
            <Col xs={2}>
              <label>{user.name}</label>
            </Col>
            <Col xs={4}>
              <label>{user.emailAddress}</label>
            </Col>
            {user.claims ? 
              <Col xs={3}>
                {user.claimKeys.includes("HeroCoach") ? <div><HeroOutlineButton
                colour="#21b4f1"
                classes="card-link"
                styles={{margin: "0rem 0.5rem"}}
                text={  "Remove Coach" }
                onClick = {() => DeleteClaim(user.userId, "HeroCoach")}
                size="xlarge" />
                <HeroOutlineButton
                colour="#21b4f1"
                classes="card-link"
                styles={{margin: "0rem 0.5rem"}}
                text={  "Edit Access" }
                onClick = {() => {
                  setpermissionData({user: user, claimName: "HeroCoach"});
                  setEditAccess(true);
                }}
                size="xlarge" />
                </div>
                : <HeroOutlineButton
                colour="#21b4f1"
                classes="card-link"
                styles={{margin: "0rem 0.5rem"}}
                text={  "Make Coach" }
                onClick = {() => MakeUserCoach(user.userId)}
                size="xlarge" />}
                {/* {user.claimKeys.includes("HeadHeroCoach") ? <div><HeroOutlineButton
                colour="#21b4f1"
                classes="card-link"
                styles={{margin: "0rem 0.5rem"}}
                text={ "Remove Head Coach" }
                onClick = {() => DeleteClaim(user.userId, "HeadHeroCoach")}
                size="xlarge" />
                {<HeroOutlineButton
                colour="#21b4f1"
                classes="card-link"
                styles={{margin: "0rem 0.5rem"}}
                text={  "EditAccess" }
                onClick = {() => {
                  setpermissionData({user: user, claimName: "HeroCoach"});
                  setEditAccess(true);
                }}
                size="xlarge" />}
                </div>
                : <HeroOutlineButton
                colour="#21b4f1"
                classes="card-link"
                styles={{margin: "0rem 0.5rem"}}
                text={  "Make Head Coach" }
                onClick = {() => MakeUserHeadCoach(user.userId)}
                size="xlarge" />} */}
                {user.claimKeys.includes("CompanyHR") ? <HeroOutlineButton
                colour="#21b4f1"
                classes="card-link"
                styles={{margin: "0rem 0.5rem"}}
                text={ "Remove Company HR" }
                onClick = {() => DeleteClaim(user.userId, "CompanyHR")}
                size="xlarge" />
                : <HeroOutlineButton
                colour="#21b4f1"
                classes="card-link"
                styles={{margin: "0rem 0.5rem"}}
                text={  "Make Company HR" }
                onClick = {() => MakeUserCompanyHR(user.userId)}
                size="xlarge" />}
                {/* <HeroOutlineButton
                colour="#21b4f1"
                classes="card-link"
                styles={{margin: "0rem 0.5rem"}}
                text={  "Insight Data" }
                onClick = {() => LoadUserInsightData(user.userId)}
                size="xlarge" /> */}
              </Col>
              :<Col xs={3}></Col>}
          </Row>
          <hr/>
        </div>
      )
      });
    }
    return usersRender
  }

  function BuildUserInsight(){
    var userOptions = [
      { value: -1, label: 'All' },
    ];
    for(var item in insightData.usersHealthInsight){
      userOptions.push({value: parseInt(item), label: insightData.usersHealthInsight[item].name })
    }
    return(
      <Container>
        <Select
          className="basic-single"
          classNamePrefix="select"
          defaultValue={userOptions[0]}
          name="userInsight"
          onChange={onChangeUsers}
          options={userOptions}
        />
        <Row>
        <label>Average Sleep: {insightIndex == -1 ? insightData.averageSleep : insightData.usersHealthInsight[insightIndex].averageSleep}</label>
        </Row>
        <Row>
        <label>Average Active Duration: {insightIndex == -1 ? insightData.averageActiveDuration : insightData.usersHealthInsight[insightIndex].averageActiveDuration}</label>
        </Row>
        <Row>
        <label>Average Calories Burned: {insightIndex == -1 ? insightData.averageCaloriesBurned : insightData.usersHealthInsight[insightIndex].averageCaloriesBurned}</label>
        </Row>
        <Row>
        <label>Total Steps: {insightIndex == -1 ? insightData.totalSteps : insightData.usersHealthInsight[insightIndex].totalSteps}</label>
        </Row>
        <Row>
        <label>Total Distance: {insightIndex == -1 ? insightData.totalDistance : insightData.usersHealthInsight[insightIndex].totalDistance}</label>
        </Row>
        <Row>
          <Col></Col>
          <Col md="auto">
            {insightData.usersHealthInsight == null ? 
              <Spinner animation="border" role="status" >
                <span className="sr-only">Loading...</span>
              </Spinner>
              :undefined
            }
          </Col>
          <Col></Col>
        </Row>
      </Container> 
    )
  }

  return (
    <Container>
      <h1 className="page__title">User Management</h1>
      <Accordion defaultActiveKey="0">
        {/*<Card>
           <Card.Header className="card-header--primary p-0">
            <Accordion.Toggle as={Button} variant="link" className="p-4 w-100" eventKey="1">
              User Insight
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <Row>
                <Col md={12} className="mb-5">{/* BuildUserInsight()</Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse> 
        </Card>*/}
        <Card>
          <Card.Header className="card-header--primary p-0">
            <Accordion.Toggle as={Button} variant="link" className="p-4 w-100" eventKey="0">
              Coach and HR Management
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <Row>
                <Col></Col>
                <Col></Col>
                <Col>
                  <HeroOutlineButton
                  colour="#21b4f1"
                  classes="card-link"
                  styles={{margin: "0.5rem 0.5rem"}}
                  text={  "New User" }
                  onClick={() => setNewUser(true)}
                  size="xlarge"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12} className="mb-5">{BuildList()}</Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      
      <Modal centered={true} show={showNewUser} onHide={()=> setNewUser(false)}>
        <Modal.Body>
          <h1 className="page__title">New User</h1>
          <div className="col-12 text-center">
            <Row>
              <Col>
                <Row>
                  <p style={{textAlign: "center", marginBottom: "0.25rem", fontSize: "0.95rem", color: "#717171"}}>
                    Email:
                  </p>
                </Row>
                <Row>
                  <HeroInputField
                    size="small"
                    type="text"
                    value={userSignUpData.email}
                    onChange={ event => changeUserInfoHandler(event, "email")}
                    styles={{margin: "0rem 1rem 0rem 1rem"}}/>
                </Row>
              </Col>
              <Col>
                <Row>
                  <p style={{textAlign: "center", marginBottom: "0.25rem", fontSize: "0.95rem", color: "#717171"}}>
                    Password:
                  </p>
                </Row>
                <Row>
                  <HeroInputField
                    size="small"
                    type="password"
                    value={userSignUpData.password}
                    onChange={ event => {
                      changeUserInfoHandler(event, "password")
                    }}
                    styles={{margin: "0rem 1rem 0rem 1rem"}}/>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  <p style={{textAlign: "center", marginBottom: "0.25rem", fontSize: "0.95rem", color: "#717171"}}>
                    First Name:
                  </p>
                </Row>
                <Row>
                  <HeroInputField
                    size="small"
                    type="text"
                    value={userSignUpData.firstName}
                    onChange={ event => changeUserInfoHandler(event, "firstName")}
                    styles={{margin: "0rem 1rem 0rem 1rem"}}/>
                </Row>
              </Col>
              <Col>
                <Row>
                  <p style={{textAlign: "center", marginBottom: "0.25rem", fontSize: "0.95rem", color: "#717171"}}>
                    Last Name:
                  </p>
                </Row>
                <Row>
                  <HeroInputField
                    size="small"
                    type="text"
                    value={userSignUpData.lastName}
                    onChange={ event => changeUserInfoHandler(event, "lastName")}
                    styles={{margin: "0rem 1rem 0rem 1rem"}}/>
                </Row>
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <p style={{textAlign: "center", marginBottom: "0.25rem", fontSize: "0.95rem", color: "#717171"}}>
                  Gender:
                </p>
              </Col>
              <Col>
                <Select />
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <p style={{textAlign: "center", marginBottom: "0.25rem", fontSize: "0.95rem", color: "#717171"}}>
                  Date of Birth:
                </p>
              </Col>
              <Col>
                <DayPickerInput
                  dayPickerProps={{
                    firstDayOfWeek: 1,
                    selectedDays: new Date(userSignUpData.dateOfBirth),
                    month: new Date(userSignUpData.dateOfBirth),
                  }}
                  classNames={{ container: "", overlayWrapper: "", overlay: "DayPickerInput-Overlay" }}
                  value={new Date(userSignUpData.dateOfBirth)}
                  format="DD-MM-YYYY"
                  placeholder="DD-MM-YYYY"
                  formatDate={(d, f) => moment(d).format(f)}
                  onDayChange={day => {
                    changeUserDOBHandler(day, "dateOfBirth")
                  }} /> 
              </Col>
            </Row>
            <br />
            {/* <Row>
              <Col>
                <p style={{textAlign: "center", marginBottom: "0.25rem", fontSize: "0.95rem", color: "#717171"}}>
                  Department:
                </p>
              </Col>
              <Col>
                <HeroInputField
                  size="small"
                  type="text"
                  value={userData.deparment}
                  onChange={ event => changeUserInfoHandler(event, "department")}
                  styles={{margin: "0rem 2.5rem 0rem 0rem"}}/>
              </Col>
            </Row>
            <br /> */}
            <Row>
              <Col>
                <Button variant="primary" type="submit" disabled={userSignUpData.email == "" || userSignUpData.password == "" } onClick={()=> SaveNewUser()}>Submit</Button>
              </Col>
              <Col>
                <Button color="gray-200" className="btn-md" onClick={()=> setNewUser(false)}>Close</Button>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      <Modal centered={true} show={showEditAccess} onHide={()=> setEditAccess(false)}>
        <Modal.Body>
          <UserManagementAccess claimName={permissionData.claimName} heroToken={props.heroToken} user={permissionData.user} users={users} LoadData={() => LoadData()}/>
          <div className="col-12 text-center">
            <Button color="gray-200" className="btn-md" onClick={()=> setEditAccess(false)}>Close</Button>
          </div>
        </Modal.Body>
      </Modal>
    </Container>
  );
}

function mapStateToProps(state) {
  return {
    heroToken: state.user.session.accessToken,
    styles: state.appObject[dataStores.APP_CONFIG].styles,
    companyId: state.appObject[dataStores.APP_CONFIG].companyId,
    companyKey: state.appObject[dataStores.APP_CONFIG].companyKey,
    users: state.users,
    user: state.user
  };
}

export default connect(mapStateToProps)(UserManagement);

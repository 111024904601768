import React, {useState, useEffect} from 'react'
import {connect} from "react-redux";
import Modal from 'react-bootstrap/Modal'
import {fetchUsers} from '../redux/actions/users'

function SelectUserModal(props) {
    const {show, setShow, handleFormSubmit} = props;
    const [selectedUserId, setSelectedUserId] = useState(null);

    useEffect(() => {
        if (!props.users || !props.users.isValid) {
            props.dispatch(fetchUsers());
        }
    }, [props.users]);

    const onChange = (event) => {
        const userId = event.target.value;
        setSelectedUserId(userId);
    }

    const isValid = () => !!selectedUserId

    const decoratedHandleFormSubmit = event => {
        event.preventDefault();

        if (!isValid()) {
            alert('Invalid selection');
            return;
        }

        handleFormSubmit(selectedUserId);

        setShow(false);
        setSelectedUserId(null);
    }

    return (
        <Modal centered show={show} onHide={() => setShow(false)}>
            <Modal.Body className="p-4">
                <form onSubmit={decoratedHandleFormSubmit}>
                    <div className="col">
                        <span className="select__label mr-3">Select a user:</span>
                        <select className="select" onChange={onChange} value={selectedUserId}>
                            <option></option>
                            {props.users &&
                            props.users.items.map(user => (
                                <option value={user.id}>{user.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col d-flex mt-3">
                        <input type="submit" className="btn btn-primary" value="Submit" disabled={!isValid()}/>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}

const mapStateToProps = state => {
    return {
        users: state.users
    };
}

export default connect(mapStateToProps)(SelectUserModal);


const createCoachesClient = (sendRequest) => () => {
    return {
    };
};



export const createCoachClient = (sendRequest) => {
    
    const getCoaches = async () => {
        const path = `/chimecalendar`;
        return await sendRequest(path);
    }
    const getMine = async () => {
        const path = `/chimecalendar/mine`;
        return await sendRequest(path);
    }

    return {
            get: getCoaches,
            getMine:getMine,
            createCoachClient: createCoachesClient(sendRequest)
    };
};







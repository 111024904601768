import React, { useState } from "react";
import { Link } from "react-router-dom";

// Redux rework
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//Redux Actions
import dataStores from "../redux/reducers/dataStores";

import * as commandActions from "../redux/actions/commands";

// BOOTSTRAP STUFF
import Container from "react-bootstrap/Container";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";

// Nutrition Components

import HealthCalendar from "../components/healthmetrics/health-calendar";

// Misc
import { format, addDays, subDays, parse } from "date-fns";

// hero Core API
import { HealthApi } from "hero-api-client";

const HeApi = new HealthApi({ basePath: process.env.REACT_APP_API_BASE_URL });

function Health (props) {
  const [dateToDisplay, setDateToDisplay] = useState(
    format(new Date(), "yyyy-MM-dd")
  );

  return (
    <Container>
      <Row className="title-container">
        <Col>
          <h1 className="page__title">Calendar</h1>
        </Col>
        <Col>
          <div className="btn--actions">
            <Link to={"/health-check"} title="Health Check" className="btn btn--transparent btn--circle">
              <i className="far fa-notes-medical"></i>
            </Link>
            <Link to={"/health-assessment"} title="Health Assessment" className="btn btn--transparent btn--circle mr-2">
              <i className="far fa-chart-area"></i>
            </Link>
          </div>
        </Col>
      </Row>
      <HealthCalendar displayDate={dateToDisplay} />
    </Container>
  );
}
function mapStateToProps(state) {
  return {
    heroToken: state.user.session.accessToken,
    appConfig: state.appConfig,
    feed: state.feed,
  };
}

function mapDispatchToProps(dispatch) {
  return {
        commandActions: bindActionCreators(commandActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Health);

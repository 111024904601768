import React, { useState, useEffect } from "react";

// Redux Connections
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//Redux Actions

import * as commandActions from "../../redux/actions/commands";
import dataStores from "../../redux/reducers/dataStores";

// BOOTSTRAP STUFF
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";

//MISC
import { FaUserCircle } from "react-icons/fa";

function GrantedPermissions(props) {
  useEffect(() => {
    //Get all the specialists that have access to my data
    if (props.dataStores[dataStores.GRANTED_PERMISSIONS].success == null) {
      props.commandActions.sendCommand(
        dataStores.GRANTED_PERMISSIONS,
        { actionType: "GET" }
      );
    }
  }, [props.dataStores[dataStores.GRANTED_PERMISSIONS]]);

  function renderGrantedPermissions() {
    if (
      props.dataStores[dataStores.GRANTED_PERMISSIONS].specialistsWithAccess &&
      props.dataStores[dataStores.GRANTED_PERMISSIONS].specialistsWithAccess
        .length > 0
    ) {
      return (
        <div className="mt-3 mb-3">
          <h3>Granted Permissions</h3>
          <p className="mb-2">
            Below is a list of coaches and specialists that have access to your
            data, if you wish, you can revoke this access at any time.
          </p>
          {GetPeopleThatCanSeeMyData()}
        </div>
      );
    } else {
      return null;
    }
  }

  function removeAccess({ target }) {
    props.commandActions.sendCommand(
      dataStores.GRANTED_PERMISSIONS,
      { actionType: "REMOVE", delegatedUserId: target.name }
    );
  }

  function GetPeopleThatCanSeeMyData() {
    var people = [];
    if (
      props.dataStores[dataStores.GRANTED_PERMISSIONS].success == true &&
      props.dataStores[dataStores.GRANTED_PERMISSIONS].specialistsWithAccess
    ) {
      props.dataStores[
        dataStores.GRANTED_PERMISSIONS
      ].specialistsWithAccess.map(person => {
        people.push(
          <Row key={person.userId}>
            <Col xs={2}>
              <FaUserCircle size="4em" color="#d3d3d3"></FaUserCircle>
            </Col>
            <Col xs={3} className="my-auto">
              {person.firstName} {person.lastName}
            </Col>
            <Col xs={4} className="my-auto">
              {person.email}
            </Col>
            <Col xs={3} className="text-right">
              <Button
                variant="danger"
                size="sm"
                className="btn--circle"
                name={person.userId}
                onClick={removeAccess}
              >
                <i className="far fa-ban"></i>
              </Button>
            </Col>
          </Row>
        );
      });
    }

    return people;
  }

  return renderGrantedPermissions();
}

function mapStateToProps(state) {
  return {
    appConfig: state.appConfig,
    feed: state.feed,
    dataStores: state.appObject
  };
}

function mapDispatchToProps(dispatch) {
  return {
        commandActions: bindActionCreators(commandActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GrantedPermissions);

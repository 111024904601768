import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// Redux rework
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as commandActions from "../../redux/actions/commands";
import dataStores from "../../redux/reducers/dataStores";

// BOOTSTRAP STUFF
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

import { IoIosHeart } from "react-icons/io";
import Select from "react-select";

// Hero Components
import HeroOutlineButton from '../../common/Buttons/OutlineButton';
import HeroInputField from "../../common/Inputs/inputField";
import ColourFitPlate from "../../components/nutrition/colour-fit-plate";
import { render } from "react-dom";
import LoadingSpinner from "../../components/controls/LoadingSpinner";

function MyMeals(props) {

  window.onscroll = detectScroll;
  const [mealList, setMealList] = useState([])
  const [displayMeal, setDisplayMeal] = useState({});
  const [paging, setPaging] = useState({
    pageNumber: 1,
    nameFilterValue: "",
    foodGoals: [],
    calories: [],
    mealTypes: [],
    dietTypes: [],
    overallGoals: [],
    nutrients: [],
    favourite: false,
    mealSort: 1
  });
  const [render, setRender] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  /* const [callSent, setCallSent] = useState(false); */
  const FoodGoals = [
    { value: 17, label: 'Fuel' },
    { value: 18, label: 'Lean Muscle' },
    { value: 19, label: 'Health' },
  ];
  const Calories = [
    { value: 'low', label: 'Low (< 80 cal/100g)' },
    { value: 'medium', label: 'Medium (81-215 cal/100g)' },
    { value: 'high', label: 'High (> 215 cal/100g)' },
  ];
  const MealTypes = [
    { value: 1, label: 'Breakfast' },
    { value: 2, label: 'Main' },
    { value: 3, label: 'Side' },
    { value: 4, label: 'Snack' },
    { value: 5, label: 'Smoothie' },
    { value: 6, label: 'Purchase' },
  ];
  const DietTypes = [
    { value: 7, label: 'Vegan' },
    { value: 8, label: 'Vegetarian' },
    { value: 9, label: 'Pescatarian' },
    { value: 10, label: 'Gluten Free' },
    { value: 11, label: 'Non-Supplement'},
  ];
  const OverallGoals = [
    { value: 12, label: 'Balanced Diet' },
    { value: 13, label: 'Muscle Gain and Recovery' },
    { value: 14, label: 'Weight Loss/Being Lean' },
    { value: 15, label: 'Fuel, Performance/Exercise' },
    { value: 16, label: 'Health/Immunity' },
  ];
  const Nutrients = [
    { value: 20, label: 'Sugars' },
    { value: 21, label: 'Fibre' },
    { value: 22, label: 'Fat' },
    { value: 23, label: 'Saturated Fat' },
    { value: 24, label: 'Polyunsaturated Fat' },
    { value: 25, label: 'Monounsaturated Fat' },
    { value: 26, label: 'Omega3' },
    { value: 27, label: 'B1 Thiamine' },
    { value: 28, label: 'B2 Riboflavin' },
    { value: 29, label: 'B3 Niacin' },
    { value: 30, label: 'B5 PantothenicAcid' },
    { value: 31, label: 'B6 Pyridoxine' },
    { value: 32, label: 'B12 Cobalamin' },
    { value: 33, label: 'Folate' },
    { value: 34, label: 'Vitamin A' },
    { value: 35, label: 'Vitamin C' },
    { value: 36, label: 'Vitamin D' },
    { value: 37, label: 'Vitamin E' },
    { value: 38, label: 'Vitamin K' },
    { value: 39, label: 'Calcium' },
    { value: 40, label: 'Copper' },
    { value: 41, label: 'Iron' },
    { value: 42, label: 'Magnesium' },
    { value: 43, label: 'Manganese' },
    { value: 44, label: 'Phosorus' },
    { value: 45, label: 'Potassium' },
    { value: 46, label: 'Selenium' },
    { value: 47, label: 'Sodium' },
    { value: 48, label: 'Zinc' },
  ];


  useEffect(() => {
    if (paging.nameFilterValue.length >= 2 || paging.nameFilterValue.length == 0) {
      props.commandActions.sendCommand(
        dataStores.MEALS,
        {
          actionType: "GET",
          pageSize: 18,
          pageNumber: paging.pageNumber,
          searchTerm: paging.nameFilterValue,
          foodGoals: paging.foodGoals,
          calories: paging.calories,
          mealTypes: paging.mealTypes,
          dietTypes: paging.dietTypes,
          overallGoals: paging.overallGoals,
          nutrients: paging.nutrients,
          favourite: paging.favourite,
          mealSort: paging.mealSort
        }
      );
      setRender(false);
    }
  }, [paging]);

  useEffect(() => {
    if (props.meals.meals) {
      var mealTemp = [];
      if (paging.pageNumber > 1) {
        mealTemp = mealList;
      }
      props.meals.meals.forEach(meal => {
        mealTemp.push(meal);
      });
      if(props.meal.meals){
        var index = mealTemp.findIndex(checkId)
        if(index != -1)
          mealTemp.splice(index, 1, props.meal.meals[0]);
      }
      setMealList(mealTemp);
      setRender(true);
    }
  }, [props.meals,props.meal]);

  useEffect(()=>{
  }, [mealList])

  function checkId({mealId, favourite}){
    return mealId == props.meal.meals[0].mealId && favourite != props.meal.meals[0].favourite;
  }
  function LoadMore() {
    if (render === true) {
      setRender(false);
      setPaging({
        pageNumber: paging.pageNumber + 1, nameFilterValue: paging.nameFilterValue,
        foodGoals: paging.foodGoals, calories: paging.calories,
        mealTypes: paging.mealTypes, dietTypes: paging.dietTypes,
        overallGoals: paging.overallGoals, nutrients: paging.nutrients,
        favourite: paging.favourite, mealSort: paging.mealSort
      });

    }
  }

  function onInputChange(event) {
    setSearchTerm(event.target.value.toLowerCase())
  }

  function updateFilterHandler() {
    if (render === true) {
      setPaging({
        pageNumber: 1, nameFilterValue: searchTerm,
        foodGoals: paging.foodGoals, calories: paging.calories,
        mealTypes: paging.mealTypes, dietTypes: paging.dietTypes,
        overallGoals: paging.overallGoals, nutrients: paging.nutrients,
        favourite: paging.favourite, mealSort: paging.mealSort
      });
      setRender(false);
    }
  }

  function onChangeFoodGoals(event) {
    var filters = []
    if(event){
      event.forEach(item => {
      filters.push(item.value)
    })}
    setPaging({
      pageNumber: 1, nameFilterValue: paging.nameFilterValue,
      foodGoals: filters, calories: paging.calories,
      mealTypes: paging.mealTypes, dietTypes: paging.dietTypes,
      overallGoals: paging.overallGoals, nutrients: paging.nutrients,
      favourite: paging.favourite, mealSort: paging.mealSort
    });
  }
  function onChangeCalories(event) {
    var filters = []
    if(event){
      event.forEach(item => {
      filters.push(item.value)
    })}
    setPaging({
      pageNumber: 1, nameFilterValue: paging.nameFilterValue,
      foodGoals: paging.foodGoals, calories: filters,
      mealTypes: paging.mealTypes, dietTypes: paging.dietTypes,
      overallGoals: paging.overallGoals, nutrients: paging.nutrients,
      favourite: paging.favourite, mealSort: paging.mealSort
    });
  }
  function onChangeMealTypes(event) {
    var filters = []
    if(event){
      event.forEach(item => {
      filters.push(item.value)
    })}
    setPaging({
      pageNumber: 1, nameFilterValue: paging.nameFilterValue,
      foodGoals: paging.foodGoals, calories: paging.calories,
      mealTypes: filters, dietTypes: paging.dietTypes,
      overallGoals: paging.overallGoals, nutrients: paging.nutrients,
      favourite: paging.favourite, mealSort: paging.mealSort
    });
  }
  function onChangeDietTypes(event) {
    var filters = []
    if(event){
      event.forEach(item => {
      filters.push(item.value)
    })}
    setPaging({
      pageNumber: 1, nameFilterValue: paging.nameFilterValue,
      foodGoals: paging.foodGoals, calories: paging.calories,
      mealTypes: paging.mealTypes, dietTypes: filters,
      overallGoals: paging.overallGoals, nutrients: paging.nutrients,
      favourite: paging.favourite, mealSort: paging.mealSort
    });
  }
  function onChangeOverallGoals(event) {
    var filters = []
    if(event){
      event.forEach(item => {
      filters.push(item.value)
    })}
    setPaging({
      pageNumber: 1, nameFilterValue: paging.nameFilterValue,
      foodGoals: paging.foodGoals, calories: paging.calories,
      mealTypes: paging.mealTypes, dietTypes: paging.dietTypes,
      overallGoals: filters, nutrients: paging.nutrients,
      favourite: paging.favourite, mealSort: paging.mealSort
    });
  }
  function onChangeNutrients(event) {
    var filters = []
    if(event){
      event.forEach(item => {
      filters.push(item.value)
    })}
    setPaging({
      pageNumber: 1, nameFilterValue: paging.nameFilterValue,
      foodGoals: paging.foodGoals, calories: paging.calories,
      mealTypes: paging.mealTypes, dietTypes: paging.dietTypes,
      overallGoals: paging.overallGoals, nutrients: filters,
      favourite: paging.favourite, mealSort: paging.mealSort
    });
  }

  function onChangeFavourite() {
    setPaging({
      pageNumber: 1, nameFilterValue: paging.nameFilterValue,
      foodGoals: paging.foodGoals, calories: paging.calories,
      mealTypes: paging.mealTypes, dietTypes: paging.dietTypes,
      overallGoals: paging.overallGoals, nutrients: paging.nutrients,
      favourite: paging.favourite ? false : true, mealSort: paging.mealSort
    });
  }

  function onChangeSort() {
    setPaging({
      pageNumber: 1, nameFilterValue: paging.nameFilterValue,
      foodGoals: paging.foodGoals, calories: paging.calories,
      mealTypes: paging.mealTypes, dietTypes: paging.dietTypes,
      overallGoals: paging.overallGoals, nutrients: paging.nutrients,
      favourite: paging.favourite, mealSort: paging.mealSort == 1 ? 2 : 1
    });
  }

  function detectScroll() {
    var scrollviewFrameHeight = window.innerHeight;
    var scrollviewContentHeight = document.documentElement.scrollHeight
    var sum = scrollviewContentHeight - scrollviewFrameHeight
    if (window.scrollY == 0) {
      //the top reached
    } else if (Math.round(window.scrollY) >= sum) {
      //the bottom reached
      if (!props.meals.meals || mealList.length == 18 * paging.pageNumber)
        LoadMore();
    }
  }

  function onFavourite(mealId) {
    props.commandActions.sendCommand(
      dataStores.MEAL,
      {
        actionType: "FAV",
        mealId: mealId,
      }
    );
    setRender(false)
  }

  function getMealCards() {
    if ((render === false && mealList[0] == undefined)) {
      return <></>;
    }
    else if ( mealList[0] == undefined){
      return <label>No matching recipes can be found. Please try broadening your search.</label>;
    }
    return (
      <Row className="meals">
        {mealList[0] ? mealList.map(x => {
          return (
            <Col lg={6} key={x.mealId}>
              <Button
                title="Favourite"
                className={`btn--transparent btn--circle btn-favourite ${x.favourite ? "text-red" : "text-white"}`} onClick={() =>
                  onFavourite(x.mealId)}>
                <i className={`fas fa-heart`}></i>
              </Button>
              <Link to={"/nutrition/meal/" + x.mealId}>
                <div className="meal card u-ph-zero u-pb-zero">
                  <div
                    className="header"
                    style={{
                      backgroundImage: `url(${x.imageUrl})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      textAlign: "right",
                      borderRadius: "10px 10px 0 0"
                    }}
                  >
                    <div className="meal-chart">
                      <ColourFitPlate
                        showIcons={false}
                        innerData={[
                          { value: x.fuel, name: "Fuel" },
                          { value: x.muscle, name: "Muscle" },
                          { value: x.health, name: "Health" }
                        ]}
                        outerData={[
                          { value: x.calorieLoad },
                          { value: 100 - x.calorieLoad }
                        ]}
                        scale={0.5} />
                    </div>
                  </div>
                  <Row className="meal-card-meta p-3">
                    <Col xs={12}>
                      <div className="meal-title mb-3"><h4>{x.title}</h4></div>
                    </Col>
                    <Col xs={4}>
                      <span className="meal-card-meta__text-large"> {x.prepTime}</span>
                      <br /> mins prep
                      </Col>
                    <Col xs={4}>
                      <span className="meal-card-meta__text-large">
                        {Math.round(x.energyKcal)}{" "}
                      </span>
                      <br /> kcals
                      </Col>
                    <Col xs={4}>
                      <span className="meal-card-meta__text-large">
                        {x.peopleServed}
                      </span>
                      <br /> serves
                      </Col>
                  </Row>
                </div>
              </Link>
            </Col>
          );
        }
        )
        : undefined}
      </Row>
    );
  }

  function getMeal(displayMealId) {
    return (
      <>
        <div
          className="post-heading-container"
          style={{
            backgroundImage: `url(${displayMeal.imageUrl})`
          }}
        >
          <h1 className="post-heading">{displayMeal.title}</h1>
        </div>
        <Container>
          <div
            className="post-content"
            dangerouslySetInnerHTML={{ __html: displayMeal.description }}
          ></div>

          {displayMeal.videoUrl && getVideo(displayMeal.videoUrl)}
        </Container>
      </>
    );
  }

  function getVideo(videoURL) {
    return (
      <video width="100%" height="50%" controls>
        <source src={videoURL} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    );
  }

  return displayMeal.mealId > 0 ? (
    getMeal()
  ) : (
      <Container>
        <Row className="title-container">
          <Col>
            <h1 className="page__title">Meals</h1>
          </Col>
          <Col>
            <div className="btn--actions">
              <Button
                title="New"
                className="btn--transparent btn--circle" onClick={() =>
                  onChangeSort()}>
                <i className="far fa-star-exclamation"></i>
              </Button>
              <Button
                title="Filters"
                className="btn--transparent btn--circle" onClick={() =>
                  setShowFilters(!showFilters)}>
                <i className="far fa-filter"></i>
              </Button>
              <Button
                title="Show Favourites"
                className="btn--transparent btn--circle" onClick={() =>
                  onChangeFavourite()}>
                <i className={`${paging.favourite ? "fas" : "far"} fa-heart`}></i>
              </Button>
            </div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={12}>
            <HeroInputField
              classes="w-100 pl-5"
              size="medium"
              type="text"
              onChange={onInputChange}
            />
            <Button
              title="Search"
              className="btn--search quick-search" onClick={() =>
                updateFilterHandler()
              }>
              <i className="fas fa-search"></i>
            </Button>
          </Col>
        </Row>
        <div className={showFilters ? "d-block" : "d-none"}>
          <Row>
            <Col xs={6}>
              <Select
                isMulti
                placeholder="Select meal type"
                name="MealTypes"
                options={MealTypes}
                onChange={onChangeMealTypes}
                className="basic-multi-select mb-3"
                classNamePrefix="select"
              />
            </Col>
            <Col xs={6}>
              <Select
                isMulti
                placeholder="Select food goals"
                name="FoodGoals"
                options={FoodGoals}
                onChange={onChangeFoodGoals}
                className="basic-multi-select mb-3"
                classNamePrefix="select"
              />
            </Col>
            <Col xs={6}>
              <Select
                isMulti
                placeholder="Select overal goals"
                name="OverallGoals"
                options={OverallGoals}
                onChange={onChangeOverallGoals}
                className="basic-multi-select mb-3"
                classNamePrefix="select"
              />
            </Col>
            <Col xs={6}>
              <Select
                isMulti
                name="Nutrients"
                placeholder="Select nutrients > 25% RDA"
                options={Nutrients}
                onChange={onChangeNutrients}
                className="basic-multi-select mb-3"
                classNamePrefix="select"
              />
            </Col>
            <Col xs={6}>
              <Select
                isMulti
                placeholder="Select diet type"
                name="DietTypes"
                options={DietTypes}
                onChange={onChangeDietTypes}
                className="basic-multi-select mb-3"
                classNamePrefix="select"
              />
            </Col>

            <Col xs={6}>
              <Select
                isMulti
                placeholder="Select calorie density"
                name="Calories"
                options={Calories}
                onChange={onChangeCalories}
                className="basic-multi-select mb-3"
                classNamePrefix="select"
              />
            </Col>
          </Row>
        </div>
        {getMealCards()}
        <Row>
          <Col></Col>
          <Col md="auto">
            {!render ?
              <LoadingSpinner/>
              :undefined
            }
          </Col>
          <Col></Col>
        </Row>
      </Container>
    );
}

function mapStateToProps(state) {
  return {
    meals: state.appObject[dataStores.MEALS],
    meal: state.appObject[dataStores.MEAL]
  };
}

function mapDispatchToProps(dispatch) {
  return {
    commandActions: bindActionCreators(commandActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MyMeals);

import React, { useState, useEffect, useRef } from 'react'
import { connect } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import { Container, Spinner, Button, Modal, Row, Col, Card, Tab, Tabs } from 'react-bootstrap'
import { FaLayerChat, FaUserPlus, FaSignOutAlt } from 'react-icons/fa'
import { IoIosNotificationsOff, IoIosNotifications } from 'react-icons/io'
import { fetchUsers } from '../redux/actions/users'
import { fetchChats, inviteUserToChat, leaveChat, getChatUser, updateChatUser } from '../redux/actions/chats'
import ChatMessages from '../components/Chat/ChatMessages'
import SelectUserModal from '../components/SelectUserModal'
import UserReport from '../components/reports/UserReport';
import CreateChatModal from '../components/Chat/CreateChatModal';
//import ChatReport from '../components/reports/ChatReport';
import moment from 'moment';
import LoadingSpinner from '../components/controls/LoadingSpinner';
import shortid from 'shortid';
import { createHeroApiClient } from '../hero-api-client';
import {CloudArrowDownIcon} from '@heroicons/react/24/solid'
function isImage(url) {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
}
function Chat(props) {
  const [chat, setChat] = useState(null)

 
  const handleSelectUserFormSubmit = (userId) => props.dispatch(inviteUserToChat(props.chatId, userId, ()=>{
    props.dispatch(fetchChats())
  }));

  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedReport, setSelectedReport] = useState(null);
  const [showReportModal, setShowReportModal] = useState(false);
  const [activityLog, setActivityLog] = useState(null);
  const [users, setUsers] = useState(null);
  const [files, setFiles] = useState(null);
  const [showSelectUserModal, setShowSelectUserModal] = useState(false);
  const setDetails = (results) => {
    setUsers(results.members);
    setFiles(results.files);
    setChat(results);
  }
  useEffect(() => {

    props.dispatch(fetchUsers());
    props.heroApiClient.getChatDetail(props.chatId).then((res)=>{setDetails(res)});
  }, []);

  useEffect(()=>{
    props.heroApiClient.getChatDetail(props.chatId).then((res)=>{setDetails(res)});
  },[props.chatId])
  

  // useEffect(() => {
  //   if (
  //     props.user.session.accessToken != null &&
  //     chat
  //   ) {
  //     var body = { userIds: chat.userIds, endDate: new Date() }
  //     const myRequest = new Request(process.env.REACT_APP_API_BASE_URL + "/useractivitydata", {
  //       method: 'POST',
  //       headers: {
  //         "hero-token": props.user.session.accessToken,
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify(body)
  //     });

  //     fetch(myRequest)
  //       .then((response) => {
  //         return response.json()
  //       })
  //       .then((data) => setActivityLog(data))
  //   }
  // }, [chat]);

  function openModal(report, userId) {
    setSelectedReport(report);
    setSelectedUserId(userId);
    setShowReportModal(true);
  }

  function handleModalHide() {
    setSelectedUserId(null);
    setSelectedReport(null);
    setShowReportModal(false);
  }

  const doLeaveChat = () => {
    if (!window.confirm(`Are you sure you want to leave chat?`)) return;
    props.dispatch(leaveChat(props.chatId,props.user.profile.id, () => {
      props.history.replace('/chats');
    }));
  }

  // const toggleMessageNotifications = () => {
  //   const chatUser = { ...props.chatUser.items[chatId], sendChatMessageNotifications: !props.chatUser.items[chatId].sendChatMessageNotifications };
  //   props.dispatch(updateChatUser(chatId, chatUser));
  //}

  function renderActivityLog() {
    var log = [];

    if (activityLog) {
      if (activityLog.length == 0) {
        return (
          <Col>
            <p>No chat activity</p>
          </Col>)
      }

      for (var key in activityLog) {
        activityLog[key].map((activity) => {
          log.push(
            <Col key={shortid.generate()} md={3}>
              <Card>
                <Card.Body>
                  <Row className="mb-2" className="text-center">
                    <Col md={12} className="text-center">
                      {
                        activity.type === 1 &&
                        <div className="user-goal ml-auto mr-auto mb-3">
                          <i className="far fa-trophy"></i>
                        </div>
                      }
                    </Col>
                    <Col md={12} className="text-center">
                      <p className="text-bold">{activity.userName}</p>
                      <p>{activity.title}</p>
                    </Col>
                    <Col md={12} className="text-center">
                      <p>{moment(activity.timestamp).fromNow()}</p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>)
        })
      }
      return log;
    }

    return undefined;
  }

  return (
    <Container>
      {chat && (
        <Container>
          <Row className="title-container">
            <Col>
              <h1 className="page__title mb-0">{chat.name}</h1>
            </Col>
            <Col>
              <div className="btn--actions">
                {/*<Button className="btn--circle mr-2" variant={"primary"} onClick={() => openModal('ChatReport')}>*/}
                {/*  <FaLayerChat />*/}
                {/*</Button>*/}
                {!props.chat?.channelSummary?.metadata && (
                  <Button className="btn--circle expandable addUser" onClick={() => setShowSelectUserModal(true)}>
                    <FaUserPlus />
                  </Button>
                )}
                {/* {props.chatUser.items[chatId] && (
                  <Button className="btn--circle ml-1" onClick={toggleMessageNotifications}>
                    {props.chatUser.items[chatId].sendChatMessageNotifications ? <IoIosNotifications /> : <IoIosNotificationsOff />}
                  </Button>
                )} */}
                <Button className="btn--circle expandable leaveChat ml-1" onClick={doLeaveChat}>
                  <FaSignOutAlt />
                </Button>
              </div>
            </Col>
          </Row>

          <h1 className="page__title"></h1>

          <Tabs variant="pills" fill defaultActiveKey="messages" id="uncontrolled-tab-example">
            <Tab eventKey="messages" title="Messages">
              <Row>
                <Col>
                  <Card>
                    <ChatMessages chatId={props.chatId}/>
                  </Card>
                </Col>
              </Row>
            </Tab>

            <Tab eventKey="members" title="Members">
              <Row>
                {users ? <>
                  {users.map(u => (
                    <Col key={`chat-member-userid-${u.id}`} md={3}>
                      <Card>
                        <Card.Body className="text-center">
                          {/* <img className="mb-2" src={u.avatarUrl || `https://eu.ui-avatars.com/api?name=${u.name}`} style={{ height: "50px", width: "50px", borderRadius: "50%" }} /> */}
                          <p>{u.member.name}</p>
                          {/* <Row>
                            <Col className="text-center">
                              <Button className="btn btn--transparent btn--circle" onClick={() => openModal('UserReport', u.id)}><i className="far fa-eye"></i></Button>
                            </Col>
                          </Row> */}
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </> : undefined}
              </Row>
            </Tab>
            <Tab eventKey="files" title="Files">
            <Row >
                {files ? <>
                  {files.map(f => {
                    return (
                      <Col key={`chat-member-userid-${f.id}`} md={3}>
                        <Card>
                          <Card.Body className="text-center">
                          <a href={'https://cdn.nvgtr.io/'+f.location} download={f.actualName} target='_blank' rel='noopener noreferrer' >
                              {isImage(f.location)?(<img src={'https://cdn.nvgtr.io/'+f.location} />):(<><CloudArrowDownIcon color={'grey'} /> <p>{f.fakeName?f.fakeName:f.actualName}</p></>)}
                           </a>
                            {/* <img className="mb-2" src={u.avatarUrl || `https://eu.ui-avatars.com/api?name=${u.name}`} style={{ height: "50px", width: "50px", borderRadius: "50%" }} /> */}
                            
                           
                            <p>Uploaded {moment(f.uploadDate).format('D/MM/YY')}</p>
                          </Card.Body>
                        </Card>
                      </Col>
                    )
                  }
                    )}
                </> : undefined}
              </Row>
              
            </Tab>
          </Tabs>
        </Container>
      )}

      {!chat && <LoadingSpinner/>}

      <SelectUserModal
        show={showSelectUserModal}
        setShow={setShowSelectUserModal}
        handleFormSubmit={handleSelectUserFormSubmit}
      />

      <Modal centered show={showReportModal} onHide={handleModalHide}>
        {selectedReport == 'UserReport' && <UserReport userId={selectedUserId} />}
      </Modal>
      
    </Container>
  );
}

const mapStateToProps = state => {
  return {
    users: state.users.items,
    user: state.user,
    heroApiClient: createHeroApiClient(state.user.session.accessToken)
  };
}

export default withRouter(connect(mapStateToProps)(Chat));


import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Card } from "react-bootstrap";
import HeroInputField from "../../common/Inputs/inputField";
import { updateUserGoals } from '../../redux/actions/userGoals';
import Button from "react-bootstrap/Button";

function GoalsSection(props) {
    const { name, goals } = props;
    const [model, setModel] = useState({});
    const [isSaving, setIsSaving] = useState (false)
    useEffect(() => {
        setModel(goals.reduce((acc, cur) => ({
            ...acc,
            [cur.healthMetricId]: cur.value
        }), {}));
    }, [goals]);

    function handleInputChange(event) {
        if (event.target.value < 0){
            return;
        }

        const newModel = {
            ...model,
            [event.target.name]: event.target.value
        };
        setModel(newModel);

        event.persist()
    }

    async function save() {
        setIsSaving(true)
        await props.dispatch(updateUserGoals(model))
        setIsSaving(false)
    }

    return (
        <Col md={12} key={"goal-section-" + name}>
            <Card className="bm">
                <Card.Header as="h5" className="card--transparent d-flex flex-column">
                    {name}
                </Card.Header>
                <Card.Body>
                    <Row>
                    {goals.map(goal => (
                        <Col md={6} key={"goal-id-" + goal.healthMetricId}>
                            <Row>
                                <Col md={6}>
                                    <label style={{ marginTop: "0.5rem" }}>
                                        {goal.name} ({goal.units})
                                    </label>
                                </Col>
                                <Col md={3}>
                                    <HeroInputField
                                        styles={{ width: "100%", padding: "0.35rem", marginBottom: "0.25rem" }}
                                        type="number"
                                        size="small"
                                        name={goal.healthMetricId}
                                        value={model[goal.healthMetricId]}
                                        onChange={handleInputChange}
                                    />
                                </Col>
                                <Col md={3}>
                                    <label className={"mr-0 metric-" + goal.healthMetricId}>
                                        <span id="km-calculator">= {model[goal.healthMetricId] / 1000} KM</span>
                                    </label>
                                </Col>
                            </Row>
                        </Col>
                    ))}
                    </Row>
                    <Col md={12}>
                    <div className="mt-4 text-right">
                        <Button disabled={isSaving} onClick={save}>{isSaving?"Saving":"Save"}</Button>
                    </div>
                    </Col>

                </Card.Body>
            </Card>
        </Col>
    );
}

export default connect()(GoalsSection);

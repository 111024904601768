
const createVideoClient = (sendRequest) => (meetingId) => {
    

    return {
    };
};



export const createVideosClient = (sendRequest) => {

    const getMeetings = async () => {
        const path = `/chime`;
        return await sendRequest(path);
    }

    const inMeeting = async (MeetingId) => {
        const path = `/chime/CurrentlyInMeeting/${MeetingId}`;
        const method = 'GET';
        return await sendRequest(path, method);
    };

    const JoinMeeting = async (MeetingId) => {
        const path = `/chime/JoinMeeting/${MeetingId}`;
        const method = 'POST';
        return await sendRequest(path,method);
    }
    const createMeeting = async ({ date, title, userIds }) => {
        const body = {date,title,userIds};
        const method = 'POST';
        const path = `/chime/Meeting`;
        return await sendRequest(path,method,body);
    };

    const deleteMeeting = async (meetingId) => {
        const path = `/chime`;
        const method = 'DELETE';
        const body = {meetingId}
        return await sendRequest(path, method,body);
    }



    const sendFeedback = async () => {
        const path = `/chime/Feedback`;
        const method = 'POST';
        return await sendRequest(path);
    }
    

    return {
            get: getMeetings,
            listUsers: inMeeting,
            start:JoinMeeting,
            create:createMeeting,
            delete:deleteMeeting,
            feedback:sendFeedback,
            createVideoClient: createVideoClient(sendRequest)
        
    };
};







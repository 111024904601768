// React
import React from "react";

// Charts
import { ResponsiveContainer, LineChart, Line, YAxis, Tooltip } from "recharts";

function feedLineChart(props) {
  function customTooltip(event) {
    if (event.payload && event.payload[0]) {

      return (
        <div
          style={{
            backgroundColor: "white",
            border: "1px solid #21b4f1",
            boxShadow: "1px 1px 3px 0px rgba(0,0,0,0.125)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <p
            style={{
              color: "#21b4f1",
              padding: "0.3rem 0.3rem 0.6rem 0.6rem",
              margin: "0 0"
            }}
          >{`${event.payload[0].payload.value} 
             ${
               event.payload[0].payload.value
                 ? event.payload[0].payload.unit
                 : ""
             }`}</p>
          <p
            style={{
              fontSize: "0.75rem",
              color: "#21b4f1",
              padding: "0 0.3rem 0.6rem 0.6rem",
              margin: "0 0"
            }}
          >{`At: ${event.payload[0].payload.time}`}</p>
        </div>
      );
    } else {
      return null;
    }
  }

  return (
    <div style={{ marginTop: "1rem" }}>
      <ResponsiveContainer width="100%" aspect={4.0 / 2.0}>
        <LineChart width={300} height={100} data={props.dataObject}>
          <Tooltip content={customTooltip} />
          <YAxis type="number" stroke="#cccccc" />
          <Line
            type="monotone"
            dataKey="value"
            stroke="#21b4f1"
            strokeWidth={2}
          />
          <Line
            type="monotone"
            dataKey="amt"
            stroke="#c2c2c2"
            strokeWidth={2}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default feedLineChart;

import React from 'react'
import { Alert, Button } from 'react-bootstrap'
import { markNotificationAsRead } from '../../redux/actions/notifications'
import { connect } from 'react-redux'

function Notification(props) {
    const { notification: { id, title, message, createdAt, unread, imageId, url, type } } = props

    return (
        <Alert key={id} variant={unread ? 'primary' : 'secondary'}>
            <Alert.Heading>{title}</Alert.Heading>
            <p>
                {toPrettyDateString(new Date(createdAt))} {message} 
            </p>
            <p>
                <Button href={url}>
                    Click here
                </Button>
            </p>
            {unread &&
                <p>
                    <Button onClick={() => props.dispatch(markNotificationAsRead(id))}>
                        Mark as read
                    </Button>
                </p>
            }
        </Alert>
    )
}

const toPrettyDateString = date =>
    datesAreOnSameDay(new Date(), date)
        ? date.toLocaleTimeString()
        : date.toLocaleDateString()

const datesAreOnSameDay = (first, second) =>
    first.getFullYear() === second.getFullYear()
    && first.getMonth() === second.getMonth()
    && first.getDate() === second.getDate()

export default connect()(Notification)

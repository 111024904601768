import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Alert } from 'react-bootstrap';
import MetricSection from '../components/sources/MetricSection';
import { fetchHealthMetrics } from '../redux/actions/healthMetrics'
import { fetchAppConnections } from '../redux/actions/appConnections';
import { fetchMetricSources } from '../redux/actions/userHealthMetricSources';

function Sources(props) {
  const [sections, setSections] = useState({});

  useEffect(() => {
    props.dispatch(fetchHealthMetrics());
  }, [props.healthMetrics]);

  useEffect(() => {
    props.dispatch(fetchAppConnections());
  }, [props.appConnections]);

  useEffect(() => {
    props.dispatch(fetchMetricSources());
  }, [props.healthMetricSources]);

  useEffect(() => {
    setSections(props.healthMetrics.items.reduce((acc, cur) => {
      if (!cur.healthWheelSectionName) return acc; // Ignore Other metrics for now
      const sectionName = cur.healthWheelSectionName || 'Other';
      return {
        ...acc,
        [sectionName]: [
          ...(acc[sectionName] || []),
          cur
        ]
      };
    }, {}));
  }, [props.healthMetrics]);

  return (
    <Container>
      <h1 className="page__title">Sources</h1>

      {(props.healthMetrics.isFetching || props.appConnections.isFetching) && <p>Loading...</p>}

      {!props.appConnections.isFetching && props.appConnections.items && !props.appConnections.items.some(x => x.connected) &&
        <Alert variant="warning">
          <Alert.Heading>You must connect to at least one app before you can configure your sources</Alert.Heading>
          Go to <a href='/app-connections'>My App Connections</a> to do this.
        </Alert>
      }

      {props.appConnections.items.some(x => x.connected) &&
        Object.keys(sections).map(sectionName => <MetricSection name={sectionName} healthMetrics={sections[sectionName]} />)
      }
    </Container>
  );
}

function mapStateToProps (state) {
  return {
    healthMetrics: state.healthMetrics,
    appConnections: state.appConnections,
    healthMetricSources: state.user.healthMetricSources
  }
}

export default connect(mapStateToProps)(Sources);

import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import dataStores from "../../redux/reducers/dataStores";
import * as commandActions from "../../redux/actions/commands";
import CalendarFunctions from "../../shared/calendarFunctions";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import {
  format as dfFormat,
  addMonths,
} from "date-fns";
import { NutritionApi } from "hero-api-client";
import { ButtonGroup, Button, Row, Col, Tooltip, Card } from "react-bootstrap";
import { PieChart, Pie, Cell } from 'recharts';

const NuApi = new NutritionApi({ basePath: process.env.REACT_APP_API_BASE_URL });

function MealCalendar(props) {
  const [monthData, setMonthData] = useState({});
  const [redirectPath, setRedirectPath] = useState("");
  const [displayMonth, setDisplayMonth] = useState(props.dateToDisplay);
  // Runs on load to get a months worth of data
  useEffect(() => {
    // We need to load a full months worth of data here
    if (
      props.heroToken != null &&
      displayMonth &&
      displayMonth.length > 0 &&
      displayMonth !== "" &&
      monthData.foodData == null
    ) {
      NuApi.foodConsumedInMonth(displayMonth, {
        headers: { "hero-token": props.heroToken }
      }).then(res => {
        setMonthData(res);
      });
    }
  }, [monthData, displayMonth]);

  // Handles the clicking of a given day
  function getRedirect() {
    if (redirectPath != "") {
      return <Redirect to={redirectPath} />;
    }
  }

  function toPercent(value, total) {
    return (value / total) * 100;
  }

  function dayContent(data) {
    if (!data) {
      return null;
    }

    const carbohydrate = parseFloat(data.carbohydrate);
    const fat = parseFloat(data.fat);
    const protein = parseFloat(data.protein);

    const total = carbohydrate + fat + protein;

    const carbohydratePc = toPercent(carbohydrate, total);
    const fatPc = toPercent(fat, total);
    const proteinPc = toPercent(protein, total);

    const pieData = [
      {
        name: "Carbohydrate",
        value: carbohydrate
      },
      {
        name: "Fat",
        value: fat
      },
      {
        name: "Protein",
        value: protein
      }
    ];

    if (pieData.every(x => isNaN(x.value))) {
      return <div>
        <PieChart width={150} height={150} className="macros-chart">
          <Pie
            data={[{
              value: 100
            }]}
            outerRadius={50}
            innerRadius={0}
            stroke="#eee"
            fill="#eee">
            {pieData.map((entry, index) => <Cell fill={"#eee"} />)}
          </Pie>
          <Tooltip />
        </PieChart>
      </div>
    }

    const colors = ['#5de49b', '#333', '#f3364d'];

    return (
      <div className="meal-cal-day-content">
        <div className="p-2">
          <Row>
            <Col xs={12} md={3} className="text-center">
              <PieChart width={150} height={150} className="macros-chart">
                <Pie
                  data={pieData}
                  outerRadius={50}
                  innerRadius={0}
                  strokeWidth={0}
                  fill="#8884d8">
                  {pieData.map((entry, index) => <Cell fill={colors[index % colors.length]} />)}
                </Pie>
                <Tooltip />
              </PieChart>
            </Col>
            <Col xs={12} md={2} className="my-auto text-left">
              <span className="color-calories text-large">
                {parseFloat(data.calories)} kcal
              </span>
            </Col>
            <Col xs={12} md={7} className="my-auto">
              <Row>
                <Col xs={6} className="text-left">
                  <span className="block block--small block-carbs"></span> Carbs
                </Col>
                <Col xs={3} className="text-right">
                  {carbohydrate}
                </Col>
                <Col xs={3} className="text-right">
                  {carbohydratePc.toFixed(1)}%
                </Col>
              </Row>
              <Row>
                <Col xs={6} className="text-left">
                  <span className="block block--small block-fat"></span> <span className=""></span> Fat
                </Col>
                <Col xs={3} className="text-right">
                  {fat}
                </Col>
                <Col xs={3} className="text-right">
                  {fatPc.toFixed(1)}%
            </Col>
              </Row>
              <Row>
                <Col xs={6} className="text-left">
                  <span className="block block--small block-protein"></span> Protein
                </Col>
                <Col xs={3} className="text-right">
                  {protein}
                </Col>
                <Col xs={3} className="text-right">
                  {proteinPc.toFixed(1)}%
            </Col>
              </Row>
            </Col>
          </Row>

        </div>
      </div>
    );
  }

  // Builds out the html for the while calendar
  function buildCalendar() {
    if (monthData.foodData == null) return <></>;
    var cal = [];
    var calMatrix = CalendarFunctions.getCalDatMatrixByDate(
      CalendarFunctions.convertDateStringToDate(displayMonth)
    );
    var index = 0;

    // Week Bar
    calMatrix.forEach(weekEle => {
      cal.push(
        <div key={index}>
          {weekEle.map(dayEle => {
            index += 1;

            return (
              <Card>
                <Card.Body>
                  <div
                    key={"day." + index}
                    onClick={() => { setRedirectPath(`/nutrition/diary/${dayEle.dateStr}`); }}
                    className={(dayEle.activeDay ? "" : "current")}>
                    <div className="cal-day-name-display">
                      {dfFormat(dayEle.dateFor, "E  do MMM")}
                      {/* Gets the content for the given date */}
                      {dayContent(
                        monthData.foodData[dfFormat(dayEle.dateFor, "yyyy-MM-dd")]
                      )}
                    </div>

                    <div className="cal-wheel"></div>
                  </div>
                </Card.Body>
              </Card>
            );
          })}
        </div>
      );
    });

    return cal;
  }

  // Handle the clicking of the month change buttons
  function handleMonthChange(monthsToAdd) {
    setDisplayMonth(
      dfFormat(addMonths(new Date(displayMonth), monthsToAdd), "yyyy-MM-dd")
    );
    setMonthData({});
  }

  return (
    <div>
      {getRedirect()}

      <Row className="mb-3">
        <Col xs={12}>
          <ButtonGroup size="lg" className="w100">
            <Button
              variant="outline-dark"
              onClick={() => {
                handleMonthChange(-1);
              }}
            >
              <FaChevronCircleLeft />
            </Button>
            <Button variant="outline-dark">
              {dfFormat(new Date(displayMonth), " MMMM yyyy")}
            </Button>
            <Button
              onClick={() => {
                handleMonthChange(1);
              }}
              variant="outline-dark"
            >
              <FaChevronCircleRight />
            </Button>
          </ButtonGroup>
        </Col>
      </Row>

      {buildCalendar()}
      <hr />
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    heroToken: state.appObject[dataStores.DELEGATED_PERMISSIONS].coachToken ? state.appObject[dataStores.DELEGATED_PERMISSIONS].coachToken : state.appObject[dataStores.HEADCOACH_PERMISSIONS].coachToken ? state.appObject[dataStores.HEADCOACH_PERMISSIONS].coachToken : state.user.session.accessToken,
    feed: state.feed,
    dateToDisplay: ownProps.displayDate
  };
}

function mapDispatchToProps(dispatch) {
  return {
    commandActions: bindActionCreators(commandActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MealCalendar);

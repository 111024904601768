export class storage {
  static storageTypes = {
    TOKEN: "token"
  };

  static GetItem(storageType) {
    return localStorage.getItem(storageType);
  }

  static SetItem(storageType, Value) {
    localStorage.setItem(storageType, Value);
    return;
  }

  static RemoveItem(storageType) {
    localStorage.removeItem(storageType);
    return;
  }
}

export default storage;

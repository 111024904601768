import { CREATE_ERROR, DISMISS_ERROR } from '../actions/errors'
import { END_USER_SESSION } from '../actions/userSession'

const initialState = {
    items: []
}

export default function errors(state = initialState, action) {
    switch (action.type) {
        case CREATE_ERROR:
            return {
                items: [ action.payload ].concat(state.items)
            }
        case DISMISS_ERROR:
            return {
                items: state.items.map(error => ({
                    ...error,
                    dismissed: action.payload.id == error.id || error.dismissed
                }))
            }
        case END_USER_SESSION:
            return initialState
        default:
            return state
    }
}
import { REQUEST_MEETINGS, RECEIVE_MEETINGS, INVALIDATE_MEETINGS } from '../actions/video'

const initialState = {
    isFetching: false,
    isValid: false,
    items:[]
}

export default function videos(state = initialState, action) {
    switch (action.type) {
        case REQUEST_MEETINGS:
            return {
                ...state,
                isFetching: true,
                isValid: true
            }
        case RECEIVE_MEETINGS:
            return {
                ...state,
                isFetching: false,
                isValid: true,
                items: action.payload
            }
        case INVALIDATE_MEETINGS:
            return {
                ...state,
                isValid: false
            }
        default:
            return state
    }
}
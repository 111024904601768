import React, { useState, useEffect, useRef } from "react";
import movementIcon from "../assets/icons/Icon-Movement.svg";
import mindfulnessIcon from "../assets/icons/Icon-Mindfulness.svg";
import activityIcon from "../assets/icons/Icon-Activity.svg";
import nutritionIcon from "../assets/icons/Icon-Nutrition.svg";
import { connect } from "react-redux";
import { fetchHealthData } from '../redux/actions/healthData';
import { fetchHealthMetrics } from '../redux/actions/healthMetrics';
import { Tooltip } from "react-svg-tooltip";

function HealthWheel(props) {
  const { keyid, displayType, segmentClickHandler, date, userId, calendarWheel } = props;
  const moodRef = useRef(null);
  const [isFetching, setIsFetching] = useState(true);
  const [abortController, setAbortController] = useState();

  if (displayType == 'big' && !segmentClickHandler) {
    throw new Error(`segmentClickHandler must not be null when displayType=='big'`);
  }

  useEffect(() => {
    setAbortController(new AbortController());
  }, []);

  useEffect(() => {
    if(abortController && abortController.abort) {
      return () => abortController.abort();
    }
  }, [abortController]);

  useEffect(() => {
    if(!abortController || calendarWheel){
      return;
    }
    props.dispatch(fetchHealthData(userId, date, abortController.signal));

    if (props.healthData && props.healthData[userId] && props.healthData[userId][date] && props.healthData[userId][date].items.length > 0) {
      setIsFetching(false);
      // NProgress.done();
    } else {
      setIsFetching(true);
      // NProgress.start();
    }
  }, [props.healthData, abortController])

  useEffect(() => {
    props.dispatch(fetchHealthMetrics());
  }, [props.healthMetrics]);

  const easingTypes = {
    linear: "linear",
    bounce: "bounce",
    easein: "easein",
    easeinout: "easeinout",
    easeout: "easeout",
    elastic: "elastic",
    backout: "backout"
  };
  const moodColor = ["", "#7a28c4", "#3a3ec0", "#0ea7c6", "#7fba20", "#03a906"];
  const wheelSettings = {
    time: 750,
    profilePicRadius: 78,
    wheelSize: 350,
    crossHairWidth: 50,
    crossHairStrokeWidth: 10,
    easing: easingTypes.easeinout,
    centerPoint: { x: 400, y: 400 },
    segTitleRange: 360,
    majorFontSize: "30px",
    segments: ["Mindfulness", "Nutrition", "Activity", "Movement"], // TODO: not hard-code this
    segGrads: {
      quarters: [
        { x: "0%", y: "100%", name: "topRight" },
        { x: "0%", y: "0%", name: "bottomRight" },
        { x: "100%", y: "0%", name: "bottomLeft" },
        { x: "100%", y: "100%", name: "topLeft" }
      ],
      notCompleteColorsSteps: [
        { offset: "0%", stopColor: "#21B4F1" },
        { offset: "100%", stopColor: "#91FFFE" }
      ],
      completeColorsSteps: [
        { offset: "0%", stopColor: "#00FF8C" },
        { offset: "100%", stopColor: "#00FF8C" }
      ]
    }
  };

  const [svgData, setSvgData] = useState({ wheel: [] });
  const [aniState, setAniState] = useState(0);
  const [wheelNumbers, setWheelNumbers] = useState({});
  const [scoreNumbers, setScoreNumbers] = useState({});
  const [segScoreInt, setSegScoreInt] = useState()

  const tooltips = useRef([]);

  useEffect(() => {
    if (aniState <= 0 && props.data && props.healthMetrics.items.length > 0) {
      setAniState(1);

      setWheelNumbers(
        wheelSettings.segments.reduce((acc, sectionName) => {
          const data = props.data.items.filter(x => x.healthMetricWheelSectionName != null && x.healthMetricWheelSectionName == sectionName && x.goalValue > 0) || [];
          if (date.length == 0) {
            return {
              ...acc,
              [sectionName]: {
                score: 0,
                percentage: 0
              }
            };
          }
          const normalizedGoalValue = Math.max.apply(Math, data.map(x => x.goalValue));
          const normalizedRecordedValues = data.map(x => x.recordedValue / x.goalValue * normalizedGoalValue)
          const averageNormalizedRecordedValue = normalizedRecordedValues.reduce((a, b) => a + b, 0) / data.length;

          return {
            ...acc,
            [sectionName]: {
              score: Math.round(averageNormalizedRecordedValue),
              percentage: Math.round(averageNormalizedRecordedValue / normalizedGoalValue * 100)
            }
          };
        }, {})
      );
      setScoreNumbers(
        wheelSettings.segments.reduce((acc, sectionName) => {
          const data = props.data.items.filter(x => x.healthMetricWheelSectionName != null && x.healthMetricWheelSectionName == sectionName && x.goalValue > 0) || [];
          if (date.length == 0) {
            return {
              ...acc,
              [sectionName]: {
                values: []
              }
            };
          }
          var scoreMap = []
          data.map(x => scoreMap.push({ name: x.name, unit: x.unit, value: x.recordedValue }))
          return {
            ...acc,
            [sectionName]: {
              values: scoreMap
            }
          };
        }, {})
      );
    }
    // Can we run the animation?
    if (aniState === 1) {
      setAniState(2);
      runAnimations();
    }
    else if ((aniState == 2) && displayType == "big") {
      //startRotatingScores();
    }
  }, [aniState]);

  useEffect(() => {
    // Reset Everything
    setSvgData({ wheel: [] });
    setWheelNumbers({});
    loadTheWheel();
    clearInterval(segScoreInt);
    if (props.data && !props.data.isFetching && props.data.isValid) {
      aniState > -1 ? setAniState(-1) : setAniState(aniState - 1);
    }
    else {
      setAniState(3)
    }

  }, [props.data]);

  function getFillId(startAngle, pc, seg) {
    //Work out the name

    var angPartId = "topLeft";
    if (startAngle < 90) {
      angPartId = "topRight";
    } else if (startAngle < 180) {
      angPartId = "bottomRight";
    } else if (startAngle < 270) {
      angPartId = "bottomLeft";
    }

    var comp = "noComplete";
    if (pc > 98) {
      comp = "Complete";
    }

    // handle over score on nutrition
    if (pc > 100 && seg == "nutrition") {
      comp = "noComplete";
    }
    if (pc < 100 && seg == "nutrition") {
      comp = "Complete";
    }

    return "url(#" + angPartId + "-" + comp + ")";
  }

  function runAnimations() {
    //try {
    //   var startAngle = 0;
    //   var incrementAngle = 360 / wheelSettings.segments.length;
    //   wheelSettings.segments.map(seg => {

    //     if (props.data) {
    //       const goals = props.data.items.filter(x => x.healthMetricWheelSectionName != null && x.healthMetricWheelSectionName == seg && x.goalValue > 0);
    //       const goalAngle = startAngle;

    //       goals.map(g => {
    //         var segElement = Snap.select("#" + seg + keyid + g.healthMetricType);
    //         var segScore = wheelNumbers[seg].percentage

    //         if (segScore > 350) {
    //           segScore = 350;
    //         }

    //         // Handle nutrition complete
    //         const nutritionIsComplete = (props.data.items.find(x => x.healthMetricType == 'energy_consumed') || {}).isComplete;
    //         if (seg === "nutrition" && nutritionIsComplete) {
    //           segScore = 350;
    //         }

    //         segElement.attr({
    //           fill: getFillId(startAngle, wheelNumbers[seg].percentage, seg)
    //         });

    //         segElement.animate(
    //           {
    //             d:
    //               describeArc(
    //                 wheelSettings.centerPoint.x,
    //                 wheelSettings.centerPoint.y,
    //                 350,
    //                 startAngle,
    //                 startAngle + segScore
    //               ) +
    //               " L " +
    //               wheelSettings.centerPoint.x +
    //               " " +
    //               wheelSettings.centerPoint.y
    //           },
    //           wheelSettings.time,
    //           window["mina"][wheelSettings.easing]
    //         );
    //       });
    //     }

    //     startAngle = startAngle + incrementAngle;
    //   });
    // } catch (error) { }

    //Trigger the Pc Increment
    if (displayType == "big") {
      startIncrementingPc();
    }
    setAniState(3);
  }

  function startIncrementingPc() {
    wheelSettings.segments.map(seg => {
      var internalMs =
        (wheelSettings.time * 1.3) / wheelNumbers[seg].percentage + 1;
      var segPcInt = setInterval(() => {
        var pcDisplay = document.getElementById(seg + "display" + keyid);
        //var scDisplay = document.getElementById(seg + "ScoreDisplay" + keyid);
        if (pcDisplay) {
          var currentValue = pcDisplay.innerHTML.replace("%", "");
          if (currentValue < wheelNumbers[seg].percentage) {
            pcDisplay.innerHTML = Number(currentValue) + 1 + "%";

            /* scDisplay.innerHTML = Number(
              wheelNumbers[seg].score *
                (Number(currentValue) / wheelNumbers[seg].percentage)
            ).toFixed(0); */
          } else {
            pcDisplay.innerHTML = wheelNumbers[seg].percentage + "%"
            //scDisplay.innerHTML = wheelNumbers[seg].score;
            clearInterval(segPcInt);
          }
        }
      }, internalMs);
    });
  }

  function startRotatingScores() {
    var internalMs = 5000;
    setSegScoreInt(setInterval(() => {
      wheelSettings.segments.map((sectionName) => {
        if (scoreNumbers[sectionName] && displayType == "big") {
          const data = scoreNumbers[sectionName].values;
          var scDisplay = document.getElementById(sectionName + "ScoreDisplay" + keyid);
          var unDisplay = document.getElementById(sectionName + "UnitDisplay" + keyid);
          var naDisplay = document.getElementById(sectionName + "NameDisplay" + keyid);
          var index = 0;
          if (scDisplay && unDisplay && naDisplay) {
            index = data.findIndex(x => { return x.name === naDisplay.innerHTML && x.unit === unDisplay.innerHTML })

            if (date.length == 0) {
              scDisplay.innerHTML = 0;
              unDisplay.innerHTML = "";
              naDisplay.innerHTML = "";
            }
            if (index != data.length - 1 && index != -1) {
              index = index + 1;
            }
            else {
              index = 0;
            }
            scDisplay.innerHTML = Number(data[index].value);
            unDisplay.innerHTML = data[index].unit;
            naDisplay.innerHTML = data[index].name;
          }
          else {
            clearInterval(segScoreInt);
          }
        }
      }, {})
    }, internalMs))
  }

  function segClicked(evt) {
    if (displayType == 'small') {
      return;
    }

    var svg = document.getElementById('wheel');
    var pt = svg.createSVGPoint();
    pt.x = evt.clientX;
    pt.y = evt.clientY;

    // The cursor point, translated into svg coordinates
    var cursorpt = pt.matrixTransform(svg.getScreenCTM().inverse());

    if (
      cursorpt.x > wheelSettings.centerPoint.x &&
      cursorpt.y < wheelSettings.centerPoint.y
    ) {
      segmentClickHandler(wheelSettings.segments[0]);
    }

    if (
      cursorpt.x > wheelSettings.centerPoint.x &&
      cursorpt.y > wheelSettings.centerPoint.y
    ) {
      segmentClickHandler(wheelSettings.segments[1]);
    }

    if (
      cursorpt.x < wheelSettings.centerPoint.x &&
      cursorpt.y > wheelSettings.centerPoint.y
    ) {
      segmentClickHandler(wheelSettings.segments[2]);
    }

    if (
      cursorpt.x < wheelSettings.centerPoint.x &&
      cursorpt.y < wheelSettings.centerPoint.y
    ) {
      segmentClickHandler(wheelSettings.segments[3]);
    }
  }

  function getIcons(displayType) {
    var s = 50;
    var x = 735;
    if (displayType != "big") {
      s = 100;
      x = 690;
    }

    return (
      <>
        <image xlinkHref={mindfulnessIcon} x={x} y="0" height={s} width={s} />
        <image xlinkHref={nutritionIcon} x={x} y="710" height={s} width={s} />
        <image xlinkHref={activityIcon} x="40" y="690" height={s} width={s} />
        <image xlinkHref={movementIcon} x="50" y="0" height={s} width={s} />
      </>
    );
  }

  function getIconsAndNumbers() {
    return (
      <>
        {/* mindfulness */}
        <text
          id={`mindfulnessScoreDisplay${keyid}`}
          x="745"
          y="100"
          alignmentBaseline="hanging"
          textAnchor="end"
          className="svg-text"
          style={{ fontSize: wheelSettings.majorFontSize }}
        >
          {/* 0 */}
        </text>
        {/* <text id={`mindfulnessUnitDisplay${keyid}`} x="750" y="50" alignmentBaseline="hanging">
          hours
        </text>
        <text id={`mindfulnessNameDisplay${keyid}`} x="750" y="65" alignmentBaseline="hanging">
          sleep
        </text> */}

        {/* nutrition */}
        <text
          id={`nutritionScoreDisplay${keyid}`}
          x="745"
          y="740"
          alignmentBaseline="hanging"
          textAnchor="end"
          style={{ fontSize: wheelSettings.majorFontSize, color: "#ffffff" }}
        >
          {/* 0 */}
        </text>
        {/* <text id={`nutritionUnitDisplay${keyid}`} x="750" y="740" alignmentBaseline="hanging">
          kcal
        </text>
        <text id={`nutritionNameDisplay${keyid}`} x="750" y="755" alignmentBaseline="hanging">
          energy
        </text> */}

        {/* ACTIVITY */}
        <text
          id={`activityScoreDisplay${keyid}`}
          x="50"
          y="740"
          alignmentBaseline="hanging"
          textAnchor="end"
          style={{ fontSize: wheelSettings.majorFontSize }}
        >
          {/* 0 */}
        </text>
        {/* <text id={`activityUnitDisplay${keyid}`} x="50" y="740" alignmentBaseline="hanging" textAnchor="start">
          active
        </text>
        <text id={`activityNameDisplay${keyid}`} x="50" y="755" alignmentBaseline="hanging" textAnchor="start">
          mins
        </text> */}

        {/* MOVEMENT */}
        <text
          id={`movementScoreDisplay${keyid}`}
          x="100"
          y="50"
          alignmentBaseline="hanging"
          textAnchor="end"
          style={{ fontSize: wheelSettings.majorFontSize }}
        >
          {/* 0 */}
        </text>
        {/* <text id={`movementUnitDisplay${keyid}`} x="100" y="50" alignmentBaseline="hanging">
          steps
        </text>
        <text id={`movementNameDisplay${keyid}`} x="100" y="75" alignmentBaseline="hanging"></text> */}
      </>
    );
  }

  function createNodeWithAttributes() {
    var atts = {};

    for (var prop in arguments[0].attributes) {
      if (Object.prototype.hasOwnProperty.call(arguments[0].attributes, prop)) {
        atts[prop] = arguments[0].attributes[prop];
      }
    }

    var newNode = React.createElement(arguments[0].name, atts);

    return newNode;
  }

  function loadTheWheel() {
    var backgroundElements = [];

    addBackgroundRadarSections(backgroundElements);

    // These are the segments that spread out
    addSegments(backgroundElements);

    getCrossHairs(backgroundElements);

    if (displayType == "big") {
      addFigures(backgroundElements);
      addText(backgroundElements);
      addProfilePic(backgroundElements);
    }

    setSvgData({ wheel: backgroundElements });
  }

  function addBackgroundRadarSections(backgroundElements) {
    // Add the background circles
    // These are the blue sections as the wheel spreads out (like a radar)
    [
      {
        key: keyid + "-1",
        cx: wheelSettings.centerPoint.x,
        cy: wheelSettings.centerPoint.y,
        r: wheelSettings.wheelSize,
        fill: "#F2F5F9",
        stroke: "#fff",
        strokeWidth: wheelSettings.crossHairStrokeWidth
      },
      {
        key: keyid + "-2",
        cx: wheelSettings.centerPoint.x,
        cy: wheelSettings.centerPoint.y,
        r: wheelSettings.wheelSize - wheelSettings.crossHairWidth,
        fill: "#fafafa",
        stroke: "#fafafa",
        strokeWidth: wheelSettings.crossHairStrokeWidth
      }
    ].map(x => {
      backgroundElements.push(
        createNodeWithAttributes({ name: "circle", attributes: x })
      );
    });
  }

  function addSegments(backgroundElements) {
    // Add the sectors (mind, nut, act, move)
    var total = 0;
    var startAngle = 0;
    var incrementAngle = 360 / wheelSettings.segments.length;
    wheelSettings.segments.map(seg => {

      if (props.data) {
        var goals = props.data.items.filter(x => x.healthMetricWheelSectionName != null && x.healthMetricWheelSectionName == seg && x.goalValue > 0);
        var goalAngle = startAngle;

        goals.map(g => {
          var goalPc = (g.recordedValue / g.goalValue) * 100;

          if(goalPc > 100) {
            goalPc = 100;
          }

          var perc = (goalPc / goals.length);
          var ingrement = (perc / 100) * incrementAngle;

          tooltips.current[total] = React.createRef();

          backgroundElements.push(
            addPieSegment(
              goalAngle,
              goalAngle + ingrement,
              350,
              "#15cbce",
              seg + keyid + g.healthMetricType,
            )
          );

          backgroundElements.push(
            addPieSegment(
              goalAngle,
              goalAngle + ingrement,
              300,
              "#fff",
              seg + keyid + g.healthMetricType + "cover",
              tooltips.current[total]
            )
          );

          backgroundElements.push(<Tooltip triggerRef={tooltips.current[total]}>
            <text x={0} y={-10} fontSize={25} fill='black'>{g.name}</text>
          </Tooltip>);

          total = total + 1;
          goalAngle = goalAngle + ingrement;
        });
      }

      startAngle = startAngle + incrementAngle;
    });
  }


  function addFigures(backgroundElements) {
    // Add the Display % figures
    var textPos = [
      { x: 530, y: 260 },
      { x: 530, y: 570 },
      { x: 260, y: 570 },
      { x: 260, y: 260 }
    ];
    wheelSettings.segments.map((seg, index) => {
      backgroundElements.push(
        React.createElement(
          "text",
          {
            key: seg + "display" + keyid,
            id: seg + "display" + keyid,
            x: textPos[index].x,
            y: textPos[index].y,
            textAnchor: "middle",
            style: { fontSize: "32pt", textAlign: "center" }
          },
          "0%"
        )
      );
    });
  }

  function addProfilePic(backgroundElements) {
    backgroundElements.push(
      createNodeWithAttributes({
        name: "image",
        attributes: {
          key: "profilePic",
          x: wheelSettings.centerPoint.x - wheelSettings.profilePicRadius,
          y: wheelSettings.centerPoint.y - wheelSettings.profilePicRadius,
          height: wheelSettings.profilePicRadius * 2,
          width: wheelSettings.profilePicRadius * 2,
          clipPath: "url(#clip)"
        }
      })
    );
  }

  function addText(backgroundElements) {
    // ********** Adding Text around each sector
    var mindDefPath = React.createElement(
      "defs",
      { key: "defs" + keyid },
      React.createElement("path", {
        id: "textpath",
        key: "textpath",
        d: describeArcInvert(400, 400, wheelSettings.segTitleRange, 0, 90)
      })
    );
    backgroundElements.push(mindDefPath);

    var segMindTitle = React.createElement(
      "text",
      { key: "segTitleMindfulness" + keyid },
      React.createElement(
        "textPath",
        {
          className: "seg-title-text",
          key: "segTitleMindfulness" + keyid,
          href: "#textpath",
          textAnchor: "middle",
          startOffset: "50%",
          side: "left"
        },
        "Mindfulness"
      )
    );
    backgroundElements.push(segMindTitle);

    // ********** Adding Text - Nutration
    var nutDefPath = React.createElement(
      "defs",
      { key: "defsNutration" + keyid },
      React.createElement("path", {
        id: "nutDefPath",
        key: "textpath",
        d: describeArc(400, 400, wheelSettings.segTitleRange + 23, 90, 180)
      })
    );
    backgroundElements.push(nutDefPath);
    var segNutTitle = React.createElement(
      "text",
      { key: "segTitleNutrition" + keyid },
      React.createElement(
        "textPath",
        {
          className: "seg-title-text",
          key: "segTitleNutrition" + keyid,
          href: "#nutDefPath",
          textAnchor: "middle",
          startOffset: "50%",
          side: "left"
        },
        "Nutrition"
      )
    );
    backgroundElements.push(segNutTitle);

    var activityDefPath = React.createElement(
      "defs",
      { key: "defsSctivity" + keyid },
      React.createElement("path", {
        id: "activityDefPath",
        key: "textpath",
        d: describeArc(400, 400, wheelSettings.segTitleRange + 23, 180, 270)
      })
    );
    backgroundElements.push(activityDefPath);
    var segActTitle = React.createElement(
      "text",
      { key: "segTitleActivity" + keyid },
      React.createElement(
        "textPath",
        {
          className: "seg-title-text",
          key: "actTitleTextActivity" + keyid,
          href: "#activityDefPath",
          textAnchor: "middle",
          startOffset: "50%",
          side: "left"
        },
        "Activity"
      )
    );
    backgroundElements.push(segActTitle);

    // ********** Adding Text - movement
    var movementDefPath = React.createElement(
      "defs",
      { key: "defsMovement" + keyid },
      React.createElement("path", {
        id: "movementDefPath",
        key: "textpath",
        d: describeArcInvert(400, 400, wheelSettings.segTitleRange, 270, 360)
      })
    );
    backgroundElements.push(movementDefPath);
    var segMovementTitle = React.createElement(
      "text",
      { key: "segTitleMovement" + keyid },
      React.createElement(
        "textPath",
        {
          className: "seg-title-text",
          key: "movementTitleText",
          href: "#movementDefPath",
          textAnchor: "middle",
          startOffset: "50%",
          side: "left"
        },
        "Movement"
      )
    );
    backgroundElements.push(segMovementTitle);
  }

  function getCrossHairs(backgroundElements) {
    [
      {
        key: 5,
        x1: wheelSettings.centerPoint.x,
        y1: 50,
        x2: wheelSettings.centerPoint.y,
        y2: 775,
        stroke: "#fff",
        strokeWidth: wheelSettings.crossHairStrokeWidth
      },
      {
        key: 7,
        x1: 25,
        y1: wheelSettings.centerPoint.x,
        x2: 775,
        y2: wheelSettings.centerPoint.y,
        stroke: "#fff",
        strokeWidth: wheelSettings.crossHairStrokeWidth
      }
    ].map(x => {
      backgroundElements.push(
        createNodeWithAttributes({ name: "line", attributes: x })
      );
    });
  }

  function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians)
    };
  }

  function polarToCartesianInvert(centerX, centerY, radius, angleInDegrees) {
    var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians)
    };
  }

  function describeArc(x, y, radius, startAngle, endAngle) {
    var start = polarToCartesian(x, y, radius, endAngle);
    var end = polarToCartesian(x, y, radius, startAngle);

    var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

    var d = [
      "M",
      start.x,
      start.y,
      "A",
      radius,
      radius,
      0,
      largeArcFlag,
      0,
      end.x,
      end.y
    ].join(" ");

    return d;
  }

  function describeArcInvert(x, y, radius, startAngle, endAngle) {
    var start = polarToCartesianInvert(y, x, radius, endAngle);
    var end = polarToCartesianInvert(y, x, radius, startAngle);

    var largeArcFlag = startAngle - endAngle <= 180 ? "0" : "1";

    var d = [
      "M",
      end.x,
      end.y,
      "A",
      radius * -1,
      radius * -1,
      0,
      0,
      1,
      start.x,
      start.y
    ].join(" ");

    return d;
  }

  function addPieSegment(startAngle, FinishAngle, height, fillColour, id, ref) {
    var pieSec = createNodeWithAttributes({
      name: "path",
      attributes: {
        ref: ref,
        id: id,
        key: id,
        d:
          describeArc(400, 400, height, startAngle, FinishAngle) + " L 400 400",
        fill: fillColour,
        stroke: "#fff",
        strokeWidth: "4",
        opacity: 0.75
      }
    });

    return pieSec;
  }

  function getRadialGradients() {
    var radialGradients = [];

    wheelSettings.segGrads.quarters.map(quarters => {
      radialGradients.push(
        <radialGradient
          id={quarters.name + "-noComplete"}
          key={quarters.name + "-noComplete"}
          cx={quarters.x}
          cy={quarters.y}
          r="100%"
        >
          {wheelSettings.segGrads.notCompleteColorsSteps.map(step => {
            return (
              <stop
                key={"step" + step.offset}
                offset={step.offset}
                stopColor={step.stopColor}
              />
            );
          })}
        </radialGradient>
      );

      radialGradients.push(
        <radialGradient
          key={quarters.name + "-Complete"}
          id={quarters.name + "-Complete"}
          cx={quarters.x}
          cy={quarters.y}
          r="100%">
          {wheelSettings.segGrads.completeColorsSteps.map(step => {
            return (
              <stop
                key={"step" + step.offset}
                offset={step.offset}
                stopColor={step.stopColor}
              />
            );
          })}
        </radialGradient>
      );
    });

    return radialGradients;
  }

  function getMoodColor() {
    var value = props.moodData && props.moodData.items.some(x => x.healthMetricType == "mood") ? props.moodData.items.find(x => x.healthMetricType == "mood").recordedValue : 0
    if (value > 0) {
      var title = "";
      var icon = "";

      switch (value) {
        case 1:
          title = "Sad";
          icon = "far fa-sad-tear";
          break;
        case 2:
          title = "Unhappy";
          icon = "far fa-frown";
          break;
        case 3:
          title = "Ok";
          icon = "far fa-meh";
          break;
        case 4:
          title = "Happy";
          icon = "far fa-smile";
          break;
        case 5:
          title = "Very Happy";
          icon = "far fa-grin-squint";
          break;
        default:
          break;
      }

      return { color: moodColor[value], title, icon };
    }

    return { color: "white", title };
  }

  return (
    <div className={`${isFetching && "animate-loading-wheel"}`}>
      <svg
        id="wheel"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="100%"
        viewBox="0,0,800,800"
        onClick={segClicked}
      >
        <defs>{getRadialGradients()}</defs>
        {svgData.wheel}
        <defs>
          <rect id="rect" x="320" y="320" width="160" height="160" rx="100" />
          <clipPath id="clip">
            <use xlinkHref="#rect" />
          </clipPath>
        </defs>
        <use xlinkHref="#rect" strokeWidth="2" stroke="white" fill="white" />

        {displayType == "big" ? (
          <image
            key="profilePic"
            x={wheelSettings.centerPoint.x - wheelSettings.profilePicRadius}
            y={wheelSettings.centerPoint.y - wheelSettings.profilePicRadius}
            xlinkHref={props.user.profile.avatarUrl || `https://eu.ui-avatars.com/api?name=${props.user.profile.givenName} ${props.user.profile.familyName}`}
            height={wheelSettings.profilePicRadius * 2}
            width={wheelSettings.profilePicRadius * 2}
            clipPath="url(#clip)"
            onClick={() => segmentClickHandler('profile')}
          />
        ) : <>
            {/* <circle
              ref={moodRef}
              cx={wheelSettings.centerPoint.x}
              cy={wheelSettings.centerPoint.y}
              r={wheelSettings.profilePicRadius}
              fill={getMoodColor().color}>
            </circle> */}
            <foreignObject ref={moodRef} x={wheelSettings.centerPoint.x - wheelSettings.profilePicRadius} y={wheelSettings.centerPoint.y- wheelSettings.profilePicRadius} width="160" height="160">
              <i xmlns="http://www.w3.org/1999/xhtml" className={getMoodColor().icon} style={{fontSize:160, color:getMoodColor().color}}></i>
            </foreignObject>
            <Tooltip triggerRef={moodRef}>
              <text x={0} y={-10} fontSize={75} fill='black'>{getMoodColor().title}</text>
            </Tooltip>
          </>}

        {displayType == "big" && getIconsAndNumbers()}
        {getIcons(displayType)}
      </svg>
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    data: (state.healthData[ownProps.userId] || {})[ownProps.date] || null,
    moodData: (state.healthDataMood[ownProps.userId] || {})[ownProps.date] || null,
    healthData: state.healthData,
    healthDataMood: state.healthDataMood,
    healthMetrics: state.healthMetrics
  };
}

export default connect(mapStateToProps)(HealthWheel);

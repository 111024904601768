import { createHeroApiClient } from '../../hero-api-client';
import { createError } from './errors';
import NProgress from "nprogress";

export const REQUEST_CHALLENGES_JOINABLE = 'REQUEST_CHALLENGES_JOINABLE'
function requestChallengesJoinable() {
    return {
        type: REQUEST_CHALLENGES_JOINABLE
    }
}

export const RECEIVE_CHALLENGES_JOINABLE = 'RECEIVE_CHALLENGES_JOINABLE'
function receiveChallengesJoinable(challenges, userId) {
    return {
        type: RECEIVE_CHALLENGES_JOINABLE,
        payload: {
            challenges: challenges,
            receivedAt: Date.now(),
            userId: userId
        }
    }
}

function doFetchChallengesJoinable(accessToken, userId) {
    return async dispatch => {
        NProgress.start();
        dispatch(requestChallengesJoinable())
        createHeroApiClient(accessToken).getChallengesJoinable()
            .then(challenges => dispatch(receiveChallengesJoinable(challenges, userId)))
            .catch(error => dispatch(createError(error.message)))
            .finally(() => NProgress.done());
    }
}

function shouldFetchChallengesJoinable(state) {
    const challenges = state.challengesJoinable
    if (!challenges) {
        return true
    } else if (challenges.isFetching) {
        return false
    } else {
        return !challenges.isValid
    }
}

export function fetchChallengesJoinable() {
    return (dispatch, getState) => {
        const state = getState()
        if (!shouldFetchChallengesJoinable(state)) {
            return Promise.resolve()
        }
        
        const accessToken = state.user.session.accessToken
        const userId = state.user.profile.id
        return dispatch(doFetchChallengesJoinable(accessToken, userId))
    }
}

import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Modal, Container, Card, Row, Col } from 'react-bootstrap'
import HealthWheel from "../../health-wheel/wheel-mk3";
import DayPicker from "react-day-picker/DayPicker";
import "react-day-picker/lib/style.css";
import * as moment from "moment";
import { fetchHealthData } from '../../redux/actions/healthData';

function UserReport(props) {
  const { userId } = props;
  const [startDate, setStartDate] = useState(moment.utc().add(-6, 'days'));
  const [showDateModal, setShowDateModal] = useState(false);
  const [dates, setDates] = useState([]);

  useEffect(() => {
    setShowDateModal(false);
    setDates([...Array(7).keys()].map(offset =>  moment.utc(startDate).add(offset, 'days').format('YYYY-MM-DD')));
  }, [startDate]);

  useEffect(() => {
    dates.forEach(date => props.dispatch(fetchHealthData(userId, date)));
  }, [dates])

  return (
    <Container>
      <Row className="title-container">
        <Col>
          <h1 className="page__title">Reports</h1>
        </Col>
        <Col>
          <div className="btn--actions">
            <a className="btn btn-primary btn--square" onClick={e => setShowDateModal(true)}><i className="far fa-calendar-alt"></i></a>
          </div>
        </Col>
      </Row>

      {dates.length > 0 ?
        <div className="user-reports">
          <div className="p-4">
            <div className="row">
              <div className="col"></div>
              <div className="col"></div>
              <div className="col">Movement (Steps)</div>
              <div className="col">Recovery (Sleep Duration)</div>
              <div className="col">Nutrition (Energy Consumed)</div>
              <div className="col">Activity (Active Duration)</div>
            </div>
          </div>
          {dates.sort((a, b) => moment(a).diff(b)).map(date =>
            <Card key={date}>
              <Card.Body>
                <div className="row">
                  <div className="col">
                    <HealthWheel
                      key={date}
                      keyid={date}
                      delay={700}
                      displayType="small"
                      date={date}
                      userId={userId}
                    />
                  </div>
                  <div className="col my-auto text-center">
                    {moment(date).format('ddd Do MMM')}
                  </div>
                  {!(props.healthData[date] && props.healthData[date].items) && <p>Loading</p>}
                  {props.healthData[date] && props.healthData[date].items && (
                    <>
                      <div className="col my-auto text-center">
                        {(props.healthData[date].items.find(x => x.healthMetricType == 'steps') || {}).recordedValue || '-'}
                      </div>
                      <div className="col my-auto text-center">
                        {(props.healthData[date].items.find(x => x.healthMetricType == 'sleep_duration') || {}).recordedValue || '-'}
                      </div>
                      <div className="col my-auto text-center">
                        {(props.healthData[date].items.find(x => x.healthMetricType == 'energy_consumed') || {}).recordedValue || '-'}
                      </div>
                      <div className="col my-auto text-center">
                        {(props.healthData[date].items.find(x => x.healthMetricType == 'active_duration') || {}).recordedValue || '-'}
                      </div>
                    </>
                  )}
                </div>
              </Card.Body>
            </Card>
          )}
        </div> :
        <p>No data available</p>
      }
      <Modal centered show={showDateModal} className="calendar-month" onHide={e => setShowDateModal(false)}>
        <Modal.Dialog style={{ width: "280px" }}>
          <Row>
            <Col>
              <p>Please select the start of the week you want to see</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <DayPicker
                firstDayOfWeek={1}
                selectedDays={[startDate.toDate()]}
                month={startDate.toDate()}
                disabledDays={{ daysOfWeek: [0, 2, 3, 4, 5, 6] }}
                value={startDate.format("DD-MM-YYYY")}
                format="DD-MM-YYYY"
                placeholder="DD-MM-YYYY"
                formatDate={(d, f) => moment(d).format(f)}
                onDayClick={e => setStartDate(moment.utc(e))} />
            </Col>
          </Row>
        </Modal.Dialog>
      </Modal>
    </Container>
  )
}

function mapStateToProps(state, ownProps) {
  return {
    healthData: state.healthData[ownProps.userId] || {}
  }
}

export default connect(mapStateToProps)(UserReport);

import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Redux Actions
import dataStores from "../../redux/reducers/dataStores";
// 
import * as commandActions from "../../redux/actions/commands";

// MISC LIBS
import CalendarFunctions from "../../shared/calendarFunctions";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import {
  format as dfFormat,
  addMonths,
} from "date-fns";

import HeroOutlineButton from "../../common/Buttons/OutlineButton"

import * as moment from "moment";

import { Container, Row, Col, Button, ButtonGroup } from "react-bootstrap";
function HealthCalendar(props) {
  const [redirectPath, setRedirectPath] = useState("");
  const [displayMonth, setDisplayMonth] = useState(props.dateToDisplay);
  const [reportDates, setReportDates] = useState([]);

  // Runs on load to get a months worth of data
  useEffect(() => {
    // We need to load a full months worth of data here
    if (
      props.heroToken != null &&
      displayMonth &&
      displayMonth.length > 0 &&
      displayMonth !== "" &&
      reportDates && reportDates.length === 0
    ) {
      const myRequest = new Request(process.env.REACT_APP_API_BASE_URL  + "/health/report-dates", {
        method: 'GET',
        headers: {
          "hero-token": props.heroToken
        },
      });

      fetch(myRequest)
      .then((response) => {
        return response.json()
      })
      .then((data) => setReportDates(data))
    }
  }, [reportDates]);

  // Handles the clicking of a given day
  function getRedirect() {
    if (redirectPath != "") {
      return <Redirect to={redirectPath} />;
    }
  }

  function removeHealthData(date){
    props.commandActions.sendCommand(
      dataStores.HEALTH_ASSESSMENT,
      { actionType: "CLEAR", reportDate: date}
    );
  }
  function hasContentForDate(dayEle) {
    return (
      reportDates.length > 0 && 
      dayEle.dateFor.getMonth() == moment(displayMonth).month() && 
      reportDates.findIndex(reportDate => moment(reportDate, "YYYY-MM-DD").isSame(moment(dayEle.dateFor, "DD-MM-YYYY"))) != -1 
    );
  }
  // Builds out the html for the while calendar
  function buildCalendar() {
    var cal = [];
    var calMatrix = CalendarFunctions.getCalDatMatrixByDate(
      CalendarFunctions.convertDateStringToDate(displayMonth)
    );
    var index = 0;

    // Week Bar
    calMatrix.forEach(weekEle => {
      cal.push(
        <div key={index} className="cal-week">
          {weekEle.map(dayEle => {
            index += 1;

            return (
              <div
                key={"'day." + index}
                className={
                  "cal-day " +
                  (dayEle.activeDay ? "" : "cal-day-not-current") +
                  " meal-day"
                }
              >
                <div className="cal-day-name-display">
                  {dfFormat(dayEle.dateFor, "E  do MMM")}
                  {/* Gets the content for the given date */
                  hasContentForDate(dayEle) &&
                   <Container>
                      <Row><br/></Row>
                      <Row noGutters>
                        <HeroOutlineButton
                          size="large"
                          colour="#21b4f1"
                          classes="card-link"
                          onClick={() => {
                          setRedirectPath(
                            `/health-check/${dayEle.dateStr}`
                          )}} 
                          text="View Report"/>
                      </Row>
                      <Row><br/></Row>
                      <Row noGutters>
                        <HeroOutlineButton
                          size="large"
                          colour="#21b4f1"
                          classes="card-link"
                          onClick={() => {
                            removeHealthData(dayEle.dateFor)
                          }}
                          text={`Clear Data`} />
                      </Row>
                      <br/>
                    </Container>
                  }
                </div>
              </div>
            );
          })}
        </div>
      );
    });

    return cal;
  }

  // Handle the clicking of the month change buttons
  function handleMonthChange(monthsToAdd) {
    setDisplayMonth(
      dfFormat(addMonths(new Date(displayMonth), monthsToAdd), "yyyy-MM-dd")
    );
  }

  return (
    <div>
      {getRedirect()}

      <div>Health Report Calendar</div>

      <Row>
        <Col xs={12}>
          <ButtonGroup size="lg" className="w100">
            <Button
              variant="outline-dark"
              onClick={() => {
                handleMonthChange(-1);
              }}
            >
              <FaChevronCircleLeft />
            </Button>
            <Button variant="outline-dark">
              {dfFormat(new Date(displayMonth), " MMMM yyyy")}
            </Button>
            <Button
              onClick={() => {
                handleMonthChange(1);
              }}
              variant="outline-dark"
            >
              <FaChevronCircleRight />
            </Button>
          </ButtonGroup>
        </Col>
      </Row>

      {buildCalendar()}
      <hr />
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    heroToken: state.user.session.accessToken,
    appConfig: state.appConfig,
    feed: state.feed,
    dateToDisplay: ownProps.displayDate
  };
}

function mapDispatchToProps(dispatch) {
  return {
        // healthDataActions: bindActionCreators(healthDataActions, dispatch),
    commandActions: bindActionCreators(commandActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HealthCalendar);

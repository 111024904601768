import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { FaPlus } from 'react-icons/fa';
import CreateGroupModal from '../components/groups/CreateGroupModal';
import { fetchGroups, createGroup } from '../redux/actions/groups';

import { Container, Row, Col, Button, Card } from "react-bootstrap";
import LoadingSpinner from '../components/controls/LoadingSpinner';
const url = require("url");

function Groups(props) {
  const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
  const [dmUsers, setDMUsers] = useState();
  const [dmCoaches, setDMCoaches] = useState();
  const [userId, setUserId] = useState();

  useEffect(() => {
    if (!props.groups || !props.groups.isValid) {
      props.dispatch(fetchGroups())
    }
  }, [props.groups])

  useEffect(() => {
    if(!dmUsers || !props.groups.isValid){
      const localVarUrlObj = url.parse(process.env.REACT_APP_API_BASE_URL  + "/managementclaims/coach-access", true);
      delete localVarUrlObj.query;
      delete localVarUrlObj.search;
      var callUrl =  url.format(localVarUrlObj)
      const myRequest = new Request(callUrl, {
        method: 'GET',
        headers: {
          "hero-token": props.heroToken
        },
      });

      fetch(myRequest)
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        setDMUsers(data)
      })
    }
  }, [dmUsers, props.groups])

  useEffect(() => {
    if(!dmCoaches || !props.groups.isValid){
      const localVarUrlObj = url.parse(process.env.REACT_APP_API_BASE_URL  + "/managementclaims/coaches", true);
      delete localVarUrlObj.query;
      delete localVarUrlObj.search;
      var callUrl =  url.format(localVarUrlObj)
      const myRequest = new Request(callUrl, {
        method: 'GET',
        headers: {
          "hero-token": props.heroToken
        },
      });

      fetch(myRequest)
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        setDMCoaches(data)
      })
    }
  }, [dmCoaches, props.groups])

  function startDM(userId){
    props.dispatch(createGroup({name: userId}, userId, true))
    setUserId(userId)
  }

  return (
    <Container>
      <Row className="title-container">
        <Col>
          <h1 className="page__title">Groups</h1>
        </Col>
        <Col>
          <div className="btn--actions">
            {!props.groups.isFetching &&
              <Button className="btn--circle" onClick={() => setShowCreateGroupModal(true)}>
                <FaPlus />
              </Button>
            }
          </div>
        </Col>
      </Row>



      {props.groups.isFetching && <LoadingSpinner />}

      <Row>
        {props.groups &&
          props.groups.items.map(group => (
            !group.dmGroup?
            <Col md={4} className="mb-3 my-auto">
              <Card key={`group-${group.id}`} className="text-center">
                <Card.Body>
                  <Link to={{ pathname: `/groups/${group.id}/${group.name}` }}>
                    <h2>{group.name}</h2>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            :undefined
          ))
        }
      </Row>
      {dmCoaches && dmCoaches.some(user => user.groupId != null) ?
      <div>
        <hr/>
        <h3> Your Coaches</h3>
        <Row>
          {dmCoaches &&
            dmCoaches.map(user => (
              user.groupId != null ?
              <Col md={4} className="mb-3 my-auto">
                <Card key={`group-${user.userId}`} className="text-center">
                  <Card.Body>
                      <Link to={{ pathname: `/groups/${user.groupId}/${user.name}` }}>
                        <h2>{user.name}</h2>
                      </Link>
                  </Card.Body>
                </Card>
              </Col>
              :undefined
            ))
          }
        </Row>
      </div>
      : undefined}
      {/* {dmUsers && dmUsers.length > 0 ?
      <div>
        <hr/>
        <h3> Direct Message</h3>
        <Row>
            {dmUsers.map(user => (
              <Col md={4} className="mb-3 my-auto">
                <Card key={`group-${user.userId}`} className="text-center">
                  <Card.Body>
                    {user.groupId != null ?
                      userId == user.userId ?
                        <Redirect to={{ pathname: `/groups/${user.groupId}/${user.name}` }}></Redirect>
                      :
                        <Link to={{ pathname: `/groups/${user.groupId}/${user.name}` }}>
                          <h2>{user.name}</h2>
                        </Link>
                    : <Link onClick={() => startDM(user.userId)}>
                        <h2>{user.name}</h2>
                      </Link>
                    }
                  </Card.Body>
                </Card>
              </Col>
            ))
          }
        </Row>
      </div>
      :undefined} */}
      <CreateGroupModal
        showCreateGroupModal={showCreateGroupModal}
        setShowCreateGroupModal={setShowCreateGroupModal}
      />
    </Container>
  );
}

const mapStateToProps = state => {
  return {
    groups: state.groups,
    heroToken: state.user.session.accessToken
  };
}

export default connect(mapStateToProps)(Groups)
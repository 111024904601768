import React from "react";
import { connect } from "react-redux";
import HealthMetricsSection from "./healthMetricsSection";
import { Row, Col } from "react-bootstrap";

function HealthMetricsArea(props) {
  function RenderSections() {
    let sectionColOne = [];
    let sectionColTwo = [];

    for (var prop in props.healthMetricsArea.sections) {
      var s = props.healthMetricsArea.sections[prop];

      if (s.sectionId % 2 == 0 || s.sectionId === 1) {
        sectionColOne.push(
          <HealthMetricsSection
            key={"sectionId-" + s.sectionId}
            healthMetricsSection={s}
            healthMetric = {props.healthMetric}
            onChange={props.onChange}
            date={props.date}
          ></HealthMetricsSection>
        );
      } else {
        sectionColTwo.push(
          <HealthMetricsSection
            key={"sectionId-" + s.sectionId}
            healthMetricsSection={s}
            healthMetric = {props.healthMetric}
            onChange={props.onChange}
            date={props.date}
          ></HealthMetricsSection>
        );
      }
    }

    return [sectionColOne, sectionColTwo];
  }

  const [sectionOne, sectionTwo] = RenderSections();

  return (
    <Row>
      <Col md>{sectionOne}</Col>
      <Col md>{sectionTwo}</Col>
    </Row>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    healthMetricsArea: ownProps.healthMetricsArea
  };
}

export default connect(mapStateToProps)(HealthMetricsArea);

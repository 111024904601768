import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { fetchHealthData } from '../../redux/actions/healthData';
import { VictoryPie, VictoryTooltip, VictoryLegend } from 'victory';
import PieSlice from "../../health-wheel/mk4-components/PieSlice";
import _ from "lodash";
import TooltipLabel, { Flyout } from "../../health-wheel/mk4-components/TooltipLabel";
import cx from "../../health-wheel/mk4-components/TooltipLabel";
import { Tooltip } from "react-svg-tooltip";
import { moodColor } from "../../health-wheel/wheel-mk4";
const PIE_CHART_HEIGHT = 250;
const LABEL_RADIUS = PIE_CHART_HEIGHT / 3.25;
const SLICE_OUTER_RADIUS = PIE_CHART_HEIGHT / 2.5;
const SLICE_INNER_RADIUS = PIE_CHART_HEIGHT / 4.5;
const wheelSettings = {
    profilePicRadius: 30,
};

function HealthWheel(props) {
    const { date, userId } = props;
    const [remaining, setRemaining] = useState(100)
    const [value, setValue] = useState(0)
    const [percentage, setPercentage] = useState(0)
    const [isFetching, setIsFetching] = useState(true);
    const [abortController, setAbortController] = useState();
    const [tooltip, setTooltip] = useState(false);
    const selectedSlice= null;
    const onSelectSlice = (evt, props)=>{
        if(props.index === 0 && !tooltip) {
            setTooltip(true)
        } else {
            setTooltip(false)
        }
    }


    const getMoodColor = ()=> {
        var value = props.moodData && props.moodData.items.some(x => x.healthMetricType == "mood") ? props.moodData.items.find(x => x.healthMetricType == "mood").recordedValue : 0
        if (value > 0) {
            var title = "";
            var icon = "";

            switch (value) {
                case 1:
                    title = "Sad";
                    icon = "far fa-sad-tear";
                    break;
                case 2:
                    title = "Unhappy";
                    icon = "far fa-frown";
                    break;
                case 3:
                    title = "Ok";
                    icon = "far fa-meh";
                    break;
                case 4:
                    title = "Happy";
                    icon = "far fa-smile";
                    break;
                case 5:
                    title = "Very Happy";
                    icon = "far fa-grin-squint";
                    break;
                default:
                    break;
            }

            return { color: moodColor[value], title, icon };
        }

        return { color: "white", title };
    }


    useEffect(() => {
        setAbortController(new AbortController());
    }, []);

    useEffect(() => {
        if(abortController && abortController.abort) {
            return () => abortController.abort();
        }
    }, [abortController]);

    useEffect(() => {
        if(!abortController){
            return;
        }
        props.dispatch(fetchHealthData(userId, date, abortController.signal));

        if (props.healthData && props.healthData[userId] && props.healthData[userId][date] && props.healthData[userId][date].items.length > 0) {
            setIsFetching(false);
            // NProgress.done();
        } else {
            setIsFetching(true);
            // NProgress.start();
        }
    }, [props.healthData, abortController])


    useEffect(()=>{
        const data = props.data && props.data.items.find((item)=>item.healthMetricType === 'active_duration')
        const dataValue = data && data.recordedValue || 0;
        const target = 150/7;
        const targetPercentage = Math.min(100, parseInt((dataValue/target) * 100))
        const remaining = 100 - targetPercentage
        setValue(dataValue)
        setPercentage(targetPercentage)
        setRemaining(remaining)

    },[props.data])

    return (
            <div style={{width:PIE_CHART_HEIGHT,height:PIE_CHART_HEIGHT, margin:"auto", position:'relative'}}>
                <VictoryPie
                    colorScale={[ "#ed0806", "#f1f4f6", ]}
                    animate={{
                        duration: 400
                    }}
                    width={PIE_CHART_HEIGHT}
                    height={PIE_CHART_HEIGHT}
                    labels={({datum,index}) => {
                        return index === 0 ? `${percentage}%` : ' '
                    }}
                    style={{labels:{fill:percentage>5?'white':"#333"}}}
                    padding={0}
                    events={[{
                        target: 'data',
                        eventHandlers: {
                            onClick: onSelectSlice,
                        },
                    }]}
                    labelPlacement="perpendicular"
                    labelRadius={({ innerRadius }) => innerRadius + 5 }
                    innerRadius={80}
                    data={[
                        { x: props.data ? percentage + "%" : " ", y: Math.min(100,percentage) },
                        { x: " ", y: remaining  },
                    ] }
                />

                <svg
                style={{
                    position: "absolute",
                    top: "95px",
                    left: "95px"
                }}
                >
                    <foreignObject
                        width={wheelSettings.profilePicRadius * 2} height={wheelSettings.profilePicRadius * 2}>

                        <img
                            key="profilePic"
                            src={props.user.profile.avatarUrl || `https://eu.ui-avatars.com/api?name=${props.user.profile.givenName} ${props.user.profile.familyName}`}
                            style={{
                                position:"absolute",
                                borderRadius:wheelSettings.profilePicRadius ,
                                height:wheelSettings.profilePicRadius * 2,
                                width:wheelSettings.profilePicRadius * 2,
                            }}

                        />
                    </foreignObject>
                    <foreignObject
                                   width="50" height="50">
                        <i xmlns="http://www.w3.org/1999/xhtml" className={getMoodColor().icon} style={{fontSize:16, color:getMoodColor().color}}></i>
                    </foreignObject>
                    <rect
                        fill="rgba(0, 0, 0, 0)"
                        width={wheelSettings.profilePicRadius * 2}
                        height={wheelSettings.profilePicRadius * 2}
                        onClick={props.onAvatarClick}
                    />
                </svg>
                <div className="tooltip-fade" style={{ opacity: tooltip? 1: 0, position:"absolute", top:10, right:-50, width:170}}>
                    <Flyout datum={{
                        label:"Daily Active Duration", y:`${parseInt(value)} min${value!==1 && "s"}`
                    }}/>
                </div>
            </div>
    );
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.user,
        data: (state.healthData[ownProps.userId] || {})[ownProps.date] || null,
        moodData: (state.healthDataMood[ownProps.userId] || {})[ownProps.date] || null,
        healthData: state.healthData,
        healthDataMood: state.healthDataMood,
        healthMetrics: state.healthMetrics
    };
}

export default connect(mapStateToProps)(HealthWheel);

import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./login.css";
import { connect } from "react-redux";
import { beginUserSession } from "../redux/actions/userSession";
import { createHeroApiClient,  EngagementEvent } from '../hero-api-client';
import dataStores from "../redux/reducers/dataStores";
import HeroOutlineSubmit from "../common/Buttons/OutlineSubmit";
import { sendCommand } from "../redux/actions/commands";
import MicrosoftLogin from "react-microsoft-login";
import CryptoJS from "crypto-js";

function Login(props) {
  console.log('test',props.location.search);
  const [model, setModel] = useState({
    email: "",
    password: ""
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);

  //props.appConfig.SAML2LoginUrl = 'https://core.herowellbeing.com/Auth/Login?returnUrl="https://inspiredvillagesteam.herowellbeing.com"';
  props.appConfig.SAML2LoginUrl = props.appConfig.samL2LoginUrl;

  const companyKey = (window.location.host.split(".")[1] && window.location.host.split(".")[1] !== 'app')
    ? window.location.host.split(".")[0]
    : "app";
  if (props.appConfig.companyKey != companyKey) {
    props.dispatch(sendCommand(
      dataStores.APP_CONFIG,
      { actionType: "GET", companyKey: companyKey }
    ));
  }
  
  const bodyClass = document.body;
  bodyClass.classList.add("company-" + companyKey);

  useEffect(() => {
    if(props.isAuthenticated) {
      const redirect = new URLSearchParams(props.location.search).get('r');
      var direction = '/dashboard';
      if(redirect && redirect.startsWith('/')){
        direction = redirect.replace('/meet-g/','/meetings/');
      }
      window.location.assign(direction);
    }
  }, [props.isAuthenticated]);

  if (!props.appConfig.styles) {
    return null;
  }

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  function loginWithSSO() {
    window.location.href = props.appConfig.SAML2LoginUrl;
  }

  function handleFormSubmit(event) {
    event.preventDefault();
    var x = Math.random().toString(36).substr(2, 6);
    var keyx = '?D(G+KbPeS' + x;

    var key = CryptoJS.enc.Utf8.parse(keyx);
    var iv = CryptoJS.enc.Utf8.parse(keyx);
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(model.password), key,
      {
          keySize: 128 / 8,
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
      });
    var message = encrypted.toString();
    createHeroApiClient().getToken(model.email, x, message, companyKey)
      .then(tokenResponse => {
        setErrorMessage('')
        createHeroApiClient(tokenResponse.access_token).createEngagementEvent(EngagementEvent.LOGIN)
          .then(() => props.dispatch(beginUserSession(tokenResponse)))
      })
      .catch((out) => {
        if(out.message === 'Locked out of system. Please wait 5 minutes.'){
          setErrorMessage(out.message);
        }
        else{
          setErrorMessage('Login Error');
        }
      }
      );
  }

  function handleInputChange(event) {
    setModel({
      ...model,
      [event.target.name]: event.target.value
     });
  }

  const anchorStyle = {
    color: "white",
    fontSize: "0.7rem"
  };

  return (
    <div>
      <div className="login">
        <div
          style={{
            backgroundRepeat: "no-repeat",
            background: `url(${
              props.appConfig.styles.logoLoginBannerUrl
            })`,
            position: "relative"
          }}
          className="login__companyImg"
        >
        </div>

        <div
          className="login__loginWindow"
          style={{backgroundColor: props.appConfig.styles.loginMiniLogoBackgroundColor}}
        >
          <img
            src={props.appConfig.styles.logoSignUpLogoUrl}
            className="login__heroLogo"
          ></img>

          <form className="loginWindow__form" onSubmit={handleFormSubmit}>
            <input
              className="form__input"
              name="email"
              type="text"
              placeholder="Enter your email"
              value={model.email}
              onChange={handleInputChange}
              required
            />

            <div
              className="password-input-container"
              style={{position: "relative", width: "80%"}}>
              <input
                className="form__input"
                name="password"
                type={passwordShown ? "text" : "password"}
                style={{width: "100%", marginLeft: "0", marginRight: "0"}}
                placeholder="Enter your password"
                value={model.password}
                onChange={handleInputChange}
                required
              />
              <i
                className="far fa-eye toggle__password"
                onClick={togglePasswordVisiblity}
                style={{position: "absolute", top: "50%", color: "#FFF", right: "-25px", transform: "translateY(-50%)"}}>
              </i>
            </div>

            <HeroOutlineSubmit
              size="xlarge"
              value="Log In"
              onClick={handleFormSubmit}
              colour={'white'}
              backgroundColor={'black'}
            />

            <div className={'mt-3'}>
              {props.appConfig.SAML2LoginUrl && (
                  <HeroOutlineSubmit
                      size="xlarge"
                      value="Log In With SSO"
                      onClick={loginWithSSO}
                      colour={'white'}
                      backgroundColor={'black'}
                  />
              )}
            </div>

            <div className={'mt-3'}>
              {props.appConfig.microsoftClientID && (
                  <MicrosoftLogin graphScopes={["email", "profile"]}
                                  prompt="select_account"
                                  tenantUrl={"https://login.microsoftonline.com/"+props.appConfig.microsoftTenant+"/"}
                                  forceRedirectStrategy={true}
                                  redirectUri={document.location.origin+"/login"}
                                  clientId={props.appConfig.microsoftClientID}
                                  authCallback={(e,data)=>{
                                      if (e && e.errorMessage) {
                                          setErrorMessage(e.errorMessage);
                                      }
                                      if (data && data.accessToken) {
                                          createHeroApiClient().getSSOToken(data.accessToken, props.appConfig.companyKey)
                                              .then(tokenResponse => {
                                                  setErrorMessage('')
                                                  createHeroApiClient(tokenResponse.access_token).createEngagementEvent(EngagementEvent.LOGIN)
                                                      .then(() => props.dispatch(beginUserSession(tokenResponse)))
                                              })
                                              .catch(() => setErrorMessage('Login Error'));
                                      }
                  }}>
                      <HeroOutlineSubmit
                          size="xlarge"
                          type="button"
                          value="Log In with Microsoft SSO"
                          colour={'white'}
                      />
                  </MicrosoftLogin>
              )}
            </div>

            <p style={{marginTop: "2rem", color: "rgb(255, 255, 255)"}}>{errorMessage}</p>
            <div className="form__choiceBox">
              <NavLink className="h-btn" to={`/sign-up`}>
                Create an account
              </NavLink>

              <NavLink className="h-btn" to={`/ForgotPassword`}>
                Forgot password
              </NavLink>
            </div>
          </form>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              bottom: "0",
              width: "300px",
              marginTop: "30px",
            }}
          >
            <p style={{ color: "rgb(255, 255, 255)", fontSize: "0.8rem" }}>
              Powered by{" "}
              <a
                style={{ color: "rgb(255, 255, 255)", textDecoration: "none" }}
                href="https://herowellbeing.com/"
                target="_blank"
              >
                <img
                    src={require("../assets/logo/hero-logo-mark.svg")}
                  style={{
                    marginLeft: "0.25rem",
                    padding: "0px 0px 6px 0px",
                    height: "28px",
                    fill: "black",
                    filter: "invert(0)"
                  }}
                ></img>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}



function mapStateToProps(state) {
  return {
    appConfig: (state.appObject[dataStores.APP_CONFIG] || {}),
    redirectPath: '/dashboard',
    isAuthenticated: state.user.session.isAuthenticated
  };
}

export default connect(mapStateToProps)(Login);

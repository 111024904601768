import { createHeroApiClient } from '../../hero-api-client';
import { createError } from './errors';

export const REQUEST_MEETINGS = 'REQUEST_MEETINGS'
function requestMeetings() {
    return {
        type: REQUEST_MEETINGS
    }
}

export const RECEIVE_MEETINGS = 'RECEIVE_MEETINGS'
function receiveMeetings(meetings) {
    return {
        type: RECEIVE_MEETINGS,
        payload: {
            meetings: meetings
        }
    }
}

export const INVALIDATE_MEETINGS = 'INVALIDATE_MEETINGS'
export function invalidateMeetings() {
    return {
        type: INVALIDATE_MEETINGS
    }
}

export const REQUEST_IN_MEETING = 'REQUEST_IN_MEETING'
function requestInMeeting() {
    return {
        type: REQUEST_IN_MEETING
    }
}

export const RECEIVE_IN_MEETING = 'RECEIVE_IN_MEETING'
function receiveInMeeting(meetingUser) {
    return {
        type: REQUEST_IN_MEETING,
        payload: {
            meetingUser,
        }
    }
}

export const INVALIDATE_MEETING_USER = 'INVALIDATE_MEETING_USER'
export function invalidateInMeeting() {
    return {
        type: INVALIDATE_MEETING_USER
    }
}

function doFetchMeetings(accessToken) {
    return async dispatch => {
        dispatch(requestMeetings());
        createHeroApiClient(accessToken).meetings.get()
            .then((meetings) => dispatch(receiveMeetings(meetings)))
            .catch(error => dispatch(createError(error.message)));
    }
}

function shouldFetchMeetings(state) {
    const meetings = state.meetings
    if (!meetings) {
        return true
    } else if (meetings.isFetching) {
        return false
    } else {
        return !meetings.isValid
    }
}

export function fetchMeetings() {
    return async (dispatch, getState) => {
        const state = getState()
        const accessToken = state.user.session.accessToken
        return dispatch(doFetchMeetings(accessToken))
    }
}

export function createMeeting(details, userId = null, dmMeeting = null) {
    return async (dispatch, getState) => {
        const accessToken = getState().user.session.accessToken;
        createHeroApiClient(accessToken).meetings.create({...details, userId, dmMeeting})
            .then(() => dispatch(invalidateMeetings()))
            .catch(error => dispatch(createError(error.message)));
    }
}



export function leaveMeeting(meetingId, userId, cb) {
    return async (dispatch, getState) => {
        const accessToken = getState().user.session.accessToken;
        const meetingClient = createHeroApiClient(accessToken).meetings
            .createMeetingClient(meetingId);
        meetingClient.leave(userId)
            .then(() => {
                dispatch(invalidateMeetings());
                if (cb) cb();
            })
            .catch(error => dispatch(createError(error.message)));
    }
}

export function getInMeeting(meetingId) {
    return async (dispatch, getState) => {
        dispatch(requestInMeeting());
        const accessToken = getState().user.session.accessToken;
        const meetingClient = createHeroApiClient(accessToken).meetings
            .createMeetingClient(meetingId);
        meetingClient.user.get()
            .then(meetingUser => {
                dispatch(receiveInMeeting(meetingUser));
            })
            .catch(error => dispatch(createError(error.message)));
    }
}

export function updateMeetingUser(meetingId, meetingUser) {
    return async (dispatch, getState) => {
        const accessToken = getState().user.session.accessToken;
        const meetingClient = createHeroApiClient(accessToken).meetings
            .createMeetingClient(meetingId);
        meetingClient.user.update(meetingUser)
            .then(meetingUser => {
                dispatch(receiveInMeeting(meetingUser));
            })
            .catch(error => dispatch(createError(error.message)));
    }
}

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, LabelList, CartesianGrid, Cell } from 'recharts';

const SavingsCalculatorBarChart = ({ output }) => {
    const data = [
        { name: 'Contributions', value: output.totalContributions, color: '#6a8caf' },
        { name: 'Interest Earned', value: output.totalInterestEarned, color: '#cc0000' }, // Darker red
        { name: 'Final Amount', value: output.finalAmount, color: '#4c5b6a' },
      ];

  const formatYAxis = (tickItem) => `£${tickItem}`;

  const formatLabel = (labelItem) => `£${labelItem.toLocaleString(undefined, { minimumFractionDigits: 2 })}`;

  return (
    <Row>
      <Col>
        <BarChart width={600} height={300} data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
          <XAxis dataKey="name" />
          <YAxis tickFormatter={formatYAxis} />
          <Tooltip />
          <Legend />
          <CartesianGrid stroke="#ccc" strokeDasharray="3 3" strokeOpacity={0.5} />
          <Bar dataKey="value">
            {data.map((entry, index) => (
              <Cell key={index} fill={entry.color} />
            ))}
            <LabelList dataKey="value" position="top" formatter={formatLabel} />
          </Bar>
        </BarChart>
      </Col>
    </Row>
  );
};

export default SavingsCalculatorBarChart;

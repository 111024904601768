import React, { useEffect, useState } from "react";

// BOOTSTRAP STUFF
import Container from "react-bootstrap/Container";

// Redux Connections
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//Redux Actions

import * as commandActions from "../redux/actions/commands";
import dataStores from "../redux/reducers/dataStores";

// Bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import HeroOutlineButton from '../common/Buttons/OutlineButton';

// Charting

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import { Link } from "react-router-dom";

function HealthAssessment(props) {
  //
  // This holds the view model for display data
  const [chartData, setChartData] = useState([]);
  //
  // This holds the settings of the compare chart
  const [compareSettings, setCompareSettings] = useState({});
  //
  // This useEffect is for loading of data from the API
  useEffect(() => {
    // When this loads we need to request the data from the server
    // The data is read only, so we don't have to save it in a useState Var
    if (props.healthAssessment.areas == null) {
      props.commandActions.sendCommand(
        dataStores.HEALTH_ASSESSMENT,
        { actionType: "GET" }
      );
    } else {
      //We have data from the API but is the chart data populated?
      if (chartData.length === 0) {
        createDataModel();
      }
    }
  }, [props.healthAssessment]);

  // this takes the data from the API and creates a cleaner view model
  function createDataModel() {
    var rawData = [];

    // Loop over each of the found keys props.dataStores[dataStores.HEALTH_ASSESSMENT].Areas;
    for (var areaKey in props.healthAssessment.areas) {
      for (var sectionKey in props.healthAssessment.areas[areaKey].sections) {
        for (var healthMetricKey in props.healthAssessment.areas[areaKey]
          .sections[sectionKey].healthMetrics) {
          var met =
            props.healthAssessment.areas[areaKey].sections[sectionKey]
              .healthMetrics[healthMetricKey];

          // rawData.push(met);

          // Look at the historical data and build a data model for the chart
          var chartData = [];
          met.userHealthMetric.map(historyMetric => {
            chartData.push({
              name: historyMetric.recorded,
              value: Number(historyMetric.value)
            });
          });
          chartData.reverse();

          // Create the view model for this metric
          rawData.push({ met, chartData });
        }
      }
    }

    setChartData(rawData);
  }
  
  function customTooltip(event) {
    if (event.payload[0]) {
      return (
        <div
          style={{
            backgroundColor: "white",
            border: "1px solid #21b4f1",
            boxShadow: "1px 1px 3px 0px rgba(0,0,0,0.125)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <p
            style={{
              color: "#21b4f1",
              padding: "0.3rem 0.3rem 0.3rem 0.3rem",
              margin: "0px 0px 0px 0px"
            }}>{`${event.payload[0].payload.value} ${event.payload[0].payload.unit ? event.payload[0].payload.unit: ""
             }`}</p>

          <p
            style={{
              color: "#21b4f1",
              padding: "0.3rem 0.3rem 0.3rem 0.3rem",
              margin: "0px 0px 0px 0px",
              fontSize: "0.65rem"
            }}>{`${event.payload[0].payload.name ? event.payload[0].payload.name: ""
             }`}</p>
        </div>
      );
    } else {
      return null;
    }
  }

  function formatDate(date) {
    const monthNumbers = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const oldDateArr = date.slice(0, 10).split("-");

    //remove 20
    const newYear = oldDateArr[0].slice(2, 4);

    let newMonth = '';

    //swap month number to name
    monthNumbers.forEach( (el, index) => {
      if (el === oldDateArr[1]) {
        newMonth = monthNames[index];
      }
    })

    return [oldDateArr[2], newMonth, newYear].join("-");
    
  }

  function GetCharts() {
    // This is the html code for all the charts
    var charts = [];

    // Loop over each of the found keys props.dataStores[dataStores.HEALTH_ASSESSMENT].Areas;
    chartData.map(item => {
      
      // ideally this needs to be pushed in the raw data
      if (item.chartData.length > 0) {

        const formattedArr = item.chartData.map( el => {
          return {...el, name: formatDate(el.name), unit: item.met.units ? item.met.units.toLowerCase() : "uk"}
        } );

        charts.push(
          <Col
            md={6}
            key={
              item.met.healthMetricAreaId +
              "." +
              item.met.healthMetricSectionId +
              "." +
              item.met.healthMetricId
            }
          >
            <Card className="bm">
              <Card.Header>{item.met.name}</Card.Header>
              <Card.Body>
                <ResponsiveContainer width="100%" aspect={4.0 / 2.0}>
                  <LineChart
                    width={600}
                    height={300}
                    data={formattedArr}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                  >
                    <XAxis dataKey="name" dy={7} tick={{fontSize: "0.8rem"}} />
                    <YAxis dataKey="value" dx={-7} tick={{fontSize: "0.8rem"}} domain={["dataMin", "dataMax"]} />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip content={customTooltip} />

                    <Line type="monotone" dataKey="value" stroke="#21b4f1" />
                  </LineChart>
                </ResponsiveContainer>
              </Card.Body>
            </Card>
          </Col>
        );
      }
    });

    // return a collection of charts
    return charts;
  }

  function updateElement({ target }) {}

  function GetCompareChart() {
    // Populate the data sets to be compared
    const data = [
      { name: "Page A", uv: 4000, pv: 2400 },
      { name: "Page B", uv: 3000, pv: 1398 },
      { name: "Page C", uv: 2000, pv: 9800 },
      { name: "Page D", uv: 2780, pv: 9800 },
      { name: "Page E", uv: 1890, pv: 4800 },
      { name: "Page F", uv: 2390, pv: 3800 },
      { name: "Page G", uv: 3490, pv: 4300 }
    ];

    

    // Dynamically populate the dropdown lists for selecting compares
    var selectOpts = [];
    chartData.map(item => {
      selectOpts.push(
        <option key={item.met.HealthMetricId} value={item.met.HealthMetricId}>
          {item.met.Name}
        </option>
      );
    });

    return (
      <Card className="bm">
        <Card.Header>
          <Row>
            <Col xs={1}></Col>
            <Col xs={4} className="ac">
              <select
                name="compare1"
                onChange={updateElement}
              >
                {selectOpts}
              </select>
            </Col>
            <Col xs={2} className="ac">
              | VS |
            </Col>
            <Col xs={4} className="ac">
              <select
                name="compare2"
                onChange={updateElement}
              >
                {selectOpts}
              </select>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <ResponsiveContainer width="100%" aspect={4.0 / 1.0}>
            <LineChart
              data={data}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis yAxisId="left" />
              <YAxis yAxisId="right" orientation="right" />
              <Tooltip />
              <Legend />
              <Line
                yAxisId="left"
                type="monotone"
                dataKey="pv"
                stroke="#21b4f1"
              />
              <Line
                yAxisId="right"
                type="monotone"
                dataKey="uv"
                stroke="#00ff8c"
              />
            </LineChart>
          </ResponsiveContainer>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Container>
      <Row className="title-container">
        <Col>
          <h1 className="page__title">Health Assessment</h1>
        </Col>
        <Col>
          <div className="btn--actions">
            <Link to={"/health-check"} title="Health Check" className="btn btn--transparent btn--circle">
              <i className="far fa-notes-medical"></i>
            </Link>
            <Link to={"/health-calendar"} title="Health Calendar" className="btn btn--transparent btn--circle mr-2">
              <i className="far fa-calendar-alt"></i>
            </Link>
          </div>
        </Col>
      </Row>
      {/* {GetCompareChart()} */}
      <Row> {GetCharts()}</Row>

      <hr />
    </Container>
  );
}

function mapStateToProps(state) {
  return {
    appConfig: state.appConfig,
    feed: state.feed,
    healthAssessment: state.appObject[dataStores.HEALTH_ASSESSMENT]
  };
}

function mapDispatchToProps(dispatch) {
  return {
        commandActions: bindActionCreators(commandActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HealthAssessment);

import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";

// Redux rework
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//Redux Actions
import dataStores from "../../redux/reducers/dataStores";
import {sendCommand} from '../../redux/actions/commands'

// BOOTSTRAP STUFF
import Container from "react-bootstrap/Container";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

// Nutrition Components
import FoodQuickAdd from "../../components/nutrition/food-quick-add";
import MealDay from "../../components/nutrition/meal-day";
import { createHeroApiClient,  EngagementEvent } from '../../hero-api-client';

// Misc
import { format, addDays, subDays, parse } from "date-fns";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";

// hero Core API
import { NutritionApi } from "hero-api-client";
import moment from "moment";
const url = require("url");


function Nutrition(props) {
  const NuApi = new NutritionApi({ basePath: process.env.REACT_APP_API_BASE_URL });
  const [dateToDisplay, setDateToDisplay] = useState();
  const [daysFood, setDaysFood] = useState({});
  const [showQuickAdd, setQuickAdd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  let { displayForDate } = useParams();



  useEffect(() => {
    if (dateToDisplay == null) {
      if (displayForDate) {
        setDateToDisplay(parse(displayForDate, "yyyy-MM-dd", new Date()));
      }
      else{
        setDateToDisplay(new Date())
      }
    }

  }, [dateToDisplay]);

  useEffect(() => {
    if(daysFood && daysFood.error == "profile does not exist"){
      fSCreateUser();
    }
  },[daysFood])

  function loadFoodForDate() {
    if (
      props.heroToken != null
      && dateToDisplay
    ) {
      setQuickAdd(false);
      setIsLoading(true);
      NuApi.foodConsumedOnDate(format(dateToDisplay, "yyyy-MM-dd"), {
        headers: {
          "hero-token": props.heroToken
        }
      }).then(res => {
        setDaysFood(res);
        setIsLoading(false);
      });
    }
  }

  function removeFoodEntry(entryId) {
    var forDate = dateToDisplay;

    props.heroApiClient.createEngagementEvent(EngagementEvent.REMOVE_CONSUMED_FOOD)
    NuApi.foodRemoveEntry(entryId, forDate, {
      headers: {
        "hero-token": props.heroToken
      }
    }).then(res => {
      loadFoodForDate();
    });
  }

  function fSCreateUser() {
    NuApi.createFSUser({
      headers: {
        "hero-token": props.heroToken
      }
    })
      .then(res => {
        loadFoodForDate();
      });
  }

  function CloneDay(dateClone){
    const localVarUrlObj = url.parse(process.env.REACT_APP_API_BASE_URL  + "/nutrition/clone-day", true);
    const localVarQueryParameter = {};
    localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, {"dateStoreS": moment(dateToDisplay).format("YYYY-MM-DD"), "dateCloneS":  moment(dateClone).format("YYYY-MM-DD")});
    delete localVarUrlObj.search;
    var callUrl =  url.format(localVarUrlObj)
    const myRequest = new Request(callUrl, {
      method: 'GET',
      headers: {
        "hero-token": props.heroToken
      },
    });

    fetch(myRequest)
    .then((response) => {
      return response.json()
    })
    .then((data) => {
      loadFoodForDate();
    })
  }

  return (
    <Container>
      <Row className="title-container">
        <Col>
          <h1 className="page__title">Food Diary</h1>
        </Col>
        <Col>
          <div className="btn--actions">
            <Button className="btn--transparent btn--circle" onClick={()=> setQuickAdd(true)}>
              <i className="far fa-plus-circle"></i>
            </Button>
            <Link to={"/nutrition/diary"} className="btn btn--transparent btn--circle">
              <i className="far fa-book-heart"></i>
            </Link>
            <Link to={"/nutrition/calendar"} className="btn btn--transparent btn--circle mr-2">
              <i className="far fa-calendar-alt"></i>
            </Link>
          </div>
        </Col>
      </Row>

      <ButtonGroup size="lg" className="w100 mb-3">
        <Button
            variant="primary-text"
            className="light"
          disabled={isLoading}
          onClick={() =>{
            setDateToDisplay(
              subDays(dateToDisplay, 1), "yyyy-MM-dd"
            );
          }}>
          <i className="fal fa-chevron-left"></i>
        </Button>
        <Button className="light" variant="primary-text">
          {dateToDisplay? format(dateToDisplay, "iiii do MMMM") : "Loading..."}
        </Button>
        <Button
        className="light"
            variant="primary-text"
          disabled={isLoading}
          onClick={() => {
            setDateToDisplay(
              addDays(dateToDisplay, 1), "yyyy-MM-dd"
            );
          }}
        >
          <i className="fal fa-chevron-right"></i>
        </Button>
      </ButtonGroup>
      <MealDay
        dateToDisplay={dateToDisplay}
        isLoading={isLoading}
        reload={loadFoodForDate}
        daysFood={daysFood}
        removeFoodEntry={removeFoodEntry}
        heroApiClient={props.heroApiClient}
      />

      <Modal centered={true} show={showQuickAdd} onHide={()=> setQuickAdd(false)}>
        <Modal.Body>
          <FoodQuickAdd reload={loadFoodForDate} mealName="Breakfast" forDate={dateToDisplay} cloneDay={CloneDay}/>
          <div className="col-12 text-center">
            <Button color="gray-200" className="btn-md" onClick={()=> setQuickAdd(false)}>Close</Button>
          </div>
        </Modal.Body>
      </Modal>
    </Container>
  );
}

function mapStateToProps(state) {
  return {
    heroToken: state.appObject[dataStores.DELEGATED_PERMISSIONS].coachToken ? state.appObject[dataStores.DELEGATED_PERMISSIONS].coachToken : state.appObject[dataStores.HEADCOACH_PERMISSIONS].coachToken ? state.appObject[dataStores.HEADCOACH_PERMISSIONS].coachToken : state.user.session.accessToken,
    coachView: state.appObject[dataStores.DELEGATED_PERMISSIONS].coachToken || state.appObject[dataStores.HEADCOACH_PERMISSIONS].coachToken ? true : false,
    appConfig: state.appConfig,
    feed: state.feed,
    heroApiClient: createHeroApiClient(state.user.session.accessToken)
  };
}

export default connect(mapStateToProps)(Nutrition);

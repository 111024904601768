import React, { useState } from 'react'
import { connect } from "react-redux";
import Modal from 'react-bootstrap/Modal'
import { createChat } from '../../redux/actions/chats';
import dataStores from "../../redux/reducers/dataStores";

function CreateChatModal(props) {
  const { regions, departments } = props;
  const [model, setModel] = useState({
    region: props.userProfile?.region,
    department: props.userProfile?.department,
  });
  const handleInputChange = event => {
    setModel({
      ...model,
      [event.target.name]: event.target.value
    });
  }

  const isValid = () => {
    return !!model.name;
  }

  const handleFormSubmit = event => {
    event.preventDefault()

    if (!isValid()) {
      return
    }

    props.dispatch(createChat(model))

    props.setShowCreateChatModal(false)
    setModel({})
  }

  return (
    <Modal centered show={props.showCreateChatModal} onHide={() => props.setShowCreateChatModal(false)}>
      <Modal.Body>
        <form onSubmit={handleFormSubmit}>
          <h3>Create Chat</h3>

          <div className="c-field mt-2">
            <label className="c-field__label">Give your chat a name!</label>
            <input className="c-input" name="name" type="text" value={model.name} onChange={handleInputChange} />
          </div>
          <input className="btn btn-primary mt-3" type="submit" value="Submit" disabled={!isValid()} />
        </form>
      </Modal.Body>
    </Modal>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    userProfile: state.user.profile,
    regions: state.appObject[dataStores.APP_CONFIG].regions || [],
    departments: state.appObject[dataStores.APP_CONFIG].departments || [],
  };
}

export default connect(mapStateToProps)(CreateChatModal);

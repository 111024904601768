import moment from 'moment';
import {
    // REQUEST_CHALLENGES, RECEIVE_CHALLENGES, 
    INVALIDATE_CHALLENGES,
    REQUEST_CHALLENGE, RECEIVE_CHALLENGE, INVALIDATE_CHALLENGE,
    DELETE_CHALLENGE_LOADED,
} from '../actions/challenges'
import { END_USER_SESSION } from '../actions/userSession'

const initialState = {}

export default function challenges(state = initialState, action) {
    switch (action.type) {
        // case REQUEST_CHALLENGES:
        //     return {
        //         ...state,
        //         isFetching: true,
        //         isValid: true
        //     }
        // case RECEIVE_CHALLENGES:
        //     const challenges = action.payload.challenges
        //         .map(challenge => {
        //             const totalContributionValue = challenge.memberContributions.reduce((total, contribution) => total + contribution.value, 0);
        //             let targetContributionValue = challenge.targetValue * challenge.members.length;
        //             if (challenge.targetType == 'Average') {
        //                 targetContributionValue = targetContributionValue * moment(challenge.endDate).diff(challenge.startDate, 'days');
        //             }
        //             return {
        //                 ...challenge,
        //                 totalContributionValue,
        //                 targetContributionValue
        //             }
        //         });
        //     return {
        //         ...state,
        //         isFetching: false,
        //         isValid: true,
        //         items: challenges,
        //         lastUpdated: action.payload.receivedAt
        //     }
        case INVALIDATE_CHALLENGES:
            Object.keys(state).forEach(id => state[id].isValid = false);
            return {
                ...state,
            }
        case REQUEST_CHALLENGE:
            if (!state[action.id]) state[action.id] = {};
            state[action.id] = {
                ...state[action.id],
                isFetching: true,
                isValid: true,
            }
            return {
                ...state
            };
        case RECEIVE_CHALLENGE:
            const challenge = action.payload.challenge;
            const id = challenge.id;
            if (!state[id]) state[id] = {};
            const totalContributionValue = challenge.memberContributions.reduce((total, contribution) => total + contribution.value, 0);
            let targetContributionValue = challenge.targetValue * challenge.members.length;
            if (challenge.targetType == 'Average') {
                targetContributionValue = targetContributionValue * moment(challenge.endDate).diff(challenge.startDate, 'days');
            }
            state[id] = {
                challenge: {
                    ...action.payload.challenge,
                    totalContributionValue,
                    targetContributionValue,
                },
                lastUpdated: action.payload.receivedAt,
                isFetching: false,
                isValid: true,
            }
            return {
                ...state
            };
        case DELETE_CHALLENGE_LOADED:
            if (state[action.id]) {
                state[action.id].isDeleted = true;
            }
            return {
                ...state
            };
        case INVALIDATE_CHALLENGE:
            if (state[action.id]) {
                state[action.id] = {
                    ...state[action.id],
                    isValid: false,
                }
            }
            return {
                ...state
            };
        case END_USER_SESSION:
            return initialState
        default:
            return state
    }
}
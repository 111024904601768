import { createHeroApiClient } from '../../hero-api-client';
import { invalidateGroups } from './groups';
import { createError } from './errors';
import NProgress from "nprogress";

// export const REQUEST_CHALLENGES = 'REQUEST_CHALLENGES'
// function requestChallenges() {
//     return {
//         type: REQUEST_CHALLENGES
//     }
// }

// export const RECEIVE_CHALLENGES = 'RECEIVE_CHALLENGES'
// function receiveChallenges(challenges, userId) {
//     return {
//         type: RECEIVE_CHALLENGES,
//         payload: {
//             challenges: challenges,
//             receivedAt: Date.now(),
//             userId: userId
//         }
//     }
// }

export const INVALIDATE_CHALLENGES = 'INVALIDATE_CHALLENGES'
export function invalidateChallenges(id) {
    return {
        type: INVALIDATE_CHALLENGES,
        id,
    }
}

export const REQUEST_CHALLENGE = 'REQUEST_CHALLENGE'
function requestChallenge(id) {
    return {
        type: REQUEST_CHALLENGE,
        id,
    }
}

export const RECEIVE_CHALLENGE = 'RECEIVE_CHALLENGE'
function receiveChallenge(challenge, userId, id) {
    return {
        type: RECEIVE_CHALLENGE,
        payload: {
            challenge,
            receivedAt: Date.now(),
            userId: userId
        }
    }
}

export const INVALIDATE_CHALLENGE = 'INVALIDATE_CHALLENGE'
export function invalidateChallenge() {
    return {
        type: INVALIDATE_CHALLENGE
    }
}

export const DELETE_CHALLENGE_LOADED = 'DELETE_CHALLENGE_LOADED'
function deleteChallengeLoaded(id) {
    return {
        type: DELETE_CHALLENGE_LOADED,
        id,
    }
}

// function doFetchChallenges(accessToken, userId) {
//     return async dispatch => {
//         NProgress.start();
//         dispatch(requestChallenges())
//         createHeroApiClient(accessToken).challenges.list()
//             .then(challenges => dispatch(receiveChallenges(challenges, userId)))
//             .catch(error => dispatch(createError(error.message)))
//             .finally(() => NProgress.done());
//     }
// }

// function shouldFetchChallenges(state) {
//     const challenges = state.challenges
//     if (!challenges) {
//         return true
//     } else if (challenges.isFetching) {
//         return false
//     } else {
//         return !challenges.isValid
//     }
// }

// export function fetchChallenges() {
//     return (dispatch, getState) => {
//         const state = getState()
//         if (!shouldFetchChallenges(state)) {
//             return Promise.resolve()
//         }
//         const accessToken = state.user.session.accessToken
//         const userId = state.user.profile.id
//         return dispatch(doFetchChallenges(accessToken, userId))
//     }
// }

export function createChallenge(model) {
    return async (dispatch, getState) => {
        NProgress.start();
        const accessToken = getState().user.session.accessToken;
        createHeroApiClient(accessToken).challenges.create(model)
            .then(() => {
                if (model.createGroup) {
                    dispatch(invalidateGroups())
                }
                dispatch(invalidateChallenges())
            })
            .catch(error => dispatch(createError(error.message)))
            .finally(() => NProgress.done());
    }
}

export function addMemberToChallenge(challengeId, memberId) {
    return async (dispatch, getState) => {
        NProgress.start();
        const accessToken = getState().user.session.accessToken;
        const challengeClient = createHeroApiClient(accessToken).challenges
            .createChallengeClient(challengeId);
        challengeClient.addMember(memberId)
            .then(() => dispatch(invalidateChallenges()))
            .catch(error => dispatch(createError(error.message)))
            .finally(() => NProgress.done());
    }
}

export function removeMemberFromChallenge(challengeId, memberId) {
    return async (dispatch, getState) => {
        NProgress.start();
        const accessToken = getState().user.session.accessToken;
        const challengeClient = createHeroApiClient(accessToken).challenges
            .createChallengeClient(challengeId);
        challengeClient.removeMember(memberId)
            .then(() => dispatch(invalidateChallenges()))
            .catch(error => dispatch(createError(error.message)))
            .finally(() => NProgress.done());
    }
}

export function addGroupToChallenge(challengeId, groupId) {
    return async (dispatch, getState) => {
        NProgress.start();
        const accessToken = getState().user.session.accessToken;
        const challengeClient = createHeroApiClient(accessToken).challenges
            .createChallengeClient(challengeId);
        challengeClient.addGroup(groupId)
            .then(() => dispatch(invalidateChallenges()))
            .catch(error => dispatch(createError(error.message)))
            .finally(() => NProgress.done());
    }
}

function doFetchChallenge(accessToken, userId, id) {
    return async dispatch => {
        NProgress.start();
        dispatch(requestChallenge(id))
        createHeroApiClient(accessToken).challenges.get(id)
            .then(challenge => dispatch(receiveChallenge(challenge, userId, id)))
            .catch(error => dispatch(createError(error.message)))
            .finally(() => NProgress.done());
    }
}

function shouldFetchChallenge(state, id) {
    const challenges = state.challenges
    if (!challenges || !challenges[id]) {
        return true
    } else if (challenges[id].isFetching) {
        return false
    } else {
        return !challenges[id].isValid
    }
}

export function fetchChallenge(id) {
    return (dispatch, getState) => {
        const state = getState()
        if (!shouldFetchChallenge(state, id)) {
            return Promise.resolve()
        }
        const accessToken = state.user.session.accessToken
        const userId = state.user.profile.id
        return dispatch(doFetchChallenge(accessToken, userId, id))
    }
}

export function deleteChallenge(id, cb) {
    return (dispatch, getState) => {
        NProgress.start();
        const accessToken = getState().user.session.accessToken;
        createHeroApiClient(accessToken).challenges.remove(id)
            .then(() => {
                dispatch(deleteChallengeLoaded(id))
                if (cb) cb();
            })
            .catch(error => dispatch(createError(error.message)))
            .finally(() => NProgress.done());
    }
}

import React, { useEffect } from 'react';
import Radium from 'radium';

import dataStores from "../../redux/reducers/dataStores";

import { connect } from 'react-redux';


//PROPS TO BE PASSED INTO THIS COMPONENT
// colour - defines outline color of the field when it's focused (COLOR CONVENTION: instead of white => #FFFFFF)1
// size - defines fontSize & padding of the inputfield
// placeholder - defines the placeholder of the inputfield
// value - maps to <input> value
// onChange - maps to <input> onChange

function inputField(props) {

    let FONT_SIZE = "0.85rem";
    let PADDING = "0.55rem 1.05rem";
    let INPUT_COLOR = "black";
    let PLACEHOLDER = 'Enter a search term...';
    let CLASSES = '';
    let INPUT_NAME = '';

    if (props.size === "small") {
        FONT_SIZE = "0.8rem";
        PADDING = "0.2rem 0.35rem"
    }
    if (props.size === "medium") {
        FONT_SIZE = "1rem";
        PADDING = "0.35rem 0.55rem";
    }
    if (props.size === "large") {
        FONT_SIZE = "1.125rem";
        PADDING = "0.5rem 0.7rem";
    }

    if (props.color) {
        INPUT_COLOR = props.color
    }

    if (props.inputPlaceholder) {
        PLACEHOLDER = props.placeholder
    }

    if(props.classes) {
        CLASSES = props.classes;
    }

    if(props.name) {
        INPUT_NAME = props.name;
    }


    const inputStyle = {
        border: "none",
        backgroundColor: "#E8EDF1",
        color: "#21537B",
        borderRadius: "6px",
        padding: PADDING,
        fontSize: FONT_SIZE,
        transition: "border 0.3s, background-color 0.3s",
        ':focus': {
            outline: "none",
            border: `1px solid ${props.colour ? props.colour : (Object.keys(props.styling).length !== 0 ? props.styling.styles.navBarBackgroundColor : "#15CBCE")}`,
            backgroundColor: "#E8EDF1",
        },
        '::-webkit-input-placeholder': {
            color: "#21537B"
        }


    }

    return (
        <input
        type={props.type}
        className={CLASSES}
        placeholder={PLACEHOLDER}
        value={props.value}
        onChange={props.onChange}
        style={{...inputStyle, ...props.styles}}
        name={INPUT_NAME} />
    );
}

function mapStateToProps(state) {
    return {
        styling: state.appObject[dataStores.APP_CONFIG]
    };
  }


export default connect(mapStateToProps, null)(Radium(inputField));

import {
    RECEIVE_CHAT_MESSAGES,
    RECEIVE_READ_RECEIPTS
} from '../actions/chatMessages';
import { END_USER_SESSION } from '../actions/userSession';
import moment from 'moment';

const initialState = {}

export default function chats(state = initialState, action) {
    switch (action.type) {
        case RECEIVE_CHAT_MESSAGES:
            var m = state[action.payload.chatId]
            if(m && m.items && action.payload.messages.every(pm => m.items.some(i => pm.outgoing == i.outgoing && pm.sender.id == i.sender.id && pm.createdTimestamp == i.createdTimestamp))){
                return {
                    ...state,
                    [action.payload.chatId]: {
                        ...state[action.payload.chatId],
                        items: ((state[action.payload.chatId] || {}).items || [])
                            .sort((a, b) => moment(a.createdTimestamp).diff(b.createdTimestamp) )
                    }
                }
            }
            return {
                ...state,
                [action.payload.chatId]: {
                    ...state[action.payload.chatId],
                    items: ((state[action.payload.chatId] || {}).items || [])
                        .concat(action.payload.messages)
                        .sort((a, b) => moment(a.createdTimestamp).diff(b.createdTimestamp) )
                }
            }
            
        case END_USER_SESSION:
            return initialState
        default:
            return state
    }
}
export function reads(state=initialState, action){
    switch (action.type) {
        case RECEIVE_READ_RECEIPTS:

            var m = state[action.payload.chatId]
            if(m && m.items){
                return {
                    ...state,
                    [action.payload.chatId]: {
                        ...state[action.payload.chatId],
                        items: ((state[action.payload.chatId] || {}).items || [])
                    }
                }
            }
            return {
                ...state,
                [action.payload.chatId]: {
                    ...state[action.payload.chatId],
                    items: ((state[action.payload.chatId] || {}).items || [])
                        .concat(action.payload.receipts)
                }
            }
            
        case END_USER_SESSION:
            return initialState
        default:
            return state
    }
}

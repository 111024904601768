import React, { useState, useEffect } from "react";
import SearchPreHealthCheckResults from "./SearchPreHealthCheckResults";
import axios from "axios";

//#region Questions and Data
const medicalQuestions = [
  {
    question: "Do you have a history of heart disease or any cardiovascular conditions?",
    questionID: 1,
    answer: false,
  },
  {
    question: "Have you ever had a heart attack or stroke?",
    questionID: 2,
    answer: false,
  },
  {
    question: "Are you currently taking any medications for heart related issues?",
    questionID: 3,
    answer: false,
  },
];

const FamilyHistoryQuestions = [
  {
    question: "Do you have a family history of heart disease or any cardiovascular conditions?",
    questionID: 4,
    answer: false,
  },
  {
    question: "Has anyone in your family ever had a heart attack or stroke?",
    questionID: 5,
    answer: false,
  },
];

const LifestyleQuestions = [
  {
    question: "Do you smoke or use any tobacco products?",
    questionID: 6,
    answer: false,
  },
  {
    question: "On a scale of 1-5 (1 being completely inactive - 5 being athletic performance) how would you rate your current activity levels?",
    questionID: 7,
    answer: false,
    type: "range",
  },
  {
    question: "On a scale of 1-5 (1 being very unhealthy - 5 being very healthy) how would you rate your current diet?",
    questionID: 8,
    answer: false,
    type: "range",
  },
];

const StressQuestions = [
  {
    question: "Do you experience high levels of stress on a regular basis?",
    questionID: 9,
    answer: false,
  },
  {
    question: "Have you ever been diagnosed with anxiety or depression?",
    questionID: 10,
    answer: false,
  },
];

const generalHealthQuestions = [
  {
    question: "Are you currently experiencing any health issues or symptoms?",
    questionID: 11,
    answer: false,
  },
  {
    question: "Have you recently undergone any surgeries or medical procedures?",
    questionID: 12,
    answer: false,
  },
];

const allergiesAndMedicationsQuestions = [
  {
    question: "Do you have any known allergies, especially to medications or medical equipment?",
    questionID: 13,
    answer: true,
  },
  {
    question: "Are you currently taking any medications, supplements, or vitamins?",
    questionID: 14,
    answer: false,
  },
];
//#endregion

const PreCheckList = ({ userID, setUser }) => {
  const [userResults, setUserResults] = useState([]);
  const [loadedData, setLoadedData] = useState(false);
  const [selectedUserID, setSelectedUserID] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
        try {
            const response = await axios.get(`https://tillo.nvgtr.io/auxData/loadAllPreHealthChecks`);
            setLoadedData(response.data);
            console.log(response.data);
            setUserResults(getUserResults(response.data));

        } catch (error) {
            console.error(error);
        }
    }
    fetchUserData();

    },[]);

  const getUserResults = (data) => {
    const userResultsArray = [];

    data.forEach((user) => {
      const userResult = {};

      user.forEach((item) => {
        if (typeof item.questionID === "string") {
          userResult[item.questionID] = item.answer;
        }
      });

      userResultsArray.push(userResult);
    });

    return userResultsArray;
  };

  const updateUserID = (userID) => {
    setSelectedUserID(userID);
    const user = userResults.find((user) => user.userID === userID);
    const dummyUser = loadedData.find((user) => user.find((item) => item.questionID === "userID").answer === userID);
    setSelectedUser(dummyUser);
    setUser(dummyUser,userID);
  }

  return (
    <div>
      <h4>Pre-Check Search</h4>
      <SearchPreHealthCheckResults preHealthCheckResults={userResults} setSelectedUser={updateUserID} />
    </div>
  );
};

export default PreCheckList;

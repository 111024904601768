import { createHeroApiClient } from '../../hero-api-client';
import { createError } from './errors';
import NProgress from "nprogress";

export const REQUEST_CHALLENGES_UPCOMING = 'REQUEST_CHALLENGES_UPCOMING'
function requestChallengesUpcoming() {
    return {
        type: REQUEST_CHALLENGES_UPCOMING
    }
}

export const RECEIVE_CHALLENGES_UPCOMING = 'RECEIVE_CHALLENGES_UPCOMING'
function receiveChallengesUpcoming(challenges, userId) {
    return {
        type: RECEIVE_CHALLENGES_UPCOMING,
        payload: {
            challenges: challenges,
            receivedAt: Date.now(),
            userId: userId
        }
    }
}

function doFetchChallengesUpcoming(accessToken, userId) {
    return async dispatch => {
        NProgress.start();
        dispatch(requestChallengesUpcoming())
        createHeroApiClient(accessToken).getChallengesUpcoming()
            .then(challenges => dispatch(receiveChallengesUpcoming(challenges, userId)))
            .catch(error => dispatch(createError(error.message)))
            .finally(() => NProgress.done());
    }
}

function shouldFetchChallengesUpcoming(state) {
    const challenges = state.challengesUpcoming
    if (!challenges) {
        return true
    } else if (challenges.isFetching) {
        return false
    } else {
        return !challenges.isValid
    }
}

export function fetchChallengesUpcoming() {
    return (dispatch, getState) => {
        const state = getState()
        if (!shouldFetchChallengesUpcoming(state)) {
            return Promise.resolve()
        }
        
        const accessToken = state.user.session.accessToken
        const userId = state.user.profile.id
        return dispatch(doFetchChallengesUpcoming(accessToken, userId))
    }
}

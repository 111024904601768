import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { beginUserSession } from '../redux/actions/userSession';
import { fetchAuthenticatedUserProfile } from '../redux/actions/userProfile';
import { sendCommand } from "../redux/actions/commands";
import dataStores from "../redux/reducers/dataStores";
import { Container, Spinner } from 'react-bootstrap';



function Landing(props) {
  const companyKey = (window.location.host.split(".")[1] && window.location.host.split(".")[1] !== 'app')
    ? window.location.host.split(".")[0]
    : "app";
  if (props.appConfig.companyKey != companyKey) {
    props.dispatch(sendCommand(
      dataStores.APP_CONFIG,
      { actionType: "GET", companyKey: companyKey }
    ));
  }

  if (props.user.session.isAuthenticated && !props.user.profile.isValid) {
    props.dispatch(fetchAuthenticatedUserProfile());
    return <Container className="text-center">
      <Spinner className="m-5" animation="border" role="status" variant="primary">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </Container>
  }

  if (props.user.session.isAuthenticated && props.user.profile.isValid) {
    console.log('test logged in');
    const redirectPath = ['/', '/login', '/sign-up'].includes(props.location.pathname)
      ? '/dashboard'
      : props.location.pathname;
    return <Redirect to={redirectPath} />
  }

  const tokenResponse = JSON.parse(window.localStorage.getItem("_tokenResponse"));
  if (!tokenResponse) {
    let regex = /\/meetings\/(.+)/;
    if(props.location.pathname.match(regex)){
      return <Redirect to={props.location.pathname.replace('meetings','meet-g')} />
    }
    //don't redirect to login if on /meetwv
    regex = /\/meetwv\/(.+)/;
    if(props.location.pathname.match(regex)){
      //stay on meetwv
      return <></>
    }
    if(props.location.pathname.length){
      return <Redirect to={"/login?r=" + props.location.pathname} />
    }
    return <Redirect to="/login" />
  }
  const tokenResponseHasExpired = new Date(tokenResponse[".expires"]) <= new Date().getUTCDate();
  if (tokenResponseHasExpired) {
    return <Redirect to="/login" />
  }

  props.dispatch(beginUserSession(tokenResponse));
  return <Container className="text-center">
    <Spinner className="m-5" animation="border" role="status" variant="primary">
      <span className="sr-only">Loading...</span>
    </Spinner>
  </Container>;
}

function mapStateToProps(state) {
  return {
    appConfig: (state.appObject[dataStores.APP_CONFIG] || {}),
    user: state.user
  }
}

export default connect(mapStateToProps)(Landing);

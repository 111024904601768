import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import { Row, Col, Card, Button } from 'react-bootstrap';
import { selectHealthDataForUserOnDate } from '../../redux/reducers/healthData';
import { addHealthData } from '../../redux/actions/healthData';
import { toast } from 'react-toastify';

function MoodCard({ date, healthData, dispatch }) {
  const [mood, setMood] = useState(0);
  const moodTitles = ["","Sad","Unhappy","Ok","Happy","Very Happy"]

  useEffect(() => {
    const moodData = healthData.find(x => x.healthMetricType == "mood");
    setMood(moodData ? moodData.recordedValue : 0)
  }, [healthData])

  function updateMood(value) {
    const moodData = healthData.find(x => x.healthMetricType == "mood");
    toast.success(`Mood set as ${moodTitles[value]}`);
    if(!moodData) {
      return;
    }
    dispatch(addHealthData(moodData.healthMetricId, value, date));
  }

  return (
    <Card className="mood">
      <Card.Body>
        <Row>
          <Col>
            <Button className={`${mood === 1 ? "selected" : ""} btn--circle`} variant={"mood-1"} onClick={() => { updateMood(1) }}>
              <i className={`far fa-sad-tear`}></i>
            </Button>
          </Col>
          <Col>
            <Button className={`${mood === 2 ? "selected" : ""} btn--circle`} variant={"mood-2"} onClick={() => { updateMood(2) }}>
              <i className={`far fa-frown`}></i>
            </Button>
          </Col>
          <Col>
            <Button className={`${mood === 3 ? "selected" : ""} btn--circle`} variant={"mood-3"} onClick={() => { updateMood(3) }}>
              <i className={`far fa-meh`}></i>
            </Button>
          </Col>
          <Col>
            <Button className={`${mood === 4 ? "selected" : ""} btn--circle`} variant={"mood-4"} onClick={() => { updateMood(4) }}>
              <i className={`far fa-smile`}></i>
            </Button>
          </Col>
          <Col>
            <Button className={`${mood === 5 ? "selected" : ""} btn--circle`} variant={"mood-5"} onClick={() => { updateMood(5) }}>
              <i className={`far fa-grin-squint`}></i>
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    healthData: selectHealthDataForUserOnDate(state.healthData, ownProps.userId, ownProps.date)
  }
}

export default connect(mapStateToProps)(MoodCard);

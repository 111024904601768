import { createHeroApiClient } from '../../hero-api-client';
import { createError } from './errors'
import { invalidateAuthenticatedUserProfile } from './userProfile'

export const REQUEST_USERS = 'REQUEST_USERS'
function requestUsers() {
    return {
        type: REQUEST_USERS
    }
}

export const RECEIVE_USERS = 'RECEIVE_USERS'
function receiveUsers(users) {
    return {
        type: RECEIVE_USERS,
        payload: {
            users: users,
            receivedAt: Date.now()
        }
    }
}

export const INVALIDATE_USERS = 'INVALIDATE_USERS'
function invalidateUsers() {
    return {
        type: INVALIDATE_USERS
    }
}

function doFetchUsers(accessToken) {
    return async (dispatch, getState) => {
        dispatch(requestUsers())
        try {
            const state = getState()
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/companies/${state.user.profile.companyId}/users`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'hero-token': accessToken
                }
            })
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const users = await response.json()
            return dispatch(receiveUsers(users))
        }
        catch (error) {
            return dispatch(createError(error.message))
        }
    }
}

function shouldFetchUsers(state) {
    const users = state.users
    if (!users) {
        return true
    } else if (users.isFetching) {
        return false
    } else {
        return !users.isValid
    }
}

export function fetchUsers() {
    return (dispatch, getState) => {
        const state = getState()
        if (shouldFetchUsers(state)) {
            const accessToken = state.user.session.accessToken
            return dispatch(doFetchUsers(accessToken))
        } else {
            return Promise.resolve()
        }
    }
}

export function updateUser(user) {
    return async (dispatch, getState) => {
        const accessToken = getState().user.session.accessToken;
        try {
            await createHeroApiClient(accessToken).updateUser(user);
            dispatch(invalidateUsers());
            dispatch(invalidateAuthenticatedUserProfile());
        }
        catch (error) {
            dispatch(createError(error.message));
        }
    }
}
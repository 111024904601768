import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './LinkBox.scss';

function LinkBox(props) {
  const [linkCopied, setLinkCopied] = useState(false);

  return (
      <div className='link-box'>
       <p className='link-box__link'>
          {props.link}
        </p>
        <CopyToClipboard text={props.link} onCopy={() => setLinkCopied(true)}>
          <button className="link-box__button">{linkCopied ? 'Copied!' : 'Copy this link'}</button>
        </CopyToClipboard>
      </div>
  );
}

export default LinkBox;
import React, {Component} from 'react';
import propTypes from 'prop-types';
import cn from 'classnames';
import profilePlaceholder from "../../assets/icons/profile-placeholder.png";
import {Row, Col} from "react-bootstrap";
import moment from "moment";
const NewsFeedListItem = class extends Component {
    static displayName = 'NewsFeedListItem';

    static propTypes = {
        label: propTypes.string.isRequired,
        icon: propTypes.string.isRequired,
        children: propTypes.node.isRequired,
        notification: propTypes.object.isRequired,
        activity: propTypes.object.isRequired,
    }

    render() {
        return (
            <>
                <Row className={cn(this.props.className, 'newsfeed-list mt-5 d-flex justify-content-center align-items-center')} >
                    {/*<Col md={2}>*/}
                    {/*    {this.props.notification ? (*/}
                    {/*        <span className="newsfeed-list__notification text-center"/>*/}
                    {/*    ) : (*/}
                    {/*        null*/}
                    {/*    )}*/}
                    {/**/}
                    {/*</Col>*/}
                    {/*<Col md={2}>*/}
                    {/*    <img src={profilePlaceholder}/>*/}
                    {/*</Col>*/}
                    <Col>
                        <p>{this.props.activity.userName} completed the <span
                            className="newsfeed-list__challenge-text">{this.props.activity.title} Challenge</span></p>
                        <p className="newsfeed-list__date-text">{moment(this.props.activity.timestamp).format("dddd Do MMMM H:mma")}</p>
                    </Col>
                </Row>
            </>
        );
    }
};

export default NewsFeedListItem;

const createChallengeClient = (sendRequest) => (challengeId) => {
    const addMember = async (memberId) => {
        const path = `/challenges/${challengeId}/members`;
        const method = 'POST';
        const body = { memberId };
        return await sendRequest(path, method, body);
    };

    const removeMember = async (memberId) => {
        const path = `/challenges/${challengeId}/members/${memberId}`;
        const method = 'DELETE';
        return await sendRequest(path, method);
    };

    return {
        addMember,
        removeMember
    };
};

export const createChallengesClient = (sendRequest) => {
    const create = async (challenge) => {
        const path = `/challenges`;
        const method = 'POST';
        const body = challenge;
        return await sendRequest(path, method, body);
    };

    const list = async () => {
        const path = `/challenges`;
        return await sendRequest(path);
    };

    const get = async (id) => {
        const path = `/challenges/${id}`;
        return await sendRequest(path);
    };

    const remove = async (id) => {
        const path = `/challenges/${id}`;
        const method = 'DELETE';
        return await sendRequest(path, method);
    };

    return {
        create,
        list,
        get,
        remove,
        createChallengeClient: createChallengeClient(sendRequest)
    };
};
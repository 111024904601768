// import React, { useState, useEffect } from 'react'
// import { connect } from "react-redux";
//
// function Select({}) {
//   return (
//     <>
//       <label>{}Metric</label>
//       <div className="select__wrapper">
//         <i className="select__icon fal fa-calendar-alt"/>
//         <select
//             className="select"
//             defaultValue={'hello'}
//             name="healthMetricId">
//           <option key="non" value="" maxvalue={0}>
//             Please Select...
//           </option>
//         </select>
//       </div>
//     </>
//   );
// }
//
// function mapStateToProps(state, ownProps) {
//   return {
//
//   }
// }
//
// export default connect(mapStateToProps)(Select);


import React, { Component } from 'react';
import { connect } from "react-redux";
import propTypes from 'prop-types';
import cn from 'classnames';

// Automatically sets relevant head tags for seo, see _app for the remainder tags
const Select = class extends Component {
  static displayName= 'Select';

  static propTypes = {
      label: propTypes.string.isRequired,
    children: propTypes.node.isRequired,
  }

  render() {
    return (
        <>
          <label className="select__label">{this.props.label}</label>
          <div className="select__wrapper">


              {this.props.icon ? (
                  <i className={cn(this.props.iconclassName, 'select__icon fal fa-calendar-alt', this.props.icon)} />
              ) : (
                  null
              )}

            <select
                className={cn(this.props.className, 'select')}
                style={{paddingLeft: this.props.icon? 38: 5}}
                onChange={this.props.onChange}
                defaultValue={this.props.defaultValue}
                name="healthMetricId">
                {this.props.children}
            </select>
          </div>
        </>
    );
  }
};

export default Select;

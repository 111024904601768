import { createHeroApiClient } from '../../hero-api-client';
import { createError } from './errors'
import moment from 'moment';

export const REQUEST_HEALTH_DATA = 'REQUEST_HEALTH_DATA'
function requestHealthData(userId, normalDate, utcoffset) {
    return {
        type: REQUEST_HEALTH_DATA,
        payload: { userId, date: normalDate, timeZone: utcoffset }
    }
}

export const REQUEST_HEALTH_DATA_RANGE = 'REQUEST_HEALTH_DATA_RANGE'
function requestHealthDataRange(userId, fromDate, toDate) {
    return {
        type: REQUEST_HEALTH_DATA_RANGE,
        payload: { userId, fromDate, toDate }
    }
}

export const REQUEST_HEALTH_DATA_MOOD = 'REQUEST_HEALTH_DATA_MOOD'
function requestHealthDataMood(userId, fromDate, toDate) {
    return {
        type: REQUEST_HEALTH_DATA_MOOD,
        payload: { userId, fromDate, toDate }
    }
}

export const RECEIVE_HEALTH_DATA = 'RECEIVE_HEALTH_DATA'
function receiveHealthData(userId, normalDate, healthData) {
    return {
        type: RECEIVE_HEALTH_DATA,
        payload: { userId, date: normalDate, healthData }
    }
}

export const RECEIVE_HEALTH_DATA_RANGE = 'RECEIVE_HEALTH_DATA_RANGE'
function receiveHealthDataRange(userId, fromDate, toDate, healthData) {
    return {
        type: RECEIVE_HEALTH_DATA_RANGE,
        payload: { userId, fromDate, toDate, healthData }
    }
}

export const RECEIVE_HEALTH_DATA_MOOD = 'RECEIVE_HEALTH_DATA_MOOD'
function receiveHealthDataMood(userId, fromDate, toDate, healthDataMood) {
    return {
        type: RECEIVE_HEALTH_DATA_MOOD,
        payload: { userId, fromDate, toDate, healthDataMood }
    }
}

export const INVALIDATE_HEALTH_DATA = 'INVALIDATE_HEALTH_DATA'
export function invalidateHealthData(userId, normalDate) {
    return {
        type: INVALIDATE_HEALTH_DATA,
        payload: { userId, date: normalDate }
    }
}

function doFetchHealthData(userId, normalDate, utcoffset, abortSignal) {
    return (dispatch, getState) => {
        dispatch(requestHealthData(userId, normalDate, utcoffset));
        const accessToken = getState().user.session.accessToken;
        return createHeroApiClient(accessToken, abortSignal).getHealthData(userId, normalDate, utcoffset)
            .then(healthData => dispatch(receiveHealthData(userId, normalDate, healthData, utcoffset)))
            .catch(error => {
                if(abortSignal && abortSignal.aborted) {
                    dispatch(invalidateHealthData(userId, normalDate, utcoffset));
                } else {
                    dispatch(createError(error.message));
                }
            })
    }
}

function shouldFetchHealthData(state, userId, date) {
    const healthData = state.healthData
    if (!healthData) {
        return true
    } else if (!healthData[userId]) {
        return true
    } else if (!healthData[userId][date]) {
        return true
    } else {
        return !healthData[userId][date].isFetching && !healthData[userId][date].isValid;
    }
}

function shouldFetchHealthDataRange(state, userId, fromDate, toDate) {
    const healthData = state.healthData
    if (!healthData) {
        return true
    } else if (!healthData[userId]) {
        return true
    } else {
        for (let date = moment(fromDate, 'YYYY-MM-DD'); date.valueOf() <= moment(toDate, 'YYYY-MM-DD').valueOf(); date = date.clone().add(1, 'day')) {
            if (!healthData[userId][date]) {
                return true;
            }
            return !healthData[userId][date].isFetching && !healthData[userId][date].isValid;
        }
    }
}

export function fetchHealthData(userId, date, abortSignal, utcoffset) {
    return (dispatch, getState) => {
        const state = getState();
        const normalDate = moment.utc(date).format('YYYY-MM-DD');
        const newDate = new Date();
        const utcoffsetNew = newDate.getTimezoneOffset() * 60 * -1;
        if (shouldFetchHealthData(state, userId, normalDate, utcoffsetNew)) {
            return dispatch(doFetchHealthData(userId, normalDate, utcoffsetNew, abortSignal));
        } else {
            return Promise.resolve();
        }
    }
}

function doFetchHealthDataRange(userId, fromDate, toDate) {
    return (dispatch, getState) => {
        dispatch(requestHealthDataRange(userId, fromDate, toDate));
        const accessToken = getState().user.session.accessToken;
        return createHeroApiClient(accessToken).getHealthDataRange(userId, fromDate, toDate)
            .then(healthData => dispatch(receiveHealthDataRange(userId, fromDate, toDate, healthData)))
            .catch(error => {
                dispatch(createError(error.message));
            })
    }
}

export function fetchHealthDataRange(userId, fromDate, toDate) {
    return (dispatch, getState) => {
        const state = getState();
        const fromDateUtc = moment.utc(fromDate).format('YYYY-MM-DD');
        const toDateUtc = moment.utc(toDate).format('YYYY-MM-DD');
        if (shouldFetchHealthDataRange(state, userId, fromDateUtc, toDateUtc)) {
            return dispatch(doFetchHealthDataRange(userId, fromDateUtc, toDateUtc));
        } else {
            return Promise.resolve();
        }
    }
}

function shouldFetchHealthDataMood(state, userId, fromDate, toDate) {
    const healthDataMood = state.healthDataMood
    if (!healthDataMood) {
        return true
    } else if (!healthDataMood[userId]) {
        return true
    } else {
        for (let date = moment(fromDate, 'YYYY-MM-DD'); date.valueOf() <= moment(toDate, 'YYYY-MM-DD').valueOf(); date = date.clone().add(1, 'day')) {
            if (!healthDataMood[userId][date]) {
                return true;
            }
            return !healthDataMood[userId][date].isFetching && !healthDataMood[userId][date].isValid;
        }
    }
}

function doFetchHealthDataMood(userId, fromDate, toDate) {
    return (dispatch, getState) => {
        dispatch(requestHealthDataMood(userId, fromDate, toDate));
        const accessToken = getState().user.session.accessToken;
        return createHeroApiClient(accessToken).getHealthDataMood(userId, fromDate, toDate)
            .then(healthDataMood => dispatch(receiveHealthDataMood(userId, fromDate, toDate, healthDataMood)))
            .catch(error => {
                dispatch(createError(error.message));
            })
    }
}

export function fetchHealthDataMood(userId, fromDate, toDate) {
    return (dispatch, getState) => {
        const state = getState();
        const fromDateUtc = moment.utc(fromDate).format('YYYY-MM-DD');
        const toDateUtc = moment.utc(toDate).format('YYYY-MM-DD');
        if (shouldFetchHealthDataMood(state, userId, fromDateUtc, toDateUtc)) {
            return dispatch(doFetchHealthDataMood(userId, fromDateUtc, toDateUtc));
        } else {
            return Promise.resolve();
        }
    }
}

export function setEnergyConsumedIsCompleted(date, value) {
    return async (dispatch, getState) => {
        const state = getState();
        const normalDate = moment.utc(date).format('YYYY-MM-DD');
        createHeroApiClient(state.user.session.accessToken)
            .setEnergyConsumedIsCompleted(normalDate, value)
            .then(response => dispatch(invalidateHealthData(state.user.profile.id, normalDate)))
            .catch(error => dispatch(createError(error.message)));
    }
}

export function addHealthData(healthMetricId, value, date) {
    return (dispatch, getState) => {
        const state = getState();
        const normalDate = moment.utc(date).format('YYYY-MM-DD');
        createHeroApiClient(state.user.session.accessToken)
            .addHealthData(healthMetricId, value, normalDate)
            .then(response => dispatch(invalidateHealthData(state.user.profile.id, normalDate)))
            .catch(error => dispatch(createError(error.message)));
    }
}
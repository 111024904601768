import React, { useState, useEffect } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import MortgageCalculatorBarChart from './MortgateBarChart';

const MortgageResults = ({ monthlyPayment, totalInterest, totalRepayment, totalBorrowed, mortgageDetails }) => {
  const round = (value, decimals = 2) => {
    const factor = 10 ** decimals;
    return Math.round(value * factor) / factor;
  };

  const [output, setOutput] = useState({
    monthlyPayment: 0,
    totalInterest: 0,
    totalRepayment: 0,
    totalBorrowed: 0,
  });

  useEffect(() => {
    setOutput({
      monthlyPayment: round(monthlyPayment),
      totalInterest: round(totalInterest),
      totalRepayment: round(totalRepayment),
      totalBorrowed: round(totalBorrowed),
    });
  }, [monthlyPayment, totalInterest, totalRepayment, totalBorrowed]);

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col>
            <h2>
              <strong>Monthly Payment:</strong> £{output.monthlyPayment.toLocaleString(undefined, { minimumFractionDigits: 2 })}
            </h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              If you borrow £<strong>{output.totalBorrowed.toLocaleString()}</strong>, you will pay a total of £<strong>{output.totalRepayment.toLocaleString()}</strong>,
              with monthly payments of £<strong>{output.monthlyPayment.toLocaleString()}</strong>. Your total interest costs will be £<strong>{output.totalInterest.toLocaleString()}</strong>.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              <strong>Total Borrowed:</strong> £{output.totalBorrowed.toLocaleString(undefined, { minimumFractionDigits: 2 })}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              <strong>Total Interest Paid:</strong> £{output.totalInterest.toLocaleString(undefined, { minimumFractionDigits: 2 })}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              <strong>Total Repayment:</strong> £{output.totalRepayment.toLocaleString(undefined, { minimumFractionDigits: 2 })}
            </p>
          </Col>
        </Row>
        <Card>
          <Card.Body>
            {/* Assuming you have a component for the mortgage chart, replace MortgageCalculatorBarChart with your actual component */}
            <MortgageCalculatorBarChart output={output} />
          </Card.Body>
        </Card>
      </Card.Body>
    </Card>
  );
};

export default MortgageResults;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// BOOTSTRAP STUFF
import Container from "react-bootstrap/Container";

// Redux Connections
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//Redux Actions

import * as commandActions from "../redux/actions/commands";
import dataStores from "../redux/reducers/dataStores";

// Bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import GrantedPermissions from "../components/profile/GrantedPermissions";
import DelegatedPermissions from "../components/profile/DelegatedPermissions";
import HeadCoachPermission from "../components/profile/HeadCoachPermission";

function Coach(props) {
  return (
    <Container>
      <Row className="title-container">
        <Col>
          <h1 className="page__title">Coach</h1>
        </Col>
        <Col>
          <div className="btn--actions">
            {props.coachToken ?
              <Button
                variant="primary"
                className="btn--circle"
                onClick={() =>
                  props.commandActions.sendCommand(
                    dataStores.DELEGATED_PERMISSIONS,
                    { actionType: "GET" }
                  )}
              >
                <i className="fal fa-user-unlock"></i>
              </Button>
              : undefined
            }
          </div>
        </Col>
      </Row>

      <Tabs fill defaultActiveKey="members" id="uncontrolled-tab-example">
        <Tab eventKey="members" title="Members">
          <DelegatedPermissions />
          <HeadCoachPermission />
        </Tab>
        <Tab eventKey="granted" title="Granted">
          <GrantedPermissions />
        </Tab>
      </Tabs>
    </Container>
  );
}

function mapStateToProps(state) {
  return {
    appConfig: state.appConfig,
    feed: state.feed,
    dataStores: state.appObject,
    styles: state.appObject[dataStores.APP_CONFIG].styles,
    coachToken: state.appObject[dataStores.DELEGATED_PERMISSIONS].coachToken,
  };
}

function mapDispatchToProps(dispatch) {
  return {
        commandActions: bindActionCreators(commandActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Coach);

import React, { useState } from 'react'
import { connect } from "react-redux";
import Modal from 'react-bootstrap/Modal'
import { createGroup } from '../../redux/actions/groups';
import dataStores from "../../redux/reducers/dataStores";

function CreateGroupModal(props) {
  const { regions, departments } = props;
  const [model, setModel] = useState({
    region: props.userProfile?.region,
    department: props.userProfile?.department,
  });

  const companyKey = (window.location.host.split(".")[1] && window.location.host.split(".")[1] !== 'app')
    ? window.location.host.split(".")[0]
    : "app";

  const showVodafoneRegionDepartmentFields = companyKey === 'vodafoneglobalchallenge';

  const handleInputChange = event => {
    setModel({
      ...model,
      [event.target.name]: event.target.value
    });
  }

  const isValid = () => {
    if (showVodafoneRegionDepartmentFields && (!model.region || !model.department)) {
      return false;
    }
    return !!model.name;
  }

  const handleFormSubmit = event => {
    event.preventDefault()

    if (!isValid()) {
      return
    }

    props.dispatch(createGroup(model))

    props.setShowCreateGroupModal(false)
    setModel({})
  }

  return (
    <Modal centered show={props.showCreateGroupModal} onHide={() => props.setShowCreateGroupModal(false)}>
      <Modal.Body>
        <form onSubmit={handleFormSubmit}>
          <h3>Create Group</h3>
          <p>Enter the details below to create your new group</p>

          <div className="c-field mt-2">
            <label className="c-field__label">Group Name</label>
            <input className="c-input" name="name" type="text" value={model.name} onChange={handleInputChange} />
          </div>
          {showVodafoneRegionDepartmentFields && (
            <>
              <div className="c-field mt-2">
                <label className="c-field__label">Region</label>
                <select className="select" name="region" value={model.region} onChange={handleInputChange} required style={{fontSize: "0.95rem"}}>
                      <option>Select a region</option>
                      {regions.map(reg => <option value={reg}>{reg}</option>)}
                    </select>
              </div>
              <div className="c-field mt-2">
                <label className="c-field__label">Department</label>
                <select className="select" name="department" value={model.department} onChange={handleInputChange} required style={{fontSize: "0.95rem"}}>
                  <option>Select a department</option>
                  {departments.map(dep => <option value={dep}>{dep}</option>)}
                </select>
              </div>
            </>
          )}
          <input className="btn btn-primary mt-3" type="submit" value="Submit" disabled={!isValid()} />
        </form>
      </Modal.Body>
    </Modal>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    userProfile: state.user.profile,
    regions: state.appObject[dataStores.APP_CONFIG].regions || [],
    departments: state.appObject[dataStores.APP_CONFIG].departments || [],
  };
}

export default connect(mapStateToProps)(CreateGroupModal);

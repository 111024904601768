import React from 'react';
import { Card } from 'react-bootstrap';

const BreathingSteps = ({ steps }) => {
  return (
    <div>
      <h2>Breathing Steps</h2>
      {steps.map((step, index) => (
        <Card key={index} className="my-2 p-2">
          <Card.Body>
            <strong>Step {step.step}:</strong> {step.description}
          </Card.Body>
        </Card>
      ))}
    </div>
  );
};

export default BreathingSteps;

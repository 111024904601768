import dataStores from '../reducers/dataStores'
import { invalidateGroups } from './groups'
import { createError } from './errors'

export const REQUEST_GROUP_INVITATIONS = 'REQUEST_GROUP_INVITATIONS'
function requestGroupInvitations() {
    return {
        type: REQUEST_GROUP_INVITATIONS
    }
}

export const RECEIVE_GROUP_INVITATIONS = 'RECEIVE_GROUP_INVITATIONS'
function receiveGroupInvitations(groupInvitations) {
    return {
        type: RECEIVE_GROUP_INVITATIONS,
        payload: {
            groupInvitations: groupInvitations,
            receivedAt: Date.now()
        }
    }
}

export const INVALIDATE_GROUP_INVITATIONS = 'INVALIDATE_GROUP_INVITATIONS'
function invalidateGroupInvitations() {
    return {
        type: INVALIDATE_GROUP_INVITATIONS
    }
}

function invalidateGroupInvitationsAfterFiveMinutes(dispatch) {
    setTimeout(() => dispatch(invalidateGroupInvitations()), 300000)
}

function doFetchGroupInvitations(accessToken) {
    return async dispatch => {
        dispatch(requestGroupInvitations())
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user/group-invitations`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'hero-token': accessToken
                }
            })
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const groupInvitations = await response.json()
            dispatch(receiveGroupInvitations(groupInvitations))
            invalidateGroupInvitationsAfterFiveMinutes(dispatch)
        }
        catch (error) {
            return dispatch(createError(error.message))
        }
    }
}

function shouldFetchGroupInvitations(state) {
    const groupInvitations = state.groupInvitations
    if (!groupInvitations) {
        return true
    } else if (groupInvitations.isFetching) {
        return false
    } else {
        return !groupInvitations.isValid
    }
}

export function fetchGroupInvitations() {
    return (dispatch, getState) => {
        const state = getState()
        if (shouldFetchGroupInvitations(state)) {
            const accessToken = state.user.session.accessToken
            return dispatch(doFetchGroupInvitations(accessToken))
        } else {
            return Promise.resolve()
        }
    }
}

function updateGroupInvitation(groupId, hasAccepted) {
    return async (dispatch, getState) => {
        const accessToken = getState().user.session.accessToken
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/groups/${groupId}/invitations`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'hero-token': accessToken
                },
                body: JSON.stringify({ hasAccepted })
            })
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            dispatch(invalidateGroupInvitations())
            dispatch(invalidateGroups())
        }
        catch (error) {
            return dispatch(createError(error.message))
        }
    }
}

export function acceptGroupInvitation(groupId) {
    return updateGroupInvitation(groupId, true)
}

export function rejectGroupInvitation(groupId) {
    return updateGroupInvitation(groupId, false)
}
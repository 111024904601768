import React from 'react';
import { connect } from 'react-redux';
import UserReport from '../components/reports/UserReport';

function Reports(props) {
  return <UserReport userId={props.userId} />
}

function mapStateToProps(state) {
  return {
    userId: state.user.profile.id
  }
}

export default connect(mapStateToProps)(Reports);
import React, { useState, useRef, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import {fetchArticle, fetchArticles} from '../../hero-api-client/ArticleContent';
import { withRouter, Link } from 'react-router-dom';
import StoryblokClient from 'storyblok-js-client';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { createError } from '../../redux/actions/errors';
import { useLocation } from 'react-router-dom';
import './styles.css';



function StoryblokContentRoot({articles, articleType, ...props}) {
  const location = useLocation();
  //get search params
  const searchParams = new URLSearchParams(location.search);
  const direction = searchParams.get('direction');

  const dispatch = useDispatch();
  const { pageHeader, location: { pathname: pathname } } = props;
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [allArticles, setAllArticles] = useState([articles]);
  const [stories, setStories] = useState([]);
  const [total, setTotal] = useState(20);
  const [showFilters, setShowFilters] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  useEffect(() => {
    if(direction){
      switch(direction){
        case 'yoga':
          setSelectedTags(['yoga']);
          break;
        case 'mental-health-library':
          setSelectedTags(['mental health']);
          break;
        case 'financial-health-library':
          setSelectedTags(['financial wellbeing']);
          break;
        case 'physical-health-library':
          setSelectedTags(['activity', 'movement']);
          break;
        case 'social-health-library':
          setSelectedTags(['social wellbeing']);
          break;
    }
    }
  }, [direction]);
  useEffect(() => {
    if(articles){
      const newArticles = articles.filter(article => article.type === articleType);
      setAllArticles(newArticles);
      setTotal(20);
    }
  }, [articles, articleType]);

  useEffect(() => {
    if(!allArticles || allArticles.length < 10) return;
    const _tags = allArticles.map(article => article.tags).flat();
    const tagCounts = _tags.reduce((acc, tag) => {
      if(!acc[tag]){
        acc[tag] = 1;
      }
      else{
        acc[tag] = acc[tag] + 1;
      }
      return acc;
    }, {});
    setTags(Object.keys(tagCounts).map(key => {
      return{
        name: key,
        taggings_count: tagCounts[key]
      }
    }));
  }, [allArticles]);

  useEffect(() => {
    if(!articles || articles.length === 0) dispatch(fetchArticles(props.user.profile.companyId));
  }, []);

  useEffect(() => {
    var results = allArticles;
  
    if(selectedTags.length > 0){
      results = results.filter((article) => article.tags.some(tag => selectedTags.includes(tag)));
    }
    if(searchTerm){
      console.log(searchTerm);

      results = results.filter((article) =>
    {
      return (article.title.toLowerCase().includes(searchTerm.toLowerCase()) || article.segments?.some((segment) => typeof segment?.content === 'string'? segment.content.toLowerCase().includes(searchTerm.toLowerCase()): false))
    }
    );
    }
    
    setStories(results.slice(0, total));
  }, [searchTerm, selectedTags, total, allArticles]);

  window.onscroll = function () {
    if (window.scrollY == 0) {
      setTotal(20);
      return;
    }

    const height = document.documentElement.scrollHeight - window.innerHeight - 100;
    if (Math.round(window.scrollY) < height) {
      return;
    }

    if (total >= allArticles.length) {
      return;
    }

    setTotal(total + 10);
  };
  const toggleTag = (tag) => {
    if(selectedTags.includes(tag.name)){
      setSelectedTags(selectedTags.filter(x => x !== tag.name));
    }
    else{
      setSelectedTags([...selectedTags, tag.name]);
    }
  }

  return (
    <Container>
      <Row className="title-container">
        <Col className="displayPosts__col--start">
          <h1 className="page__title">{pageHeader}</h1>
        </Col>

        <Col className="displayPosts__col--end">
          <div className="btn--actions">
            <button
              title="Filters"
              className='thin light noborder'
             onClick={() =>
                setShowFilters(!showFilters)}>
              <i className="far fa-filter"></i>
            </button>
            {/* <Button
              className="btn--transparent btn--circle"
              onClick={handleToggleRegionFilter}>
              <i className={`${filters.assignedToRegionOnly ? "fas" : "far"} fa-building`}></i>
            </Button> */}
          </div>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col md={12}>
          <input
            classes="w-100 p-2 input"
            size="medium"
            type="text"
            placeholder="Enter a search term&hellip;"
            value={searchTerm}
            onChange={(event) => setSearchTerm(event.target.value)}
          />
        </Col>
      </Row>
      {
        showFilters ?
          <Row>
            <Col className="p-4">
              {categories.length > 0 && (
                <>
                  <div>Categories</div>
                  <div className="hero-flex-wrap displayPosts__categoryContainer js--page__categoryContainer">
                    {categories.map(category => (
                      <button
                        key={`category_${category.name}`}
                        className={`displayPosts__categoryButton ${selectedTags.includes(category.name) ? '' : 'displayPosts__categoryButton--disabled'}`}
                        variant="info"
                        value={category.name}
                        name="category"
                        onClick={()=>toggleTag(category)}>
                        {category.name} ({category.taggings_count})
                      </button>
                    ))}
                  </div>
                </>
              )}

              {tags.length > 0 && (
                <>
                  <div>Tags</div>
                  <div className="hero-flex-wrap displayPosts__container js--page__tagContainer">
                    {tags.map(tag => (
                      <button
                        key={`tag_${tag.name}`}
                        className={`displayPosts__tagButton ${selectedTags.includes(tag.name) ? '' : 'displayPosts__tagButton--disabled'}`}
                        value={tag.name}
                        name="tag"
                        onClick={()=>toggleTag(tag)}>
                        {tag.name} ({tag.taggings_count})
                      </button>
                    ))}
                  </div>
                </>
              )}
            </Col>
          </Row> : undefined
      }

      <Row>
        {stories.map(story => (
          <Col lg={6} key={`story_${story.id}`}>
            <Link to={`/article/${story.id}`}>
              <div className="card card-image hero-card-parent mb-30">
                <div
                  className="hero-card-child"
                  style={{ backgroundImage: `url(${story.headImage})`, zIndex: "1" }}>
                </div>
                <div className="card-title"><p>{story.title}</p></div>
              </div>
            </Link>
          </Col>
        ))}
        {
          stories.length === 0 ?
          <Col>
            <p>No items available</p>
          </Col> : undefined
        }
      </Row>
    </Container>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    articles: state.articleContent.articles
  }
}

export default withRouter(connect(mapStateToProps)(StoryblokContentRoot));

import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";

const FinanceSliderInput = ({
  labelBefore = "",
  labelAfter = "",
  minValue,
  maxValue,
  step,
  onValueChange,
  toFixed = 0,
  defaultValue = 0,
}) => {
  const [sliderValue, setSliderValue] = useState(defaultValue);
  const [customValue, setCustomValue] = useState(defaultValue.toString());
  const [minSliderValue, setMinSliderValue] = useState(minValue);
  const [maxSliderValue, setMaxSliderValue] = useState(maxValue);
  const [showCustomInput, setShowCustomInput] = useState(false);

  useEffect(() => {
    setSliderValue(defaultValue);
  }, [defaultValue]);

  const handleSliderChange = (e) => {
    const roundedValue = parseFloat(e.target.value);
    setSliderValue(roundedValue);
    onValueChange(roundedValue);
  };

  const handleCustomInputChange = (text) => {
    if (!isNaN(text)) {
      setCustomValue(text);
    }
  };

  const handleCustomValueSubmit = () => {
    const numericValue = parseFloat(customValue);
    if (!isNaN(numericValue)) {
      setSliderValue(numericValue);
      onValueChange(numericValue);
      if (numericValue > maxSliderValue) setMaxSliderValue(numericValue);
      if (numericValue < minSliderValue) setMinSliderValue(numericValue);
      setShowCustomInput(false);
    }
  };

  return (
    <Card className="p-3">
      <Card.Title>
        {labelBefore}
        <span style={{ color: '#3498db' }}>{sliderValue}</span>
        {labelAfter}
      </Card.Title>
      <Card.Body>
      <input
        type="range"
        min={minValue}
        max={maxValue}
        step={step}
        value={sliderValue}
        onChange={handleSliderChange}
      />
      
      {showCustomInput && (
        <div>
          <input
  type="range"
  min={minValue}
  max={maxValue}
  step={step}
  value={sliderValue}
  onChange={handleSliderChange}
  style={{ background: '#3498db', width: '100%', height: '30px', borderRadius: '15px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}
/>


          <button onClick={handleCustomValueSubmit}>Submit</button>
        </div>
      )}
      </Card.Body>
    </Card>
  );
};

export default FinanceSliderInput;


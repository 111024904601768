import { createHeroApiClient } from '../../hero-api-client';
import { createError } from './errors';

export const REQUEST_CHATS = 'REQUEST_CHATS'
function requestChats() {
    return {
        type: REQUEST_CHATS
    }
}

export const RECEIVE_CHATS = 'RECEIVE_CHATS'
function receiveChats(chats) {
    return {
        type: RECEIVE_CHATS,
        payload: {
            chats: chats,
            receivedAt: Date.now()
        }
    }
}

export const INVALIDATE_CHATS = 'INVALIDATE_CHATS'
export function invalidateChats() {
    return {
        type: INVALIDATE_CHATS
    }
}

export const REQUEST_CHAT_USER = 'REQUEST_CHAT_USER'
function requestChatUser() {
    return {
        type: REQUEST_CHAT_USER
    }
}

export const RECEIVE_CHAT_USER = 'RECEIVE_CHAT_USER'
function receiveChatUser(chatUser) {
    return {
        type: RECEIVE_CHAT_USER,
        payload: {
            chatUser,
        }
    }
}

export const INVALIDATE_CHAT_USER = 'INVALIDATE_CHAT_USER'
export function invalidateChatUser() {
    return {
        type: INVALIDATE_CHAT_USER
    }
}

function doFetchChats(accessToken) {
    return async dispatch => {
        dispatch(requestChats());
        createHeroApiClient(accessToken).chats.list()
            .then((chats) => dispatch(receiveChats(chats)))
            .catch(error => dispatch(createError(error.message)));
    }
}

function shouldFetchChats(state) {
    const chats = state.chats
    if (!chats) {
        return true
    } else if (chats.isFetching) {
        return false
    } else {
        return !chats.isValid
    }
}

export function fetchChats() {
    return async (dispatch, getState) => {
        const state = getState()
        const accessToken = state.user.session.accessToken
        return dispatch(doFetchChats(accessToken))
    }
}

export function createChat(details, userId = null, dmChat = null) {
    return async (dispatch, getState) => {
        const accessToken = getState().user.session.accessToken;
        createHeroApiClient(accessToken).chats.create({...details, userId, dmChat})
            .then(() => dispatch(invalidateChats()))
            .catch(error => dispatch(createError(error.message)));
    }
}

export function inviteUserToChat(chatId, userId, cb) {
    return async (dispatch, getState) => {
        const accessToken = getState().user.session.accessToken;
        const chatClient = createHeroApiClient(accessToken).chats
            .createChatClient(chatId);
        chatClient.invitations.create([ userId ])
            .then(()=>{
                if(cb) {
                    cb();
                }
            })
            .catch(error => dispatch(createError(error.message)));
    }
}

export function leaveChat(chatId, userId, cb) {
    return async (dispatch, getState) => {
        const accessToken = getState().user.session.accessToken;
        const chatClient = createHeroApiClient(accessToken).chats
            .createChatClient(chatId);
        chatClient.leave(userId)
            .then(() => {
                dispatch(invalidateChats());
                if (cb) cb();
            })
            .catch(error => dispatch(createError(error.message)));
    }
}

export function getChatUser(chatId) {
    return async (dispatch, getState) => {
        dispatch(requestChatUser());
        const accessToken = getState().user.session.accessToken;
        const chatClient = createHeroApiClient(accessToken).chats
            .createChatClient(chatId);
        chatClient.user.get()
            .then(chatUser => {
                dispatch(receiveChatUser(chatUser));
            })
            .catch(error => dispatch(createError(error.message)));
    }
}

export function updateChatUser(chatId, chatUser) {
    return async (dispatch, getState) => {
        const accessToken = getState().user.session.accessToken;
        const chatClient = createHeroApiClient(accessToken).chats
            .createChatClient(chatId);
        chatClient.user.update(chatUser)
            .then(chatUser => {
                dispatch(receiveChatUser(chatUser));
            })
            .catch(error => dispatch(createError(error.message)));
    }
}

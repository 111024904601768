export const createAuthenticatedUserClient = (sendRequest) => {
    // const joinChallenge = async (challengeId) => {
    //     const path = `/user/challenges/${challengeId}`
    //     const method = 'POST';
    //     return await sendRequest(path, method);
    // };

    // const leaveChallenge = async (challengeId) => {
    //     const path = `/user/challenges/${challengeId}`
    //     const method = 'DELETE';
    //     return await sendRequest(path, method);
    // };

    const get = async () => {
        const path = '/user';
        return await sendRequest(path);
    }

    const getGoals = async () => {
        const path = '/user/goals';
        return await sendRequest(path);
    }

    const updateGoal = async (healthMetricId, value) => {
        const path = '/user/goals';
        const method = 'PUT';
        const body = { healthMetricId, value };
        return await sendRequest(path, method, body);
    }

    return {
        // joinChallenge,
        // leaveChallenge,
        get,
        goals: {
            get: getGoals,
            update: updateGoal
        }
    };
};
import React from "react";

function DataProtectionCheckbox(props) {
    return (
        <label for={props.name} className="c-switch u-mr-small">
            <input
                id={props.name}
                className="c-switch__input"
                type="checkbox"
                name={props.name}
                checked={props.value}
                onChange={props.handleInputChange} />
            <span className="c-switch__label">
                {props.label && `${props.label}:`}
            </span>
        </label>
    )
}

export default DataProtectionCheckbox
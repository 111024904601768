import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import dataStores from "../redux/reducers/dataStores";
import { beginUserSession } from "../redux/actions/userSession";
import { sendCommand } from "../redux/actions/commands";
import HeroOutlineSubmit from "../common/Buttons/OutlineSubmit";
import { createHeroApiClient } from '../hero-api-client';
import "./signup.css";
import CryptoJS from "crypto-js";

function SignUp(props) {
  const { regions, departments } = props;

  const [model, setModel] = useState({
    inviteCode: "",
    emailAddress: "",
    password: "",
    confirmPassword: "",
    givenName: "",
    familyName: "",
    department: '',
    region: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [acceptedTCs, setAcceptedTCs] = useState(false);
  const [contains11C, setContains11C] = useState(false);
  const [containsUC, setContainsUC] = useState(false);
  const [containsLC, setContainsLC] = useState(false);

  const companyKey = (window.location.host.split(".")[1] && window.location.host.split(".")[1] !== 'app')
    ? window.location.host.split(".")[0]
    : "app";

  const bodyClass = document.body;
  bodyClass.classList.add("company-" + companyKey);
  const showVodafoneRegionDepartmentFields = companyKey === 'vodafoneglobalchallenge';

  if (props.appConfig.companyKey != companyKey) {
    props.dispatch(sendCommand(
      dataStores.APP_CONFIG,
      { actionType: "GET", companyKey: companyKey }
    ));
  }

  const handleFormSumbit = async event => {
    event.preventDefault();

    if (!acceptedTCs) {
      return
    }

    if (showVodafoneRegionDepartmentFields && (!model.region || !model.department)) {
      return;
    }

    if (model.password !== model.confirmPassword) {
      return;
    }
    var x = Math.random().toString(36).substr(2, 6);
    var keyx = '?D(G+KbPeS' + x;

    var key = CryptoJS.enc.Utf8.parse(keyx);
    var iv = CryptoJS.enc.Utf8.parse(keyx);
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(model.password), key,
      {
          keySize: 128 / 8,
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
      });
    var message = encrypted.toString();
    var encCode = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(model.inviteCode), key,
    {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    }).toString();
    try {
      const request = {
        ...model,
        password:message,
        inviteCode:encCode,
        companyKey: companyKey,
        x:x
      };
      await props.heroApiClient.registerUser(request);
      const tokenResponse = await props.heroApiClient
        .getToken(model.emailAddress,x,message,companyKey);
      props.dispatch(beginUserSession(tokenResponse))
    } catch(error) {
      setErrorMessage(error.message);
    }
  };

  const handleInputChange = event => {
    setModel({
      ...model,
      [event.target.name]: event.target.value
    });

    if (model.password.length >= 10) {
      setContains11C(true);
    } else {
      setContains11C(false);
    }

    if (model.password.toLowerCase() != model.password) {
      setContainsUC(true);
    } else {
      setContainsUC(false);
    }

    if (model.password.toUpperCase() != model.password) {
      setContainsLC(true);
    } else {
      setContainsLC(false);
    }
  };

  const onAcceptedTCsChange = event => {
    setAcceptedTCs(event.target.checked);
  }

  if (props.user.session.isAuthenticated) {
    return <Redirect to={"/dashboard?signup=1"} />
  }

  if (!props.appConfig.styles) {
    return null;
  }

  return (
    <div>
      <div className="signUp">
        <div
          style={{
            backgroundRepeat: "no-repeat",
            background: `url(${
              props.appConfig.styles
                .logoSignUpBannerUrl
              })`,
            position: "relative"
          }}
          className="signUp__companyImg"
        >
        </div>

        <div
          className="signUp__loginWindow"
          style={{
            backgroundColor:
              props.appConfig.styles
                .loginMiniLogoBackgroundColor
          }}
        >
          <img
            src={props.appConfig.styles.logoSignUpLogoUrl}
            className="signUp__heroLogo"
          ></img>

          <form className="signUpWindow__form" onSubmit={handleFormSumbit}>
            {props.appConfig.styles.companyRegistrationMessage ?
              <div
                className="signUp__greeting"
                dangerouslySetInnerHTML={{ __html: props.appConfig.styles.companyRegistrationMessage }}
              ></div>
              :
              <p className="signUp__greeting">
                Lets get started,
                  <br /> to sign up, please complete the form below:
                </p>
            }

            {props.appConfig.inviteCodeRequired && (
              <Row>
                <Col className="signUp__col" xs="5">
                  Invite Code:
                </Col>
                <Col className="signUp__inputCol" xs="7">
                  <input
                    className="signUp__input"
                    placeholder="Invite Code"
                    name="inviteCode"
                    type="text"
                    value={model.inviteCode}
                    onChange={handleInputChange}
                  />
                </Col>
              </Row>
            )}

            <Row>
              <Col className="signUp__col" xs="5">
                First Name:
                </Col>
              <Col className="signUp__inputCol" xs="7">
                <input
                  className="signUp__input"
                  placeholder="First Name"
                  name="givenName"
                  type="text"
                  value={model.givenName}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>

            <Row>
              <Col className="signUp__col" xs="5">
                Last Name:
                </Col>
              <Col className="signUp__inputCol" xs="7">
                <input
                  className="signUp__input"
                  placeholder="Last Name"
                  name="familyName"
                  type="text"
                  value={model.familyName}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>

            <Row>
              <Col className="signUp__col" xs="5">
                Email:
                </Col>
              <Col className="signUp__inputCol" xs="7">
                <input
                  className="signUp__input"
                  placeholder="Enter email"
                  name="emailAddress"
                  type="email"
                  value={model.emailAddress}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>

            <Row>
              <Col className="signUp__col" xs="5">
                Password:
                </Col>
              <Col className="signUp__inputCol" xs="7">
                <input
                  className="signUp__input"
                  name="password"
                  type="Password"
                  placeholder="Password"
                  value={model.password}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>

            <Row>
              <Col className="signUp__col" xs="5"></Col>
              <Col className="signUp__col" xs="7">
                <ul class="pass-val-checker">
                  <li>at least 11 characters</li>
                  <li>at least one uppercase character</li>
                  <li>at least one lowercase character</li>
                  <li>at least one numeric character</li>
                  <li>at least one special character</li>
                </ul>
              </Col>
            </Row>

            <Row>
              <Col className="signUp__col" xs="5">
                Confirm Password:
                </Col>
              <Col className="signUp__inputCol" xs="7">
                <input
                  className="signUp__input"
                  name="confirmPassword"
                  type="Password"
                  placeholder="Confirm Password"
                  value={model.confirmPassword}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>

            {regions?.length ? (
              <Row>
                <Col className="signUp__col text-right" xs="5">
                  Market / Group Function:
                </Col>
                <Col className="signUp__inputCol" xs="7">
                  <select className="signUp__input" name="region" value={model.region} onChange={handleInputChange} required>
                    <option>Select a Market / Group Function</option>
                    {regions?.map(item => <option key={item}>{item}</option>)}
                  </select>
                </Col>
              </Row>
            ) : null}

            {showVodafoneRegionDepartmentFields && (
              <>
                <Row>
                  <Col className="signUp__col text-right" xs="5">
                    Market / Group Function:
                  </Col>
                  <Col className="signUp__inputCol" xs="7">
                    <select className="signUp__input" name="region" value={model.region} onChange={handleInputChange} required>
                      <option>Select a Market / Group Function</option>
                      {regions.map(reg => <option value={reg}>{reg}</option>)}
                    </select>
                  </Col>
                </Row>
                <Row>
                  <Col className="signUp__col" xs="5">
                    Department:
                  </Col>
                  <Col className="signUp__inputCol" xs="7">
                    <select className="signUp__input" name="department" value={model.department} onChange={handleInputChange} required>
                      <option>Select a department</option>
                      {departments.map(dep => <option value={dep}>{dep}</option>)}
                    </select>
                  </Col>
                </Row>
              </>
            )}

            <Row className="signUp__acceptTCs-row">
              <input type="checkbox" onChange={onAcceptedTCsChange} className="signUp__acceptTCs-checkbox" />
              <span className="signUp__acceptTCs">I accept the <a className="signUp__acceptTCs-link" href={require('../assets/pp-terms-final.pdf')} target={'_blank'}>T&amp;Cs and privacy policy</a></span>
            </Row>

            <div className="ac">
              <p className="signUp__error">{errorMessage}</p>
            </div>

            <div className="ac signUp__signupButton">
              <HeroOutlineSubmit
                size="large"
                value="Sign Up"
                onClick={handleFormSumbit}
                colour={'white'}
                disabled={!acceptedTCs}
              />
            </div>

            <Row>
              <Link className="signUp__loginLink" to={`/login`}>
                Or log in using an existing account
                </Link>
            </Row>
          </form>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              bottom: "0",
              width: "300px",
              marginTop: "30px"
            }}
          >
            <p style={{ color: "rgb(255, 255, 255)", fontSize: "0.8rem" }}>
              Powered by{" "}
              <a
                style={{ color: "rgb(255, 255, 255)", textDecoration: "none" }}
                href="https://herowellbeing.com/"
                target="_blank"
              >
                <img
                    src={require("../assets/logo/hero-logo.png")}

                  style={{
                    marginLeft: "0.25rem",
                    padding: "0px 0px 6px 0px",
                    height: "18px",
                    width: "auto",
                    fill: "black",
                    filter: "invert(0)"
                  }}
                ></img>
              </a>
                .
              </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    appConfig: (state.appObject[dataStores.APP_CONFIG] || {}),
    heroApiClient: createHeroApiClient(),
    regions: state.appObject[dataStores.APP_CONFIG].regions || [],
    departments: state.appObject[dataStores.APP_CONFIG].departments || [],
  };
}

export default connect(mapStateToProps)(SignUp);

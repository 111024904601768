import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as commandActions from "../../redux/actions/commands";
import dataStores from "../../redux/reducers/dataStores";
import { Row, Col, Card } from "react-bootstrap";

function DelegatedPermissions(props) {
  useEffect(() => {
    //Get all the users i have access too
    if (props.dataStores[dataStores.DELEGATED_PERMISSIONS].success == null) {
      props.commandActions.sendCommand(
        dataStores.DELEGATED_PERMISSIONS,
        { actionType: "GET" }
      );
    }
  }, [props.dataStores[dataStores.DELEGATED_PERMISSIONS]]);

  function LogInAsUser(target) {
    props.commandActions.sendCommand(
      dataStores.DELEGATED_PERMISSIONS,
      target
    );
  }

  function GetUsersIHavePermissionToView() {
    var people = [];
    if (
      props.dataStores[dataStores.DELEGATED_PERMISSIONS].success == true &&
      props.dataStores[dataStores.DELEGATED_PERMISSIONS].specialistsWithAccess
    ) {
      props.dataStores[
        dataStores.DELEGATED_PERMISSIONS
      ].specialistsWithAccess.map(person => {
        people.push(
          <Col md={3}>
            <Link onClick={() => LogInAsUser({
              actionType: "GET_ACCESS",
              delegatedUserId: props.user.profile.id,
              requestForAccessToUserId: person.userId,
              email: props.user.profile.emailAddress,
              userEmail: person.email
            })}>
              <Card key={person.userId}>
                <Card.Body className="text-center">
                  <img className="mb-2" src={person.imageUrl || `https://eu.ui-avatars.com/api?name=${person.email}`} style={{ height: "50px", width: "50px", borderRadius: "50%" }} />
                  <p>{person.firstName} {person.lastName}</p>
                  <span>{person.email}</span>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        );
      });
    }

    return people;
  }

  return props.dataStores[dataStores.DELEGATED_PERMISSIONS]
    .specialistsWithAccess &&
    props.dataStores[dataStores.DELEGATED_PERMISSIONS].specialistsWithAccess
      .length > 0 ? (
      <div className="mb-3 mt-3">
        <h3>Coach Access</h3>
        <p className="mb-2">Below is a list of users you have access to.</p>
        <Row>
          {GetUsersIHavePermissionToView()}
        </Row>
      </div>
    ) : (
      <></>
    );
}
function mapStateToProps(state) {
  return {
    coachToken: state.appObject[dataStores.DELEGATED_PERMISSIONS].coachToken,
    AccessId: state.appObject[dataStores.DELEGATED_PERMISSIONS].uid,
    appConfig: state.appConfig,
    feed: state.feed,
    dataStores: state.appObject,
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    commandActions: bindActionCreators(commandActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DelegatedPermissions);

import { REQUEST_COMMAND_RESPONSE, RECEIVE_COMMAND_RESPONSE } from '../actions/commands'
import { END_USER_SESSION, CLEAR_DELEGATED_PERMISSIONS } from '../actions/userSession'
import dataStores from "./dataStores";

function initialState() {
  return Object.keys(dataStores).reduce((acc, cur) => ({
    ...acc,
    [cur]: {}
  }), {
    '.oustandingRequests': new Set([])
  });
}

export default function commands(
  state = initialState(),
  action
) {
  switch (action.type) {
    case REQUEST_COMMAND_RESPONSE:
      return {
        ...state,
        '.oustandingRequests': state['.oustandingRequests'].add(action.payload.requestKey)
      }

    case RECEIVE_COMMAND_RESPONSE:
      if (action.payload) {
        const oustandingRequests = new Set(state['.oustandingRequests']);
        oustandingRequests.delete(action.payload.requestKey)

        return {
          ...state,
          [action.payload.commandResponse.objectType]: action.payload.commandResponse.objectData,
          '.oustandingRequests': oustandingRequests
        };
      }
      return state;

    case END_USER_SESSION:
      return initialState();

    case CLEAR_DELEGATED_PERMISSIONS:
      return {
        ...state,
        [dataStores.DELEGATED_PERMISSIONS]: { ...state[dataStores.DELEGATED_PERMISSIONS], coachToken: null, uid: null }
      }

    default:
      return state;
  }
}

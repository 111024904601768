export const BEGIN_USER_SESSION = 'BEGIN_USER_SESSION'
export function beginUserSession(tokenResponse) {
    window.localStorage.setItem("_tokenResponse", JSON.stringify(tokenResponse))
    return {
        type: BEGIN_USER_SESSION,
        payload: tokenResponse
    }
}

export const END_USER_SESSION = 'END_USER_SESSION'
export function endUserSession() {
    window.localStorage.removeItem("_tokenResponse")
    return {
        type: END_USER_SESSION
    }
}

export const CLEAR_DELEGATED_PERMISSIONS = 'CLEAR_DELEGATED_PERMISSIONS'
export function clearDelegatedPermissions() {
    return {
        type: CLEAR_DELEGATED_PERMISSIONS
    }
}

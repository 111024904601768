import { REQUEST_GROUP_USER, RECEIVE_GROUP_USER, INVALIDATE_GROUP_USER } from '../actions/groups'
import { END_USER_SESSION } from '../actions/userSession'

const initialState = {
    isFetching: false,
    isValid: false,
    items: {}
}

export default function groupUser(state = initialState, action) {
    switch (action.type) {
        case REQUEST_GROUP_USER:
            return {
                ...state,
                isFetching: true,
                isValid: true
            }
        case RECEIVE_GROUP_USER:
            const items = { ...state.items, [action.payload.groupUser.groupId]: action.payload.groupUser };
            return {
                ...state,
                isFetching: false,
                isValid: true,
                items,
            }
        case INVALIDATE_GROUP_USER:
            return {
                ...state,
                isValid: false
            }
        case END_USER_SESSION:
            return initialState
        default:
            return state
    }
}
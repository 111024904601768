import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import {fetchArticle, fetchArticles} from '../hero-api-client/ArticleContent';
import HeadImage  from '../components/articles/HeadImage';
import ArticleSegment from '../components/articles/ArticleSegment';
import SimilarArticles from '../components/articles/SimilarArticles';


function Article({ article, articles}) {
    const { id } = useParams(); // get the article ID from the URL
    const dispatch = useDispatch();
    const [loadedArticle, setLoadedArticle] = useState(false); 
    const [pageArticle, setPageArticle] = useState({});

    const findArticle = (id) => {
        if (articles.length === 0) dispatch(fetchArticles()); // fetch the articles list from the server
        if(article.id === id){
            setPageArticle(article);
            setLoadedArticle(true);
            return;
        }
        else if(articles.length > 0){
            const article = articles.find(x => x.id === id);
            if(Object.keys(article).length > 0){
                setPageArticle(article);
                setLoadedArticle(true);
                return;
            }
            
        }
        dispatch(fetchArticle(id)); // fetch the article data from the server using the ID
    };
    
    useEffect(() => {
        window.scrollTo({
            top: 250,
            behavior: 'smooth',
          });
        setLoadedArticle(false);
        findArticle(id);
    }, [id]);
   

    useEffect(() => {
    
        if(!loadedArticle){
          findArticle(id);
        }
       
    }, [dispatch,id, article, articles]);

    return (
        <div className="mt-10 mb-10 overflow-auto pr-3">
        {pageArticle && pageArticle.id &&
        <>
        <HeadImage article={pageArticle} />
        <div>
        {pageArticle?.segments?.map((segment, index) => {
          return (
            <ArticleSegment key={'test' + segment.id} article={segment} />
         )
         })}
        </div>
        </>}
        {(articles?.length && pageArticle.tags?.length > 0)?(
            <SimilarArticles excludeId={pageArticle.id} articles={articles} tags={pageArticle.tags} />
        ):null
         
        }
      </div>
    );
}


function mapStateToProps(state) {
    return {
        article: state.articleContent.article,
        articles: state.articleContent.articles
    };
}

export default connect(mapStateToProps)(Article);

import React from "react";
export const segmentTypes = [
    "Header",
    "Subheader",
    "Paragraph",
    "Image",
    "Video",
    "List",
    "Quote",
    "Link",
    "Button",
    "CustomButton",
    "Iframe",
  ];
  
  const markupConverter = (markup) => {
    if(!markup) return;
    const boldMatches = markup.match(boldRegex);
    //wrap any html tags in code tags
    // markup = markup.replace(/<[^>]*>?/gm, (tag) => {
    //   return `<code>${tag}</code>`;
    // });
    markup = markup.replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/\n/g, '<br>');
    if (boldMatches) {
      boldMatches.forEach((match) => {
        const boldText = match.substring(2, match.length - 2);
        markup = markup.replace(match, `<strong class='bold'>${boldText}</strong>`);
      });
    }
    const italicMatches = markup.match(italicRegex);
    if (italicMatches) {
      italicMatches.forEach((match) => {
        const italicText = match.substring(2, match.length - 2);
        // Apply italic styling to italicText
        // Example: Replace _italic text_ with <em>italic text</em>
        markup = markup.replace(match, `<span class='italic'>${italicText}</span>`);
      });
    }
    const linkMatches = markup.match(linkRegex);
    if (linkMatches) {
      linkMatches.forEach((match) => {
        const linkText = match.match(/\[([^\]]+)\]/)[1];
        const linkUrl = match.match(/\(([^)]+)\)/)[1];
        markup = markup.replace(
          match,
          `<a class="text-blue-500 underline" href="${linkUrl}">${linkText}</a>`
        );
      });
    }
    return markup;
  };
  
    export const boldRegex = /\*\*([^\*]+)\*\*/g; // Matches **bold text**
    const italicRegex = /__([^_]+)__/g; // Matches __text__
    export const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g; // Matches [link text](URL)
  
  
    
  const PreviewSegmentWrapper = ({children, id, ...rest}) => {
    return (
      <div id={id} key={id} style={{maxWidth:'100%',paddingRight:30, marginTop:10, marginBottom:10}}  className="articles container mx-auto my-3 ml-10" {...rest}>
        {children}
      </div>
    );
  };
  
  export const HeaderSegment = ({ article, sub=false }) => {
    return (
      <PreviewSegmentWrapper id={'segment' + article.id}>
        {sub?<h5 className="mb-4">{article.content}</h5>:
        <h2 className={"mb-4"}>{article.content}</h2>}
        
      </PreviewSegmentWrapper>
    );
  };
  
  export const ParagraphSegment = ({ article }) => {
    return (
      <PreviewSegmentWrapper id={'segment' + article.id}>
        <p style={{textAlign: 'justified'}} dangerouslySetInnerHTML={{ __html: markupConverter(article.content) }} />
      </PreviewSegmentWrapper>
    );
  };
  
  export const ImageSegment = ({ article }) => {
    return (
      <PreviewSegmentWrapper id={'segment' + article.id}>
        <img src={article.content} alt={article.content} />
      </PreviewSegmentWrapper>
    );
  };
  
  export const VideoSegment = ({ article }) => {
    return (
      <PreviewSegmentWrapper id={'segment' + article.id}>
        <video style={{width:'100%'}} src={article.content} controls />
      </PreviewSegmentWrapper>
    );
  };
  
  export const ListSegment = ({ article }) => {
    return (
      <PreviewSegmentWrapper id={'segment' + article.id}>
        <ul className="list-disc ml-10">
          {article?.content?.length && article.content.map((item) => (
            <li><div dangerouslySetInnerHTML={{__html:markupConverter(item)}}/></li>
          ))}
        </ul>
      </PreviewSegmentWrapper>
    );
  };
  
  export const QuoteSegment = ({ article }) => {
    return (
      <PreviewSegmentWrapper className="text-center w-100" id={'segment' + article.id}>
        <div className="bold">
          <blockquote>"{article.content}"</blockquote>
        </div>
        <p className="inline-block" style={{ fontFamily: "TTNormsPro-bold" }}>
          {article.content2}
        </p>
      </PreviewSegmentWrapper>
    );
  };
  
  export const LinkSegment = ({ article }) => {
    return (
      <PreviewSegmentWrapper className="text-center w-100" id={'segment' + article.id}>
       <div className="flex justify-center">
        <a className="underline" href={article.content}>
          {article.content2}
        </a>
          </div>
      </PreviewSegmentWrapper>
    );
  };
  
  export const ButtonSegment = ({ article }) => {
    const className = "py-2 px-10 rounded-full";
  
    return (
      <PreviewSegmentWrapper className="text-center w-100" id={'segment' + article.id}>
        <div className="flex justify-between">
          <div>
          {article.content?.length && article.content.map((item) => {
            var customClassName =
              className +
              (item.style === "dark"
                ? " bg-black text-white"
                : " bg-white text-black border-black border");
            return (
              <a href={item.link}>
                <button className={item.style}>{item.text}</button>
              </a>
            );
          })}
          </div>
        </div>
  
      </PreviewSegmentWrapper>
    );
  };
  export const CustomButtonSegment = ({ article }) => {
      
  
      return (
        <PreviewSegmentWrapper className="text-center w-100" id={'segment' + article.id}>
          <div className="flex justify-between mx-auto">
            <div/>
            {article.content?.length && article.content.map((item) => {
              return (
                <a href={item.link}>
                  <button className={item.style==='dark'?'light':'dark'} style={{backgroundColor:item.color}}>{item.text}</button>
                </a>
              );
            })}
            <div/>
          </div>
    
        </PreviewSegmentWrapper>
      );
  };
  const IframeSegment = ({ article }) => {
    return (
      <PreviewSegmentWrapper className="text-center w-100" id={'segment' + article.id}>
        <iframe className="text-center w-100 h-100" style={{minHeight:window.innerHeight}} src={article.content}/>
      </PreviewSegmentWrapper>
    );
  };
  
  const ArticleSegment = ({ article }) => {
    switch (article.type) {
      case "Header":
        return <HeaderSegment key={'header' + article.id} article={article} />;
      case "Subheader":
        return <HeaderSegment key={'header' + article.id} sub article={article} />;
      case "Paragraph":
        return <ParagraphSegment key={'paragraph' + article.id} article={article} />;
      case "Image":
        return <ImageSegment key={'image' + article.id} article={article} />;
      case "Video":
        return <VideoSegment key={'Video' + article.id} article={article} />;
      case "List":
        return <ListSegment key={'List' + article.id} article={article} />;
      case "Quote":
        return <QuoteSegment key={'Quote' + article.id} article={article} />;
      case "Link":
        return <LinkSegment key={'Link' + article.id} article={article} />;
      case "Button":
        return <ButtonSegment key={'Button' + article.id} article={article} />;
      case "CustomButton":
          return <CustomButtonSegment key={'CustomButtomSegment' + article.id} article={article} />;
      case "Iframe":
        return <IframeSegment key={'Iframe' + article.id} article={article} />;
      default:
        return <></>;
    }
  };
  
  export default ArticleSegment;
  
import React, { useEffect, useState } from "react";

// Redux rework
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//Redux Actions
import * as commandActions from "../redux/actions/commands";
import dataStores from "../redux/reducers/dataStores";



import HeroOutlineButton from '../common/Buttons/OutlineButton';

// BOOTSTRAP STUFF
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";



function UserManagementAccess(props) {
  const [claims, setClaims] = useState(props.user.claims);


  /* useEffect(() => {
    if(props.users == null || props.users.length == 0)
      props.commandActions.sendCommand(
        dataStores.USER_MANAGEMENT,
        {
          companyId: props.companyId
        }
      );
  })
  
  function LoadData(){
    props.commandActions.sendCommand(
      dataStores.USER_MANAGEMENT,
      {
        companyId: props.companyId
      }
    );
  } */

  function RemovePermission(userId, claimName, permissionItem){
    const myRequest = new Request(process.env.REACT_APP_API_BASE_URL  + "/managementclaims/permission/remove", {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        "hero-token": props.heroToken
      },
      body: JSON.stringify({"userId": userId, "claimName": claimName, "permissionItem": permissionItem})
    });

    fetch(myRequest)
    .then((response) => {
      return response.json()
    })
    .then((data) => {
      setClaims(data) 
      props.LoadData()
    })
  }

  function AddPermission(userId, claimName, permissionItem){
    const myRequest = new Request(process.env.REACT_APP_API_BASE_URL  + "/managementclaims/permission/add", {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        "hero-token": props.heroToken
      },
      body: JSON.stringify({"userId": userId, "claimName": claimName, "permissionItem": permissionItem})
    });

    fetch(myRequest)
    .then((response) => {
      return response.json()
    })
    .then((data) => {
      setClaims(data) 
      props.LoadData();
    })
  }

  function BuildList(){
    var users = []
    if(claims != null){
      var claimValues = claims[props.claimName].replace(/[\"\[\]]/g,"").split(",")
      if(props.users){
        props.users.forEach(user => {
        users.push(
          <div>
            <Row>
              <Col xs={3}>
                <label>{user.name}</label>
              </Col>
              {props.user ? 
                <Col xs={4}>
                  {claimValues.includes(user.userId) ? <HeroOutlineButton
                    colour="#21b4f1"
                    classes="card-link"
                    styles={{margin: "0rem 0.5rem"}}
                    text={  "Remove Permission" }
                    onClick = {() => RemovePermission(props.user.userId, props.claimName, user.userId)}
                    size="xlarge" />
                  : <HeroOutlineButton
                    colour="#21b4f1"
                    classes="card-link"
                    styles={{margin: "0rem 0.5rem"}}
                    text={  "Add Permission" }
                    onClick = {() => AddPermission(props.user.userId, props.claimName, user.userId)}
                    size="xlarge" />}
                </Col>
                :<Col xs={3}></Col>}
            </Row>
            <hr/>
          </div>
        )
        });
      }
      return users
    }
  }

  return (
    <Container>
      <h1 className="page__title">Set Access</h1>
      {BuildList()}
    </Container>
  );
}

function mapStateToProps(state) {
  return {
    styles: state.appObject[dataStores.APP_CONFIG].styles,
    companyId: state.appObject[dataStores.APP_CONFIG].companyId,
  };
}

export default connect(mapStateToProps)(UserManagementAccess);
